import React from 'react'
import { Link } from 'react-router-dom'
import AccordianData from '../AccordianData'
import DefaultLayout from '../DefaultLayout'

const CreateCustomer = ({handleActiveTab}) => {
    const head = {
        "Content-Type": "application/json",
        "Authorization": "Basic keyinfo=="
    }
    const body = {
        "merchant_key": "(merchant_key)",
        "first_name": "Abc",
        "last_name": "DEF",
        "email": "de12345f@gmail.com",
        "phone_number": "7898789878"
    }

    const headerData = {
        heading: 'Customers',
        subHeading: 'Create Customer',
        method: 'POST',
        api: ['customers', 'add'],
    }


    const backNavigate = {
        title: "Void Sale",
        url: "/docs/api/void-sale"
    }
    const forwardNavigate = {
        title: "Get Customer",
        url: "/docs/api/get-customer"
    }

  return (
      <>
          <DefaultLayout headerData={headerData} head={head} body={body} backNavigate={backNavigate} forwardNavigate={forwardNavigate} handleActiveTab={handleActiveTab} />
          <div
              className="hidden xl:flex self-start sticky h-[calc(100vh-8rem-1rem)] top-32"
              id="content-side-layout"
          >
              <div className="w-[28rem] gap-6 grid grid-rows-[repeat(auto-fit,minmax(0,min-content))] grid-rows">
                  <div className="not-prose relative overflow-hidden bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50">
                      <div
                          className="flex h-10 text-xs leading-6 border-b bg-black/40 rounded-t-xl border-gray-900/80"
                          role="tablist"
                          aria-orientation="horizontal"
                      >
                          <div className="flex overflow-x-auto">
                              <button
                                  className="group flex items-center relative px-2 pt-2.5 pb-2 text-gray-400 outline-none whitespace-nowrap font-medium text-primary-light"
                                  id="headlessui-tabs-tab-:r22:"
                                  style={{
                                      background: 'none',
                                      border: 0,
                                      borderBottom: '2px #A66FF8 solid'
                                  }}
                                  role="tab"
                                  type="button"
                                  aria-selected="true"
                                  tabIndex={0}
                                  data-headlessui-state="selected"
                                  aria-controls="headlessui-tabs-panel-:r23:"
                              >
                                  <div className="px-2 rounded-md">
                                      <div className="z-10">200</div>
                                  </div>
                                  <div className="absolute inset-0 border-b pointer-events-none border-primary-light" />
                              </button>
                          </div>
                          <div className="flex-auto flex justify-end items-center pr-4 rounded-tr">
                              <div className="group z-10 relative">
                                  <button className="h-7 w-7 flex items-center justify-center rounded-md" style={{
                                      background: 'none',
                                      border: 0
                                  }}>
                                      <svg
                                          className="fill-gray-700 group-hover:fill-gray-400"
                                          width={16}
                                          height={16}
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                                      </svg>
                                  </button>
                                  <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                                      Copy
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="flex max-h-[calc(100%-40px)]">
                          <div
                              className="flex-none text-gray-50 p-5 min-w-full text-sm overflow-x-auto scrollbar-thumb-white/25 dark:scrollbar-thumb-white/10 w-full scrollbar-track-transparent scrollbar-thin scrollbar-thumb-rounded text-xs leading-[1.35rem]"
                              id="headlessui-tabs-panel-:r23:"
                              role="tabpanel"
                              tabIndex={0}
                              data-headlessui-state="selected"
                              style={{ fontVariantLigatures: "none" }}
                              aria-labelledby="headlessui-tabs-tab-:r22:"
                          >
                              <pre>
                                  <code>
                                      <span className="token punctuation">{"{"}</span>
                                      {"\n"}
                                      {"  "}
                                      <span className="token property">"data"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token punctuation">{"{"}</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"customer_id"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">
                                          "0329ed49-28dd-11ef-af4e-0ef0812e6179"
                                      </span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"customerEmail"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"sally@software.com"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"customerPhone"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"9987654321"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"firstName"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"Sally"</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"    "}
                                      <span className="token property">"lastName"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">"Sanders"</span>
                                      {"\n"}
                                      {"  "}
                                      <span className="token punctuation">{"}"}</span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"  "}
                                      <span className="token property">"message"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token string">
                                          "Customer added successfully"
                                      </span>
                                      <span className="token punctuation">,</span>
                                      {"\n"}
                                      {"  "}
                                      <span className="token property">"result"</span>
                                      <span className="token operator">:</span>{" "}
                                      <span className="token boolean">true</span>
                                      {"\n"}
                                      <span className="token punctuation">{"}"}</span>
                                  </code>
                              </pre>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>

  )
}

export default CreateCustomer