import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToken } from "../../../hooks/useToken";
import { toast } from "react-toastify";
import baseUrl from "../../../baseUrl";
import Loader from "../../../DefaultComponent/Loader";
import Pagination from "../../../DefaultComponent/PaginationPg.js";
import Payment from "../Tabs/Payment.js";
import { Table } from "react-bootstrap";
import BreadCrumbs from "../../../components/Breadcrumbs.js";
import "./Customer.css";
import { debounce } from "lodash";
import View from "../../../assets/images/View.svg";

const CustomerTable = () => {
  const token = useToken();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const [customer, setCustomer] = useState([]);
  const [state, setstate] = useState({
    page: 1,
    perPage: 10,
    totalPage: null,
    searchParam: "",
    message: "",
    loading: false,
  });

  const toggleDropdown = (id) => {
    navigate(`/customers/${id}`);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 10000);
  }, [showDropdown]);
  const fetchCustomers = async (pageNo, perPageNo, searchValue) => {
    try {
      setstate((prev) => ({
        ...prev,
        loading: true,
      }));
      const params = {
        page: pageNo,
        perPage: perPageNo,
      };
      if (searchValue) {
        params.search = searchValue;
      }
      const { data } = await axios.post(
        `${baseUrl}/api/v1/admin/admin-customer/get-all-customer`,
        params,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setstate((prev) => ({
        ...prev,
        message: data?.message,
      }));
      if (data?.data?.allList?.length) {
        setCustomer(data?.data?.allList);
        setstate((prev) => ({
          ...prev,
          totalPage: data?.data?.totalPage,
        }));
      }
      setstate((prev) => ({
        ...prev,
        loading: false,
      }));
    } catch (error) {
      setstate((prev) => ({
        ...prev,
        loading: false,
      }));
      toast.error(error?.response.data.message);
      if (error.response.data.message.includes("Unauthorized")) {
        navigate("/login");
        localStorage.removeItem("user-data");
      }
      console.log(error);
    }
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${month}-${day}-${year}`;
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setCustomer([]);
    setstate((prev) => ({
      ...prev,
      searchParam: searchValue,
      loading: true,
      totalPage: null,
    }));
    handleSearch(searchValue);
    // fetchCustomers(state.page, state.perPage, searchValue);
  };

  const handlePageChange = (pageNumber) => {
    setCustomer([]);
    setstate((prev) => ({
      ...prev,
      page: pageNumber,
      loading: true,
      totalPage: null,
    }));
    fetchCustomers(pageNumber, state.perPage, state.searchParam);
  };

  const handlePerPageChange = (e) => {
    const perPageNo = e.target.value;
    setCustomer([]);
    setstate((prev) => ({
      ...prev,
      perPage: perPageNo,
      loading: true,
      totalPage: null,
    }));
    fetchCustomers(state.page, perPageNo, state.searchParam);
  };

  useEffect(() => {
    fetchCustomers(state.page, state.perPage, state.searchParam);
  }, []);

  const breadcrumbItems = [
    { label: "Dashboard", href: "/", active: false },
    { label: "Customers", href: "/customers", active: true },
  ];

  const handleSearch = useCallback(
    debounce((searchQuery) => {
      fetchCustomers(state.page, state.perPage, searchQuery);
    }, 700),
    []
  );
  return (
    <>
      <Loader loading={state.loading} />

      <div className="container-xl">
        {/* <BreadCrumbs items={breadcrumbItems} /> */}

        <div className=" row">
          <div className="col-sm-6 col-12 ">
            <p className="heading">Customers</p>
          </div>

          {/* <div className="search-container col-sm-6 col-12 d-flex justify-content-end">
            <input
              className="searchbox"
              type="text"
              placeholder="Search..."
              name="search"
              onChange={handleSearchChange}
              value={state.searchParam}
            />
          </div> */}
        </div>

        <div className="tab-content" id="orders-table-tab-content">
          <div
            className="tab-pane fade show active"
            id="orders-all"
            role="tabpanel"
            aria-labelledby="orders-all-tab"
          >
            <div className="app-card app-card-orders-table shadow-sm mb-5">
              {/* <div
                style={{
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <label>Per Page: </label>
                  <select
                    name="page"
                    id="page"
                    value={state.perPage}
                    onChange={handlePerPageChange}
                  >
                    <option value="5">5</option>
                    <option value="10" selected>
                      10
                    </option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>
              </div> */}
              <div className="app-card-body" style={{ padding: 3 }}>
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th>Merchant Name</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Created Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customer && customer.length > 0 ? (
                        customer?.map((item, ind) => {
                          return (
                            <tr
                              className="table-hover cursor-pointer"
                              key={ind}
                            >
                              <td>{item?.Merchant_detail?.bussiness_name}</td>
                              <td>
                                {item.FirstName} {item.LastName}
                              </td>
                              <td>
                                <span className="truncate">{item.Email}</span>
                              </td>
                              <td>{item.Phone}</td>
                              <td>
                                {item.create_date
                                  ? formatDate(item.create_date)
                                  : ""}
                              </td>
                              <td align="center">
                                <div className="action-column button-hover">
                                  {/* <i
                                    className="fa-solid fa-eye cursor-pointer"
                                    onClick={() =>
                                      toggleDropdown(item.CustomerId)
                                    }
                                  ></i> */}
                                  <img
                                    src={View}
                                    alt="View"
                                    onClick={() => toggleDropdown(item.CustomerId)}
                                  />
                                  {/* {showDropdown[item.CustomerId] && (
                                    <div className="action-dropdown">
                                      <div>
                                        <button
                                          style={{
                                            border: "none",
                                            color: "green",
                                            background: "none",
                                          }}
                                          onClick={() =>
                                            handleViewDetails(item.CustomerId)
                                          }
                                        >
                                          View Details
                                        </button>
                                      </div>
                                    </div>
                                  )} */}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="6" style={{ textAlign: "center" }}>
                            No customer available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div>
                  {state.totalPage && (
                    <Pagination
                      totalPages={state.totalPage}
                      onPageChange={handlePageChange}
                      current={state.page}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Payment /> */}
    </>
  );
};

export default CustomerTable;
