import React from 'react'
import hero2 from "../../asset/hero2.png"
import { Link } from 'react-router-dom';

const ImplementationGuide = ({setActiveTab}) => {
    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    };
    return (    
        <>
            <div
                className="relative grow overflow-hidden mx-auto  lg:-ml-12 lg:pl-[23.7rem]"
                id="content-area"
            >
                <header id="header" className="relative">
                    <div className="mt-0.5 space-y-2.5">
                        <div className="eyebrow h-5 text-primary dark:text-primary-light text-sm font-semibold">
                            API Documentation
                        </div>
                         {/* <div className="flex items-center">
                            <h1 className="inline-block text-2xl sm:text-3xl font-extrabold text-gray-900 tracking-tight dark:text-gray-200">
                                Implementation Guide
                            </h1>
                        </div> */}
                    </div>
                    {/* <div className="mt-2 text-lg prose prose-gray dark:prose-invert">
                        <p>Integrations simplified</p>
                    </div>  */}
                </header>
                <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-6 xl:hidden" />
                </div>
                <div className="relative mt-8 prose prose-gray dark:prose-invert">
                    <h1
                        className="flex whitespace-pre-wrap group text-2xl sm:text-3xl mt-8"
                        id="implementation-guide"
                    >
                        <div className="absolute">
                            <a
                              
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Implementation Guide</span>
                    </h1>
                    {/* <p>
                        Information for partners who will integrate Fractal’s features into their
                        apps.
                    </p> */}
                    <p>
                        This guide provides partners with all the information to set up, test, and
                        launch Fractal integration as part of their application. This process
                        includes four main phases:
                    </p>
                    <ol>
                        <li>
                            <a >
                                Creating an SOW
                            </a>
                            - Work with Fractal to create a Scope of Work describing your
                            integration and identifying requirements across the Fractal Platform.
                        </li>
                        <li>
                            <a >
                                Sandbox Setup
                            </a>{" "}
                            - In the Fractal Sandbox environment, you will define the settings and
                            workflows utilized for your
                            integration.
                            <ul>
                                <li>Merchant Boarding</li>
                                <li>Payment Acceptance</li>
                                <li>Fees and Billing</li>
                                <li>Funding</li>
                            </ul>
                        </li>
                        <li>
                            <a >
                                Testing and Migration
                            </a>{" "}
                            - Once your sandbox application is set up, Fractal will help to test and
                            migrate your implementation to the Fractal Production environment.
                        </li>
                        <li>
                            <a >
                                Merchant Support Features
                            </a>{" "}
                            - Enabling Fractal Merchant Support Features gives your merchants the
                            ability to manage disputes and run reports.
                        </li>
                    </ol>
                    <p>
                        The diagram below illustrates how the Payments Integration and Support
                        Features work together to provide the benefits of the Fractal platform:
                    </p>
                    <p>
                        <span aria-owns="rmiz-modal-68b69aae73f1" data-rmiz="">
                            <span data-rmiz-content="found" style={{ visibility: "visible" }}>
                                <img
                                    src={hero2}
                                    alt="Fractal Payment Workflow"
                                />
                            </span>
                            <span
                                data-rmiz-ghost=""
                                style={{ height: 577, left: 0, width: 721, top: 0 }}
                            >
                                <button
                                    aria-label="Expand image: Fractal Payment Workflow"
                                    data-rmiz-btn-zoom=""
                                    type="button"
                                >
                                    <svg
                                        aria-hidden="true"
                                        data-rmiz-btn-zoom-icon="true"
                                        fill="currentColor"
                                        focusable="false"
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M 9 1 L 9 2 L 12.292969 2 L 2 12.292969 L 2 9 L 1 9 L 1 14 L 6 14 L 6 13 L 2.707031 13 L 13 2.707031 L 13 6 L 14 6 L 14 1 Z"></path>
                                    </svg>
                                </button>
                            </span>
                        </span>
                    </p>
                    <hr />
                    <h1
                        className="flex whitespace-pre-wrap group text-2xl sm:text-3xl mt-8"
                        id="scope-of-work-creation"
                    >
                        <div className="absolute">
                            <a
                               
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Scope Of Work Creation</span>
                    </h1>
                    <p>
                        This resource outlines the Scope of Work requirements pre-Implementation.
                    </p>
                    <p>
                        All integrating Partners and software platforms work with the Fractal
                        Solution Engineers and Sales Team to create a Scope of Work that describes
                        what your integration will look like and the associated requirements
                        across the Fractal Platform.
                    </p>
                    <h2 className="flex whitespace-pre-wrap group" id="table-of-contents">
                        <div className="absolute">
                            <a
                               
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Table of Contents</span>
                    </h2>
                    <ul>
                        <li>
                            <ol>
                                <li>
                                    <a >
                                        Overview
                                    </a>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <ol start={2}>
                                <li>
                                    <a >
                                        Merchant Onboarding Details
                                    </a>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <ol start={3}>
                                <li>
                                    <a >
                                        Payment Workflow
                                    </a>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <ol start={4}>
                                <li>
                                    <a >
                                        Risk and Compliance
                                    </a>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <ol start={5}>
                                <li>
                                    <a >
                                        Funding/Disbursement
                                    </a>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <ol start={6}>
                                <li>
                                    <a >
                                        Partner Launch Timeline
                                    </a>
                                </li>
                            </ol>
                        </li>
                    </ul>
                    <h2 className="flex whitespace-pre-wrap group" id="overview">
                        <div className="absolute">
                            <a
                               
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Overview</span>
                    </h2>
                    <p>
                        When building a Scope of Work we discuss features, assess risks, 
                        and gather data on how clients plan to use the Fractal Platform for the 4 merchant support 
                        features related to payment facilitation.
                    </p>
                    <ul>
                        <li>Merchant Boarding</li>
                        <li>Payment Acceptance</li>
                        <li>Fees, Billing, and Funding - tbd</li>
                        <li>Merchant Support - tbd</li>
                    </ul>
                    <h2
                        className="flex whitespace-pre-wrap group"
                        id="merchant-onboarding-details"
                    >
                        <div className="absolute">
                            <a
                               
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Merchant Onboarding Details</span>
                    </h2>
                    <h3 className="flex whitespace-pre-wrap group" id="boarding-integration">
                        <div className="absolute">
                            <a
                                
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Boarding Integration</span>
                    </h3>
                    <p>
                        Select your Merchant Boarding Integration. See the onboarding Integration
                        Options table for an explanation of each option.
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Boarding Integration Options</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Portal - TBD</td>
                                <td>Clients will be boarded via the Fractal portal.</td>
                            </tr>
                            <tr>
                                <td>
                                    <a
                                        
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Signup Form
                                    </a>
                                </td>
                                <td>Merchants will be boarded via an online signup form.</td>
                            </tr>
                            <tr>
                                <td>
                                    <a >
                                        Full API Boarding
                                    </a>
                                </td>
                                <td>
                                    The Full Boarding API is a managed service allowing you complete
                                    flexibility in the creation of new merchant locations.
                                </td>
                            </tr>
                            <tr>
                                <td>Bulk</td>
                                <td>
                                    Fractal will work with you to meet the necessary technical and
                                    underwriting requirements for bulk onboarding, simplifying the
                                    process of migrating your existing Merchants to Fractal from another
                                    platform.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a
                                        
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Onboarding Webhooks
                                    </a>
                                </td>
                                <td>
                                    The onboarding webhooks provide you complete awareness around the
                                    merchant onboarding process.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2 className="flex whitespace-pre-wrap group" id="payment-workflow">
                        <div className="absolute">
                            <a
                                
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Payment Workflow</span>
                    </h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>User Story Example</th>
                                <th>Payment Methods</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Please describe your ideal payment workflow:</td>
                                <td>
                                    <strong>Steps:</strong> <br/>1. Customer visits your website, selects
                                    products, clicks “pay now”.
                                    <br/>2. The customer enters payment
                                    information to pay for the product.
                                    <br/>3. Payment is approved, and the
                                    customer is given the option to save card details for future
                                    payments.
                                    <br/>4. The product is dispatched.
                                </td>
                                <td>
                                    - Tokenization &amp; Recurring Payments 
                                    <br/>- Direct Portal Payments &amp; Quick Charge
                                    <br/>- Bridge Payments Custom Portal
                                    <br/>- Direct API Payments
                                    <br/>- Card Present Payments
                                    <br/>- eCommerce &amp; Online Payments
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <tr>
                                <th>Payment Integration Options</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <a
                                        
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Widgets
                                    </a>
                                </td>
                                <td>
                                    A suite of link and payment widgets that can be seamlessly embedded
                                    into your application.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a
                                        
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Links
                                    </a>
                                </td>
                                <td>
                                    An iframe solution where the payment capture process will occur
                                    either within the iFrame or on a hosted page.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a
                                        
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Fractal JS
                                    </a>
                                </td>
                                <td>
                                    An embeddable JavaScript tokenizer that encrypts card data, ensuring
                                    PCI compliance for your application.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a >
                                        Wallet
                                    </a>
                                </td>
                                <td>
                                    Provides merchants with a collection of card-on-file and
                                    billing-on-file solutions via API.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a
                                        
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Charges Webhooks
                                    </a>
                                </td>
                                <td>
                                    A feature that allows the platform to receive real-time
                                    notifications when charges are captured on our hosted solutions.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2 className="flex whitespace-pre-wrap group" id="risk-and-compliance">
                        <div className="absolute">
                            <a
                                
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Risk and Compliance</span>
                    </h2>
                    <h3
                        className="flex whitespace-pre-wrap group"
                        id="disputes-and-chargebacks"
                    >
                        <div className="absolute">
                            <a
                                
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Disputes and Chargebacks</span>
                    </h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Portal Only</td>
                                <td>Disputes are managed via the portal</td>
                            </tr>
                            <tr>
                                <td>API Integration chargeback documents/responses</td>
                                <td>Disputes are managed by Partner/Client via API</td>
                            </tr>
                        </tbody>
                    </table>
                    <h2 className="flex whitespace-pre-wrap group" id="funding">
                        <div className="absolute">
                            <a
                                
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Funding</span>
                    </h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Fractal Only</td>
                                <td>Funding is managed via Fractal only.</td>
                            </tr>
                            <tr>
                                <td>Next-Day Funding</td>
                                <td>Funding is available the next day.</td>
                            </tr>
                        </tbody>
                    </table>
                    <h2 className="flex whitespace-pre-wrap group" id="partner-launch-timeline">
                        <div className="absolute">
                            <a
                                
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Partner Launch Timeline</span>
                    </h2>
                    <h3 className="flex whitespace-pre-wrap group" id="projected-timeline">
                        <div className="absolute">
                            <a
                                
                                className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                                aria-label="Navigate to header"
                            >

                                <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="gray"
                                        height="12px"
                                        viewBox="0 0 576 512"
                                    >
                                        <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <span className="cursor-pointer">Projected Timeline</span>
                    </h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Implementation Kick-Off Call:</td>
                                <td>Date of the first implementation call</td>
                            </tr>
                            <tr>
                                <td>Anticipated Integration Target Start Date:</td>
                                <td>When do you expect to start the integration</td>
                            </tr>
                            <tr>
                                <td>Anticipated Integration Target Finish Date:</td>
                                <td>Expected date to finalize integration</td>
                            </tr>
                            <tr>
                                <td>Target Beta Onboarded:</td>
                                <td>Estimated Target Beta Onboarded Date</td>
                            </tr>
                            <tr>
                                <td>Target Full Launch:</td>
                                <td>Target full launch date</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="leading-6 mt-14">
                    <div className="mb-12 px-0.5 flex items-center text-sm font-semibold text-gray-700 dark:text-gray-200">
                        <Link to={"/docs/documentation/introduction"}
                            className="flex items-center space-x-3 group"
                            onClick={()=>setActiveTab('/docs/documentation/introduction')}
                        >
                            <svg
                                viewBox="0 0 3 6"
                                className="h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                            >
                                <path
                                    d="M3 0L0 3L3 6"
                                    fill="none"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                                Introduction
                            </span>
                        </Link>
                        <Link to={"/docs/documentation/webhooks"}
                            className="flex items-center ml-auto space-x-3 group"
                           onClick={()=>setActiveTab('/docs/documentation/webhooks')}
                        >
                            <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                                Webhooks
                            </span>
                            <svg
                                viewBox="0 0 3 6"
                                className="rotate-180 h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                            >
                                <path
                                    d="M3 0L0 3L3 6"
                                    fill="none"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Link>
                    </div>
                    <footer className="justify-between pt-10 border-t border-gray-100 sm:flex dark:border-gray-800/50 pb-28">
                        <div className="flex mb-6 space-x-6 sm:mb-0">
                            {/* <a
                              
                           
                            >
                                <span className="sr-only">linkedin</span>
                                <svg
                                    className="w-5 h-5 bg-gray-400 dark:bg-gray-500 hover:bg-gray-500 dark:hover:bg-gray-400"
                                    style={{
                                        WebkitMaskImage:
                                            "url(https://mintlify.b-cdn.net/v6.5.1/brands/linkedin.svg)",
                                        WebkitMaskRepeat: "no-repeat",
                                        WebkitMaskPosition: "center"
                                    }}
                                />
                            </a> */}
                        </div>
                        <div className="sm:flex">
                            <a
                               
                                rel="noreferrer"
                                className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                            >
                                Fractal Pay
                            </a>
                        </div>
                    </footer>
                </div>
            </div>
            {/* on this page right bar */}
            <div
                className="z-10 hidden xl:flex flex-none pl-10 w-[19rem]"
                id="table-of-contents"
            >
                <div className="fixed text-gray-600 text-sm leading-6 w-[16.5rem] overflow-y-auto space-y-2 h-[calc(100%-10rem)]">
                    <div className="text-gray-700 dark:text-gray-300 font-medium flex items-center space-x-2">
                        <svg
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3 w-3"
                        >
                            <path
                                d="M2.44434 12.6665H13.5554"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M2.44434 3.3335H13.5554"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M2.44434 8H7.33323"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span>On this page</span>
                    </div>
                    <ul>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('implementation-guide'); }}
                                href="#implementation-guide"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Implementation Guide
                            </a>
                        </li>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('scope-of-work-creation'); }}
                                href="#scope-of-work-creation"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Scope Of Work Creation
                            </a>
                        </li>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('table-of-contents'); }}
                                href="#table-of-contents"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Table of Contents
                            </a>
                        </li>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('overview'); }}
                                href="#overview"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Overview
                            </a>
                        </li>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('merchant-onboarding-details'); }}
                                href="#merchant-onboarding-details"
                                className="py-1 block font-medium -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                            >
                                Merchant Onboarding Details
                            </a>
                        </li>
                        <li style={{ marginLeft: "1rem" }}>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('boarding-integration'); }}
                                href="#boarding-integration"
                                className="group flex items-start py-1 whitespace-pre-wrap text-primary dark:text-primary-light"
                            >
                                Boarding Integration
                            </a>
                        </li>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('payment-workflow'); }}
                                href="#payment-workflow"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Payment Workflow
                            </a>
                        </li>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('risk-and-compliance'); }}
                                href="#risk-and-compliance"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Risk and Compliance
                            </a>
                        </li>
                        <li style={{ marginLeft: "1rem" }}>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('disputes-and-chargebacks'); }}
                                href="#disputes-and-chargebacks"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Disputes and Chargebacks
                            </a>
                        </li>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('funding'); }}
                                href="#funding"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Funding
                            </a>
                        </li>
                        <li>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('partner-launch-timeline'); }}
                                href="#partner-launch-timeline"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Partner Launch Timeline
                            </a>
                        </li>
                        <li style={{ marginLeft: "1rem" }}>
                            <a onClick={(e) => { e.preventDefault(); scrollToSection('projected-timeline'); }}
                                href="#projected-timeline"
                                className="py-1 block font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                            >
                                Projected Timeline
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </>
    )
}

export default ImplementationGuide