import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { useRoutes, useToken } from "../hooks/useToken";
// import axios from "axios";
// import baseUrl from "../baseUrl";
// import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
import { useRefToken } from "../hooks/useToken";
// import Loader from "../DefaultComponent/Loader";

const SuperAdminProtected = (props) => {
  // const [sidebarTrigger,{ data: routes, isLoading, isError, error }] = useLazyGetSidebarQuery();
  // const dispatch=useDispatch()

  const refToken = useRefToken();
   const { Component } = props;
   const navigate = useNavigate();
   const location = useLocation();
  //  const [ accessible , setAccessible ] = useState(false);
  // const route = routes?.data?.allowedPath;
  // const routes = useRoutes();

  // console.log(window.location.pathname)
  // if(error?.status==401){
  //   localStorage.clear();
  //   dispatch(apiSlice.util.resetApiState());
  //   toast.error("Unauthorized");
  //   navigate("/login");
  // }
  
  useEffect(() => {
    if (!refToken) {
      navigate("/super-admin-login");
    }
  }, [location.pathname])

  // const checkPermission = async(matchId) => {
  //   try {
  //       const response = await axios.get(`${baseUrl}/api/v1/admin/auth/check-access`, {
  //         headers: {
  //           Authorization: token,
  //           menu_id: matchId,
  //         },
  //       });
       
  //         if(response?.data?.result){
  //           setAccessible(response?.data?.result);
  //         } else  {
  //           navigate(routes?.data?.allowedPath[0]?.slug);
  //         }
        
  //   } catch (error) {
  //     setAccessible(false);
  //     if (error.response.status == 401) {
  //       navigate("/login");
  //       localStorage.clear();
  //       toast.error(error.response.statusText)
  //     }else{
  //       toast.error(error?.message)
  //     }
  //   }
  // };

 
  // if(isLoading) {
  //   return <Loader loading={isLoading} />

  // }
  return refToken 
    && <Component />;
};

export default SuperAdminProtected;


