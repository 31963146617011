import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import baseUrl from "../baseUrl";
import { toast } from "react-toastify";
import logo from "../assets/images/Logo_Fractal_Icon_Black.svg";
import merchantlogo from "../assets/images/merchantlogo.png";
import { useToken } from "../hooks/useToken";
import { CheckDomain } from "../hooks/CheckDomain";
// import BreadCrumbs from "../components/Breadcrumbs";


const ForgotManagerPassword = () => {

  const token = useToken();
  const navigate = useNavigate();
  const managerId = useParams().id;
  const [loading, setLoading] = useState(false);
  const verifyToken = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseUrl}/api/v1/admin/auth/verify-expiry/${managerId}`);

      setLoading(false);
    } catch (error) {
      console.log(error)
      setLoading(false);
      if (error?.response?.status === 401) {
        navigate('/forgot-password', { replace: true });
      }
      toast.error(error?.response.data.message || "Error changing password");
    }
  }
  useEffect(() => {
    verifyToken();
  }, [])

  const [userData, setUserData] = useState({
    password: "",
    cnf_password: "",
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userData.password.trim() || !userData.cnf_password) {
      toast.error("All fields are required");
      return;
    }
    if (userData.cnf_password !== userData.password) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseUrl}/api/v1/admin/auth/update-password/${managerId}`,
        userData,
        // {
        //   headers: {
        //     Authorization: token,
        //   },
        // }
      );
      if (data?.result === true) {
        toast.success(`${data?.message}`);
        navigate("/login");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response.data.message || "Error changing password");
    }
  };
  // const breadcrumbItems = [
  //   { label: "Dashboard", href: "/", active: false },
  //   { label: "Account-Manager", href: "/admin-manager", active: false },
  //   { label: "Forgot-Password", href: "/forgot-password", active: true },
  // ];

  return (
    <div className="app app-login p-0">
      <div className="row g-0 app-auth-wrapper">
        <div className="col-12 col-md-6 col-lg-6 auth-main-col log-left otp-mn-sec">
          <div className="d-flex flex-column align-content-end">
            <div className="app-auth-body mx-auto">
              <div className="app-auth-branding mb-5 app-logo">
                {
                  CheckDomain() ? (
                    <img className="logo-icon me-2" style={{ width: '200px' }} src={merchantlogo} alt="logo" />
                  ) : (
                    <img className="logo-icon me-2" src={logo} alt="logo" />
                  )
                }
              </div>


              <h2 className="auth-heading mt-5">Reset Your Password</h2>

              <div className="auth-form-container text-start mt-3">
                <form className="auth-form login-form" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="password">New Password</label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      className="form-control signin-email"
                      placeholder="Enter new password"
                      value={userData.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="cnf_password">Confirm New Password</label>
                    <input
                      id="cnf_password"
                      name="cnf_password"
                      type="password"
                      className="form-control signin-email"
                      placeholder="Confirm new password"
                      value={userData.cnf_password}
                      onChange={handleChange}
                    />
                  </div>
                  <button className="btn app-btn-primary w-100" type="submit" disabled={loading}>
                    {loading ? "Changing Password..." : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotManagerPassword;
