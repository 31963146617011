import React, { useCallback, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import baseUrl from "../../../baseUrl";
import axios from "axios";
import Loader from "../../../DefaultComponent/Loader";
import "../../Merchant/Tabs/MerchantOnboard.css";
import "./Payment.css"
import { useToken } from "../../../hooks/useToken";
import View from "../../../assets/images/View.svg";
import moment from "moment";
import { toast } from "react-toastify";
import ExportCSV from "../../../components/ExportCSV";
import { useGetSidebarQuery } from "../../../redux/apiSlice";
import { debounce } from "lodash";
import { useDispatch } from 'react-redux'
import { dateAction } from "../../../redux/dateSlice";
import CardType from "../../../DefaultComponent/CardType";
import DatePickers from "../../../DefaultComponent/DatePickers";

const Payments = () => {
  const dispatch = useDispatch();
  const { data: userData, isLoading, isError } = useGetSidebarQuery();
  const { role_id, staff_roll_type } = userData?.data;
  const token = useToken();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filterDays, setFilterDays] = useState("");
  const [graphFilter, setGraphFilter] = useState("week");
  const [totalPage, setTotalPage] = useState(null);
  const [perPage, setPerPage] = useState(15);
  const [transactionData, setTransactionData] = useState([]);
  const handleDates = (val, hours, minutes) => {
    const Dates = new Date();
    Dates.setDate(Dates.getDate() - val);
    Dates.setHours(hours, minutes, 0, 0);
    return Dates;
  };
  const [startDate, setStartDate] = useState(() => {
    const start = handleDates(6, 0, 0); // 7 days ago, start of the day
    start.setHours(0, 0, 0, 0);
    return start;
  });
  const [endDate, setEndDate] = useState(() => {
    const end = new Date(); // Today
    end.setHours(23, 59, 59, 999); // Set to the end of today
    return end;
  });
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const loader = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [fetMoreData, setFetchMoreData] = useState(0);
  const [noDataMessage, setNoDataMessage] = useState(false);
  // set initial filter from local storage
  let initialFilter = localStorage.getItem("trans-fltr");
  // useEffect(() => {
  //   let fltr = JSON.parse(initialFilter);
  //   if (initialFilter) {
  //     setFilterDays(fltr);
  //     if (fltr !== "all") {
  //       const days = parseInt(fltr, 10);
  //       const start = moment().subtract(days, 'days').toDate();
  //       setStartDate(start);
  //       setEndDate(new Date());
  //       fetchData(page, perPage, start, new Date(), searchTerm);
  //       let strtDate = convertMili(start);
  //       let enDate = convertMili(new Date());
  //       dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))


  //     } else {
  //       // setStartDate(null);
  //       // setEndDate(null);
  //       // fetchData(page, perPage, null, null, searchTerm);

  //       let strtDate = convertMili(new Date());
  //       let enDate = convertMili(new Date());
  //       dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))
  //       fetchData(page, perPage, new Date(), new Date(), searchTerm);

  //     }
  //   }
  //   else {
  //     // setFilterDays("1");
  //     fetchData(page, perPage, startDate, endDate, searchTerm);
  //     let strtDate = convertMili(startDate);
  //     let enDate = convertMili(endDate);
  //     dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))
  //   }

  // }, [])

  const handleViewDetails = (item) => {
    if (item) {
      navigate(`/transactions/${item}`);
    } else {

    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowDropdown(false);
  //   }, 10000);
  // }, [showDropdown]);
  // const handlePageChange = (pageNumber) => {
  //   setTransactionData([]);
  //   setTotalPage(null);
  //   fetchData(pageNumber);
  // };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return (
          <div className="paymentcard-status">
            <i style={{ fontSize: "12px" }} className="fas">
              &#xf110;
            </i>
            Pending
          </div>
        );
      case 2:
        return (
          <div className="paymentcard-status">
            <i style={{ fontSize: "12px", color: 'green' }} className="fas fa-check"></i>
            Success
          </div>
        );
      case 5:
        return (
          <div className="paymentcard-status">
            <i style={{ fontSize: "12px", color: '#8161C6' }} className="fas fa-redo-alt"></i>
            Refunded
          </div>
        );
      case 3:
        return (
          <div className="paymentcard-status">
            <i style={{ fontSize: "12px", color: 'red' }} className="far fa-times-circle"></i>
            Declined
          </div>
        );
      case 4:
        return (
          <div className="paymentcard-status">
            <i style={{ fontSize: "12px", color: '#BE7D4D' }} className="fas fa-pause"></i>
            On-hold
          </div>
        );
      case 6:
        return (
          <div className="paymentcard-status">
            <i style={{ fontSize: "12px", color: '#9E3636' }} className="fas fa-circle-minus"></i>
            Voided
          </div>
        );
      case 7:
        return (
          <div className="paymentcard-status">
            <i style={{ fontSize: "12px", color: 'green' }} className="fa-solid fa-check"></i>
            Settled
          </div>
        );
      case 8:
        return (
          <div className="paymentcard-status">
            <i style={{ fontSize: "12px" }} className="fas">
              &#xf110;
            </i>
            Authorized
          </div>
        );
      default:
        return "";
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");

    return `${month}.${day}.${year}`.replace(" ", ", ");
  };
  function convertMili(da) {
    const date = new Date(da);
    return date.getTime();
  }
  const isScroll = true;
  const fetchData = async (
    newPage,
    newPerPage,
    startDate,
    endDate,
    searchData
  ) => {
    setPage(newPage);
    setPerPage(newPerPage);
    try {
      const requestBody = {
        page: newPage,
        perPage: newPerPage,
      };


      if (startDate && endDate) {
        requestBody.startDate = convertMili(startDate);
        requestBody.endDate = convertMili(endDate);
      } else if (startDate) {
        requestBody.startDate = convertMili(startDate);
      }
      if (searchData) {
        requestBody.search = searchData;
      }
      if (requestBody?.endDate) {
        let final = {};
        let localStorageDay = localStorage.getItem("trans-fltr");
        if (localStorageDay) {
          let day = JSON.parse(localStorageDay);
          if (day == "all") {
            final = {
              page: newPage,
              perPage: newPerPage,
              allTimeFilter: true
            }
          }
          else {
            final = { ...requestBody }
          }
        }
        else {
          final = { ...requestBody }
        }

        // if(filterDays==)
        setLoading(true);
        if (isScroll) {
          const response = await axios.post(
            `${baseUrl}/api/v1/admin/transaction`,
            final,
            {
              headers: {
                Authorization: token,
              },
            }
          );

          setLoading(false);
          if (!response?.data?.status) {
            setHasMore(false)
            isScroll = false;
          }
          // if (!response?.data?.status && newPage==1) {
          //   console.log("============================no more datea====================", newPage);

          //   setTransactionData(null);
          //   return false;
          // }

          if (newPage == 1) {
            setTransactionData([]);
          }
          // if (response?.data?.status) {

          //   if (response.data?.data?.allTransaction?.length === 0) {
          //     setHasMore(false);
          //   } else {
          //     setTransactionData((prev) => [...prev, ...formattedTransactions]);
          //     setPage((prev) => prev + 1);
          //     setTotalPage(response.data?.data?.totalPage);
          //   }
          // } else {
          //   setHasMore(response?.data?.status);
          //   setShow(response?.data?.status);
          //   setMessage(response?.data?.message);
          // }
          const formattedTransactions = response.data?.data?.allTransaction?.map(
            (item) => ({
              ...item,
              Amount: `$${parseFloat(item?.Amount).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
              Date: formatDate(item.Date),
            })
          );
          if (formattedTransactions?.length === 0 && response.data?.data?.totalPage) {
            setHasMore(false);
            setNoDataMessage(true);
            setFetchMoreData(0);
            setLoading(false);
            setTransactionData([]);
            return false;
          }

          if (response.data?.data?.totalPage <= newPage) {
            setHasMore(false)
            setFetchMoreData(0);
          }
          else {
            setHasMore(true);
            setFetchMoreData(fetMoreData + 1);

          }
          if (response?.data?.status) {
            setTransactionData((prev) => [...prev, ...formattedTransactions]);
            setNoDataMessage(false);
            setTotalPage(response.data?.data?.totalPage);
            // if (custom) {
            setPage(response.data?.data?.page + 1);
            // }else{
            //   setCustom(true)
            // }
          }
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching transaction data:", error);
    }
  };

  useEffect(() => {
    fetchData(1, 15, startDate, endDate, searchTerm);
    setPage(1)
    dispatch(dateAction.setDates({ startDate: startDate, endDate: endDate }))
  }, [token, startDate, endDate])

  useEffect(() => {
    if (hasMore || !searchTerm || !endDate) {
      const options = {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      };
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && isScroll) {
          console.log("calling thgis aopiiiiii");
          fetchData(page, perPage, startDate, endDate, searchTerm);
        }
      }, options);

      if (loader.current) {
        observer.observe(loader.current);
      }
      return () => {
        if (loader.current) {
          observer.unobserve(loader.current);
        }
      };
    }
  }, [page, fetMoreData]);


  const handlePerPageChange = (e) => {
    const perPageNo = e.target.value;
    setTransactionData([]);
    setTotalPage(null);
    setPerPage(perPageNo);
    fetchData(page, perPageNo, startDate, endDate, searchTerm);
  };

  const handleBoxChange = (e) => {
    const { value } = e.target;
    setTransactionData([]);
    setTotalPage(null);
    switch (value) {
      case "1":
        fetchData(page, perPage, startDate, endDate, searchTerm, 1);
        setPaymentStatus(1);

        break;
      case "2":
        fetchData(page, perPage, startDate, endDate, searchTerm, 2);
        setPaymentStatus(2);

        break;
      case "5":
        fetchData(page, perPage, startDate, endDate, searchTerm, 5);
        setPaymentStatus(5);

        break;
      case "3":
        fetchData(page, perPage, startDate, endDate, searchTerm, 3);
        setPaymentStatus(3);

        break;
      case "4":
        fetchData(page, perPage, startDate, endDate, searchTerm, 4);
        setPaymentStatus(4);

        break;
      case "7":
        fetchData(page, perPage, startDate, endDate, searchTerm, 7);
        setPaymentStatus(7);

        break;
      case "6":
        fetchData(page, perPage, startDate, endDate, searchTerm, 6);
        setPaymentStatus(6);

        break;
      default:
        fetchData(page, perPage, startDate, endDate, searchTerm, null);
        setPaymentStatus(null);
    }
  };

  // function setLocalStorageFilter(data) {
  //   let item = JSON.stringify(data)
  //   localStorage.setItem("trans-fltr", item);
  // }
  // const handleFilterDays = () => {
  //   setDays(true);
  // };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterDays(value);
    let start, end;
    if (value == "0" || value == "1") {
      start = new Date(); // Today or Yesterday
      start.setDate(start.getDate() - (value == "1" ? 1 : 0));
      end = new Date(start);
      console.log("startstart1", start);
      console.log("endend1", end);
      setStartDate(start);
      setEndDate(end);
      // fetchData(1, perPage, start, end, searchTerm);
      let strtDate = convertMili(start);
      let enDate = convertMili(end);
      dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))
      // setTransactionData([]);
    } else if (value == "7") {
      start = new Date();
      start.setDate(start.getDate() - 6);
      end = new Date();
      console.log("startstart1", start);
      console.log("endend1", end);
      setStartDate(start);
      setEndDate(end);
      // setFetchMoreData(fetMoreData+1)
      // fetchData(1, perPage, start, end, searchTerm);
      let strtDate = convertMili(start);
      let enDate = convertMili(end);
      dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))
    } else if (value == "14") {
      start = new Date();
      start.setDate(start.getDate() - 13);
      end = new Date();
      console.log("startstart1", start);
      console.log("endend1", end);
      setStartDate(start);
      setEndDate(end);
      // fetchData(1, perPage, start, end, searchTerm);
      let strtDate = convertMili(start);
      let enDate = convertMili(end);
      dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))
    } else if (value == "30") {
      start = new Date();
      start.setMonth(start.getMonth() - 1);
      end = new Date();
      console.log("startstart1", start);
      console.log("endend1", end);
      setStartDate(start);
      setEndDate(end);
      // fetchData(1, perPage, start, end, searchTerm);
      let strtDate = convertMili(start);
      let enDate = convertMili(end);
      dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))
    }

    if (value != "100") { // Set full-day times for all preset ranges
      if (start) start.setHours(0, 0, 0, 0); // Start of the day
      if (end) end.setHours(23, 59, 59, 999); // End of the day
      setStartDate(start);
      setEndDate(end);
      console.log("startstart1", start);
      console.log("endend1", end);
      setStartDate(start);
      setEndDate(end);
      fetchData(1, perPage, start, end, searchTerm);
      setPage(1)
      let strtDate = convertMili(start);
      let enDate = convertMili(end);
      dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))
    } else { // Custom range
      console.log("custome range===============");

      setPage(1)
      setFetchMoreData(fetMoreData + 1)
      setStartDate(null);
      setEndDate(null);
    }
    // console.log("transactionDatatransactionData",fetMoreData);


    // setLocalStorageFilter(value);
    // if (value == "100") {
    //   setStartDate(null);
    //   setEndDate(null);
    // } else if (value != "0") {
    //   let start = handleDates(value, 0, 0)
    //   let end = handleDates(1, 0, 0)
    //   console.log("startstart1", start);
    //   console.log("endend1", end);
    //   setStartDate(start);

    //   setEndDate(end);
    //   fetchData(page, perPage, start, end, searchTerm);
    //   let strtDate = convertMili(start);
    //   let enDate = convertMili(end);
    //   dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))
    // } else {
    //   const Dates = new Date();
    //   Dates.setHours(0, 0, 0, 0);
    //   console.log("startstart1", Dates);
    //   console.log("endend1", Dates);
    //   setStartDate(Dates);
    //   setEndDate(Dates);
    //   fetchData(page, perPage, Dates, Dates, searchTerm);
    //   let strtDate = convertMili(Dates);
    //   let enDate = convertMili(Dates);
    //   dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))
    // }
    //   if(value=="-1"){

    //     const days = parseInt(value, 10);
    //     const start = moment().add(days, 'days').toDate();
    //     setStartDate(start);
    //     setEndDate(new Date());
    //     fetchData(page, perPage, start, new Date(), searchTerm);
    //   }
    //  else if (value != "0") {
    //     const days = parseInt(value, 10);
    //     const start = moment().add(days, 'days').toDate();
    //     setStartDate(start);
    //     setEndDate(new Date());
    //     fetchData(page, perPage, start, new Date(), searchTerm);
    //   } else if (value == "0") {
    //     setStartDate(new Date());
    //     setEndDate(new Date());
    //     fetchData(page, perPage, new Date(), new Date(), searchTerm);
    //   }
  };

  // console.log("===================================page", page);

  // const handleDateChange = ([start, end]) => {
  //   setStartDate(start);
  //   setEndDate(end ? end : null);
  //   setFilterDays("all");
  //   fetchData(page, perPage, start, end, searchTerm);
  // };
  const handleDateChange = ([start, end]) => {
    setPage(1)
    // setCustom(false)
    if (start && end) {
      const diffInDays = (end - start) / (1000 * 60 * 60 * 24);
      if (diffInDays <= 30) {
        let strtDate = convertMili(start);
        let enDate = convertMili(end);
        dispatch(dateAction.setDates({ startDate: strtDate, endDate: enDate }))

        setStartDate(start);
        setEndDate(end);
      } else {
        // toast.error("The date range cannot exceed 30 days.")
        // alert('The date range cannot exceed 30 days.');
      }
    } else {
      setStartDate(start);
      setEndDate(end);
    }

    setFilterDays("all");
    fetchData(1, perPage, start, end, searchTerm);
  };

  // export csv handle
  const handleExportCsv = async () => {
    if (!startDate || !endDate) {
      toast.error("Start and End date required");
      return;
    }
    try {
      setLoading(true);
      let data = {
        "paymentStatus": 2,
        "isAdminExport": true,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }
      const resp = await axios.post(`${baseUrl}/api/v1/admin/transaction`, data, {
        headers: {
          Authorization: token,
        },
      }
      );
      setLoading(false);
      if (resp?.status == 200) {
        const link = document.createElement('a');
        link.href = `${resp?.data?.data}`;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  const handleSearchChange = (e) => {
    const searchData = e.target.value;
    setSearchTerm(searchData)
    setPage(1);
    handleSearch(startDate, endDate, searchData);
  };

  function convertString(str) {
    if (str && str.length > 6) {
      return '...' + str.slice(-6);
    }
    return str;
  }

  const handleSearch = useCallback(
    debounce((start, end, searchQuery) => {
      fetchData(1, perPage, start, end, searchQuery);
      setTransactionData([]);
    }, 700),
    []
  );

  let str = "";
  const handleRow = (guid, value) => {
    str += value;
    if (str == "viewEye") {
      handleViewDetails(guid)
    } else if (str == "view") {
      handleViewDetails(guid)
    }
  }
  function formatAmount(amount) {
    const num = parseInt(amount, 10);
    const formattedAmount = new Intl.NumberFormat('en-US').format(Math.abs(num));
    return num < 0 ? `-$${formattedAmount}` : `$${formattedAmount}`;
  }
  return (
    <>
      <div className="" >

        <div className="dashboard-filter payments mb-4 row">
          <div className="col-lg-3">
            <p className="heading">Transactions</p>
          </div>
          <div className="d-flex col-lg-9 justify-content-end search-dates" >

            <div className="" >
              {((role_id == "132" && staff_roll_type == "269") || (role_id == "132" && staff_roll_type == "277") || (role_id == "223")) && <ExportCSV id={null} searchKey={searchTerm} dataLength={transactionData.length} />}
              {/* <button onClick={handleShow} className="btn app-btn purp border border-1 mx-1">Export CSV</button> */}
            </div>
            {/* <div className="dropdown" >
                  <button onClick={handleFilterDays}>
                    <span className="icon" style={{ paddingRight: '10px' }}>
                      <i className="fa-regular fa-clock"></i>
                    </span>
                    {getFilterText()}
                  </button>
                  <select onChange={handleFilterChange} value={filterDays}>
                    <option value="1">Today</option>
                    <option value="7">Last 7 Days</option>
                    <option value="14">Last 14 Days</option>
                    <option value="30">Last 30 Days</option>
                    <option value="all">All Time</option>
                  </select>
                </div> */}
            <div className="search-box">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_7372_11113)">
                  <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                </g>
                <defs>
                  <clipPath id="clip0_7372_11113">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <input
                className="searchbox"
                type="text"
                placeholder="Search"
                name="search"
                disabled={loading}
                onChange={handleSearchChange}
              // value={state.searchValue}
              />
            </div>
            {/* <div className="cursor-pointer">
              <DatePickers startDate={startDate} endDate={endDate} filterDays={filterDays} handleDateChange={handleDateChange} handleFilterChange={handleFilterChange} />
            </div> */}
            <div className="cursor-pointer position-relative">
              <DatePickers startDate={startDate} endDate={endDate} setGraphFilter={setGraphFilter} setStartDate={setStartDate} setEndDate={setEndDate} />
            </div>
          </div>
        </div>

      </div>

      {/* <div
          className="orders-table-tab app-nav-tabs nav shadow-sm flex-column flex-sm-row mb-4"
          style={{ background: "#fff", padding: 0 }}
        >
          {["All", "Succeeded", "Refunded", "Pending", "Failed"].map(
            (box, index) => (
              <div
                key={index}
                className={`flex-sm-fill text-sm-center nav-link ${
                  activeBox === box ? "active" : ""
                }`}
                onClick={() => handleBoxChange(box)}
              >
                <span>{box}</span>
                {box === "All" && (
                  <span>
                    (
                    {analyticsData?.allTranstionCount ??
                      selectedAnalytics?.allTranstionCount}
                    )
                  </span>
                )}
                {box === "Succeeded" && (
                  <span>
                    (
                    {analyticsData?.totalSuccedTranstion ??
                      selectedAnalytics?.totalSuccedTranstion}
                    )
                  </span>
                )}
                {box === "Refunded" && (
                  <span>
                    (
                    {analyticsData?.totalRefund ??
                      selectedAnalytics?.totalRefund}
                    )
                  </span>
                )}
                {box === "Pending" && (
                  <span>
                    (
                    {analyticsData?.totalUncapture ??
                      selectedAnalytics?.totalUncapture}
                    )
                  </span>
                )}
                {box === "Failed" && (
                  <span>
                    (
                    {analyticsData?.totalFailedTransition ??
                      selectedAnalytics?.totalFailedTransition}
                    )
                  </span>
                )}
                {activeBox === box && (
                  <div
                    style={{
                      width: "100%",
                      height: "2px",
                      marginTop: "10px",
                    }}
                  />
                )}
              </div>
            )
          )}
        </div> */}
      {/* <div className="d-flex justify-content-end mb-3"> */}
      {/* <input
            className="searchbox"
            type="text"
            placeholder="Search..."
            name="search"
            onChange={handleSearchChange}
            value={state.searchTerm}
          /> */}
      {/* </div> */}
      {/* <div className="app-card app-card-orders-table shadow-sm"> */}
      {/* <div className="row px-3">
            <div className="col-6 col-md-3 mt-3">
              <label>Per Page: </label>
              <select
                name="page"
                id="page"
                value={perPage}
                onChange={handlePerPageChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <div className="col-6 col-md-3 mt-3">
              <select
                className="form-select form-control "
                // style={{  marginRight: 8, borderRadius: "10px" }}
                aria-label="Small select example"
                onChange={(e) => handleBoxChange(e)}
                defaultValue="0"
              >
                <option value>All</option>
                <option value="1">Pending</option>
                <option value="2">Success</option>
                <option value="3">Failed</option>
                <option value="4">On Hold</option>
                <option value="5">Refund</option>
                <option value="6">Void</option>
                <option value="7">Settle</option>
              </select>
            </div>
            <div className="col-6 col-md-3 mt-3">
              <DatePicker
                className="form-control "
                style={{ width: "100%" }}
                selected={startDate}
                onChange={handleStartDate}
                placeholderText="Select start date"
              />
            </div>
            <div className="col-6 col-md-3 mt-3">
              <DatePicker
                className="form-control"
                selected={endDate}
                onChange={handleEndDate}
                placeholderText="Select end date"
              />
            </div>
          </div> */}
      <div className="date-picker-container overflow-x-auto scroll-tbl">
        <Table>
          <thead>
            <tr>
              <th>Created</th>
              {/* <th>ID</th> */}
              <th>Merchant Name</th>
              <th>Amount</th>
              <th>Type</th>
              {/* <th>Last 4</th> */}
              <th>Brand</th>
              <th>Status</th>
              {(role_id == "1" || role_id == "277") && <>
                <th> Fee </th>
                <th>Net</th>
              </>}
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {transactionData && transactionData.length > 0 ? (
              transactionData?.map((transaction, ind) => (
                <tr
                  key={ind}
                  className="table-hover cursor-pointer"
                  onClick={() => handleRow(transaction.pos_guid, "view")}
                >
                  <td>{formatDate(transaction.Date)}</td>
                  {/* <td>{convertString(transaction.tran_id)}</td> */}
                  <td>
                    {transaction.merchantDetails?.businessName
                      ? transaction.merchantDetails?.businessName
                      : "Merchant Name"}
                  </td>
                  <td style={{ color: "#383838", fontWeight: 700, }}>
                    <div className="" style={{ color: "#383838", fontWeight: 700, }}>
                      {String(transaction.Amount).slice(1) < 0 ? (<span className="minus">{`-$${String(transaction.Amount).slice(2)}`}</span>) : <span>&nbsp;&nbsp;{transaction.Amount}</span>}
                    </div>
                  </td>
                  <td>{transaction.Type}</td>
                  <td>
                    {(transaction.cardType || transaction.lastFourDigits) ? (
                      <CardType type={transaction.cardType} lastFour={transaction.lastFourDigits} />
                    ) : null}
                  </td>
                  <td>{getStatusLabel(transaction.is_status)}</td>
                  {(role_id == "1" || role_id == "277") && <>
                    <td>{`$${Number(transaction.fee_amount).toFixed(2)}`}</td>
                    <td>{`$${Number(transaction.Net).toFixed(2)}`}</td>
                  </>}
                  {/* <td align="center">
                    <div className="action-column button-hover">
                      <img
                        src={View}
                        alt="View"
                        onClick={() => handleRow(transaction.pos_guid, "viewEye")}
                      />
                    </div>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: "center" }}>
                  No transaction available
                </td>
              </tr>
            )}
            {page >= 2 && transactionData.length > 12 && !loading && <div ref={loader} style={{ height: "20px" }} />}
          </tbody>
        </Table>

      </div>
      <div>
        {/* <PaginationPg
            totalPages={totalPage}
            onPageChange={handlePageChange}
            current={currentPage}
          /> */}
        {loading && <Loader loading={loading} />}
        {/* {!show && (
          <div className="row alert alert-danger mt-3">
            <small className="alert-info text-center">
              {message}
            </small>
          </div>
        )} */}


      </div>

    </>
  );
};

export default Payments;
