import React, { useEffect, useRef, useState } from "react";
import "../assets/css/portal.css";
import logo from "../assets/images/Logo_Fractal_Icon_Black.svg";
import merchantlogo from "../assets/images/merchantlogo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import baseUrl from "../baseUrl";
import Loader from "../DefaultComponent/Loader";
import { isEqual } from "lodash";
import { useToken } from "../hooks/useToken";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { CheckDomain } from "../hooks/CheckDomain";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const formRef = useRef(null);  // outside click handler
  // eye handles
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const token = useToken();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });

  // form data source
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value?.trim() });
  };

  useEffect(() => {
    if (token) {
      return navigate("/");
    }
    localStorage.removeItem("timer");
  }, []);

  // form validation
  const Validation = () => {
    let error = {};
    if (!requiredValue(userData.password)) {
      error.password = "Password is required";
    }
    if (!requiredValue(userData.confirm_password)) {
      error.confirm_password = "Confirm password is required";
    }
    if (!requiredValue(userData.email)) {
      error.email = "Email is required";
    } else if (!handleEmail(userData.email)) {
      error.email = "Please enter a valid email";
    }
    if (!checkPassword(userData.password, userData.confirm_password)) {
      error.confirm_password = "Confirm password and password must be same";
    }
    if (Object.keys(error).length < 1) {
      return false;
    }
    return error;
  };

  // password validation
  const checkPassword = (pass, cnf_pass) => {
    if (isEqual(pass, cnf_pass)) {
      return true;
    }
    return false;
  };

  // required validation
  const requiredValue = (value) => {
    if (!value) {
      return false;
    }
    return true;
  };

  // email validation
  const handleEmail = (value) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  };

  // Resgistration
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});
    let errors = Validation();
    if (!errors) {
      setLoading(true);
      try {
        const resp = await axios.post(
          `${baseUrl}/api/v1/merchant/account/register`,
          userData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setLoading(false);
        if (resp?.data?.result) {   // check API status
          navigate("/verify-email", { state: resp?.data?.token });
          toast.success("Registration Successful");
          console.log("resp?.data?.data", resp?.data?.token);
        } else {
          toast.error("Something went wrong!");
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response.data.message || "Registration failed. Please try again.");
        console.log(error);
      }
    } else {
      setError(errors);   // form validation error
    }
  };

  // outside click handler
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setError({});
    }
  };

  // Check outside click event
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Loader loading={loading} />   {/* loader */}
      <div className="app app-login p-0">
        <div className="row g-0 app-auth-wrapper">
          <div className="col-12 col-md-6 col-lg-6 auth-main-col log-left otp-mn-sec">
            <div className="d-flex flex-column align-content-end">
              <div className="app-auth-body mx-auto">
                <div className="app-auth-branding mb-5 app-logo">
                  {
                    CheckDomain() ? (
                      <img className="logo-icon me-2" style={{ width: '200px' }} src={merchantlogo} alt="logo" />
                    ) : (
                      <img className="logo-icon me-2" src={logo} alt="logo" />
                    )
                  }
                </div>
                <div className="back-button mb-3">
                  {/* back button */}
                  <button
                    className="btn btn-link text-black-50 fs-6 text-decoration-none"
                    onClick={() => navigate(-1)}
                    style={{ fontWeight: 'regular', fontSize: '1rem', textDecoration: 'none' }}
                  >
                    <i className="fas fa-arrow-left me-2"></i> Back
                  </button>
                </div>
                <h2 className="auth-heading mt-5">Please create your login</h2>
                <div className="auth-form-container text-start  mt-3">
                  {/* Registration form */}
                  <form
                    className="auth-form login-form"
                    onSubmit={handleSubmit}
                    ref={formRef} // outside click trigger access
                    autoComplete="off"
                  >
                    <div className="email mb-3">
                      <label>Email Address</label>
                      <input
                        id="email"
                        name="email"
                        type="text"
                        className="form-control signin-email"
                        placeholder="Enter email address"
                        value={userData.email}
                        onChange={handleChange}
                      />
                      {error.email && (
                        <span className="error text-danger fs-12">
                          {error.email}
                        </span>
                      )}
                    </div>
                    <div className="password mb-3">
                      <label>Password</label>
                      <div className="psss-inpt">
                        <input
                          id="password"
                          name="password"
                          value={userData.password}
                          type={showPassword ? 'text' : 'password'}
                          className="form-control signin-password"
                          placeholder="Enter password"
                          onChange={handleChange}
                        />
                        <button
                          type="button"
                          className="toggle-password-btn"
                          onClick={togglePasswordVisibility}
                          aria-label="Toggle password visibility"
                        >
                          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                      </div>
                      {error.password && (
                        <span className="error text-danger fs-12">
                          {error.password}
                        </span>
                      )}
                    </div>
                    <div className="password mb-3">
                      <label>Confirm Password</label>
                      <div className="psss-inpt">
                        <input
                          id="confirm_password"
                          name="confirm_password"
                          value={userData.confirm_password}
                          type={showConfirmPassword ? 'text' : 'password'}
                          className="form-control signin-password"
                          placeholder="Enter password"
                          onChange={handleChange}
                        />
                        <button
                          type="button"
                          className="toggle-password-btn"
                          onClick={toggleConfirmPasswordVisibility}
                          aria-label="Toggle password visibility"
                        >
                          <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                        </button>
                      </div>
                      {error.confirm_password && (
                        <span className="error text-danger fs-12">
                          {error.confirm_password}
                        </span>
                      )}
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className=" idle-green-btn w-100 theme-btn mx-auto mb-3"
                        disabled={loading}
                      >
                        Register
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* BACKGROUND Image */}
          <div className="col-12 col-md-6 col-lg-6 auth-background-col">
            <div className="auth-background-holder"></div>
            <div className="auth-background-overlay p-3 p-lg-5">
              <div className="d-flex flex-column align-content-end">
                <div className="h-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
