import React from 'react'
import { Link } from 'react-router-dom'
import AccordianData from '../AccordianData'
import DefaultLayout from '../DefaultLayout';

const GetMerchantByClient = ({handleActiveTab}) => {
    const body = {
    };

    const head = {
        "Content-Type": "application/json",
        "Authorization": "Basic keyinfo=="
    }

    const headerData = {
        heading: 'Merchant Onboarding',
        subHeading: 'Get Merchants by Client',
        method: 'GET',
        api: ['merchant'],
    }


    const backNavigate = {
        title: "Full Merchant Submit",
        url: "/docs/api/full-merchant-submit"
    }
    const forwardNavigate = {
        title: "Create Link",
        url: "/docs/api/create-link"
    }

  return (
    <>
          <DefaultLayout headerData={headerData} head={head} body={body} backNavigate={backNavigate} forwardNavigate={forwardNavigate} handleActiveTab={handleActiveTab} />
              {/* <div
                  className="relative grow overflow-hidden mx-auto  lg:-ml-12 lg:pl-[23.7rem]"
                  id="content-area"
              >
                  <header id="header" className="relative">
                      <div className="mt-0.5 space-y-2.5">
                          <div className="eyebrow h-5 text-primary dark:text-primary-light text-sm font-semibold">
                              Merchant Onboarding
                          </div>
                          <div className="flex items-center">
                              <h1 className="inline-block text-2xl sm:text-3xl font-extrabold text-gray-900 tracking-tight dark:text-gray-200">
                                  Get Merchants by Client
                              </h1>
                          </div>
                      </div>
                      <div className="mt-2 text-lg prose prose-gray dark:prose-invert">
                          <p>Get Merchants by Client</p>
                      </div>
                  </header>
                  <div className="flex flex-col gap-8">
                      <div className="mt-6 flex w-full flex-col space-y-4">
                          <div className="flex w-full flex-col space-y-3 bg-background-light dark:bg-background-dark border border-gray-200/70 dark:border-white/10 rounded-xl ring-2 ring-gray-100 dark:ring-white/5 p-4">
                              <div className="flex items-center space-x-2">
                                  <div className="relative flex-1 flex gap-2 min-w-0 rounded-lg items-center cursor-pointer p-1.5 border border-gray-200/70 dark:border-white/10">
                                      <div className="rounded-md font-bold px-1.5 py-0.5 text-sm leading-5 bg-green-400/20 text-green-700 dark:bg-green-400/20 dark:text-green-400">
                                          GET
                                      </div>
                                      <div className="w-px h-4 bg-gray-200 dark:bg-white/10" />
                                      <div className="group flex items-center flex-1 gap-1 overflow-x-auto font-mono">
                                          <div className="absolute right-3 hidden group-hover:block">
                                              <svg
                                                  className="w-4 h-4 bg-gray-400 dark:bg-white/30"
                                                  style={{
                                                      WebkitMaskImage:
                                                          "url(https://mintlify.b-cdn.net/v6.5.1/regular/clone.svg)",
                                                      WebkitMaskRepeat: "no-repeat",
                                                      WebkitMaskPosition: "center"
                                                  }}
                                              />
                                          </div>
                                          <div className="text-sm text-gray-400">/</div>
                                          <div className="text-sm font-medium text-gray-800 dark:text-white min-w-max">
                                              merchant
                                          </div>
                                      </div>
                                  </div>
                                  <button className="flex items-center justify-center w-16 h-9 text-white font-medium rounded-lg mouse-pointer disabled:opacity-70 hover:opacity-80 bg-[#2AB673]">
                                      Send
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <AccordianData header={head} body={body} />
                  <div className="leading-6 mt-14">
                      <div className="mb-12 px-0.5 flex items-center text-sm font-semibold text-gray-700 dark:text-gray-200">
                          <Link to={"/docs/api/full-merchant-submit"}
                              className="flex items-center space-x-3 group"
                           onClick={()=>handleActiveTab("/docs/api/full-merchant-submit")}
                          >
                              <svg
                                  viewBox="0 0 3 6"
                                  className="h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                              >
                                  <path
                                      d="M3 0L0 3L3 6"
                                      fill="none"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                  />
                              </svg>
                              <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                                  Full Merchant Submit
                              </span>
                          </Link>
                          <Link to={"/docs/api/create-link"}
                              className="flex items-center ml-auto space-x-3 group"
                             onClick={()=>handleActiveTab("/docs/api/create-link")}
                          >
                              <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                                  Create Link
                              </span>
                              <svg
                                  viewBox="0 0 3 6"
                                  className="rotate-180 h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                              >
                                  <path
                                      d="M3 0L0 3L3 6"
                                      fill="none"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                  />
                              </svg>
                          </Link>
                      </div> */}
                      {/* <footer className="justify-between pt-10 border-t border-gray-100 sm:flex dark:border-gray-800/50 pb-28">
                          <div className="flex mb-6 space-x-6 sm:mb-0"> */}
                              {/* <a
                               
                              >
                                  <span className="sr-only">linkedin</span>
                                  <svg
                                      className="w-5 h-5 bg-gray-400 dark:bg-gray-500 hover:bg-gray-500 dark:hover:bg-gray-400"
                                      style={{
                                          WebkitMaskImage:
                                              "url(https://mintlify.b-cdn.net/v6.5.1/brands/linkedin.svg)",
                                          WebkitMaskRepeat: "no-repeat",
                                          WebkitMaskPosition: "center"
                                      }}
                                  />
                              </a> */}
                          {/* </div>
                          <div className="sm:flex">
                              <a
                                  
                                  rel="noreferrer"
                                  className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                              >
                                  Fractal Pay
                              </a>
                          </div>
                      </footer> */}
                  {/* </div>
              </div> */}
              <div
                  className="hidden xl:flex self-start sticky h-[calc(100vh-8rem-1rem)] top-32"
                  id="content-side-layout"
              >
                  <div className="w-[28rem] gap-6 grid grid-rows-[repeat(auto-fit,minmax(0,min-content))] grid-rows">
                      <div className="not-prose relative overflow-hidden bg-[#0F1117] dark:bg-codeblock rounded-xl dark:ring-1 dark:ring-gray-800/50">
                          <div
                              className="flex h-10 text-xs leading-6 border-b bg-black/40 rounded-t-xl border-gray-900/80"
                              role="tablist"
                              aria-orientation="horizontal"
                          >
                              <div className="flex overflow-x-auto">
                                  <button
                                      className="group flex items-center relative px-2 pt-2.5 pb-2 text-gray-400 outline-none whitespace-nowrap font-medium text-primary-light"
                                      id="headlessui-tabs-tab-:r2:"
                                  style={{
                                      background: 'none',
                                      border: 0,
                                      borderBottom: '2px #A66FF8 solid'
                                  }}
                                      role="tab"
                                      type="button"
                                      aria-selected="true"
                                      tabIndex={0}
                                      data-headlessui-state="selected"
                                      aria-controls="headlessui-tabs-panel-:r3:"
                                  >
                                      <div className="px-2 rounded-md">
                                          <div className="z-10">200</div>
                                      </div>
                                      <div className="absolute inset-0 border-b pointer-events-none border-primary-light" />
                                  </button>
                              </div>
                              <div className="flex-auto flex justify-end items-center pr-4 rounded-tr">
                                  <div className="group z-10 relative">
                                  <button className="h-7 w-7 flex items-center justify-center rounded-md" style={{
                                      background: 'none',
                                      border: 0
                                  }}>
                                          <svg
                                              className="fill-gray-700 group-hover:fill-gray-400"
                                              width={16}
                                              height={16}
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                          >
                                              <path d="M2 14.5H9C9.275 14.5 9.5 14.275 9.5 14V12H11V14C11 15.1031 10.1031 16 9 16H2C0.896875 16 0 15.1031 0 14V7C0 5.89687 0.896875 5 2 5H4V6.5H2C1.725 6.5 1.5 6.725 1.5 7V14C1.5 14.275 1.725 14.5 2 14.5ZM7 11C5.89687 11 5 10.1031 5 9V2C5 0.896875 5.89687 0 7 0H14C15.1031 0 16 0.896875 16 2V9C16 10.1031 15.1031 11 14 11H7Z"></path>
                                          </svg>
                                      </button>
                                      <div className="absolute top-11 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:block text-white rounded-lg px-1.5 py-0.5 text-xs bg-primary-dark">
                                          Copy
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="flex max-h-[calc(100%-40px)]">
                              <div
                                  className="flex-none text-gray-50 p-5 min-w-full text-sm overflow-x-auto scrollbar-thumb-white/25 dark:scrollbar-thumb-white/10 w-full scrollbar-track-transparent scrollbar-thin scrollbar-thumb-rounded text-xs leading-[1.35rem]"
                                  id="headlessui-tabs-panel-:r3:"
                                  role="tabpanel"
                                  tabIndex={0}
                                  data-headlessui-state="selected"
                                  aria-labelledby="headlessui-tabs-tab-:r2:"
                                  style={{ fontVariantLigatures: "none" }}
                              >
                                  <pre>
                                      <code>
                                          <span className="token punctuation">{"{"}</span>
                                          {"\n"}
                                          {"  "}
                                          <span className="token property">"data"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token punctuation">[</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"{"}</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"businessname"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Fractal Demo"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"email"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"FractalSBox@fractal.com"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"first_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Evie"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"join_date"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"2024-04-15T01:37:57.000Z"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"last_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Mann"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"merchant_id"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">
                                              "c985b556-fac8-11ee-b78a-02fabd890500"
                                          </span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"phonenumber"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"1234567895"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"website"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"https://testwebsite.com/"</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"}"}</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"{"}</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"businessname"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"testing business"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"email"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"SB155@fractal.com"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"first_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Evie"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"join_date"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"2024-04-17T15:15:01.000Z"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"last_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Mann"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"merchant_id"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">
                                              "42d50f0a-fccd-11ee-aa14-02fabd890500"
                                          </span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"phonenumber"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"1234567895"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"website"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"https://testwebsite.com/"</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"}"}</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"{"}</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"businessname"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"testing business"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"email"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"SB1555@fractal.com"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"first_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Evie"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"join_date"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"2024-04-17T15:15:28.000Z"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"last_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Mann"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"merchant_id"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">
                                              "5313f526-fccd-11ee-aa14-02fabd890500"
                                          </span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"phonenumber"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"1234567895"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"website"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"https://testwebsite.com/"</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"}"}</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"{"}</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"businessname"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"testing business"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"email"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"SB1555b@fractal.com"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"first_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Evie"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"join_date"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"2024-04-17T15:15:58.000Z"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"last_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Mann"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"merchant_id"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">
                                              "64beb736-fccd-11ee-aa14-02fabd890500"
                                          </span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"phonenumber"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"1234567895"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"website"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"https://testwebsite.com/"</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"}"}</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"{"}</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"businessname"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"testing business"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"email"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"SB122333@fractal.com"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"first_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Evie"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"join_date"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"2024-04-17T16:14:45.000Z"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"last_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Mann"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"merchant_id"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">
                                              "9b38521e-fcd5-11ee-aa14-02fabd890500"
                                          </span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"phonenumber"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"1234567895"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"website"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"https://testwebsite.com/"</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"}"}</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"{"}</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"businessname"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"testing business"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"email"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"SB3@fractal.com"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"first_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Evie"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"join_date"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"2024-05-01T12:59:31.000Z"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"last_name"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"Mann"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"merchant_id"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">
                                              "a6d207df-07ba-11ef-aa14-02fabd890500"
                                          </span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"phonenumber"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"1234567895"</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"      "}
                                          <span className="token property">"website"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">"https://testwebsite.com/"</span>
                                          {"\n"}
                                          {"    "}
                                          <span className="token punctuation">{"}"}</span>
                                          {"\n"}
                                          {"  "}
                                          <span className="token punctuation">]</span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"  "}
                                          <span className="token property">"message"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token string">
                                              "Merchants fetched successfully"
                                          </span>
                                          <span className="token punctuation">,</span>
                                          {"\n"}
                                          {"  "}
                                          <span className="token property">"result"</span>
                                          <span className="token operator">:</span>{" "}
                                          <span className="token boolean">true</span>
                                          {"\n"}
                                          <span className="token punctuation">{"}"}</span>
                                      </code>
                                  </pre>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
    </>
  )
}

export default GetMerchantByClient