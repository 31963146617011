import axios from "axios";
import baseUrl from "../../baseUrl";

export const createClient = async (token, formData) => {
    const response = await axios.post(
        `${baseUrl}/api/v1/super-admin/client/onboarding`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
            },
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const updateClient = async (token, body, id) => {
    const response = await axios.patch(
        `${baseUrl}/api/v1/super-admin/client/${id}`,
        body,
        {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
            },
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const deleteClient = async (token, id) => {
    const response = await axios.delete(
        `${baseUrl}/api/v1/super-admin/client/deletion/${id}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            }
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const getFeeMerchant = async (token, id) => {
    const response = await axios.get(
        `${baseUrl}/api/v1/super-admin/fee/get-by-merchantId/${id}`,
        {
            headers: {
                Authorization: token,
            },
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response.data.data;
};

export const createFeeMerchant = async (token, body, id) => {
    const response = await axios.post(
        `${baseUrl}/api/v1/super-admin/fee/create`,
        { ...body, merchantId: id },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response.data;
};

export const getMerchantDocuments = async (token, id) => {
    const response = await axios.get(
        `${baseUrl}/api/v1/super-admin/merchant/get-pdf/${id}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        }
    );
    if (!response.data.result) {
        throw new Error(response.data.message);
    }
    return response?.data?.data;
};


