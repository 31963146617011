import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tokenAction } from '../redux/tokenSlice';

const Toaster = () => {
    const { toast } = useSelector(state => state.token);
    const dispatch = useDispatch()
    const [state, setstate] = useState(false);
    const handleClose = () => {
        setstate(false)
        dispatch(tokenAction.showToaster({ status: false, message: '', open: false }));
    }

    useEffect(() => {
        setstate(toast.open);
        setTimeout(() => (
            setstate(false)
        ), 5000)
    }, [toast])


    return state ? (
        <div className='container-xl d-flex'>
            {toast.status ?
                <SuccessLayout message={toast.message} handleClose={handleClose} />
                : <FailedLayout message={toast.message} handleClose={handleClose} />}
        </div>
    ) : null;
}

export default Toaster;

export const SuccessLayout = ({ message, handleClose }) => {
    return (
        <div className='toast-Bottom'>
            <p>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_7409_97)">
                        <path d="M19.7988 10.1113C19.7988 15.4616 15.4616 19.7988 10.1113 19.7988C4.76105 19.7988 0.423828 15.4616 0.423828 10.1113C0.423828 4.76106 4.76105 0.423828 10.1113 0.423828C15.4616 0.423828 19.7988 4.76106 19.7988 10.1113ZM8.99078 15.2408L16.1783 8.05328C16.4223 7.80922 16.4223 7.41348 16.1783 7.16941L15.2944 6.28555C15.0504 6.04145 14.6546 6.04145 14.4105 6.28555L8.54883 12.1472L5.81215 9.41051C5.56809 9.16645 5.17234 9.16645 4.92824 9.41051L4.04437 10.2944C3.80031 10.5384 3.80031 10.9342 4.04437 11.1782L8.10688 15.2407C8.35098 15.4848 8.74668 15.4848 8.99078 15.2408Z" fill="#61C699" />
                    </g>
                    <defs>
                        <clipPath id="clip0_7409_97">
                            <rect width="20" height="20" fill="white" transform="translate(0.111328 0.111328)" />
                        </clipPath>
                    </defs>
                </svg>
                {/* <span>edvardmorrow@gmail.com</span> invitation sent successfully <button>Cancel</button></p> */}
                <span>{message}</span>
                <button onClick={handleClose}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path d="M3 3L8 8M13 13L8 8M8 8L13 3L3 13" stroke="#35254D" stroke-width="2"/>
</g>
</svg>
</button>
            </p>
        </div>
    )
}
export const FailedLayout = ({ message, handleClose }) => {
    return (
        <div className='toast-color'>
            <p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_7602_6443)">
                        <path d="M21.8889 12.2222C21.8889 17.7462 17.4114 22.2222 11.8889 22.2222C6.36646 22.2222 1.88892 17.7462 1.88892 12.2222C1.88892 6.70132 6.36646 2.22217 11.8889 2.22217C17.4114 2.22217 21.8889 6.70132 21.8889 12.2222ZM11.8889 14.2383C10.8645 14.2383 10.0341 15.0687 10.0341 16.0931C10.0341 17.1175 10.8645 17.948 11.8889 17.948C12.9133 17.948 13.7438 17.1175 13.7438 16.0931C13.7438 15.0687 12.9133 14.2383 11.8889 14.2383ZM10.1279 7.57112L10.427 13.055C10.441 13.3116 10.6532 13.5125 10.9102 13.5125H12.8677C13.1246 13.5125 13.3368 13.3116 13.3508 13.055L13.6499 7.57112C13.665 7.29394 13.4444 7.06088 13.1668 7.06088H10.611C10.3334 7.06088 10.1128 7.29394 10.1279 7.57112Z" fill="#D93A3A" />
                    </g>
                    <defs>
                        <clipPath id="clip0_7602_6443">
                            <rect width="20" height="20" fill="white" transform="translate(1.88892 2.22217)" />
                        </clipPath>
                    </defs>
                </svg>

                {/* <span>edvardmorrow@gmail.com</span> invitation sent successfully <button>Cancel</button></p> */}
                <span>{message}</span>
                <button onClick={handleClose}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path d="M3 3L8 8M13 13L8 8M8 8L13 3L3 13" stroke="#35254D" stroke-width="2"/>
</g>
</svg>
</button>
            </p>
        </div>
    )
}
