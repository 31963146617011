import React, { useState } from "react";
import { forgotPinEmployee } from "../../../api/Employee";
import { useToken } from "../../../hooks/useToken";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

const PinEmployee = (props) => {
  const token = useToken();
  const { handleState, setState, state, fetchEmployees } = props;
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const handleChange = (e) => {
    const { value } = e.target;
    setData(value);
  };

  const handleData = async (e) => {
    e.preventDefault();
    if (!isEmpty(data)) {
      const body = {
        pin: data,
      };
      try {
        const response = await forgotPinEmployee(token, body, state.pin.id);
        fetchEmployees(
          state.page,
          state.perPage,
          state.searchParam,
          state.filterParam
        );
        toast.success(response.message);
        handleState("pin", false);
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      } catch (error) {
        console.log("error", error);
        toast.error(error?.response?.data?.message?.pin);
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } else {
      setError({ pin: "Pin field is required" });
    }
  };

  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <label
              className="labelStyle"
              style={{ display: "block", marginTop: "2px" }}
            >
              Pin*
            </label>
            <input
              className="custom-date-picker"
              type="text"
              placeholder="Pin"
              name="pin"
              maxLength="4"
              onChange={handleChange}
            />
            {error.pin && (
              <span className="error" style={{ color: "red" }}>
                {error.pin}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="col d-flex" style={{ float: "right" }}>
        <button
          className="btn btn-primary purp border border-1 text-black"
          disabled={state.loading}
          onClick={handleData}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default PinEmployee;
