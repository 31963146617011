import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRoutes, useToken } from "../hooks/useToken";
import axios from "axios";
import baseUrl from "../baseUrl";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import {   apiSlice, useGetSidebarQuery, useLazyGetSidebarQuery } from "../redux/apiSlice";
import { useDispatch } from "react-redux";
import Loader from "../DefaultComponent/Loader";
import PageNotFound from "../DefaultComponent/PageNotFound";


const Protected = (props) => {
  const [sidebarTrigger,{ data: routes, isLoading, isError, error }] = useLazyGetSidebarQuery();
  const dispatch=useDispatch()
   const token = useToken();
   const { Component } = props;
   const navigate = useNavigate();
   const location = useLocation();
   const [ accessible , setAccessible ] = useState(false);
  // const route = routes?.data?.allowedPath;
  // const routes = useRoutes();

  // console.log(window.location.pathname)
 
  useEffect(()=>{
if(!token){
  localStorage.removeItem("token");
  toast.error("Unauthorized");
  navigate("/login");

}
  },[])
  
  if(error?.status==401){
    localStorage.removeItem("token");
    dispatch(apiSlice.util.resetApiState());
    toast.error("Unauthorized");
    navigate("/login");
  }
  
  useEffect(() => {
    if (routes) {
 
      let slug = location?.pathname?.split("/")?.filter((item) => item != "")[0];
      let matchId = routes?.data?.allowedPath?.filter((item) => item?.slug == (slug ? `/${slug}` : '/'))[0]?.menu_id;
      if (matchId) {
        checkPermission(matchId);
      } else {
        navigate(routes?.data?.allowedPath[0]?.slug);
        if (isEmpty(routes?.data?.allowedPath[0]?.slug)) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      }
    } 
    else if (token && !routes){
      sidebarTrigger();
    }
     else{
      // toast.error("Unauthorized");
  
      localStorage.removeItem("token");
      navigate("/login");

    }
  }, [location.pathname,routes])

  const checkPermission = async(matchId) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/admin/auth/check-access`, {
          headers: {
            Authorization: token,
            menu_id: matchId,
          },
        });
       
          if(response?.data?.result){
            setAccessible(response?.data?.result);
          } else  {
            navigate(routes?.data?.allowedPath[0]?.slug);
          }
        
    } catch (err) {
      setAccessible(false);
      if (err?.response?.status == 401) {
        navigate("/login");
        localStorage.removeItem("token");
        toast.error(err?.response?.statusText)
      }else{
        toast.error(err?.message)
      }
    }
  };

  if (error?.status == "FETCH_ERROR") {
    return (<PageNotFound />)
  }
  if(isLoading) {
    return <Loader loading={isLoading} />

  }
  return token && accessible
    && <Component />;
};

export default Protected;


