import axios from "axios";
import baseUrl from "../baseUrl";

export const getEmployee = async (token, params) => {
  const response = await axios.get(`${baseUrl}/api/v1/admin/employee/get-all`, {
    headers: {
      Authorization: token,
    },
    params,
  });
  // if (!response.data.result) {
  //   throw new Error(response.data.message);
  // }
  return response.data;
};

export const getRolesAll = async (token) => {
  const response = await axios.get(`${baseUrl}/api/v1/admin/role/get-all`, {
    headers: {
      Authorization: token,
    },
    params: {
      isAll: true,
    },
  });
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const createEmployee = async (token, body) => {
  const response = await axios.post(
    `${baseUrl}/api/v1/admin/employee/create`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const updateEmployee = async (token, body, id) => {
  const response = await axios.patch(
    `${baseUrl}/api/v1/admin/employee/update`,
    { ...body, employeeId: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const forgotPasswordEmployee = async (token, body, id) => {
  const response = await axios.patch(
    `${baseUrl}/api/v1/admin/employee/update-password`,
    { ...body, employeeId: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const forgotPinEmployee = async (token, body, id) => {
  const response = await axios.patch(
    `${baseUrl}/api/v1/admin/employee/set-pin`,
    { ...body, employeeId: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const deleteEmployee = async (token, id) => {
  const response = await axios.delete(
    `${baseUrl}/api/v1/admin/employee/delete`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      params: {
        employeeId: id,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};