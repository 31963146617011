import React, { useEffect, useState } from 'react'
import Loader from '../DefaultComponents/Loader'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid"
import SardineService from '../../components/SardineService';
import axios from 'axios';
import baseUrl from '../../baseUrl';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { tokenAction } from '../../redux/tokenSlice';
import logo from "../../assets/images/Logo_Fractal_Icon_Black.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useRefToken, usesuperLoginDetails } from '../hooks/useToken';

const LoginScreen = () => {
  const refToken = useRefToken();
  const Details = usesuperLoginDetails();
  // const captchaReference = useRef(null);
  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    sessionKey: uuidv4(),
    clientId: process.env.REACT_APP_CLIENT_KEY,
    bot_key: ""
  });
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    if (userData?.clientId) {
      SardineService.initialize(userData?.sessionKey);
    }
  }, [userData?.clientId]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  useEffect(() => {
    if (refToken) {
      return navigate("/admin/dashboard-admin");
    } else if (Details) {
      setUserData((prev) => ({ ...prev, email: Details.email }));
      setCheck(Details.check)
    }
    localStorage.removeItem("timer");
  }, []);
  const updateSardineData = (id) => {
    SardineService.updateConfig({
      userIdHash: id,
      flow: "login",
    });
  };

  const Validation = () => {
    let error = {};
    if (!requiredValue(userData.password)) {
      error.password = "password is required";
    }
    if (!requiredValue(userData.email)) {
      error.email = "email is required";
    } else if (!handleEmail(userData.email)) {
      error.email = "email must be valid format";
    }
    if (Object.keys(error).length < 1) {
      return false;
    }
    return error;
  };

  const requiredValue = (value) => {
    if (!value) {
      return false;
    }
    return true;
  };

  const handleEmail = (value) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});
    let errors = Validation();
    if (!errors) {
      setLoading(true);
      let loginTimeout;
      try {
        // Timeout to stop loading if login takes too long
        loginTimeout = setTimeout(() => {
          setLoading(false);
          toast.error("Login error. Please try again.");
        }, 11000); // Timeout after 11 seconds

        const resp = await axios.post(
          `${baseUrl}/api/v1/super-admin/auth/login`,
          userData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        if (resp?.status == 200) {
          // localStorage.clear();
          clearTimeout(loginTimeout); // Clear the timeout when the request succeeds
          setLoading(false);
          updateSardineData(resp?.data?.email);
          if (process.env.REACT_APP_TWO_FA == 1) {
            toast.success("Two-Factor Authentication Code Sent");
            let user = JSON.stringify(resp?.data);
            localStorage.setItem("local-data", user);
            if (check) {
              localStorage.setItem("superLoginDetails", JSON.stringify({ email: userData.email, check: check }));
            } else {
              localStorage.removeItem("superLoginDetails");
            }
            navigate("/super-admin-otp", { replace: true, state: true });
          } else {
            if (typeof resp?.data?.data == 'object') {
              if (!isEmpty(resp?.data?.data?.token)) {
                toast.success("Login Successful");
              }
              dispatch(tokenAction.getToken(resp?.data?.data?.token));
              let refreshToken = JSON.stringify(resp?.data?.data?.refreshToken);
              localStorage.setItem("refreshToken", refreshToken);
              if (check) {
                localStorage.setItem("superLoginDetails", JSON.stringify({ email: userData.email, check: check }));
              } else {
                localStorage.removeItem("superLoginDetails");
              }
              navigate("/admin/dashboard-admin", { state: { welcome: true } });
            } else {
              localStorage.clear();
              toast.error("Something went wrong");
            }
          }
        }
      } catch (error) {
        clearTimeout(loginTimeout); // Clear timeout if an error occurs
        setLoading(false);
        toast.error(error?.response.data.message);
        console.log(error);
      }
    } else {
      setError(errors);
    }
  };

  // const verifyRecaptcha = async (token) => {
  //   const secretKey = process.env.REACT_APP_SITE_KEY; // Replace with your actual secret key
  //   const url = "https://www.google.com/recaptcha/api/siteverify";
  //   const params = new URLSearchParams();
  //   params.append("secret", secretKey);
  //   params.append("response", token);

  //   try {
  //     const response = await axios.post(url, params.toString(), {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     });



  //     const { success, score } = response.data;

  //     if (success && score >= 0.5) {
  //       // Token is valid and score is acceptable (adjust score threshold as needed)
  //       toast.success("Token is valid and score is acceptable");
  //       return true;
  //     } else {
  //       // Token is invalid or score is too low
  //       toast.error("Token is invalid or score is too low");
  //       return false;
  //     }
  //   } catch (error) {
  //     console.error("Error verifying reCAPTCHA token:", error);
  //     return false;
  //   }
  // };

  const handleCheck = () => {
    setCheck(!check);
  };

  return (
    <>
      <Loader loading={loading} />
      <div className="app app-login p-0">
        <div className="row g-0 app-auth-wrapper">
          <div className="col-12 col-md-6 col-lg-6 auth-main-col log-left otp-mn-sec">
            <div className="d-flex flex-column align-content-end">
              <div className="app-auth-body mx-auto">
                <div className="app-auth-branding mb-5 app-logo">
                  <img className="logo-icon me-2" src={logo} alt="logo" />
                </div>
                <h2 className="auth-heading mt-5">Access the Fractal Portal</h2>
                <div className="auth-form-container text-start  mt-3">
                  <form
                    className="auth-form login-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="email mb-3">
                      {/* <label className="sr-only" htmlFor="signin-email">
                        Email
                      </label> */}
                      <label>Email Address</label>
                      <input
                        id="email"
                        name="email"
                        type="text"
                        className="form-control signin-email"
                        placeholder="Enter email address"
                        value={userData.email}
                        onChange={handleChange}
                        autocomplete="off"
                      />
                      {error.email && (
                        <span className="error text-danger fs-12">
                          {error.email}
                        </span>
                      )}
                    </div>
                    {/*//form-group*/}
                    <div className="password mb-3">
                      {/* <label className="sr-only" htmlFor="signin-password">
                        Password
                      </label> */}
                      <label>Password</label>
                      <div className="psss-inpt">
                        <input
                          id="password"
                          name="password"
                          value={userData.password}
                          type={showPassword ? 'text' : 'password'}
                          className="form-control signin-password"
                          placeholder="Enter password"
                          onChange={handleChange}
                          autocomplete="off"
                        />
                        <button
                          type="button"
                          className="toggle-password-btn"
                          onClick={togglePasswordVisibility}
                          aria-label="Toggle password visibility"
                        >
                          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                      </div>
                      {error.password && (
                        <span className="error text-danger fs-12">
                          {error.password}
                        </span>
                      )}
                    </div>
                    <input type='text' id='bot_key' name='bot_key' value={userData?.bot_key} onChange={handleChange} style={{ display: 'none' }} />

                    <div className="text-center">
                      {/* <ReCAPTCHA
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          // apiUrl = {process.env.REACT_APP_API_URL}
                          ref={captchaReference}
                          onChange={verifyRecaptcha}
                          // onClick={verifyRecaptcha}
                        /> */}
                      <button
                        type="submit"
                        className=" idle-green-btn w-100 theme-btn mx-auto mb-3"
                        disabled={loading}
                      >
                        Log In
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <p className="remember"
                        style={{
                          fontWeight: 600,
                          color: "#35254d",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center"
                        }}
                        onClick={handleCheck}
                      >
                        <input
                          type="radio"
                          checked={check}
                          onChange={() => setCheck(!check)}

                        />
                        Remember me
                      </p>

                      {<p style={{ fontWeight: 600, color: "#35254d" }}>
                        <Link to="/super-forgot-password"> Forgot Password</Link>
                      </p>}
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 auth-background-col">
            <div className="auth-background-holder"></div>
            <div className="auth-background-overlay p-3 p-lg-5">
              <div className="d-flex flex-column align-content-end">
                <div className="h-100" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default LoginScreen
