import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const tkn = getState().token.token;
        const tk = localStorage.getItem('token');
        if (tkn || tk) {
            let token = JSON.parse(tk);
            if (token || tkn) {
                headers.set('Authorization', `${tkn ?? token}`);

            }
            return headers;
        }
    },
});

export const merchantApiSlice = createApi({
    reducerPath: 'merchantApi',
    baseQuery,
    tagTypes: ['merchant'],
    endpoints: (builder) => ({
        getMerchantTransaction: builder.query({
            query: () => '/api/v1/merchant/account/transaction/list',
        }),
        getTransactionDetailById: builder.query({
            query: (id) => `/api/v1/merchant/account/transaction/get-by-id/${id}`,
        }),
        // transformErrorResponse: (response, meta, arg) => {
        //     console.log("erroro =============response",response,meta)
        //     return response
        // },

    }),
});

export const { useGetMerchantTransactionQuery,useGetTransactionDetailByIdQuery } = merchantApiSlice;
