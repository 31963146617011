import React, { useEffect, useState } from 'react'
import Loader from '../../../DefaultComponents/Loader';
import axios from 'axios';
import baseUrl from '../../../../baseUrl';
// import { useRefToken } from '../../../hooks/useRefToken';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useRefToken } from '../../../hooks/useToken';

const Profile = () => {
    const refToken = useRefToken();
    const navigate = useNavigate();
    const [state, setState] = useState({
        accData: {},
        loading: false,
        // key: false,
        // keys: false,
    });

    const fetchClientData = async () => {
        try {
            setState((prev) => ({ ...prev, loading: true }));
            const response = await axios.get(`${baseUrl}/api/v1/super-admin/auth/get-profile`, {
                headers: {
                    Authorization: refToken,
                },
            });
            setState((prev) => ({ ...prev, loading: false }));
            const responseData = response?.data;
            console.log("responseData", responseData);
            if (responseData?.result) {
                // setData(responseData.data);
                setState((prev) => ({ ...prev, accData: responseData?.data }));
            }
        } catch (error) {
            setState((prev) => ({ ...prev, loading: false }));
            toast.error(error?.response?.data?.message || "Failed to fetch profile data");
            if (error?.response?.data?.message?.includes("Unauthorized")) {
                navigate("/super-admin-login");
                localStorage.clear();
            }
            console.error("Fetch client data error:", error);
        }
    };

    useEffect(() => {
        fetchClientData();
    }, []);
    
    return (
        <div className="container-xl">
            <div className="d-flex justify-content-between align-items-start mb-3"></div>
            <div className="tab-content" id="orders-table-tab-content">
                <div
                    className="tab-pane fade show active"
                    id="orders-all"
                    role="tabpanel"
                    aria-labelledby="orders-all-tab"
                >
                    <div className="app-card-body">
                        <h4 className="heading">Developer</h4>
                        <p className="sub-heading">Profile</p>

                        <div className="white-box-content">

                            <div className="row">
                                <div className="col-3 Apikey"  >Email</div>
                                <div className="col-9 Apikey"><span style={{ wordBreak: 'break-all' }}>{state.accData?.email}</span></div>
                            </div>
                            <hr />


                            <div className="row ">
                                <div className="col-3 Apikey">Name</div>
                                <div className="col-9 Apikey">{state.accData?.first_name} {state.accData?.last_name}</div>
                            </div>
                            <hr />




                            {/* <p className="sub-heading" style={{ marginBottom: "1.5em", marginTop: '1em' }}>
                              Credentials
                          </p> */}


                            {/* <div className="row">
                              <div className="col-md-3 col-12 Apikey" >Client ID</div>
                              <div className="col-md-9 col-12 Apikey"> {data?.h_guid ? data?.h_guid : data?.guid}</div>
                          </div> */}
                            {/* <div className="row">
                              <div className="col-md-3 col-12 Apikey" >Secret Key</div>
                              <div className="col-md-7 col-12 Apikey d-flex">
                                  <div className="d-flex align-items-center">
                                      <span style={{ wordBreak: 'break-all' }}>

                                          {data.public_data?.api_key ? (
                                              <PasswordCell
                                                  password={data.public_data.api_key}
                                                  showPassword={showPasswords}
                                              />
                                          ) : (
                                              "N/A"
                                          )}



                                      </span>
                                      {data.public_data?.api_key &&
                                          (showPasswords ? (
                                              <i className="fa fa-eye-slash" aria-hidden="true" onClick={togglePasswordsVisibility}></i>
                                              // <img src={eye} className="ms-2" aria-hidden="true" onClick={togglePasswordsVisibility} />
                                          ) : (
                                              <i className="fa fa-eye ms-2" aria-hidden="true" onClick={togglePasswordsVisibility}></i>

                                          ))}  <div className="sml-line"></div></div>




                                  <div>
                                      {data.public_data?.api_key && (
                                          <>
                                              <div className="copy-code">
                                                  <img
                                                      src={Reload}
                                                      alt="Reload"
                                                      onClick={() => handleKeys(true)}
                                                  />
                                                  <CopyToClipboard text={data.public_data.api_key} onCopy={handleCopy}>
                                                      <img
                                                          src={Copy}
                                                          alt="Copy"
                                                          style={{ marginLeft: 2 }}
                                                      />
                                                  </CopyToClipboard>
                                              </div>
                                          </>
                                      )}
                                  </div>

                              </div>
                          </div> */}
                            {/* <hr />

                          <Modal
                              className="confirm-key"
                              style={{ width: "100%" }}
                              show={state.key}
                              onHide={handleClose}
                          >
                              <Modal.Header closeButton>
                              </Modal.Header>
                              <Modal.Body>
                                  <KeyConfirm
                                      setState={setState}
                                      accountData={data}
                                      state={props.state}
                                  />
                              </Modal.Body>
                          </Modal> */}

                        </div>
                    </div>
                </div>
            </div>
            <Loader loading={state.loading} />
        </div>
    )
}

export default Profile
