import React from 'react'
import Calculator from './pos/Calculator'

const DigitalPos = () => {
  return (
    <div>
<Calculator />
        
    </div>
  )
}

export default DigitalPos