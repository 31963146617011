import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import BackBtn from '../../../DefaultComponents/BackBtn';
import axios from 'axios';
import baseUrl from '../../../../baseUrl';
import DatePicker from "react-datepicker";
import { useRefToken } from '../../../hooks/useToken';
import moment from 'moment';
import { dataIndustries } from './Industries';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import Loader from '../../../DefaultComponents/Loader';

const EditClientMerchant = () => {
    const param = useParams();
    const refToken = useRefToken();
    const navigate = useNavigate();
    const { merchantId: m_id } = param;
    const [errors, setErrors] = useState({});
    const [website, setUrl] = useState("https://www.abc.com");
    const [state, setState] = useState({
        loading: false,
    });
    const [buttonLoading, setButtonLoading] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);
    const [bot_key, setBot_key] = useState("");
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        date_of_birth: "",
        established_date: "",
        country: "",
        address: "",
        per_city: "",
        per_state: "",
        zip: "",
        business_country: "",
        business_industry: "",
        business_name: "",
        business_structure: "",
        website: "",
        processing_environment: "",
        avgTicket: "",
        annualCCSales: "",
        b_title: "CEO",
        dba: "",
        ein: "",
        MerchantApplicationId: "",
        ssn: "",
        ownership_percent: "",
        ownershiptype: "CORPORATION",
        legal_b2c: "",
        legal_b2b: "",
        legal_cnpinternet: "",
        legal_cnpphoneemail: "",
        legal_percentdeliveredover30days: "",
        legal_percentdeliveredsameday: "",
        percentdelievered7days: "",
        percentdelivered14days: "",
        legal_cp: "",
        legal_productssold: "",
        file: null,
        business_email: "",
        // commission_type: "",
        personal_email: "",
        Industry_name: "",
    });
    const [per_address, setPer_address] = useState(
        "4069 Lake Drive Southeast Grand Rapids MI USA"
    );
    const [per_city, setPer_city] = useState("Grand Rapids");
    const [per_state, setPer_state] = useState("Michigan");
    const [businessCountry, setBusinessCountry] = useState("US");
    const [per_zip, setPer_zip] = useState("49546");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePhoneChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value.replace(/[^0-9]/g, ""),
        }));
    };

    const handleDOBChange = (inputDate) => {
        if (inputDate) {
            const formattedDate = moment(inputDate).format("YYYY-MM-DD");
            setFormData((prevState) => ({ ...prevState, date_of_birth: formattedDate }));
        }
    };

    const handleIndustryChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleUrlChange = (event) => {
        const newValue = event.target.value;
        if (newValue) {
            if (newValue == "https:/") {
                setUrl("https://");
            } else {
                if (newValue.startsWith("https://")) {
                    setUrl(newValue);
                } else {
                    setUrl("https://" + newValue);
                }
            }
        }
    };

    const handleBusinessSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            const { address_components } = results[0];
            let streetNumber = "";

            // Initialize variables to hold address components
            let selectedAddress = {
                per_address: address,
                per_city: "",
                per_state: "",
                business_country: "",
                per_zip: "",
            };

            address_components.forEach((component) => {
                const { types, long_name, short_name } = component;
                if (types.includes("street_number")) {
                    streetNumber = short_name;
                }
                if (types.includes("locality")) {
                    selectedAddress.per_city = short_name;
                } else if (types.includes("administrative_area_level_1")) {
                    selectedAddress.per_state = short_name;
                } else if (types.includes("country")) {
                    selectedAddress.business_country = short_name;
                } else if (types.includes("postal_code")) {
                    selectedAddress.per_zip = long_name;
                } else if (types.includes("route")) {
                    selectedAddress.per_address =
                        short_name;
                }
            });
            setPer_address(address);
            setPer_city(selectedAddress.per_city);
            setPer_state(selectedAddress.per_state);
            setBusinessCountry(selectedAddress.business_country);
            setPer_zip(selectedAddress.per_zip);

            setFormData((prevFormData) => ({
                ...prevFormData,
                address: selectedAddress.per_address,
                city: selectedAddress.per_city,
                business_state: selectedAddress.business_state,
                business_country: selectedAddress.business_country,
                zip: selectedAddress.per_zip,
            }));
            // Fetch coordinates if needed
            const latLng = await getLatLng(results[0]);
        } catch (error) {
            console.error("Error selecting address:", error);
        }
    };

    const handleEdit = async (id) => {
        try {
            setState((prev) => ({ ...prev, loading: true }));
            const { data } = await axios.get(
                `${baseUrl}/api/v1/super-admin/merchant/show/${id}`,
                {
                    headers: {
                        Authorization: refToken,
                    },
                }
            );
            setState((prev) => ({ ...prev, loading: false }));
            setFormData({
                merchant_id: data.data?.merchantData?.id,
                ownership_percent: data.data?.merchantData?.b_ownership_percentage,
                first_name: data.data?.merchantData?.first_name,
                last_name: data?.data?.merchantData?.last_name,
                phone: data.data?.merchantData?.phone_no,
                business_name: data.data?.merchantData?.business_name,
                business_email: data.data?.merchantData?.business_email,
                website: data.data?.merchantData?.website,
                annualCCSales: data.data?.merchantData?.annualCCSales,
                legal_b2b: data.data?.merchantData?.legal_b2b,
                legal_b2c: data.data?.merchantData?.legal_b2c,
                avgTicket: data.data?.merchantData?.avgTicket,
                business_structure: data.data?.merchantData?.cmp_structure,
                processing_environment: data.data?.merchantData?.processing_environment,
                legal_productssold: data.data?.merchantData?.legal_productssold,
                date_of_birth: data.data?.merchantData?.date_of_birth,
                established_date: data.data?.merchantData?.established_date,
                address: data.data?.merchantData?.per_address,
                city: data.data?.merchantData?.per_city,
                state: data.data?.merchantData?.per_state,
                zip: data.data?.merchantData?.per_zip,
                country: data.data?.merchantData?.country,
                ein: data.data?.merchantData?.ein,
                ssn: data.data?.merchantData?.ssn,
                email: data.data?.merchantData?.personal_email,
                b_title: data.data?.merchantData?.b_title,
                business_industry: data.data?.merchantData?.Industry,
                legal_cnpinternet: data.data?.merchantData?.legal_cnpinternet,
                legal_cnpphoneemail: data.data?.merchantData?.legal_cnpphoneemail,
                legal_percentdeliveredover30days: data.data?.merchantData?.legal_percentdeliveredover30days,
                legal_percentdeliveredsameday: data.data?.merchantData?.legal_percentdeliveredsameday,
                percentdelievered7days: data.data?.merchantData?.percentdelievered7days,
                percentdelivered14days: data.data?.merchantData?.percentdelivered14days,
                legal_cp: data.data?.merchantData?.legal_cp,
            });
            setPer_address(data.data?.merchantData?.per_address);
            setPer_city(data.data?.merchantData?.per_city);
            setPer_state(data.data?.merchantData?.per_state);
            setBusinessCountry(data.data?.merchantData?.country);
            setPer_zip(data.data?.merchantData?.per_zip);
        } catch (error) {
            setState((prev) => ({ ...prev, loading: false }));
            console.error("Error:", error);
        }
    };
    console.log("formData", formData);


    const handleBlur = (e) => {
        const { name } = e.target;
        if (name === 'website') {
            setFormData(prevState => {
                const value = prevState[name];
                const formattedValue = value.startsWith('http://') || value.startsWith('https://')
                    ? value
                    : `https://${value}`;
                return { ...prevState, [name]: formattedValue };
            });
        }
    };

    const formatPhoneNumber = (numericValue) => {
        const cleaned = ("" + numericValue).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return numericValue;
    };

    const formattedDate = (data) => {
        if (!data) {
            return "";
        } else {
            const momentDate = moment(data);
            if (momentDate.isValid()) {
                return momentDate.format("YYYY-MM-DD");
            } else {
                return "Invalid date";
            }
        }
    };

    const isValidDate = (dateString) => {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        return regex.test(dateString);
    };

    const isValidEmail = (e) => {
        let regex = /^\S+@\S+\.\S+$/;
        return regex.test(e);
    };

    const isValidUrl = (url) => {
        console.log("url", url);

        let regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+)(\.[a-zA-Z]{2,})+$/;
        console.log("regex.test(url)", regex.test(url));

        return regex.test(url);
    };

    const validateForm = () => {
        let errors = {};
        if (formData.first_name == "") {
            errors.first_name = "First name is required";
        }
        if (formData.last_name == "") {
            errors.last_name = "Last name is required";
        }
        if (formData.business_name == "") {
            errors.business_name = "Business name is required";
        }

        if (formData.personal_email == "") {
            errors.personal_email = "Email is required";
        }
        // business_email
        if (formData.website !== "" && !isValidUrl(formData.website)) {
            errors.website = "Please enter a valid website URL";
        }
        if (formData.business_email == "") {
            errors.business_email = "Business email is required";
        } else if (!isValidEmail(formData.business_email)) {
            errors.business_email = "Please enter a valid email";
        }
        if (formData.per_address == "") {
            errors.per_address = "Business address is required";
        }
        if (formData.per_city == "") {
            errors.per_city = "City is required";
        }
        if (formData.per_state == "") {
            errors.per_state = "State is required";
        }
        if (formData?.business_country == "") {
            errors.business_country = "Country is required";
        }
        if (formData.mid && formData.mid == "") {
            errors.mid = "Merchant Application Id is required";
        }

        // if (formData.commission_type == "") {
        //     errors.commission_type = "Commission type is required";
        // }
        // if (formData.cms_acct == "") {
        //     errors.cms_acct = "Commission account is required";
        // }

        if (formData.user_name == "") {
            errors.user_name = "User name is required";
        }
        if (formData.ssn == "") {
            errors.ssn = "Security social number must be 9 characters";
        }

        if (formData.ein == "") {
            errors.ein = "Employee identification number must be 9 characters";
        }
        if (formData.date_of_birth == "") {
            errors.date_of_birth = "Date of birth is required";
        } else if (!isValidDate(formData.date_of_birth)) {
            errors.date_of_birth = "Please enter a valid date of birth (YYYY-MM-DD)";
        }
        if (formData.per_zip == "") {
            errors.per_zip = "Zip code is required";
        }
        console.log("errors", errors);

        setErrors(errors);
        return Object.keys(errors).length == 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonLoading(true);
        if (!validateForm()) {
            setButtonLoading(false);
            toast.error("Please fill out all required fields.");
            return;
        }
        try {
            setState((prev) => ({ ...prev, loading: true }));
            const response = await axios.post(
                `${baseUrl}/api/v1/super-admin/merchant/update`,
                { ...formData, bot_key },
                {
                    headers: {
                        Authorization: refToken,
                        "Content-Type": "application/JSON",
                    },
                }
            );
            setState((prev) => ({ ...prev, loading: false }));
            if (response.status === 200) {
                toast.success("Merchant Updated successfully!");
                navigate(-1);
            } else {
                toast.error(response.data.message);
            }
            setButtonLoading(false);
        } catch (error) {
            setState((prev) => ({ ...prev, loading: false }));
            console.error("Error:", error);
            if (error.response && error.response.data) {
                console.log("Error response data:", error.response.data);
                if (error.response.data.errors) {
                    const errorMessages = Object.values(error.response.data.errors).join(
                        ", "
                    );
                    toast.error(errorMessages);
                } else if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("An unexpected error occurred. Please try again later.");
                }
            }
            setButtonLoading(false);
        }
    };

    useEffect(() => {
        if (m_id) {
            handleEdit(m_id);
        }
    }, [m_id]);

    return (
        <>
            <div className="pb-2">
                <BackBtn />
            </div>
            <div className="container">
                <h1 className="app-page-title text-center">Edit Merchant</h1>
                <h5 className="app-page-title">Basic Details</h5>
                <div className="step-content">
                    <>
                        <div className="step-pane active" id="step-1">
                            <div className="container all-steps">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label
                                            className="labelStyle"
                                            style={{ display: "block" }}
                                        >
                                            First Name*
                                        </label>
                                        <input
                                            className="custom-date-picker"
                                            type="text"
                                            placeholder="First Name"
                                            name="first_name"
                                            value={formData.first_name}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.first_name && (
                                            <span className="error" style={{ color: "red" }}>
                                                {errors.first_name}
                                            </span>
                                        )}
                                    </div>

                                    <div className="col-md-4">
                                        <label
                                            className="labelStyle"
                                            style={{ display: "block" }}
                                        >
                                            Last Name*
                                        </label>
                                        <input
                                            className="custom-date-picker"
                                            type="text"
                                            name="last_name"
                                            value={formData.last_name}
                                            placeholder="Last Name"
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.last_name && (
                                            <span className="error" style={{ color: "red" }}>
                                                {errors.last_name}
                                            </span>
                                        )}
                                    </div>

                                    <div className="col-md-4">
                                        <label
                                            className="labelStyle"
                                            style={{ display: "block" }}
                                        >
                                            Phone Number*
                                        </label>
                                        <input
                                            className="custom-date-picker"
                                            type="text"
                                            name="phone"
                                            placeholder="Phone"
                                            value={formatPhoneNumber(formData.phone)}
                                            onChange={handlePhoneChange}
                                            // pattern="[0-9]*"
                                            inputMode="numeric"
                                            maxLength="13"
                                            required
                                        />
                                        {errors.phone && (
                                            <span className="error" style={{ color: "red" }}>
                                                {errors.phone}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label
                                            className="labelStyle"
                                            style={{ display: "block" }}
                                        >
                                            Ein
                                        </label>
                                        <input
                                            className="custom-date-picker"
                                            type="text"
                                            name="ein"
                                            maxLength={9}
                                            placeholder="EIN"
                                            onChange={handleChange}
                                            value={formData.ein}
                                            required
                                        />
                                        {errors.ein && (
                                            <span className="error" style={{ color: "red" }}>
                                                {errors.ein}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label
                                            className="labelStyle"
                                            style={{ display: "block" }}
                                        >
                                            SSN
                                        </label>
                                        <input
                                            className="custom-date-picker"
                                            type="text"
                                            name="ssn"
                                            maxLength={9}
                                            placeholder="SSN"
                                            onChange={handleChange}
                                            required
                                            value={formData.ssn}
                                        />
                                        {errors.ssn && (
                                            <span className="error" style={{ color: "red" }}>
                                                {errors.ssn}
                                            </span>
                                        )}
                                    </div>
                                    {/* <div className="col-md-4">
                                        <label
                                            className="labelStyle"
                                            style={{ display: "block" }}
                                        >
                                            Commision Type
                                        </label>
                                        <input
                                            className="custom-date-picker"
                                            type="text"
                                            name="commission_type"
                                            placeholder="Commision Type"
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.commission_type && (
                                            <span className="error" style={{ color: "red" }}>
                                                {errors.commission_type}
                                            </span>
                                        )}
                                    </div> */}
                                    {/* <div className="col-md-4">
                                        <label
                                            className="labelStyle"
                                            style={{ display: "block" }}
                                        >
                                            Commision Account
                                        </label>
                                        <input
                                            className="custom-date-picker"
                                            type="text"
                                            name="cms_acct"
                                            placeholder="Commision Account"
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.cms_acct && (
                                            <span className="error" style={{ color: "red" }}>
                                                {errors.cms_acct}
                                            </span>
                                        )}
                                    </div> */}
                                    <div className="col-md-4">
                                        <label
                                            className="labelStyle"
                                            style={{ display: "block" }}
                                        >
                                            Email
                                        </label>
                                        <input
                                            className="custom-date-picker"
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            onChange={handleChange}
                                            required
                                            value={formData.email}
                                        />
                                        {errors.personal_email && (
                                            <span className="error" style={{ color: "red" }}>
                                                {errors.personal_email}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        <label
                                            className="labelStyle"
                                            style={{ display: "block" }}
                                        >
                                            Date Of Birth*
                                        </label>
                                        <DatePicker
                                            name="dob"
                                            selected={formattedDate(formData.date_of_birth)}
                                            onChange={handleDOBChange}
                                            className="custom-datepicker"

                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="YYYY-MM-DD"
                                        />


                                        {errors.date_of_birth && (
                                            <span className="error" style={{ color: "red" }}>
                                                {errors.date_of_birth}
                                            </span>
                                        )}
                                    </div>

                                    <div className="heading-container">
                                        <div className="row">
                                            <div className="heading-text">
                                                <h4 className="section-title">Other Details</h4>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    className="labelStyle"
                                                    style={{ display: "block" }}
                                                >
                                                    Business name
                                                </label>
                                                <input
                                                    className="custom-date-picker"
                                                    type="text"
                                                    name="business_name"
                                                    value={formData.business_name}
                                                    placeholder="Business name"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.business_name && (
                                                    <span className="error" style={{ color: "red" }}>
                                                        {errors.business_name}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label className="labelStyle">Business Email</label>
                                                <input
                                                    className="custom-date-picker"
                                                    type="text"
                                                    name="business_email"
                                                    value={formData.business_email}
                                                    placeholder="Business email"
                                                    onChange={handleChange}
                                                />
                                                {errors.business_email && (
                                                    <span className="error" style={{ color: "red" }}>
                                                        {errors.business_email}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="col-md-6">
                                                <label
                                                    className="labelStyle"
                                                    htmlFor="industrySelect"
                                                    style={{
                                                        display: "block",
                                                        width: "11.3vw",
                                                    }}
                                                >
                                                    Industry
                                                </label>
                                                <select
                                                    className="custom-date-picker"
                                                    id="industrySelect"
                                                    onChange={handleIndustryChange}
                                                    defaultValue=""
                                                    value={formData.business_industry}
                                                    name="business_industry"
                                                >
                                                    <option value="">Select Industry</option>
                                                    {dataIndustries.map((industry) => (
                                                        <option
                                                            value={industry.value}
                                                            key={industry.value}
                                                        >
                                                            {industry.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-md-6">
                                                <label
                                                    className="labelStyle"
                                                    style={{ display: "block" }}
                                                >
                                                    Website
                                                </label>
                                                <input
                                                    className="custom-date-picker"
                                                    type="text"
                                                    name="website"
                                                    value={formData.website}
                                                    placeholder="Website link"
                                                    onChange={handleChange}
                                                    onBlur={(e) => handleBlur(e)}
                                                    required
                                                />
                                                {errors.website && (
                                                    <span className="error" style={{ color: "red" }}>
                                                        {errors.website}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    className="labelStyle"
                                                    style={{ display: "block" }}
                                                >
                                                    Ownership Percent (%)
                                                </label>
                                                <input
                                                    className="custom-date-picker"
                                                    type="text"
                                                    name="ownership_percent"
                                                    value={formData.ownership_percent}
                                                    placeholder="Ownership Percent"
                                                    onChange={handleChange}

                                                    required
                                                />
                                                {errors.ownership_percent && (
                                                    <span className="error" style={{ color: "red" }}>
                                                        {errors.ownership_percent}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="col-md-6">
                                                <label className="labelStyle">Position/Title</label>
                                                <select
                                                    className="custom-date-picker"
                                                    name="b_title"
                                                    value={formData.b_title}
                                                    onChange={handleChange}
                                                    placeholder="Title"
                                                >
                                                    <option value>Title</option>
                                                    <option value="CEO">CEO</option>
                                                    <option value="CFO">CFO </option>
                                                    <option value="COO">COO</option>
                                                    <option value="President">President</option>
                                                    <option value="Secretary">Secretary </option>
                                                    <option value="Treasurer">Treasurer</option>
                                                    <option value="Owner">Owner</option>
                                                    <option value="Vice President">
                                                        Vice President
                                                    </option>
                                                </select>
                                            </div>

                                            <div className="col-md-12">
                                                <label
                                                    className="labelStyle"
                                                    style={{ display: "block" }}
                                                >
                                                    Business Address
                                                </label>
                                                <PlacesAutocomplete
                                                    value={per_address}
                                                    onChange={(text) => {
                                                        setPer_address(text);
                                                    }}
                                                    onSelect={handleBusinessSelect}
                                                >
                                                    {({
                                                        getInputProps,
                                                        suggestions,
                                                        getSuggestionItemProps,
                                                        loading,
                                                    }) => (
                                                        <div>
                                                            <input
                                                                className="custom-date-picker"
                                                                {...getInputProps({
                                                                    placeholder: "Search owner address",
                                                                })}
                                                                required
                                                            />
                                                            <div>
                                                                {loading ? <div>Loading...</div> : null}
                                                                {suggestions.map((suggestion) => {
                                                                    const style = {
                                                                        backgroundColor: suggestion.active
                                                                            ? "#41b6e6"
                                                                            : "#fff",
                                                                        border: suggestion.active
                                                                            ? "1px solid #41b6e6"
                                                                            : "1px solid #ccc",
                                                                        width: "410px",
                                                                        marginLeft: "10px", // Add margin right
                                                                        marginTop: "5px",
                                                                    };
                                                                    return (
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                style,
                                                                            })}
                                                                        >
                                                                            {suggestion.description}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                                {errors.address && (
                                                    <span className="error" style={{ color: "red" }}>
                                                        {errors.address}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="col-md-6">
                                                <label
                                                    className="labelStyle"
                                                    style={{ display: "block" }}
                                                >
                                                    City
                                                </label>
                                                <input
                                                    className="custom-date-picker"
                                                    type="text"
                                                    placeholder="City"
                                                    name="per_city"
                                                    value={per_city}
                                                    onChange={handleChange}
                                                />
                                                {errors.per_city && (
                                                    <span className="error" style={{ color: "red" }}>
                                                        {errors.per_city}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    className="labelStyle"
                                                    style={{ display: "block" }}
                                                >
                                                    State
                                                </label>
                                                <input
                                                    className="custom-date-picker"
                                                    type="text"
                                                    name="per_state"
                                                    value={per_state}
                                                    placeholder="State"
                                                    onChange={handleChange}
                                                />
                                                {errors.per_state && (
                                                    <span className="error" style={{ color: "red" }}>
                                                        {errors.per_state}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    className="labelStyle"
                                                    style={{ display: "block" }}
                                                >
                                                    Country
                                                </label>
                                                <input
                                                    className="custom-date-picker"
                                                    type="text"
                                                    name="business_country"
                                                    value={businessCountry}
                                                    placeholder="Country"
                                                    onChange={handleChange}
                                                />

                                                {errors.business_country && (
                                                    <span className="error" style={{ color: "red" }}>
                                                        {errors.business_country}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    className="labelStyle"
                                                    style={{ display: "block" }}
                                                >
                                                    Zip Code
                                                </label>
                                                <input
                                                    className="custom-date-picker"
                                                    type="text"
                                                    placeholder="Zip Code"
                                                    name="per_zip"
                                                    value={per_zip}
                                                    onChange={handleChange}
                                                />
                                                {errors.per_zip && (
                                                    <span className="error" style={{ color: "red" }}>
                                                        {errors.per_zip}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    className="labelStyle"
                                                    style={{ display: "block" }}
                                                >
                                                    Established Date
                                                </label>
                                                <input
                                                    className="custom-date-picker"
                                                    type="text"
                                                    selected={formattedDate(formData.established_date)}
                                                    placeholder="Established Date"
                                                    name="established_date"
                                                    value={formData.established_date}
                                                    onChange={handleChange}
                                                />
                                                {errors.established_date && (
                                                    <span className="error" style={{ color: "red" }}>
                                                        {errors.established_date}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="col-md-6">
                                                <label className="labelStyle" style={{ display: "block" }}>
                                                    Company Structure
                                                </label>
                                                <select
                                                    className="custom-date-picker"
                                                    name="business_structure"
                                                    value={formData.business_structure}
                                                    onChange={handleChange}
                                                    placeholder="Select Company Structure"
                                                >
                                                    <option value="">Select Company Structure</option>
                                                    <option value="Sole Prop">Sole Prop</option>
                                                    <option value="LLC">LLC</option>
                                                    <option value="Private Corporation">Private Corporation</option>
                                                    <option value="Public Corporation">Public Corporation</option>
                                                    <option value="Non profit">Non Profit</option>
                                                    <option value="limited liability partnership">Limited  Liability Partnership</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label className='labelStyle' >Processing Environment
                                                </label>
                                                <select
                                                    className='custom-date-picker'
                                                    name="processing_environment"
                                                    value={formData.processing_environment}
                                                    onChange={handleChange}
                                                    placeholder='Select Processing Environment'
                                                >
                                                    <option value="">Select Processing Environment</option>
                                                    <option value="cardPresent">Card Present</option>
                                                    <option value="Ecommerce">E-Commerce</option>
                                                    <option value="fuel">Fuel</option>
                                                    <option value="moto">Mail Order/Telephone Order</option>
                                                    <option value="restaurant">Restaurant</option>
                                                    <option value="serviceStation">Service Station</option>
                                                    <option value="supermarket">Supermarket</option>

                                                </select>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="labelStyle" style={{ display: "block" }}>
                                                    Products Sold
                                                </label>
                                                <input
                                                    className="custom-date-picker"
                                                    type="text"
                                                    name="legal_productssold"
                                                    value={formData.legal_productssold}
                                                    placeholder="Products Sold"
                                                    onChange={handleChange}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container all-steps">
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="labelStyle" style={{ display: "block" }}>
                                        Average Ticket
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        name="avgTicket"
                                        value={formData.avgTicket}
                                        placeholder="($)"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labelStyle" style={{ display: "block" }}>
                                        Annual Credit Card Sales
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        name="annualCCSales"
                                        value={formData.annualCCSales}
                                        placeholder="($)"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labelStyle" style={{ display: "block" }}>
                                        B2C Transactions
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="a. Cumulative to 100%"
                                        name="legal_b2c"
                                        value={formData.legal_b2c}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labelStyle" style={{ display: "block" }}>
                                        B2B Transactions
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="a. Cumulative to 100%"
                                        name="legal_b2b"
                                        value={formData.legal_b2b}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labelStyle" style={{ display: "block" }}>
                                        Card Present
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="b. Cumulative to 100%"
                                        name="legal_cp"
                                        value={formData.legal_cp}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labelStyle" style={{ display: "block" }}>
                                        Manual Entry via Phone/Email
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="b. Cumulative to 100%"
                                        name="legal_cnpphoneemail"
                                        value={formData.legal_cnpphoneemail}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labelStyle" style={{ display: "block" }}>
                                        Manual Entry via Internet
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="b. Cumulative to 100%"
                                        name="legal_cnpinternet"
                                        value={formData.legal_cnpinternet}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label className="labelStyle" style={{ display: "block" }}>
                                        Same-Day Delivery
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="c. Cumulative to 100%"
                                        name="legal_percentdeliveredsameday"
                                        value={formData.legal_percentdeliveredsameday}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label
                                        className="labelStyle"
                                        style={{ display: "block" }}
                                        name="legal_percentdeliveredover30days"
                                    >
                                        Deferred Delivery {">"} 7 Days
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="c. Cumulative to 100%"
                                        name="percentdelievered7days"
                                        value={formData.percentdelievered7days}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labelStyle" style={{ display: "block" }}>
                                        Deferred Delivery {">"} 14 Days
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="c. Cumulative to 100%"
                                        name="percentdelivered14days"
                                        value={formData.percentdelivered14days}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labelStyle" style={{ display: "block" }}>
                                        Deferred Delivery {">"} 30 Days
                                    </label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="c. Cumulative to 100%"
                                        name="legal_percentdeliveredover30days"
                                        value={formData.legal_percentdeliveredover30days}
                                        onChange={handleChange}
                                    />
                                </div>
                                <input type='text' id='bot_key' name='bot_key' value={bot_key} onChange={(e) => { setBot_key(e.target.value) }} style={{ display: 'none ' }} />
                                <div className="row pt-4">
                                    <div className="col  d-flex justify-content-center ">
                                        <div className="row-md-5">
                                            <button onClick={handleSubmit} className="btn dark-purp">
                                                {buttonLoading ? (
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                ) : (
                                                    'Submit'
                                                )}
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <Modal
                                    show={showResultModal}
                                    onHide={() => setShowResultModal(false)}
                                >
                                    <Modal.Header closeButton></Modal.Header>
                                    <Modal.Body style={{ textAlign: "center" }}>
                                        <svg
                                            width="60"
                                            height="60"
                                            viewBox="0 0 60 60"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.5"
                                                y="0.5"
                                                width="59"
                                                height="59"
                                                rx="29.5"
                                                stroke="#31B379"
                                            />
                                            <g clipPath="url(#clip0_2659_5018)">
                                                <path
                                                    d="M41.1778 22.248C40.7483 21.8184 40.0518 21.8184 39.6222 22.248L26.4435 35.4268L21.3778 30.3611C20.9483 29.9315 20.2518 29.9316 19.8222 30.3611C19.3926 30.7907 19.3926 31.4871 19.8222 31.9167L25.6657 37.7601C26.0951 38.1897 26.7921 38.1894 27.2213 37.7601L41.1778 23.8036C41.6074 23.3741 41.6074 22.6776 41.1778 22.248Z"
                                                    fill="#31B379"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2659_5018">
                                                    <rect
                                                        width="22"
                                                        height="22"
                                                        fill="white"
                                                        transform="translate(19.5 19.0039)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <h2>
                                            Merchant details updated <br /> successfully
                                        </h2>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                        <Loader loading={state.loading} />
                    </>
                </div>
            </div>
        </>
    )
}

export default EditClientMerchant
