import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Tabs/MerchantOnboard.css";
import axios from "axios";
import { Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import baseUrl from "../../../baseUrl";
// import PaginationPg from "../../../DefaultComponent/PaginationPg";
import Loader from "../../../DefaultComponent/Loader";
import { useToken } from "../../../hooks/useToken";
import DownloadMerchantCSV from "../Form/DownloadMerchantCSV";
import debounce from "lodash/debounce";
import View from "../../../assets/images/View.svg";
import Edit from "../../../assets/images/Edit.svg";
import Transaction from "../../../assets/images/Transaction.svg";
import Download from "../../../assets/images/Download.svg";
import { isEmpty } from "lodash";
import { useGetSidebarQuery } from "../../../redux/apiSlice";
import moment from "moment";
import ExportCSV from "../../../components/ExportCSV";
import ReactDatePicker from "react-datepicker";
// import DatePickers from "../../../DefaultComponent/DatePicker";

const AllVerifiedMerchants = () => {
  const { data: userData, data: menu, isLoading, isError } = useGetSidebarQuery();
  const { role_id, staff_roll_type } = userData?.data;
  const token = useToken();
  const navigate = useNavigate();
  const [merchantList, setMerchantList] = useState([]);
  const [filterDays, setFilterDays] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [state, setState] = useState({
    page: 1,
    perPage: 15,
    searchValue: "",
    totalPage: null,
    loading: false,
    download: false,
    row: {},
  });
  const loader = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [show, setShow] = useState(true);
  const [noDataMessage, setNoDataMessage] = useState(false);
  const [fetMoreData, setFetchMoreData] = useState(0);
  const handleMerchantDetail = (id) => {
    let flag = 'view';
    navigate(`/merchants/${id}/${flag}`);
  };

  const handleEdit = (row) => {
    navigate("/edit-merchant", { state: { data: row.id } });
  };

  const handleTransaction = (id) => {
    let flag = 'transaction';
    navigate(`/merchants/${id}/${flag}`);
  };

  const handleDownload = (row) => {
    handleState("download", true);
    handleState("row", row);
  };

  const verifiedMerchant = async (encodeId) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/admin/merchant/approve-merchant/${encodeId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Merchant has been verified successfully.");
        fetchMerchants();
      } else {
        toast.error("Merchant is not valid. Please try again.");
      }
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
      if (error.response && error.response.data) {
        if (error.response.data.errors) {
          const errorMessages = Object.values(error.response.data.errors).join(
            ", "
          );
          toast.error(errorMessages);
        } else if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred. Please try again later.");
        }
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setMerchantList([]);
    setState((prev) => ({ ...prev, page: pageNumber, totalPage: null }));
    fetchMerchants(pageNumber, state.perPage, state.searchValue);
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return `${month}.${day}.${year}`;
  };

  const fetchMerchants = async (newPage, newPerPage, searchValue) => {
    setShow(true)
    setState((prev) => ({ ...prev, loading: true }));
    // function convertMili(da) {
    //   const date = new Date(da);
    //   return date.getTime();
    // }
    try {
      const params = {
        page: newPage,
      };

      if (newPerPage) {
        params.perPage = newPerPage;
      }

      if (searchValue) {
        params.search = searchValue;
      }
      // if (startDate) {
      //   let date = convertMili(startDate) == convertMili(endDate)
      //     ? getTodayStart()
      //     : convertMili(startDate)
      //   params.startDate = date;
      // }
      // if (endDate) {
      //   let date = convertMili(endDate)
      //   params.endDate = date;
      // }
      const { data } = await axios.get(
        `${baseUrl}/api/v1/admin/merchant/get-all-merchant`,
        {
          headers: {
            Authorization: token,
          },
          params,
        }
      );
      if (searchValue?.trim() || newPage == 1) {
        setMerchantList([]);
      }
      setState((prev) => ({ ...prev, loading: false }));
      const result = data?.data?.allVerifiedMerchant;
      // if (data?.status) {
      //   if (result?.length === 0) {
      //     setHasMore(false);
      //   } else {
      //     setHasMore(data?.status);
      //     setMerchantList((prev) => [...prev, ...result]);
      //     setState((prev) => ({ ...prev, page: state.page + 1, totalPage: data?.data?.totalPage }));
      //   }
      // } else {
      //   setHasMore(data?.status);
      //   setShow(data?.status);
      //   setMessage(data?.message);
      // }

      if (result?.length === 0 && data?.data?.totalCount) {
        setHasMore(false);
        setNoDataMessage(true);
        setFetchMoreData(0);
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
        setMerchantList([]);
        return false;
      }

      if (data?.data?.totalPage == newPage) {
        setHasMore(false)
        setFetchMoreData(0);
      }
      else {
        setHasMore(true);
        setFetchMoreData(fetMoreData + 1);

      }
      if (data?.status) {
        setMerchantList((prev) => [...prev, ...result]);
        setNoDataMessage(false);
        setState((prev) => ({
          ...prev,
          // page: response?.data?.page + 1,
          page: state.page + 1,
        }));
      }
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
      if (error.response.data.message.includes("Unauthorized")) {
        navigate("/login");
        localStorage.removeItem("token");
      }
      toast.error(error?.response.data.message);
      console.error(error);
    }
  };

  useEffect(() => {
    if (isEmpty(state.searchValue) && hasMore) {
      const options = {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      };

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMerchants(state.page, state.perPage, state.searchValue);
        }
      }, options);

      if (loader.current) {
        observer.observe(loader.current);
      }

      return () => {
        if (loader.current) {
          observer.unobserve(loader.current);
        }
      };
    }
  }, [state.page, fetMoreData]);

  useMemo(() => (
    fetchMerchants(state.page, state.perPage, state.searchValue)
  ), []);

  const handleSearchChange = (e) => {
    const searchData = e.target.value;
    setState((prev) => ({ ...prev, searchValue: searchData, page: 1, totalPage: null }));
    handleSearch(e.target.value);
  };

  const handlePerPageChange = (e) => {
    setMerchantList([]);
    const PageValue = e.target.value;
    setState((prev) => ({ ...prev, perPage: PageValue, totalPage: null }));
    fetchMerchants(state.page, PageValue, state.searchValue);
  };

  const handleState = (item, key) => {
    setState((prev) => ({
      ...prev,
      [item]: key,
    }));
  };

  // search debouncing
  const handleSearch = useCallback(
    debounce((searchQuery) => {
      fetchMerchants(state.page, state.perPage, searchQuery);
      setMerchantList([]);
    }, 700),
    []
  );

  // export csv function
  function convertMili(da) {
    const date = new Date(da);
    return date.getTime();
  }
  const handleDateChange = async ([start, end]) => {
    setStartDate(start);
    setEndDate(end ? end : null);
    let startdate = convertMili(start);
    let enddate = end ? convertMili(end) : null;
    if (!start || !end) return
    try {
      setState((pre) => ({ ...pre, loading: true }));
      // setLoading(true);

      const resp = await axios.get(`${baseUrl}/api/v1/admin/merchant/get-all-merchant?isMerchantExport=true&startDate=${startdate}&endDate=${enddate}`, {
        headers: {
          Authorization: token,
        },
      }
      );
      setState((pre) => ({ ...pre, loading: false }));
      // setLoading(false);
      if (resp?.status == 200) {
        const link = document.createElement('a');
        link.href = `${resp?.data?.data}`;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      console.log("milil dates=======", startdate, enddate);

    } catch (error) {
      console.log(error);
    }

  };

  const handleDates = (val, hours, minutes) => {
    const Dates = new Date();
    Dates.setDate(Dates.getDate() - val);
    Dates.setHours(hours, minutes, 0, 0);
    return Dates;
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterDays(value);
    console.log("valuelihe", value);
    // setLocalStorageFilter(value);
    if (value == "100") {
      setStartDate(null);
      setEndDate(null);
    } else if (value != "0") {
      let start = handleDates(value, 0, 0)
      let end = handleDates(1, 23, 59)
      setStartDate(start);
      console.log("startstart", start);
      console.log("endend", end);
      setEndDate(end);
    } else {
      const Dates = new Date();
      Dates.setHours(0, 1, 0, 0);
      console.log("startstart1", Dates);
      console.log("endend1", new Date());
      setStartDate(Dates);
      setEndDate(new Date());
    }
  };


  let str = "";
  const handleRow = (item, value) => {
    str += value;
    if (str == "download") {
      handleDownload(item)
    } else if (str == "view") {
      handleMerchantDetail(item.id)
    } else if (str == "edit") {
      handleEdit(item)
    } else if (str == "merchant") {
      verifiedMerchant(item?.encodeId)
    } else if (str == "transaction") {
      handleTransaction(item.id)
    }
  }
  const exportCsv = async () => {
    if (!merchantList && merchantList?.length < 1) { return }
    try {
      setState((pre) => ({ ...pre, loading: true }));
      // setLoading(true);

      const resp = await axios.get(`${baseUrl}/api/v1/admin/merchant/get-all-merchant?isMerchantExport=true&search=${state.searchValue}`, {
        headers: {
          Authorization: token,
        },
      }
      );
      setState((pre) => ({ ...pre, loading: false }));
      // setLoading(false);
      if (resp?.status == 200) {
        const link = document.createElement('a');
        link.href = `${resp?.data?.data}`;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      // console.log("milil dates=======", startdate, enddate);

    } catch (error) {
      setState((pre) => ({ ...pre, loading: false }));
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  }

  return (
    <>
      <div className="container-xl">
        {/* <BreadCrumbs items={breadcrumbItems} /> */}

        <div className="row">
          <div className="col-12 col-md-4">
            <p className="heading">Merchants</p>
          </div>
          <div className="col-12  col-md-8">
            <div className="search-container d-flex flex-wrap justify-content-md-end justify-content-between">
              <div className="" >
                {/* {merchantList?.length > 0 &&((role_id == "132" && staff_roll_type == "277") || (role_id == "223")) &&
                  <ReactDatePicker
                    className="cursor-pointer"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateChange}
                    maxDate={new Date()}
                    // filterDate={isSelectable}
                    withPortal
                    customInput={
                      <div className="cursor-pointer">


                      </div>
                    }
                    />
                    
                    
                  } */}
                {((role_id == "132" && staff_roll_type == "277") || (role_id == "223")) && <button className="btn app-btn purp border border-1 mx-1 export-csv-btn" onClick={exportCsv} >Export CSV</button>}
                {/* <button onClick={handleShow} className="btn app-btn purp border border-1 mx-1">Export CSV</button> */}
              </div>
              <div className="search-box">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_7372_11113)">
                    <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_7372_11113">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <input
                  className="searchbox"
                  type="text"
                  placeholder="Search"
                  name="search"
                  onChange={handleSearchChange}
                  value={state.searchValue}
                />
              </div>

              {/* <div className="cursor-pointer">
                <DatePickers startDate={startDate} endDate={endDate} filterDays={filterDays} handleDateChange={handleDateChange} handleFilterChange={handleFilterChange} />
              </div> */}
              {/* {userData?.data?.allowedPath?.some((item) => item?.menu_id == 100) &&  */}
              {/* <button
                className="second-blk-btn text-md-base text-sm px-2 px-md-3"
                onClick={() => {
                  navigate(`/merchant-onboarding`);
                }}
              >
                Add Merchant  <i className="fa-solid fa-plus"></i>
              </button> */}
              {/* } */}
            </div>
          </div>
        </div>

        {/* <div className="app-card app-card-orders-table shadow-sm mb-5"> */}
        {/* <div
            style={{
              display: "flex",
              padding: "20px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <label>Per Page: </label>
              <select
                name="page"
                id="page"
                value={state.perPage}
                onChange={handlePerPageChange}
              >
                <option value="5">5</option>
                <option value="10" selected>
                  10
                </option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
          </div> */}
        <div className="app-card-body overflow-auto">
          <div className="sm-table-responsive md-none">
            <table className="table">
              <thead>
                <tr>
                  <th align="right">Merchant Name</th>
                  <th align="right">Join Date</th>
                  <th align="right">State</th>
                  <th align="right">Status</th>
                  <th align="right">Action</th>
                </tr>
              </thead>
              <tbody>
                {merchantList && merchantList?.length > 0 ? (
                  merchantList?.map((item) => (
                    <tr key={item.id} className="table-hover cursor-pointer" onClick={() => handleRow(item, "view")}>
                      <td>{item.businessname}</td>
                      <td>{formatDate(item.join_date)}</td>
                      {/* <td>{`${item.first_name} ${item.last_name}`}</td> */}
                      <td>{item.state}</td>
                      <td>
                        {item.isApproved ? (
                          //  <div
                          //    className="status-box success"
                          //     style={{ lineHeight: "12px" }}
                          //   >
                          //     <span className="label"></span>
                          //     <span className="value">Active</span>
                          //   </div>
                          // <img src={Active} alt="Active" />
                          <div className=" d-flex "> <span className="active-btn">Approved</span></div>
                        ) : (
                          <div className="d-flex"><span className="active-btn">Not Approved</span></div>
                          // <div
                          //   className="status-box pending"
                          //   style={{
                          //     lineHeight: "12px",
                          //     color: "rgb(235 3 3)",
                          //     backgroundColor: "rgb(253 157 157)",
                          //   }}
                          // >
                          //   <span className="label"></span>
                          //   <span className="value">Inactive</span>
                          // </div>
                        )}
                      </td>
                      <td align="left" style={{ width: "14%" }}>
                        <div className="button-hover">
                          {/* <OverlayTrigger
                            overlay={<Tooltip id="tooltip-view">View</Tooltip>}
                          > */}
                          {/* <i
                              className="fa-solid fa-eye"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleMerchantDetail(item)}
                            ></i> */}
                          <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="" data-original-title="Disabled tooltip">
                            <div className="tooltip-toggle" aria-label="View" tabIndex="0">
                              <img
                                src={View}

                                onClick={() => handleRow(item, "view")}
                              />
                            </div>
                          </span>
                          {/* </OverlayTrigger> */}
                          {/* <OverlayTrigger
                            overlay={<Tooltip id="tooltip-edit">Edit</Tooltip>}
                          > */}
                          {role_id == '1' &&
                            <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                              <img
                                src={Edit}
                                alt="Edit"
                                style={{ marginLeft: "6px" }}
                                onClick={() => handleRow(item, "edit")}
                              /></div>
                          }
                          {/* <i
                              className="fa-solid fa-pencil text-info mx-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEdit(item)}
                              ></i> */}
                          {/* </OverlayTrigger> */}
                          {/* <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-transaction">
                                Transaction
                              </Tooltip>
                            }
                          > */}
                          {/* <i
                              className="fa-solid fa-receipt"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleTransaction(item)}
                              ></i> */}
                          <div className="tooltip-toggle" aria-label="Transactions" tabIndex="0">
                            <img

                              src={Transaction}
                              alt="Transactions"
                              style={{ marginLeft: "6px" }}
                              onClick={() => handleRow(item, "transaction")}
                            /></div>
                          {/* </OverlayTrigger> */}
                          {/* <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-download">
                                Upload Documents
                              </Tooltip>
                            }
                          > */}
                          <div className="tooltip-toggle" aria-label="Upload" tabIndex="0">
                            <img
                              src={Download}
                              alt="Download"
                              style={{ marginLeft: "6px" }}
                              onClick={() => handleRow(item, "download")}
                            /></div>
                          {/* <i
                              className="fa fa-upload mx-2"
                              style={{ cursor: "pointer" }}
                            ></i> */}
                          {/* </OverlayTrigger> */}
                          {/* <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-download">
                                {item?.isApproved
                                  ? "Verified Merchant"
                                  : "Verify as Merchant"}
                              </Tooltip>
                            }
                          > */}
                          {role_id == '1' &&
                            <i
                              className="fas fa-user-check"
                              style={{ marginLeft: "6px" }}
                              onClick={() => handleRow(item, "merchant")}
                            ></i>}
                          {/* </OverlayTrigger> */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10} style={{ textAlign: "center" }}>
                      No merchants available
                    </td>
                  </tr>
                )
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="py-2">

          {/* <PaginationPg
              totalPages={state.totalPage}
              onPageChange={handlePageChange}
              current={state.page}
            /> */}
          {state.loading && <Loader loading={state.loading} />}
          {/* {!show && (
            <div className="row alert alert-danger mt-3">
              <small className="alert-info text-center">
                {message}
              </small>
            </div>
          )} */}
          {/* {noDataMessage && <div className="text-center mt-2">No Data Available</div>} */}
          {merchantList.length > 9 && <div ref={loader} style={{ height: "20px" }} />}
        </div>
      </div>
      {/* </div> */}
      {/* <Loader loading={state.loading} /> */}

      <Modal
        className="upload-documents"
        show={state.download}
        onHide={() => handleState("download", false)}
      >
        <Modal.Header className="upload-doc" closeButton>
          <Modal.Title>Upload Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DownloadMerchantCSV handleState={handleState} state={state} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllVerifiedMerchants;
