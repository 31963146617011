import React, { useEffect, useState } from "react";
import "../../Dashboard/Page/Dashboard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import axios from "axios";
import baseUrl from "../../../baseUrl";
import { useToken } from "../../../hooks/useToken";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import Graph from "../../../DefaultComponent/Graph";
import { numberFormat } from "../../../hooks/numberFormat";
import Loader from "../../../DefaultComponent/Loader";
import StatsFormatter from "../../../DefaultComponent/StatsFormatter";
import PercentFormatter from "../../../DefaultComponent/PercentFormatter";
import StatsFlexFormatter from "../../../DefaultComponent/StatsFlexFormatter";
import DatePickers from "../../../DefaultComponent/DatePickers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Dashboard = () => {
  const token = useToken();
  const handleDates = (val, hours, minutes) => {
    const Dates = new Date();
    Dates.setDate(Dates.getDate() - val);
    Dates.setHours(hours, minutes, 0, 0);
    return Dates;
  };
  const [startDate, setStartDate] = useState(() => {
    const start = handleDates(6, 0, 0); // 7 days ago, start of the day
    start.setHours(0, 0, 0, 0);
    return start;
  });

  // const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)

  // console.log("start Date:  ",sevenDaysAgo);

  const [endDate, setEndDate] = useState(() => {
    const end = new Date(); // Today
    end.setHours(23, 59, 59, 999); // Set to the end of today
    return end;
  });

  const [days, setDays] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const [analyticsData, setAnalyticsData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [graphData, setGraphData] = useState("");
  const [graphFilter, setGraphFilter] = useState("week");
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Sales Over Time",
        data: [],
        backgroundColor: "rgba(117,193,129,0.2)",
        borderColor: "rgba(117,193,129, 0.8)",
        fill: true,
        tension: 0.4,
      },
    ],
  });

  const handleFilterDays = () => {
    setDays(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate) {
        return;
      }
      try {

        setIsLoading(true);
        function getTodayStart() {
          const now = new Date();
          const todayStart = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
          );
          return todayStart?.toISOString();
        }
        function convertMili(da) {
          const date = new Date(da);
          return date.getTime();
        }
        let params = {};
        if (graphFilter == "allTime") {
          params = { graphFilter: graphFilter }
        }
        else {
          params = {
            startDate: convertMili(startDate),
            // startDate:
            //   convertMili(startDate) == convertMili(endDate)
            //     ? convertMili(getTodayStart())
            //     : convertMili(startDate),

            endDate: convertMili(endDate),
            graphFilter: graphFilter
          }
        }
        console.log("date picker", startDate, endDate)
        const response = await axios.get(
          `${baseUrl}/api/v1/admin/dashboard/analytics`,
          {
            headers: {
              Authorization: token,
            },
            params,
          }
        );
        if (response.status == 200) {
          const data = response.data.analytics.data;
          const labels = data?.map((item) => `${item.label}`);
          const salesData = data?.map((item) => parseFloat(item.total_amount));
          const minValue = Math.min(...salesData);
          const yAxisMin = Math.floor(minValue - (minValue * 0.1));
          setAnalyticsData(yAxisMin)
          setResponseData(response?.data?.analytics?.analyticsData);
          setGraphData({ labels, salesData });
          setData({
            labels,
            datasets: [
              {
                label: "Sales Over Time",
                data: salesData,
                borderColor: "rgba(75, 192, 192, 1)",
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                fill: true,
                tension: 0.4,
                pointRadius: 5,
                pointHoverRadius: 10,
                pointStyle: "circle",
              },
            ],
          });
        } else {
          console.error("Error:", response.status, response.statusText);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error rohit", error);
      }
    };
    fetchData();
  }, [token, startDate, endDate]);



  return (
    <div className="container-xl">
      <div className="row">
        <div className="col-sm-4">
          <h4 className="heading">Dashboard</h4>
        </div>
      </div>


      <div className="row dash-sale p-4">
        <div className="col-lg-3 d-flex db-white-box flex-column">
          <div className="cursor-pointer position-relative">
            <DatePickers startDate={startDate} endDate={endDate} setGraphFilter={setGraphFilter} setStartDate={setStartDate} setEndDate={setEndDate} />
          </div>
          <div className="tans-main">
            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> TOTAL SALES</span>
              </div>
              <StatsFlexFormatter
                amount={responseData?.totalSalesAmount?.currentTotalTransactionAmount} />
            </div>

            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> ESTIMATED REVENUE</span>
              </div>
              <StatsFlexFormatter amount={responseData?.RangeProfitAmount?.calculatedProfitAmount} tofixed={true} />
            </div>

            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl">TOTAL TRANSACTIONS </span>
              </div>
              <StatsFlexFormatter isProfit={responseData?.totalTransactionCount?.isProfit} totalNum={responseData?.totalTransactionCount?.currentTotalTransactionCount} diffNum={responseData?.totalTransactionCount?.diffTotalTransactionCount} />
            </div>

            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> AVG TICKET</span>
              </div>
              <StatsFlexFormatter amount={responseData?.todayTransaction?.todayAmount} />
            </div>

            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> ACTIVE MERCHANTS</span>
              </div>
              <StatsFormatter totalNum={responseData?.activeMerchantCount?.activeMerchant} />
            </div>
            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> MERCHANT APPLICATIONS</span>
              </div>
              <StatsFormatter totalNum={responseData?.rangeOnboardingMerchants?.rangeOnboardingMerchants} />
            </div>
            {/* <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> TOTAL SALES VOID</span>
              </div>
              <StatsFormatter totalNum={responseData?.totalSalesVoid} />
            </div>
            <div className="actv-trans">
              <div className="mb-1">
                <span className="fs-12 prpl"> TOTAL SALES REFUND</span>
              </div>
              <StatsFormatter totalNum={responseData?.totalSalesRefund} />
            </div> */}
          </div>
        </div>
        <div className="col-lg-9 text-end">
          <div className="app-card app-card-chart shadow-sm">
            <div className="app-card-body p-3 p-lg-4">
              <div className="chart-container">
                <Graph data={graphData} yAxisMin={analyticsData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={isLoading} />
    </div>
  );
};

export default Dashboard;
