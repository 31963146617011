import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import baseUrl from '../../../../baseUrl';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Delete from "../../../../assets/images/Delete.svg";
import Edit from "../../../../assets/images/Edit.svg";
import Loader from '../../../DefaultComponents/Loader';
import { Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { tokenAction } from '../../../../redux/tokenSlice';
import AddClient from '../Form/AddClient';
import EditClient from '../Form/EditClient';
import PaginationPg from "../../../DefaultComponents/PaginationPg";
import { debounce } from 'lodash';
import { useRefToken } from '../../../hooks/useToken';
import { deleteClient } from '../../../api/client';

const AllClientsList = () => {
    const dispatch = useDispatch();
    const refToken = useRefToken();
    const navigate = useNavigate();
    const [clientList, setClientList] = useState([]);
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        totalPage: null,
        searchValue: "",
        deleteId: '',
        delete: false,
        add: false,
        edit: false,
        loading: false,
        row: {},
    });
    const fetchClients = async (
        newPage, searchValue
    ) => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const params = {
                page: newPage,
            };

            // if (newPerPage) {
            //     params.perPage = newPerPage;
            // }

            if (searchValue) {
                params.search = searchValue;
            }
            const { data } = await axios.get(
                `${baseUrl}/api/v1/super-admin/client`,
                {
                    headers: {
                        Authorization: refToken,
                    },
                    params,
                }
            );
            setState((prev) => ({ ...prev, loading: false }));
            if (data?.result) {
                setClientList(data?.data);
                setState((prev) => ({ ...prev, totalPage: data?.totalPage }));
            }
        } catch (error) {
            setState((prev) => ({ ...prev, loading: false }));
            if (error.response.data.message.includes("Unauthorized")) {
                navigate("/super-admin-login");
                localStorage.clear();
            }
            toast.error(error?.response.data.message);
            console.error(error);
        }
    };

    const handleState = (item, value) => {
        setState((prev) => ({
            ...prev,
            [item]: value,
        }));
    };

    const handleDelete = (id) => {
        handleState("delete", true)
        setState((prev) => ({
            ...prev,
            deleteId: id,
        }));
    }

    const handleEditClient = (item) => {
        handleState("edit", true)
        setState((prev) => ({
            ...prev,
            row: item,
        }));
    }

    const handleMerchants = (id) => {
        navigate(`${id}/merchants`);
    }
    const handleTransactions = (id) => {
        navigate(`${id}/transactions`);
    }
    const handlePageChange = (pageNo) => {
        setState((prev) => ({
            ...prev,
            page: pageNo,
        }));
        fetchClients(pageNo, state.searchValue);
        setClientList([]);
    }

    const handleSearchChange = (e) => {
        const searchData = e.target.value;
        setState((prev) => ({
            ...prev,
            searchValue: searchData,
        }));
        handleSearch(searchData);
    };

    const handleSearch = useCallback(
        debounce((searchQuery) => {
            setClientList([]);
            fetchClients(state.page, searchQuery);
        }, 700),
        []
    );

    const handleDeleteClient = async () => {
        setState((prev) => ({
            ...prev,
            loading: true,
        }));
        try {
            const response = await deleteClient(refToken, state.deleteId);
            dispatch(tokenAction.showToaster({ status: true, message: response.message, open: true }));
            fetchClients(state.page, state.searchValue);
            handleState("delete", false);
            setState((prev) => ({
                ...prev,
                loading: false,
            }));
        } catch (error) {
            dispatch(tokenAction.showToaster({ status: false, message: error?.response?.data?.message, open: true }));
            setState((prev) => ({
                ...prev,
                loading: false,
            }));
            handleState("delete", false);
        }
    };

    useEffect(() => {
        fetchClients(state.page, state.searchValue);
    }, []);

    const handleClientDetail = (id) => {
        navigate(`${id}`)
    }

    let str = "";
    const handleClick = (id, type) => {
        str += type;
        if (str === 'view') {
            handleClientDetail(id)
        } else if (str === 'merchants') {
            handleMerchants(id);
        } else if (str === 'transactions') {
            handleTransactions(id);
        } else if (str === 'edit') {
            handleEditClient(id);
        } else if (str === 'delete') {
            handleDelete(id);
        }
    };

    return (
        <>
            <div className="container-xl">
                {/* <BreadCrumbs items={breadcrumbItems} /> */}

                <div className="row">
                    <div className="col-12 col-md-4">
                        <p className="heading">Clients</p>
                    </div>
                    <div className="col-12 col-md-8">
                        <div className="search-container d-flex flex-wrap justify-content-md-end justify-content-between">
                            <div className="search-box">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_7372_11113)">
                                        <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_7372_11113">
                                            <rect width="14" height="14" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <input
                                    className="searchbox"
                                    type="text"
                                    placeholder="Search"
                                    name="search"
                                    onChange={handleSearchChange}
                                    value={state.searchValue}
                                />
                            </div>
                            {/* <button
                                className="second-blk-btn"
                                // onClick={() => {
                                //     navigate(`/merchant-onboarding`);
                                // }}
                            >
                                Add Client  <i className="fa-solid fa-plus"></i>
                            </button> */}

                            {/* <div className="cursor-pointer">
                                <DatePickers startDate={startDate} endDate={endDate} filterDays={filterDays} handleDateChange={handleDateChange} handleFilterChange={handleFilterChange} />
                            </div> */}
                            <button
                                className="second-blk-btn"
                                onClick={() => handleState("add", true)}
                            >
                                Add Client  <i className="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>

                {/* <div className="app-card app-card-orders-table shadow-sm mb-5"> */}
                {/* <div
            style={{
              display: "flex",
              padding: "20px",
              justifyContent: "space-between",
            }}
          >
            <div>
              <label>Per Page: </label>
              <select
                name="page"
                id="page"
                value={state.perPage}
                onChange={handlePerPageChange}
              >
                <option value="5">5</option>
                <option value="10" selected>
                  10
                </option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
          </div> */}
                <div className="app-card-body overflow-auto">
                    <div className="sm-table-responsive md-none">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th align="right">Client Name</th>
                                    <th align="right">Email</th>
                                    <th align="right">Phone</th>
                                    <th align="right">Bussiness Name</th>
                                    <th align="right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientList && clientList?.length > 0 ? (
                                    clientList?.map((item) => (
                                        <tr key={item.id} className="table-hover cursor-pointer" onClick={() => handleClick(item.id, "view")}>
                                            <td>{`${item.first_name} ${item.last_name}`}</td>
                                            <td>{item.email}</td>
                                            <td>{item.phonenumber}</td>
                                            <td>{item.businessname}</td>
                                            <td style={{ width: '14%' }}>
                                                <div className="button-hover">
                                                    <div className="tooltip-toggle" aria-label="Merchants" tabIndex="0">
                                                    <svg onClick={() => handleClick(item.id, "merchants")} width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16.0342 1.86679C16.0332 1.86679 16.0322 1.86667 16.0312 1.86667H11.7812V1.4C11.7812 0.628056 11.1504 0 10.375 0H6.625C5.84961 0 5.21875 0.628056 5.21875 1.4V1.86667H0.96875C0.707764 1.86667 0.5 2.07958 0.5 2.33333V12.6C0.5 13.3719 1.13086 14 1.90625 14H15.0938C15.8691 14 16.5 13.3719 16.5 12.6V2.34318C16.5 2.34257 16.5 2.34196 16.5 2.34135C16.4821 2.03486 16.2927 1.86849 16.0342 1.86679ZM6.15625 1.4C6.15625 1.14273 6.36658 0.933333 6.625 0.933333H10.375C10.6334 0.933333 10.8438 1.14273 10.8438 1.4V1.86667H6.15625V1.4ZM15.3809 2.8L13.9252 7.14753C13.8613 7.33846 13.6826 7.46667 13.4806 7.46667H10.8438V7C10.8438 6.74224 10.6339 6.53333 10.375 6.53333H6.625C6.36609 6.53333 6.15625 6.74224 6.15625 7V7.46667H3.51941C3.31738 7.46667 3.13867 7.33846 3.07483 7.14753L1.61914 2.8H15.3809ZM9.90625 7.46667V8.4H7.09375V7.46667H9.90625ZM15.5625 12.6C15.5625 12.8573 15.3522 13.0667 15.0938 13.0667H1.90625C1.64783 13.0667 1.4375 12.8573 1.4375 12.6V5.20905L2.18542 7.44273C2.37708 8.01536 2.91321 8.4 3.51941 8.4H6.15625V8.86667C6.15625 9.12443 6.36609 9.33333 6.625 9.33333H10.375C10.6339 9.33333 10.8438 9.12443 10.8438 8.86667V8.4H13.4806C14.0868 8.4 14.6229 8.01536 14.8146 7.44273L15.5625 5.20905V12.6Z" fill="#35254D" />
                                                    </svg>
                                                    </div>
                                                    <div className="tooltip-toggle" aria-label="Transactions" tabIndex="0">
                                                    <svg onClick={() => handleClick(item.id, "transactions")} style={{ marginLeft: "4px" }} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_7114_891)">
                                                            <path d="M16.5 5.99853C16.5004 6.06434 16.4869 6.12958 16.4604 6.19051C16.4339 6.25144 16.3948 6.30686 16.3453 6.35359L13.1453 9.35412C13.0955 9.40047 13.0364 9.43714 12.9714 9.46203C12.9064 9.48692 12.8369 9.49953 12.7667 9.49915C12.6973 9.49995 12.6285 9.48806 12.564 9.46414C12.4658 9.4263 12.3818 9.36182 12.3229 9.27896C12.264 9.19611 12.2328 9.09864 12.2333 8.99906V7.49879H7.43333C7.29189 7.49879 7.15623 7.44611 7.05621 7.35232C6.95619 7.25854 6.9 7.13134 6.9 6.9987V4.99835C6.9 4.86572 6.95619 4.73852 7.05621 4.64473C7.15623 4.55095 7.29189 4.49826 7.43333 4.49826H12.2333V2.99799C12.2338 2.89909 12.2655 2.80253 12.3244 2.72052C12.3834 2.63852 12.467 2.57475 12.5646 2.53728C12.6622 2.49981 12.7695 2.49032 12.8729 2.51002C12.9762 2.52971 13.071 2.57771 13.1453 2.64793L16.3453 5.64846C16.4439 5.74161 16.4994 5.86733 16.5 5.99853ZM9.56666 8.49897H4.76667V6.9987C4.76615 6.89997 4.73446 6.8036 4.67562 6.72172C4.61678 6.63985 4.5334 6.57614 4.436 6.53862C4.33888 6.50033 4.23183 6.48986 4.12837 6.50855C4.02491 6.52724 3.92967 6.57425 3.85467 6.64364L0.654675 9.64418C0.605245 9.6909 0.566139 9.74632 0.539598 9.80725C0.513056 9.86818 0.499603 9.93342 0.500009 9.99924C0.499603 10.0651 0.513056 10.1303 0.539598 10.1912C0.566139 10.2522 0.605245 10.3076 0.654675 10.3543L3.85467 13.3548C3.90451 13.4012 3.96361 13.4379 4.02859 13.4627C4.09357 13.4876 4.16315 13.5002 4.23334 13.4999C4.3033 13.5016 4.37271 13.4879 4.436 13.4599C4.5334 13.4223 4.61678 13.3586 4.67562 13.2768C4.73446 13.1949 4.76615 13.0985 4.76667 12.9998V11.4995H9.56666C9.70811 11.4995 9.84377 11.4468 9.94379 11.353C10.0438 11.2592 10.1 11.132 10.1 10.9994V8.99906C10.1 8.86643 10.0438 8.73923 9.94379 8.64544C9.84377 8.55166 9.70811 8.49897 9.56666 8.49897Z" fill="#35254D" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_7114_891">
                                                                <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                    <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                                                    <img
                                                        style={{ marginLeft: 5 }}
                                                        src={Edit}
                                                        alt="Edit"
                                                        onClick={() => handleClick(item, "edit")}
                                                    />
                                                    </div>
                                                    <div className="tooltip-toggle" aria-label="Delete" tabIndex="0">
                                                    <img
                                                        style={{ marginLeft: "4px" }}
                                                        src={Delete}
                                                        alt="Delete"
                                                        onClick={() => handleClick(item.id, "delete")}
                                                    />
                                                    </div>
                                                    {/* <svg  style={{ marginLeft: "4px" }} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 2L8 14" stroke="#35254D" stroke-width="2" />
                                                        <path d="M4 6L12 6" stroke="#35254D" stroke-width="2" />
                                                        <path d="M4 10L12 10" stroke="#35254D" stroke-width="2" />
                                                    </svg> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={10} style={{ textAlign: "center" }}>
                                            No clients available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {state.loading && <Loader loading={state.loading} />}
                <div className="py-2">

                    <PaginationPg
                        totalPages={state.totalPage}
                        onPageChange={handlePageChange}
                        current={state.page}
                    />
                    {/* {state.loading && <Loader loading={state.loading} />}
                    {/* {!show && (
            <div className="row alert alert-danger mt-3">
              <small className="alert-info text-center">
                {message}
              </small>
            </div>
          )} */}
                    {/* {merchantList.length > 9 && <div ref={loader} style={{ height: "20px" }} />} */}
                </div>
            </div>
            {/* </div> */}
            {/* <Loader loading={state.loading} /> */}

            <Modal
                className="delete-employee"
                style={{ width: "100%" }}
                show={state.delete}
                size="sm"
                centered
                onHide={() => handleState("delete", false)}
            >
                <Modal.Header className="delete-member-header" closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <h5 className="text-center">Are you sure want delete client?</h5>
                        <div className="row pt-3">
                            <div className="col-6">
                                <button
                                    className="cancel-btn "
                                    onClick={() => {
                                        handleState("delete", false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="add-btn" onClick={handleDeleteClient}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                className="edit-members"
                show={state.add}
                centered
                onHide={() => handleState("add", false)}
            >
                <Modal.Header className="add-member-header" closeButton>
                    <Modal.Title>Add Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddClient
                        handleState={handleState}
                        setState={setState}
                        fetchClients={fetchClients}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                className="edit-members"
                style={{ width: "100%" }}
                show={state.edit}
                centered
                onHide={() => handleState("edit", false)}
            >
                <Modal.Header className="edit-member-header" closeButton>
                    <Modal.Title>Edit {state.row.first_name} {state.row.last_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditClient
                        state={state}
                        handleState={handleState}
                        setState={setState}
                        fetchClients={fetchClients}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AllClientsList
