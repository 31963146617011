import React from "react";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
const DocsHeader = () => {
  return (
    <>
      <div className="relative lg:border-b lg:border-gray-500/5 dark:border-gray-50/[0.06]">
        <div className="flex items-center lg:px-12 border-b border-gray-500/10 lg:border-0 dark:border-gray-300/10 h-16 mx-4 lg:mx-0">
          <div className="h-full relative flex-1 flex items-center gap-x-4">
            <div className="flex-1 flex items-center gap-x-4">
              <Link to="/docs/documentation/introduction">
                <span className="sr-only">
                  API Documentation{/* */} home page
                </span>
                <img
                  className="w-auto h-7 relative block dark:hidden"
                  src={logo}
                  alt="light logo"
                />
                <img
                  className="w-auto h-7 relative hidden dark:block"
                  src="./Introduction - API Documentation_files/dark.svg"
                  alt="dark logo"
                />
              </Link>
            </div>
            <div className="mx-px relative flex-1 bg-white dark:bg-gray-900 pointer-events-auto rounded-lg">
              <button
                type="button"
                className="search-ask hidden w-full lg:flex items-center text-sm leading-6 rounded-lg py-1.5 pl-2.5 pr-3 shadow-sm text-gray-400 dark:text-white/50 bg-background-light dark:bg-background-dark dark:brightness-[1.1] dark:ring-1 dark:hover:brightness-[1.25] ring-1 ring-gray-400/20 hover:ring-gray-600/25 dark:ring-gray-600/30 dark:hover:ring-gray-500/30 focus:outline-primary"
                id="search-bar-entry"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-search ml-1 mr-3 flex-none text-gray-700 hover:text-gray-800 dark:text-gray-300 hover:dark:text-gray-200"
                >
                  <circle cx={11} cy={11} r={8} />
                  <path d="m21 21-4.3-4.3" />
                </svg>
                Search or ask...
                <span className="ml-auto flex-none text-xs font-semibold">
                  Ctrl K
                </span>
              </button>
            </div>
            <div className="page-sting flex-1 relative hidden lg:flex items-center ml-auto justify-end space-x-4">
              <nav className="text-sm">
                <ul className="flex space-x-6 items-center">
                  <li>
                    <Link
                    to={'/'}
                      className="font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                    >
                      Back to Dashboard
                    </Link>
                  </li>
                  <li>
                    <a
                      href="mailto:support@fractalpay.com"
                      className="font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                      target="_blank"
                    >
                      Support
                    </a>
                  </li>
                </ul>
              </nav>
              {/* <div className="flex items-center">
                <button className="group p-2 flex items-center justify-center">
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 block text-gray-400 dark:hidden group-hover:text-gray-600"
                  >
                    <g clipPath="url(#clip0_2880_7340)">
                      <path
                        d="M8 1.11133V2.00022"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.8711 3.12891L12.2427 3.75735"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.8889 8H14"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12.8711 12.8711L12.2427 12.2427"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 14.8889V14"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M3.12891 12.8711L3.75735 12.2427"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.11133 8H2.00022"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.12891 3.12891L3.75735 3.75735"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.00043 11.7782C10.0868 11.7782 11.7782 10.0868 11.7782 8.00043C11.7782 5.91402 10.0868 4.22266 8.00043 4.22266C5.91402 4.22266 4.22266 5.91402 4.22266 8.00043C4.22266 10.0868 5.91402 11.7782 8.00043 11.7782Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2880_7340">
                        <rect width={16} height={16} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 hidden dark:block text-gray-500 dark:group-hover:text-gray-300"
                  >
                    <g clipPath="url(#clip0_2880_7355)">
                      <path
                        d="M11.5556 10.4445C8.48717 10.4445 6.00005 7.95743 6.00005 4.88899C6.00005 3.68721 6.38494 2.57877 7.03294 1.66943C4.04272 2.22766 1.77783 4.84721 1.77783 8.0001C1.77783 11.5592 4.66317 14.4445 8.22228 14.4445C11.2196 14.4445 13.7316 12.3948 14.4525 9.62321C13.6081 10.1414 12.6187 10.4445 11.5556 10.4445Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2880_7355">
                        <rect width={16} height={16} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div> */}
            </div>
            <div className="flex lg:hidden items-center right-side-btn">
              <button
                type="button"
                className="ml-auto text-gray-500 w-8 h-8 -my-1 items-center justify-center hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
                id="search-bar-entry-mobile"
              >
                <span className="sr-only">Search</span>
                <svg
                  className="h-4 w-4 bg-gray-500 dark:bg-gray-400 hover:bg-gray-600 dark:hover:bg-gray-300"
                  style={{
                    WebkitMaskImage:
                      "url(https://mintlify.b-cdn.net/v6.5.1/solid/magnifying-glass.svg)",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskPosition: "center",
                  }}
                />
              </button>
              <button className="h-7 w-5 flex items-center justify-end">
                <svg
                  className="h-4 w-4 bg-gray-500 dark:bg-gray-400 hover:bg-gray-600 dark:hover:bg-gray-300"
                  style={{
                    WebkitMaskImage:
                      "url(https://mintlify.b-cdn.net/v6.5.1/solid/ellipsis-vertical.svg)",
                    WebkitMaskRepeat: "no-repeat",
                    WebkitMaskPosition: "center",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
        <div className="brd-drm flex items-center h-14 py-4 px-5 border-b border-gray-500/10 lg:hidden dark:border-gray-50/[0.06]">
          <button
            type="button"
            className="text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
          >
            <span className="sr-only">Navigation</span>
            <svg
              className="h-4"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path>
            </svg>
          </button>
          <div className="ml-4 flex text-sm leading-6 whitespace-nowrap min-w-0 space-x-3">
            <div className="flex items-center space-x-3">
              <span>API Documentation</span>
              <svg
                width={3}
                height={24}
                viewBox="0 -9 3 24"
                className="h-5 rotate-0 overflow-visible fill-gray-400"
              >
                <path
                  d="M0 0L3 3L0 6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div className="font-semibold text-gray-900 truncate dark:text-gray-200">
              Introduction
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocsHeader;
