import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import baseUrl from '../../../../baseUrl';
import { useRefToken } from '../../../hooks/useToken';
import PaginationPg from '../../../DefaultComponents/PaginationPg';
import Loader from '../../../DefaultComponents/Loader';
import { debounce } from 'lodash';

const Customers = () => {
    const token = useRefToken()
    const [clientList, setClientList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null);

    const fetchClients = async (page, search) => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/api/v1/super-admin/customer/customer-list`, {
                params: {
                    perPage: 20,
                    page,
                    search,
                },
                headers: {
                    Authorization: token,
                },
            });
            setClientList([]);
            const { allList, totalPage } = response.data.data;
            setClientList(allList);
            setTotalPage(totalPage);
        } catch (err) {
            console.log("error", err);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchValue(value);
        handleSearch(page, value);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
        fetchClients(newPage, searchValue);
    };

    const handleSearch = useCallback(
        debounce((page, searchQuery) => {
            fetchClients(page, searchQuery);
        }, 700),
        []
    );

    const handleClick = (id, action) => {
        console.log(`Clicked item ID: ${id}, Action: ${action}`);
    };

    useEffect(() => {
        fetchClients(page, searchValue);
    }, []);

    return (
        <div className="container-xl">
            <div className="row">
                <div className="col-12 col-md-4">
                    <p className="heading">Clients Customers</p>
                </div>
                <div className="col-12 col-md-8">
                    <div className="search-container d-flex flex-wrap justify-content-md-end justify-content-between">
                        <div className="search-box">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_7372_11113)">
                                    <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7372_11113">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <input
                                className="searchbox"
                                type="text"
                                placeholder="Search"
                                name="search"
                                onChange={handleSearchChange}
                                value={searchValue}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="app-card-body overflow-auto">
                <div className="sm-table-responsive md-none">
                    <table className="table">
                        <thead>
                            <tr>
                                <th align="right">Client Name</th>
                                <th align="right">Email</th>
                                <th align="right">Phone</th>
                                <th align="right">Business Name</th>

                            </tr>
                        </thead>
                        <tbody>
                            {clientList?.length > 0 ? (
                                clientList?.map((item, ind) => (
                                    <tr key={ind} className="table-hover cursor-pointer" onClick={() => handleClick(item.customer_id, "view")}>
                                        <td>{`${item?.first_name} ${item?.last_name}`}</td>
                                        <td>{item?.Email}</td>
                                        <td>{item?.Phone}</td>
                                        <td>{item?.Merchant_detail?.bussiness_name}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5} style={{ textAlign: "center" }}>
                                        No customer available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {loading && <Loader loading={loading} />}
            <div className="py-2">
                <PaginationPg
                    totalPages={totalPage}
                    onPageChange={handlePageChange}
                    current={page}
                />
            </div>
        </div>
    );
};

export default Customers;