import React from "react";

const MastercardLogo = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
      <rect x="2" y="2" width="20" height="20" rx="3" fill="#D02027" />
      <path d="M10 11L10 13 16 13 16 11" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10 7L10 9 14 9 14 7" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10 15L10 17 14 17 14 15" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    )
}
export default MastercardLogo;