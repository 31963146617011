import React, { useState } from 'react'
import { useGetSidebarQuery } from '../redux/apiSlice';
import DatePicker, { CalendarContainer } from "react-datepicker";
import calenderImg from "../assets/images/Group.png";
import { useDispatch } from 'react-redux';
import { dateAction } from '../redux/dateSlice';

const DatePickers = ({ startDate, endDate, setGraphFilter, setStartDate, setEndDate }) => {
    const { data: menu } = useGetSidebarQuery();
    const dispatch = useDispatch();
    const maxRangeDays = 61;
    const isSelectable = (date) => {
        if (!startDate) {
            return true;
        }
        const diffFromStart = (date - startDate) / (1000 * 60 * 60 * 24);
        return diffFromStart >= -maxRangeDays && diffFromStart <= maxRangeDays;
    };
    const [filterDays, setFilterDays] = useState("7");
    const MyContainer = ({ className, children }) => {
        return (
            <div style={{ padding: "16px", color: "#fff", display: "flex" }}>
                <CalendarContainer className={`${className} d-flex`} >
                    <div className='left-days'>
                        <p>QUICK FILTERS</p>
                        <ul onClick={handleFilterChange} value={filterDays}>
                            <li className={`${filterDays == "0" ? 'sky-btn-filter-1' : 'sky-btn-filter-2'} text-left`} value="0">Today</li>
                            <li className={`${filterDays == "1" ? 'sky-btn-filter-1' : 'sky-btn-filter-2'} text-left`} value="1">Yesterday</li>
                            <li className={`${filterDays == "7" ? 'sky-btn-filter-1' : 'sky-btn-filter-2'} text-left`} value="7">Last 7 days</li>
                            <li className={`${filterDays == "14" ? 'sky-btn-filter-1' : 'sky-btn-filter-2'} text-left`} value="14">Last 14 days</li>
                            <li className={`${filterDays == "30" ? 'sky-btn-filter-1' : 'sky-btn-filter-2'} text-left`} value="30">Last Month</li>
                            <li className={`${filterDays == "100" ? 'sky-btn-filter-1' : 'sky-btn-filter-2'} text-left`} value="100">Custom</li>
                        </ul>
                    </div>
                    <div style={{ position: "relative" }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };

    function formatDateToMonthYear(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
        const options = { month: 'short' };
        const monthFormatter = new Intl.DateTimeFormat('en-US', options);
        const month = monthFormatter.format(date);
        const day = String(date.getDate()).padStart(2, '0');
        return `${month} ${day}`;
    }

    const handleFilterChange = (e) => {
        setFilterDays(e.target.value);
        let value = e.target.value;
        let start, end;
        if (value == "0" || value == "1") {
            setGraphFilter("day");
            start = new Date(); // Today or Yesterday
            start.setDate(start.getDate() - (value == "1" ? 1 : 0));
            end = new Date(start);
        } else if (value == "7") {
            setGraphFilter("week");
            start = new Date();
            start.setDate(start.getDate() - 6);
            end = new Date();
        } else if (value == "14") {
            setGraphFilter("twoWeek");
            start = new Date();
            start.setDate(start.getDate() - 13);
            end = new Date();
        } else if (value == "30") {
            setGraphFilter("month");
            start = new Date();
            start.setMonth(start.getMonth() - 1);
            end = new Date();
        }
        if (value != "100") { // Set full-day times for all preset ranges
            if (start) start.setHours(0, 0, 0, 0); // Start of the day
            if (end) end.setHours(23, 59, 59, 999); // End of the day
            setStartDate(start);
            setEndDate(end);
        } else { // Custom range
            setStartDate(null);
            setEndDate(null);
            dispatch(dateAction.setDates({ startDate: '', endDate: '' }))
        }
    };


    const handleDateChange = ([start, end]) => {
        // console.log("start", start, "end", end);
        setStartDate(start);
        setEndDate(end ? end : null);
        setFilterDays("all");
        setGraphFilter("");
        dispatch(dateAction.setDates({ startDate: start, endDate: end }))
    };

    return (
        <DatePicker
            className="cursor-pointer"
            // minDate={menu?.data?.created_at}
            selectsRange={true}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            // maxDate={new Date()}
            // filterDate={isSelectable}
            calendarContainer={MyContainer}
            customInput={
                <div className="input-group cursor-pointer">
                    <input
                        className="cursor-pointer"
                        type="text"
                        placeholder="Choose date"
                        value={
                            startDate
                                ? endDate
                                    ? `${formatDateToMonthYear(startDate)} - ${formatDateToMonthYear(endDate)}`
                                    : formatDateToMonthYear(startDate)
                                : ""
                        }
                        style={{
                            backgroundImage: `url(${calenderImg})`,
                            backgroundPosition: "10px 11px",
                            backgroundRepeat: "no-repeat",
                            height: 45,
                            borderRadius: '12px'
                        }}
                        readOnly
                    />
                </div>
            }
            disabledKeyboardNavigation
        />
    )
}

export default DatePickers
