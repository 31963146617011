export const useToken = () => {
  const user = localStorage.getItem("token");
  const token = JSON.parse(user);
  if (token){ 
    return token;
  }
  return null;
};

export const useLoginDetails = () => {
  const login = localStorage.getItem("loginDetails");
  const Details = JSON.parse(login);
  if (Details) {
    return Details;
  }
  return null;
};


