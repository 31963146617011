// apiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers,{getState}) => {
        const tkn = getState().token.token;
        const tk = localStorage.getItem('token');
        if (tkn||tk) {
            let token = JSON.parse(tk);
            if (token||tkn) {
                headers.set('Authorization', `${tkn ?? token}`);
               
            }
            return headers;
        }
    },
});

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery,
    tagTypes: ['Sidebar'],
    endpoints: (builder) => ({
        getSidebar: builder.query({
            query: () => '/api/v1/admin/auth/get-all-menu',
        }),
        // transformErrorResponse: (response, meta, arg) => {
        //     console.log("erroro =============response",response,meta)
        //     return response
        // },

    }),
});

export const { useLazyGetSidebarQuery,useGetSidebarQuery } = apiSlice;
