import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import baseUrl from "../../../baseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToken } from "../../../hooks/useToken";
import "../../Merchant/Tabs/MerchantOnboard.css";
import Modal from "react-bootstrap/Modal";
import KeyConfirm from "../Froms/KeyConfirm";
import Reload from "../../../assets/images/Reload.svg";
import Copy from "../../../assets/images/Copy.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyTextToClipboard from "../../../DefaultComponent/ClipBoard";
import { useGetSidebarQuery } from "../../../redux/apiSlice";

const ProfileView = (props) => {
  const { data: userData, isLoading, isError } = useGetSidebarQuery();
  const { role_id, staff_roll_type } = userData?.data;
  const token = useToken();
  const { title, state, setState } = props;
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [showPasswords, setShowPasswords] = useState(false);
  const [webhookurl, setWebhookurl] = useState("");
  const [bot, setBot] = useState("");
  const [showWebHookModal, setShowWebHookModal] = useState(false);
  const handleWebHookClose = () => setShowWebHookModal(false);
  const handleWebHookShow = () => setShowWebHookModal(true);
  const fetchUsersData = async () => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      const response = await axios.get(`${baseUrl}/api/v1/admin/auth/get-profile`, {
        headers: {
          Authorization: token,
        },
      });

      const responseData = response?.data;
      setWebhookurl(response?.data?.data?.webhook_url)
      // if (responseData?.result === true) {
      setData(responseData.data);
      setState((prev) => ({ ...prev, accData: responseData.data?.public_data }));
      // } else {
      //   toast.error(responseData?.message);
      // }
      setState((prev) => ({ ...prev, loading: false }));
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
      toast.error(error?.response?.data?.message || "Failed to fetch profile data");
      if (error?.response?.data?.message?.includes("Unauthorized")) {
        navigate("/login");
        localStorage.removeItem("user-data");
      }
      console.error("Fetch user data error:", error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const handleKeys = (flag) => {
    setState((prev) => ({ ...prev, key: true, keys: flag }));
  };

  const handleClose = () => {
    setState((prev) => ({ ...prev, key: false }));
  };

  const togglePasswordsVisibility = () => {
    setShowPasswords(!showPasswords);
  };

  const handleCopy = (coptText) => {
    toast.success(coptText);
  };
  const handleCopykey = () => {
    toast.success("Auth Key copied");
  };
  // handle webhook url
  const handleWebHookUrl = async () => {
    try {
      setState({ loading: true });
      const { data } = await axios.patch(
        `${baseUrl}/api/v1/admin/auth/update-webhook`,
        { webhook_url: webhookurl, bot_key: bot },
        {
          headers: { Authorization: token },
        }
      );
      console.log(data)
      if (data?.result === true) {
        handleWebHookClose();
        fetchUsersData();
        toast.success(`${data?.message}`);
      }
      setState({ loading: false });
    } catch (error) {
      console.log(error)
      setState({ loading: false });
      toast.error(error?.response.data.message || "Error updating webhook url");
    }
  }
  // console.log("sdf===========",webhookurl);

  return (
    <div
      className="tab-pane fade show active"
      id="orders-all"
      role="tabpanel"
      aria-labelledby="orders-all-tab"
    >
      <div className="app-card-body">
        <h4 className="heading">{title}</h4>
        <p className="sub-heading">Profile</p>
        <div className="white-box-content">
          <div className="row">
            <div className="col-3 Apikey">Email</div>
            <div className="col-9 Apikey"><span style={{ wordBreak: 'break-all' }}>{data.email}</span></div>
          </div>
          <hr />
          <div className="row ">
            <div className="col-3 Apikey">Name</div>
            <div className="col-9 Apikey">{data.first_name?.concat(" ", data.last_name)}</div>
          </div>
          <hr />
          <p className="sub-heading" style={{ marginBottom: "1.5em", marginTop: '1em' }}>
            Credentials
          </p>
          <div className="row">
            <div className="col-md-3 col-12 Apikey" >Client ID</div>
            <div className="col-md-9 col-12 Apikey"> {data?.h_guid ? data?.h_guid : data?.guid}</div>
          </div>
          <hr />
          <div className="row">

            <div className="col-md-3 col-12 Apikey" >Webhook URL</div>
            <div className="col-md-9 col-12 Apikey d-flex align-items-center">
              {/* https://webhook.site/e20d85fe-da85-4235-8e0d-9af55f85b6e5 */}
              <div className="col-md-9 col-12 Apikey"> {data?.webhook_url ? data?.webhook_url : data?.webhook_url}</div>

              {/* <i className="fa fa-pencil ms-2" aria-hidden="true" onClick={handleEdit}></i> */}
              {(role_id == 223 || (role_id == 132 && staff_roll_type == 269)) && <i className="fa fa-pencil ms-2" aria-hidden="true" onClick={() => { handleWebHookShow(); setWebhookurl(data?.webhook_url) }}></i>}

            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-3 col-12 Apikey" >Public Key</div>
            <div className="col-md-6 col-12 Apikey d-flex justify-content-between">
              <span style={{ wordBreak: 'break-all' }}>{state?.accData?.public_key ? state?.accData?.public_key : "N/A"}</span>
              <div>  {!state?.accData?.public_key && (
                <div className="copy-code">
                  {!state?.accData?.public_key && <img
                    src={Reload}
                    alt="Reload"
                    onClick={() => handleKeys(false)}
                  />}

                  {/* <img
                  src={Copy}
                  alt="Copy"
                  style={{ marginLeft: 2 }}
                  onClick={() =>
                    copyTextToClipboard(data.public_data.public_key, "Public Key copied")
                  }
                /> */}
                  <CopyToClipboard text={state?.accData?.public_key} onCopy={() => handleCopy("Public Key copied")}>
                    <img
                      src={Copy}
                      alt="Copy"
                      style={{ marginLeft: 2 }}
                    />
                  </CopyToClipboard>
                </div>
              )}</div></div>

          </div>
          <hr />
          <div className="row">
            <div className="col-md-3 col-12 Apikey">Secret Key</div>
            <div className="col-md-7 col-12 Apikey d-flex">
              <div className="d-flex align-items-center">
                <span style={{ wordBreak: 'break-all' }}>

                  {state.accData?.api_key ? (
                    <PasswordCell
                      password={state.accData?.api_key}
                      showPassword={showPasswords}
                    />
                  ) : (
                    "N/A"
                  )}
                </span>
                {state.accData?.api_key &&
                  (showPasswords ? (
                    <i className="fa fa-eye-slash" aria-hidden="true" onClick={togglePasswordsVisibility}></i>
                    // <img src={eye} className="ms-2" aria-hidden="true" onClick={togglePasswordsVisibility} />
                  ) : (
                    <i className="fa fa-eye ms-2" aria-hidden="true" onClick={togglePasswordsVisibility}></i>

                  ))}  <div className="sml-line">

                </div>
              </div>
              <div>
                <div className="copy-code">
                  {!state?.accData?.api_key && <img
                    src={Reload}
                    alt="Reload"
                    onClick={() => handleKeys(true)}
                  />}
                  {state.accData?.api_key && (
                    <>
                      <CopyToClipboard text={state.accData?.api_key} onCopy={() => handleCopy("Secret Key copied")}>
                        <img
                          src={Copy}
                          alt="Copy"
                          style={{ marginLeft: 2 }}
                        />
                      </CopyToClipboard>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-3 col-12 Apikey" >Auth Key</div>
            <div className="col-md-9 col-12 Apikey d-flex align-items-center"> <div className="authkey">{data?.public_data?.auth_key ? data.public_data.auth_key : "N/A"}</div>
              <div className="d-flex">
                <div className="sml-line"></div>
                {data?.public_data?.auth_key && (
                  <>
                    <div className="copy-code">
                      <CopyToClipboard text={data?.public_data?.auth_key} onCopy={handleCopykey}>
                        <img
                          src={Copy}
                          alt="Copy"
                          style={{ marginLeft: 2 }}
                        />
                      </CopyToClipboard>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <hr />
          <Modal
            className="confirm-key"
            style={{ width: "100%" }}
            show={state.key}
            onHide={handleClose}
          >
            <Modal.Header className="edit-member-header" closeButton>
            </Modal.Header>
            <Modal.Body>
              <KeyConfirm
                setState={setState}
                accountData={data}
                state={props.state}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>

      {/* webhook url handle */}
      <Modal show={showWebHookModal} onHide={handleWebHookClose}>
        <Modal.Header closeButton className="edit-member-header">
          <h4 className="text-center  w-full">Webhook Url</h4>

        </Modal.Header>
        <Modal.Body>
          <div className="row ">
            <div className="px-4 mb-4">
              <input className="form-control" placeholder="webhook url" value={webhookurl} onChange={(e) => { setWebhookurl(e.target.value) }} />
              <input type="text" name="bot_key" onChange={(e) => { setBot(e.target.value) }} style={{ display: "none !important" }} hidden />
            </div>
            <div className="col-6">

              <button
                className="cancel-btn "
                onClick={handleWebHookClose}
              >
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button className="add-btn" onClick={handleWebHookUrl}>
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const PasswordCell = ({ password, showPassword }) => {
  return <>{showPassword ? password : "*".repeat(password?.length)}</>;
};

export default ProfileView;