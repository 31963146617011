import { isEqual } from "lodash";
import React, { useState } from "react";
import { useRefToken } from "../../../hooks/useToken";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { tokenAction } from "../../../../redux/tokenSlice";
import DragNDrop from "../../../../components/DragNDrop";
import { createClient } from "../../../api/client";

const AddClient = (props) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const { handleState, setState, fetchClients } = props;
  const phoneNum =
    /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
  const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const token = useRefToken();
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const Validation = () => {
    let error = {};
    if (!requiredValue(data.first_name)) {
      error.first_name = "first name is required";
    }
    if (!requiredValue(data.last_name)) {
      error.last_name = "last name is required";
    }
    if (!requiredValue(data.username)) {
      error.username = "username is required";
    }
    if (!requiredValue(data.business_name)) {
      error.business_name = "business name is required";
    }
    if (!requiredValue(data.email)) {
      error.email = "email is required";
    } else if (!handleEmail(data.email)) {
      error.email = "email must be valid format";
    }
    if (!requiredValue(data.password)) {
      error.password = "password name is required";
    }
    if (!requiredValue(data.confirm_password)) {
      error.confirm_password = "confirm password is required";
    }
    if (!checkPassword(data.password, data.confirm_password)) {
      error.confirm_password = "both password must be same";
    }
    if (!requiredValue(data.phoneNumber)) {
      error.phoneNumber = "phone number is required";
    } else if (!handlePhoneNumber(data?.phoneNumber)) {
      error.phoneNumber = "phone number must be number";
    }
    if (Object.keys(error).length < 1) {
      return false;
    }
    return error;
  };

  const handlePhoneNumber = (num) => {
    let no = num.replace(/\D/g, "");
    return phoneNum.test(no);
  };
  const handleEmail = (value) => {
    return emailFormat.test(value);
  };

  const handleChange = (e) => {
    let newData = { ...data };
    const { name, value } = e.target;
    newData[name] = value?.trim();
    setData(newData);
  };

  const handleData = async (e) => {
    e.preventDefault();
    setError({});
    let errors = Validation();
    if (!errors) {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
      try {
        const formData = new FormData();
        formData.append("first_name", data.first_name);
        formData.append("last_name", data.last_name);
        formData.append("email", data.email);
        formData.append("phone", data.phoneNumber);
        formData.append("password", data.password);
        formData.append("confirm_password", data.confirm_password);
        formData.append("business_name", data.business_name);
        formData.append("username", data.username);
        formData.append("bot_key", data?.bot_key || "");
        files.forEach((fileData) => {
          formData.append("business_logo", fileData);
        });
        const response = await createClient(token, formData);
        fetchClients();
        dispatch(tokenAction.showToaster({ status: true, message: response.message, open: true }));
        handleState("add", false);
        setState((prev) => ({
          ...prev,
          ...prev,
          loading: false,
        }));
      } catch (error) {
        dispatch(tokenAction.showToaster({ status: false, message: error?.response?.data?.message, open: true }));
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } else {
      setError(errors);
    }
  };

  const requiredValue = (value) => {
    if (!value) {
      return false;
    }
    return true;
  };

  const checkPassword = (pass, cnf_pass) => {
    if (isEqual(pass, cnf_pass)) {
      return true;
    }
    return false;
  };

  // const fetchRoles = async () => {
  //   setState((prev) => ({
  //     ...prev,
  //     loading: true,
  //   }));
  //   const param = {
  //     isAll: true,
  //   };
  //   try {
  //     const response = await getRolesAll(token, param);
  //     setRole(response);
  //     setState((prev) => ({
  //       ...prev,
  //       loading: false,
  //     }));
  //   } catch (error) {
  //     setState((prev) => ({
  //       ...prev,
  //       loading: false,
  //     }));
  //   }
  // };

  const handleRoleDetails = (value) => {
    setState((prev) => ({
      ...prev,
      role: value,
    }));
  }

  const handlePermissions = () => {
    if (!data?.role_id) {
      toast.error("Select role type then you see role permission");
    } else {
      window.open(`permissions/${data.role_id}`)
    }
  }
  // useEffect(() => {
  //   fetchRoles();
  // }, []);
  const formatPhoneNumber = (numericValue) => {
    const cleaned = ("" + numericValue).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return numericValue;
  };
  // const handleSelectRole = (roleId,roleName) => {
  //   let newData = { ...data };
  //   newData["role_id"] = roleId;
  //   setData(newData);
  //   setShowRole(roleName);
  // }
  // console.log("dataa", showRole)
  return (
    <>
      <div className="container-xl">
        <div className="row">
          <div className="col-6">
            <label className="">FIRST NAME</label>
            <input
              className="custom-date-picker"
              type="text"
              placeholder="Enter first name"
              name="first_name"
              onChange={handleChange}
              required
              autoComplete="off"
            />
            {error.first_name && (
              <span className="error text-danger fs-12">
                {error.first_name}
              </span>
            )}
          </div>
          <div className="col-6">
            <label className="">LAST NAME</label>
            <input
              className="custom-date-picker"
              type="text"
              placeholder="Enter last name"
              name="last_name"
              onChange={handleChange}
              required
              autoComplete="off"
            />
            {error.last_name && (
              <span className="error text-danger fs-12">
                {error.last_name}
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label className="">USER NAME</label>
            <input
              className="custom-date-picker"
              type="text"
              placeholder="Enter user name"
              name="username"
              onChange={handleChange}
              required
              autoComplete="off"
            />
            {error.username && (
              <span className="error text-danger fs-12">
                {error.username}
              </span>
            )}
          </div>
          <div className="col-6">
            <label className="">BUSSINESS NAME</label>
            <input
              className="custom-date-picker"
              type="text"
              placeholder="Enter bussiness name"
              name="business_name"
              onChange={handleChange}
              autoComplete="off"
            />
            {error.business_name && (
              <span className="error text-danger fs-12">
                {error.business_name}
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label className="">EMAIL ADDRESS</label>
            <input
              className="custom-date-picker"
              type="text"
              name="email"
              placeholder="Enter email address"
              onChange={handleChange}
              inputMode="numeric"
              required
              autoComplete="off"
            />
            {error.email && (
              <span className="error text-danger fs-12">{error.email}</span>
            )}
          </div>
          <div className="col-6">
            <label className="">PHONE</label>
            <input
              className="custom-date-picker"
              type="text"
              maxLength="10"
              value={formatPhoneNumber(data?.phoneNumber)}
              autoComplete="off"
              name="phoneNumber"
              placeholder="Phone"
              onChange={handleChange}
              required
            />

            {error.phoneNumber && (
              <span className="error text-danger fs-12">
                {error.phoneNumber}
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label className="">PASSWORD</label>
            <input
              className="custom-date-picker"
              type="password"
              placeholder="Enter password"
              name="password"
              onChange={handleChange}
              required
              autoComplete="off"
            />
            {error.password && (
              <span className="error text-danger fs-12">{error.password}</span>
            )}
          </div>

          <div className="col-6">
            <label className="">CONFIRM PASSWORD</label>
            <input
              className="custom-date-picker"
              type="password"
              name="confirm_password"
              placeholder="Confirm password"
              onChange={handleChange}
              autoComplete="off"
            />
            {error.confirm_password && (
              <span className="error text-danger fs-12">
                {error.confirm_password}
              </span>
            )}
          </div>
        </div>
        <input type='text' id='bot_key' name='bot_key' onChange={handleChange} style={{ display: 'none' }} />
        <div className="row d-flex justify-content-center align-items-center my-4">
          <DragNDrop
            onFilesSelected={setFiles}
            width=" auto"
            height="auto"
          />
        </div>
        <div className="row">
          <div className="col-6">
            <button className="idle-blk-btn w-100" onClick={() => handleState("add", false)}>Cancel</button>
          </div>
          <div className="col-6">
            <button className="idle-green-btn w-100" onClick={handleData}>
              Add
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClient;
