import React, { useEffect, useState } from 'react'
import ClientRoutes from './ClientRoutes'
import MerchantRoutes from './MerchantRoutes'
import { useGetSidebarQuery } from '../redux/apiSlice';

const AdminLayout = () => {
  const {data:userData,isLoading,isError}=useGetSidebarQuery();
  // console.log("user========dfrata======",userData);

  return (
    <>
      {
        userData?.data?.role_id === 3 ? <MerchantRoutes /> : <ClientRoutes />
      }

    </>
  )
}

export default AdminLayout
