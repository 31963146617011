
import React, { memo, useState, useEffect } from 'react';

const Pagination = ({ totalPages, onPageChange, current }) => {
    const [currentPage, setCurrentPage] = useState(current);

    useEffect(() => {
        setCurrentPage(current);
    }, [current]);

    const handlePrevious = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage);
            onPageChange(newPage);
        }
    };

    const handleNext = (e) => {
        e.preventDefault();
        if (currentPage < totalPages) {
            const newPage = currentPage + 1;
            setCurrentPage(newPage);
            onPageChange(newPage);
        }
    };

    const handlePageClick = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(renderPageNumber(i));
            }
        } else {
            if (currentPage > 3) {
                pageNumbers.push(renderPageNumber(1));
                if (currentPage > 4) {
                    pageNumbers.push(<li key="start-break" className="page-item disabled"><span className="page-link">...</span></li>);
                }
            }

            const startPage = Math.max(currentPage - 1, 1);
            const endPage = Math.min(startPage + 2, totalPages);
            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(renderPageNumber(i));
            }

            if (currentPage < totalPages - 2) {
                if (currentPage < totalPages - 3) {
                    pageNumbers.push(<li key="end-break" className="page-item disabled"><span className="page-link">...</span></li>);
                }
                pageNumbers.push(renderPageNumber(totalPages));
            }
        }
        return pageNumbers;
    };

    const renderPageNumber = (pageNumber) => (
        <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
            <a className="page-link cursor-pointer" onClick={(e) => handlePageClick(e, pageNumber)}>
                {pageNumber}
            </a>
        </li>
    );

    return (
    
        <nav className="app-pagination py-2">
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link cursor-pointer" onClick={handlePrevious}>
                      
                        <i className="fa-solid fa-chevron-left"></i>
                    </a>
                </li>
                {getPageNumbers()}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a className="page-link cursor-pointer" onClick={handleNext}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default memo(Pagination);
