import React from "react";
import "./Footer.css";
import { CheckDomain } from "../hooks/CheckDomain";

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="container text-center py-3">
        <small className="copyright">
          <a className="app-link">
          © 2024 { CheckDomain() ? 'Command Alkon' : 'Fractal'}
          </a>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
