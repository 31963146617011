import React, { useCallback, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRefToken } from '../../hooks/useToken';
import baseUrl from '../../../baseUrl';
import axios from 'axios';
import CardCheck from '../../DefaultComponents/CardCheck';
import { GetStatusLabelGlobal } from '../../hooks/GetStatusLabelGlobal';
import moment from 'moment';
import PaginationPg from '../../DefaultComponents/PaginationPg';
import Loader from '../../DefaultComponents/Loader';
import { debounce } from 'lodash';
import DatePickers from '../../DefaultComponents/DatePickers';
import { RefundIcon, VoidIcon } from '../../../DefaultComponent/Svgicons';

const TransactionList = () => {
    const navigate = useNavigate();
    const refToken = useRefToken();
    const [transactionList, setTransactionList] = useState([])
    const [graphFilter, setGraphFilter] = useState("week");
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        totalPage: null,
        searchValue: "",
        deleteId: '',
        delete: false,
        add: false,
        edit: false,
        loading: false,
        row: {},
    });

    function convertMili(da) {
        const date = new Date(da);
        return date.getTime();
    }

    const handleDates = (val, hours, minutes) => {
        const Dates = new Date();
        Dates.setDate(Dates.getDate() - val);
        Dates.setHours(hours, minutes, 0, 0);
        return Dates;
    };
    const [startDate, setStartDate] = useState(() => {
        const start = handleDates(6, 0, 0); // 7 days ago, start of the day
        start.setHours(0, 0, 0, 0);
        return start;
    });
    const [endDate, setEndDate] = useState(() => {
        const end = new Date(); // Today
        end.setHours(23, 59, 59, 999); // Set to the end of today
        return end;
    });



    const FetchTransacrtionList = async (newPage, searchValue) => {
        if (startDate && endDate) {
            if (calculateDifferenceInDays(startDate, endDate) > 365) {
                toast.error("Maximum selectable time period is one year.")
                return
            }
            setState((prev) => ({ ...prev, loading: true }));
            try {
                const params = {
                    page: newPage,
                    startDate: convertMili(startDate),
                    endDate: convertMili(endDate)
                };

                if (searchValue) {
                    params.search = searchValue;
                }

                console.log("params", params);

                const { data } = await axios.get(
                    `${baseUrl}/api/v1/super-admin/transaction/list`,
                    {
                        headers: {
                            Authorization: refToken,
                        },
                        params,
                    }
                );
                setState((prev) => ({ ...prev, loading: false }));
                if (data?.status) {
                    setTransactionList(data?.data?.allTransaction);
                    setState((prev) => ({ ...prev, totalPage: data?.data?.totalPage }));
                }
            } catch (error) {
                setState((prev) => ({ ...prev, loading: false }));
                if (error?.response?.data?.message.includes("Unauthorized")) {
                    navigate("/super-admin-login");
                    localStorage.removeItem("refreshToken");
                }
                toast.error(error?.response?.data?.message || "Something went wrong!!");
                console.error(error);
            }
        }
    }

    const handlePageChange = (pageNo) => {
        setState((prev) => ({
            ...prev,
            page: pageNo,
        }));
        setTransactionList([]);
        FetchTransacrtionList(pageNo, state.searchValue);
    }

    const handleSearch = useCallback(
        debounce((searchQuery) => {
            setTransactionList([]);
            FetchTransacrtionList(state.page, searchQuery);
        }, 700),
        []
    );

    const handleSearchChange = (e) => {
        const searchData = e.target.value;
        setState((prev) => ({
            ...prev,
            searchValue: searchData,
        }));
        handleSearch(searchData);
    }

    console.log(transactionList, "transactionList");

    useEffect(() => {
        FetchTransacrtionList(state.page, state.searchValue)
    }, [startDate, endDate])

    const calculateDifferenceInDays = (startTime, endTime) => {
        const differenceInMilliseconds = endTime - startTime;
        const millisecondsInADay = 24 * 60 * 60 * 1000;
        return Math.floor(differenceInMilliseconds / millisecondsInADay);
    };



    const ExportCsv = async () => {
        if (startDate && endDate) {
            console.log("qqqqwert",
                calculateDifferenceInDays(startDate, endDate)
            );
            if (calculateDifferenceInDays(startDate, endDate) > 365) {
                toast.error("Maximum selectable time period is one year.")
                return
            }
            let params = {
                startDate: convertMili(startDate),
                endDate: convertMili(endDate),
                search: state.searchValue,
                isAdminExport: true
            }

            setState((prev) => ({ ...prev, loading: true }));

            try {
                const { data } = await axios.get(
                    `${baseUrl}/api/v1/super-admin/transaction/list`,
                    {
                        headers: {
                            Authorization: refToken,
                        },
                        params,
                    }
                );
                console.log(data, "export");
                if (data) {
                    const link = document.createElement('a');
                    link.href = `${data?.data}`;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }

            } catch (error) {
                toast.error(error?.response?.data?.message || "Something went wrong")
                if (error.response.data.message.includes("Unauthorized")) {
                    navigate("/super-admin-login");
                    localStorage.removeItem("refreshToken");
                }
                console.log(error);
            } finally {
                setState((prev) => ({ ...prev, loading: false }));

            }
        } else {
            toast.error("Start Date and End Date is Required")
        }

    }

    const handleAction = async (id, amount, action) => {
        setState((prev) => ({ ...prev, loading: true }));
        let url = action === 'refund' ? `${baseUrl}/api/v1/super-admin/transaction/sales-return` : `${baseUrl}/api/v1/super-admin/transaction/sales-void`
        let obj = {
            transaction_id: id,
        }
        try {
            const response = await axios.post(url, obj, {
                headers: {
                    Authorization: refToken
                }
            })
            console.log(url, id, response, "response");
            if (response?.status == 200) {
                FetchTransacrtionList(state.page, state.searchValue)
            }
        } catch (error) {
            console.log(error?.response,"lplp");

            toast.error(error?.response?.data?.message || "Something went wrong!!")
            setState((prev) => ({ ...prev, loading: false }));
        }
    }

    const HandleRefund = (e, transaction) => {
        e.stopPropagation()
        console.log(transaction, "Refund", transaction?.pos_guid);
        handleAction(transaction?.pos_guid, transaction?.Amount, 'refund')
    }

    const HandleVoid = (e, transaction) => {
        e.stopPropagation()
        console.log(transaction, "Void");
        handleAction(transaction?.pos_guid, transaction?.Amount, 'void')
    }


    return (
        <>
            <div className='' >
                <div className="dashboard-filter payments mb-4 row">
                    <div className="col-lg-3">
                        <p className="heading">Transactions</p>
                    </div>
                    <div className="d-flex col-lg-9 justify-content-end search-dates" >
                        <div className="" >
                            <button
                                onClick={ExportCsv}
                                className="btn app-btn purp border border-1 mx-1 export-csv-btn">Export CSV</button>
                        </div>
                        <div className="search-box">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_7372_11113)">
                                    <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7372_11113">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <input
                                className="searchbox"
                                type="text"
                                placeholder="Search"
                                name="search"
                                onChange={handleSearchChange}
                                value={state.searchValue}
                            />
                        </div>
                        <div className="cursor-pointer position-relative">
                            <DatePickers startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} setGraphFilter={setGraphFilter} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="date-picker-container overflow-x-auto">
                <Table>
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>Merchant Name</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Last 4</th>
                            {/* <th>Brand</th> */}
                            <th>Status</th>
                            <th>Fee</th>
                            <th>Net</th>
                            <th style={{ width: '12%' }} >Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionList && transactionList.length > 0 ? (
                            transactionList?.map((transaction) => (
                                <tr
                                    className="table-hover cursor-pointer"
                                    key={transaction.tran_id}
                                    onClick={() => navigate(`/admin/transaction/${transaction?.pos_guid}`)}
                                >
                                    <td>{moment(transaction.Date).format("MM.DD.YYYY")}</td>
                                    <td>
                                        {transaction.merchantDetails?.businessName
                                            ? transaction.merchantDetails?.businessName
                                            : "Merchant Name"}
                                    </td>
                                    <td style={{ color: "#383838", fontWeight: 700 }}>
                                        {typeof transaction.Amount !== "string"
                                            ? `$${parseFloat(transaction.Amount).toLocaleString(
                                                "en-US",
                                                {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            )}`
                                            : `${transaction.Amount.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}`}
                                    </td>
                                    <td>{transaction.Type}</td>
                                    <td>
                                        {(transaction.cardType || transaction.lastFourDigits) ? (
                                            <CardCheck type={transaction.cardType} lastFour={transaction.lastFourDigits} />
                                        ) : null}
                                    </td>
                                    <td>{<GetStatusLabelGlobal status={transaction.is_status} />}</td>
                                    <td>{`$${Number(transaction.fee_amount).toFixed(2)}`}</td>
                                    <td>{`$${Number(transaction.Net).toFixed(2)}`}</td>
                                    <td>
                                        {
                                            transaction?.is_status == 2 && (
                                                <div className="button-hover">
                                                    <div className="tooltip-toggle" aria-label="Refund" tabIndex="0" >
                                                        <div onClick={(e) => { HandleRefund(e, transaction) }} >
                                                            <RefundIcon />
                                                        </div>
                                                    </div>
                                                    <div className="tooltip-toggle" aria-label="Void" tabIndex="0" >
                                                        <div onClick={(e) => { HandleVoid(e, transaction) }}  >
                                                            <VoidIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={10} style={{ textAlign: "center" }}>
                                    No transaction available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <div>
                <PaginationPg
                    totalPages={state.totalPage}
                    onPageChange={handlePageChange}
                    current={state.page}
                />
                {state.loading && <Loader loading={state.loading} />}
            </div>
        </>
    )
}

export default TransactionList