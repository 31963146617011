import React from 'react'

const Calculator = () => {
  return (
    <div>
          <div className="calculator-wrapper text-left calculator-part-right">
              <div className="calculator">
                  <div id="output" className="text-right">
                      9
                  </div>
                  <div className="btns">
                      <div className="row">
                          <div className="col-md-10 pr-0">
                              <button className="btn num" value={1}>
                                  1
                              </button>
                              <button className="btn num" value={2}>
                                  2
                              </button>
                              <button className="btn num" value={3}>
                                  3
                              </button>
                              <button className="btn num" value={4}>
                                  4
                              </button>
                              <button className="btn num" value={5}>
                                  5
                              </button>
                              <button className="btn num" value={6}>
                                  6
                              </button>
                              <button className="btn num" value={7}>
                                  7
                              </button>
                              <button className="btn num" value={8}>
                                  8
                              </button>
                              <button className="btn num" value={9}>
                                  9
                              </button>
                              <button className="btn num dotinput" value=".">
                                  .
                              </button>
                              <button className="btn num" value={0}>
                                  0
                              </button>
                              <button className="btn orng-btn" value="C">
                                  C
                              </button>
                          </div>
                          <div className="col-sm-12 col-md-2 col-lg-2 add_btn pl-md-0">
                              <button className="btn blue-btn" value="+">
                                  +
                              </button>
                              <button className="btn blue-btn" value="x">
                                  <i className="fa fa-arrow-left" aria-hidden="true" />
                              </button>
                              {/* <a class="btn btn-primary" id="add" value="+">+</a> */}
                              {/* <a class="btn blue-btn" onclick="removeSingleElement();">
                                                      <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                                  </a> */}
                          </div>
                      </div>
                  </div>
              </div>
          </div>

    </div>
  )
}

export default Calculator