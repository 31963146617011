import React, { useEffect, useState } from "react";
import DocsHeader from "./DocsHeader";
import DocsSideBar from "./DocsSideBar";

import DocsRightBar from "./DocsRightBar";
import "./docs.css";
import "./ketx.css";

import logo from "../assets/images/logo.png";
import ContentRoutes from "./content/ContentRoutes";
import { Link, useLocation, useNavigate } from "react-router-dom";

const DocsLayout = () => {
  const navigate=useNavigate();
  const [show, setShow] = useState("documentation");
  const [activeTab, setActiveTab] = useState(
    "/docs/documentation/introduction"
  );
  const [method, setMethod] = useState("/docs/api/full-merchant-submit");
  const location = useLocation();

  const handleActiveTab = (route) => {
    setActiveTab(route);
    setMethod(route);
  };

  useEffect(() => {
    if (location?.pathname=="/docs"){
      navigate("/docs/documentation/introduction")
    }
    
    if (location?.pathname?.includes("docs/documentation")) {
      setShow("documentation");
    } else {
      setShow("api");
    }
  }, [location?.pathname]);
console.log("documentaitlain",location.pathname);
  return (
    <>
      <div id="__next">
        <main className="jsx-2860762205">
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        :root {\n          --primary: 166 111 248;\n          --primary-light: 166 111 248;\n          --primary-dark: 53 37 77;\n          --background-light: 247 244 250;\n          --background-dark: 0 0 0;\n          --gray-50: 247 246 250;\n          --gray-100: 243 241 245;\n          --gray-200: 227 225 230;\n          --gray-300: 211 209 213;\n          --gray-400: 163 161 166;\n          --gray-500: 117 115 119;\n          --gray-600: 85 83 87;\n          --gray-700: 67 65 70;\n          --gray-800: 42 40 44;\n          --gray-900: 27 26 30;\n          --gray-950: 15 13 17;\n        }\n      ",
            }}
          />
          <div className="relative antialiased text-gray-500 dark:text-gray-400">
            <span className="fixed inset-0 bg-background-light dark:bg-background-dark" />
            <div id="navbar" className="z-30 fixed top-0 w-full">
              <div className="max-w-8xl mx-auto">
                <div
                  id="navbar-transition"
                  className="backdrop-blur flex-none transition-colors duration-500 bg-background-light supports-backdrop-blur:bg-background-light/95 dark:bg-background-dark/75"
                >
                  <DocsHeader />
                  <div className="hidden lg:flex px-12 h-12 border-b border-gray-500/5 dark:border-gray-50/[0.06]">
                    <div className="h-full flex text-sm space-x-6">
                      {show == "documentation" ? (
                        <>
                          <Link
                            to={"/docs/documentation/introduction"}
                            className="group relative h-full flex items-center text-gray-800 dark:text-gray-200 font-semibold"
                          >
                            Documentation
                            <div className="absolute -bottom-px h-[1.5px] w-full bg-primary dark:bg-primary-light" />
                          </Link>
                          <Link
                            to={"/docs/api/full-merchant-submit"}
                            className="group relative h-full flex items-center font-medium text-gray-600 dark:text-gray-400 group-hover:text-gray-800 dark:group-hover:text-gray-300"
                          >
                            API Reference
                            <div className="absolute -bottom-px h-[1.5px] w-full group-hover:bg-gray-200 dark:group-hover:bg-gray-700"></div>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link
                            to={"/docs/documentation/introduction"}
                            className="group relative h-full flex items-center font-medium text-gray-600 dark:text-gray-400 group-hover:text-gray-800 dark:group-hover:text-gray-300"
                          >
                            Documentation
                            <div className="absolute -bottom-px h-[1.5px] w-full group-hover:bg-gray-200 dark:group-hover:bg-gray-700"></div>
                          </Link>
                          <Link
                            to={"/docs/api/full-merchant-submit"}
                            className="group relative h-full flex items-center text-gray-800 dark:text-gray-200 font-semibold"
                          >
                            API Reference
                            <div className="absolute -bottom-px h-[1.5px] w-full bg-primary dark:bg-primary-light" />
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 mx-auto max-w-8xl lg:px-8">
              <DocsSideBar show={show} activeTab={activeTab} setActiveTab={setActiveTab} method={method} setMethod={setMethod} handleActiveTab={handleActiveTab}/>
              <div className="" id="content-container">
                <div className="flex flex-row items-stretch gap-12 pt-[9.5rem]">
                  <ContentRoutes setActiveTab={setActiveTab}  handleActiveTab={handleActiveTab}/>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default DocsLayout;
