import React from 'react';

const StatsFlexFormatter = (props) => {

    const isValidNumber = (value) => {
        return value !== null && value !== undefined && !isNaN(value) && value !== '';
    };

    const formatAmount = (value, isWholeNumber = false) => {
        if (!isValidNumber(value)) {
            return isWholeNumber ? '0' : '0.00';
        }
        return Number(value).toLocaleString('en-US', {
            minimumFractionDigits: isWholeNumber ? 0 : 2,
            maximumFractionDigits: isWholeNumber ? 0 : 2,
        });
    };

    return (
        <>
            {(isValidNumber(props?.amount) || isValidNumber(props?.diffAmt)) ? (
                <div className="">
                    {(props?.amount === '0' || props?.amount === undefined) && (props?.diffAmt === '0' || props?.diffAmt === undefined) ? (
                        <h4 className="">$0.00</h4>
                    ) : (
                        <div className="d-flex bottm-total">
                            <h4 className="">
                                ${formatAmount(props?.amount)}
                            </h4>
                            {isValidNumber(props?.diffAmt) && (
                                <h6 className={`${props?.diffAmt > 0 ? "d-green" : props?.diffAmt < 0 ? "d-red" : "d-black"}`}>
                                    ${props?.diffAmt < 0 
                                        ? formatAmount(props?.diffAmt?.toString()?.slice(1)) 
                                        : formatAmount(props?.diffAmt)}
                                    {props?.diffAmt > 0 ? (
                                        <i className="fa-solid fa-arrow-up"></i>
                                    ) : props?.diffAmt < 0 ? (
                                        <i className="fa-solid fa-arrow-down"></i>
                                    ) : null}
                                </h6>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div className="">
                    {(props?.totalNum === '0' || props?.totalNum === undefined) && (props?.diffNum === '0' || props?.diffNum === undefined) ? (
                        <h6 className="">0</h6>
                    ) : (
                        <div className='d-flex bottm-total'>
                            <h4 className="">
                                {formatAmount(props?.totalNum, true)}
                            </h4>
                            {isValidNumber(props?.diffNum) && (
                                <h6
                                    className={`${props?.diffNum > 0
                                        ? "d-green"
                                        : props?.diffNum < 0
                                            ? "d-red"
                                            : "d-black"
                                        }`}
                                >
                                    {props?.diffNum > 0 ? "+" : null}
                                    {formatAmount(props?.diffNum)}
                                    {props?.diffNum < 0
                                        ? <i className="fa-solid fa-arrow-down"></i>
                                        : props?.diffNum > 0
                                            ? <i className="fa-solid fa-arrow-up"></i>
                                            : null}
                                </h6>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default StatsFlexFormatter;
