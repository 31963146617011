import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import DragNDrop from "../../../components/DragNDrop";
import baseUrl from "../../../../baseUrl";
import { useRefToken } from "../../../hooks/useToken";

const DownloadSuperMerchantCSV = ({ handleState, state }) => {
  const id = state.row.id;
  const refToken = useRefToken();
  const [files, setFiles] = useState([]);
  const [bot_key, setBot_key] = useState("");

  const handleSubmit = async () => {
    if (files.length === 0) {
      toast.error("Please select a file.");
      return;
    }
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("bot_key",bot_key || "")

    try {
      handleState("loading", true);
      const response = await axios.post(
        `${baseUrl}/api/v1/super-admin/merchant/upload-documents/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: refToken,
          },
        }
      );
      handleState("download", false);
      toast.success("Files uploaded successfully.");
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error(error);
    } finally {
      handleState("loading", false);
    }
  };

  return (
    <div>
      <div className="row d-flex justify-content-center align-items-center my-4">
        <DragNDrop onFilesSelected={setFiles} width="auto" height="auto" />
      </div>
      <input type='text' id='bot_key' name='bot_key' value={bot_key} onChange={(e) => setBot_key(e.target.value)} style={{ display: 'none' }} />

      <div className="row">
        <div className="col d-flex justify-content-center">
          <button
            onClick={handleSubmit}
            className="btn btn-primary"
            disabled={state.loading}
          >
            {state.loading ? "Uploading..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadSuperMerchantCSV;
