import React from 'react'
import { useNavigate } from 'react-router-dom'

const BackBtn = () => {
    const navigate = useNavigate();
    return (
        <>
            <button className="back-btn" onClick={() => navigate(-1)}>
                <span>
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.189083 6.46824L5.49144 11.8083C5.61408 11.9319 5.77779 12 5.95235 12C6.12691 12 6.29062 11.9319 6.41325 11.8083L6.80373 11.4151C7.05782 11.1589 7.05782 10.7425 6.80373 10.4867L2.35121 6.00249L6.80867 1.51326C6.93131 1.38965 6.99902 1.22487 6.99902 1.04916C6.99902 0.873261 6.93131 0.708482 6.80867 0.584775L6.41819 0.191706C6.29546 0.0680971 6.13185 0 5.95729 0C5.78273 0 5.61902 0.0680971 5.49638 0.191706L0.189083 5.53664C0.0661554 5.66064 -0.00136232 5.8262 -0.000975132 6.00219C-0.00136232 6.17888 0.0661554 6.34434 0.189083 6.46824Z" fill="#35254D" />
                    </svg>

                </span>
                <span className="ms-2">
                    Back
                </span>
            </button>
        </>
    )
}

export default BackBtn