import React from 'react'
import hero from "../../asset/hero1.png"
import { Link } from 'react-router-dom'

const Introduction = ({setActiveTab}) => {
  return (
    <>
          <div
              className="relative grow overflow-hidden mx-auto  lg:-ml-12 lg:pl-[23.7rem]"
              id="content-area"
          >
              <header id="header" className="relative">
                  <div className="mt-0.5 space-y-2.5">
                      <div className="eyebrow h-5 text-primary dark:text-primary-light text-sm font-semibold">
                          API Documentation
                      </div>
                      <div className="flex items-center">
                          <h1 className="inline-block text-2xl sm:text-3xl font-extrabold text-gray-900 tracking-tight dark:text-gray-200">
                              Introduction
                          </h1>
                      </div>
                  </div>
                  <div className="mt-2 text-lg prose prose-gray dark:prose-invert">
                      <p>Welcome to the Fractal documentation</p>
                  </div>
              </header>
              <div className="flex flex-col gap-8">
                  <div className="flex flex-col gap-6 xl:hidden" />
              </div>
              <div className="relative mt-8 prose prose-gray dark:prose-invert">
                  <span aria-owns="rmiz-modal-40fd289bb3fe" data-rmiz="">
                      <span
                          data-rmiz-content="found"
                          style={{ visibility: "visible" }}
                      >
                          <img
                              className="block dark:hidden"
                              src={hero}
                              alt="Hero Light"
                          />
                      </span>
                      <span
                          data-rmiz-ghost=""
                          style={{ height: 405, left: 0, width: 721, top: 0 }}
                      >
                          <button
                              aria-label="Expand image: Hero Light"
                              data-rmiz-btn-zoom=""
                              type="button"
                          >
                              <svg
                                  aria-hidden="true"
                                  data-rmiz-btn-zoom-icon="true"
                                  fill="currentColor"
                                  focusable="false"
                                  viewBox="0 0 16 16"
                                  xmlns="http://www.w3.org/2000/svg"
                              >
                                  <path d="M 9 1 L 9 2 L 12.292969 2 L 2 12.292969 L 2 9 L 1 9 L 1 14 L 6 14 L 6 13 L 2.707031 13 L 13 2.707031 L 13 6 L 14 6 L 14 1 Z"></path>
                              </svg>
                          </button>
                      </span>
                  </span>
                  <span aria-owns="rmiz-modal-1d582f8e8bf4" data-rmiz="">
                      <span
                          data-rmiz-content="not-found"
                          style={{ visibility: "visible"}}
                      >
                          <img
                              className="hidden dark:block"
                              src="./Introduction - API Documentation_files/7.6.png"
                              alt="Hero Dark"
                          />
                      </span>
                  </span>
                  <h2 className="flex whitespace-pre-wrap group" id="welcome">
                      <div className="absolute">
                          <a
                              href="https://docs.fractalpay.com/introduction#welcome"
                              className="-ml-10 flex items-center opacity-0 border-0 group-hover:opacity-100"
                              aria-label="Navigate to header"
                          >

                              <div className="w-6 h-6 text-gray-400 rounded-md flex items-center justify-center zinc-box bg-white ring-1 ring-gray-400/30 dark:ring-gray-700/25 hover:ring-gray-400/60 dark:hover:ring-white/20">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="gray"
                                      height="12px"
                                      viewBox="0 0 576 512"
                                  >
                                      <path d="M0 256C0 167.6 71.6 96 160 96h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C98.1 144 48 194.1 48 256s50.1 112 112 112h72c13.3 0 24 10.7 24 24s-10.7 24-24 24H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c61.9 0 112-50.1 112-112s-50.1-112-112-112H344c-13.3 0-24-10.7-24-24s10.7-24 24-24h72c88.4 0 160 71.6 160 160zM184 232H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"></path>
                                  </svg>
                              </div>
                          </a>
                              {/* <span className="cursor-pointer">Welcome</span> */}
              </div>
                  </h2>
                  <p>
                      Welcome to the documentation for our suite of Gateway APIs.
                      This set of tools is designed to empower developers and SaaS
                      platforms by providing a seamless and efficient interface to
                      manage all things payments. From the onboarding of companies
                      to the streamlined handling of payments, this suite of APIs
                      is purpose-built to be robust, secure, and easy to
                      integrate.
                  </p>
                      <h2>Test Card Data</h2>
                        <p>Our sandbox uses the same test card for all information. This card would be prefilled for your testing needs each time a payment widget or form is activated.

                        Use the following card information for testing purposes:</p>
                        
                        <h3>Visa</h3>
                        <ul>
                            <li><strong>Card Number:</strong> 4761731000000043</li>
                            <li><strong>Expiration Date:</strong> 12/2024</li>
                            <li><strong>CVV:</strong> 201</li>
                        </ul>

                        <h3>Mastercard</h3>
                        <ul>
                            <li><strong>Card Number:</strong> 5413330089020060</li>
                            <li><strong>Expiration Date:</strong> 12/2025</li>
                            <li><strong>CVV:</strong> 201</li>
                        </ul>
              </div>
              <div className="leading-6 mt-14">
                  <div className="mb-12 px-0.5 flex items-center text-sm font-semibold text-gray-700 dark:text-gray-200">
                      <Link
                          to={"/docs/documentation/implementation-guide"}
                          className="flex items-center ml-auto space-x-3 group"
                          href="https://docs.fractalpay.com/implementationguide"
                          onClick={()=>setActiveTab("/docs/documentation/implementation-guide")}
                      >
                          <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                              Implementation Guide
                          </span>
                          <svg
                              viewBox="0 0 3 6"
                              className="rotate-180 h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                          >
                              <path
                                  d="M3 0L0 3L3 6"
                                  fill="none"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                              />
                          </svg>
                      </Link>
                  </div>
                  <footer className="justify-between pt-10 border-t border-gray-100 sm:flex dark:border-gray-800/50 pb-28">
                      <div className="flex mb-6 space-x-6 sm:mb-0">
                          {/* <Link
                             
                              target="_blank"
                          >
                              <span className="sr-only">linkedin</span>
                              <svg
                                  className="w-5 h-5 bg-gray-400 dark:bg-gray-500 hover:bg-gray-500 dark:hover:bg-gray-400"
                                  style={{
                                      maskImage:
                                          'url("https://mintlify.b-cdn.net/v6.5.1/brands/linkedin.svg")',
                                      maskRepeat: "no-repeat",
                                      maskPosition: "center center"
                                  }}
                              />
                          </Link> */}
                      </div>
                      <div className="sm:flex">
                          <a
                            
                            
                              rel="noreferrer"
                              className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                          >
                              Fractal Pay
                          </a>
                      </div>
                  </footer>
              </div>
          </div>

          {/* right bar */}

          <div
              className="z-10 hidden xl:flex flex-none pl-10 w-[19rem]"
              id="table-of-contents"
          >
              <div className="fixed text-gray-600 text-sm leading-6 w-[16.5rem] overflow-y-auto space-y-2 h-[calc(100%-10rem)]">
                  <div className="text-gray-700 dark:text-gray-300 font-medium flex items-center space-x-2">
                      <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3 w-3"
                      >
                          <path
                              d="M2.44434 12.6665H13.5554"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          />
                          <path
                              d="M2.44434 3.3335H13.5554"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          />
                          <path
                              d="M2.44434 8H7.33323"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                          />
                      </svg>
                      <span>On this page</span>
                  </div>
                  <ul>
                      <li>
                          <Link
                        //    to={"#welcome"}
                              className="py-1 block -ml-px font-medium text-primary dark:text-primary-light border-l border-primary dark:border-primary-light"
                          >
                              Welcome
                          </Link>
                      </li>
                  </ul>
              </div>
          </div>

    </>
  )
}

export default Introduction