import { createSlice } from "@reduxjs/toolkit";


let tokenSlice = createSlice({
    name: "token",
    initialState: { token: null, toast: {status: null,message: '',open: null}, error : false },
    reducers: {
        getToken: (state, action) => {
            state.token = action.payload;
        },
        showToaster: (state, action) => {
            state.toast = action.payload;
        },
        showError: (state, action) => {
            state.error = action.payload;
        }
    }
})

export const tokenAction = tokenSlice.actions;
const tokenReducer = tokenSlice.reducer;
export default tokenReducer;