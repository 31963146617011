import React from 'react';
import { numberFormat } from '../hooks/numberFormat';

const StatsFormatter = (props) => {

    const isValidNumber = (value) => {
        return value !== null && value !== undefined && !isNaN(value) && value !== '';
    };

    return (
        <>
            {(isValidNumber(props?.amount) || isValidNumber(props?.diffAmt)) ? (
                <div className="">
                    {(props?.amount === '0' || props?.amount === undefined) && (props?.diffAmt === '0' || props?.diffAmt === undefined) ? (
                        <h4 className="">$0.00</h4>
                    ) : (
                        <>
                            {isValidNumber(props?.diffAmt) && (
                                <h6 className={`${props?.diffAmt > 0 ? "d-green" : props?.diffAmt < 0 ? "d-red" : "d-black"}`}>
                                    {props?.diffAmt > 0 ? (<i className="fa-solid fa-arrow-up"></i>) :
                                        props?.diffAmt < 0 ? (<i className="fa-solid fa-arrow-down"></i>) : null}
                                </h6>
                            )}
                            <h4 className="">${numberFormat(props?.amount, false)}</h4>
                           
                        </>
                    )}
                </div>
            ) : (
                <div className="">
                    {(props?.totalNum === '0' || props?.totalNum === undefined) && (props?.diffNum === '0' || props?.diffNum === undefined) ? (
                        <h4 className="">0</h4>
                    ) : (
                        <>
                            {isValidNumber(props?.diffNum) && (
                                <h6
                                    className={`${props?.diffNum > 0
                                        ? "d-green"
                                        : props?.diffNum < 0
                                            ? "d-red"
                                            : "d-black"
                                        }`}
                                >
                                    {props?.diffNum > 0 ? "+" : null}
                                    {numberFormat(props?.diffNum, false)}
                                    {props?.diffNum < 0
                                        ? <i className="fa-solid fa-arrow-down"></i>
                                        : props?.diffNum > 0
                                            ? <i className="fa-solid fa-arrow-up"></i>
                                            : null}
                                </h6>
                            )}
                            <h4 className="">{numberFormat(props?.totalNum, false)}</h4>
                           
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default StatsFormatter;
