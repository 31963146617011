import React from "react";
import { createPublicKey } from "../../../api/Profile";
import { useToken } from "../../../hooks/useToken";

const KeyConfirm = (props) => {
  const { setState, accountData, state } = props;
  const token = useToken();
  const handleClose = () => {
    setState((prev) => ({ ...prev, key: false }));
  };

  const handleSubmit = async () => {
    const body = {
      guid: accountData?.h_guid ? accountData?.h_guid : accountData?.guid,
      clientId: "",
      isUpdateApikeys: state.keys,
    };
    try {
      setState((prev) => ({ ...prev, loading: true }));
      const data = await createPublicKey(token, body);
      setState((prev) => ({ ...prev, accData: data, loading: false }));
      handleClose();
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
      console.error("Error creating item:", error);
    }
  };
  return (
    <div className="">
      <h5 className="text-center">Are you sure ?</h5>
      {/* <p className="text-center">
        Note :- If you update your key then app is change
      </p> */}
      {/* <div className="d-flex gap-2 justify-content-center">
        <button className="btn btn-primary" onClick={handleSubmit}>
          YES
        </button>
        <button className="btn btn-danger text-white" onClick={handleClose}>
          CANCEL
        </button>
      </div> */}
      <div className="row pt-3">
        <div className="col-6">
          <button
            className="cancel-btn "
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button className="add-btn" onClick={handleSubmit}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default KeyConfirm;
