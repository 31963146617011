import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import baseUrl from "../../../baseUrl";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import Input from 'react-phone-number-input/input'
import { tokenAction } from "../../../../redux/tokenSlice";
import { getSuprAdminRolesAll, updateSuperAdminEmployee } from "../../../api/staff";
import { useRefToken } from "../../../hooks/useToken";
import baseUrl from "../../../../baseUrl";

const EditEmployee = (props) => {
  const dispatch = useDispatch();
  const [showRole, setShowRole] = useState(null)
  const phoneNum = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
  const { setEmployee, handleState, setState, state, setHasMore, employee } = props;
  const token = useRefToken();
  const [data, setData] = useState({
    first_name: "",
    email: "",
    role_id: "",
    phonenumber: "",
    bot_key: "",
  });
  const [error, setError] = useState({});
  const [role, setRole] = useState([]);

  const Validation = () => {
    let error = {};
    if (!requiredValue(data.first_name)) {
      error.first_name = "first name is required";
    }
    if (!requiredValue(data?.role_id)) {
      error.role_id = "Please select a role";
    }
    if (!requiredValue(data?.phonenumber)) {
      error.phonenumber = "Please enter a valid phone number";
    }
    if (Object.keys(error).length < 1) {
      return false;
    }
    return error;
  };


  const handleChange = (name, value) => {
    let newData = { ...data };
    // const { name, value } = e.target;
    console.log(value)
    newData[name] = value?.trim();
    setData(newData);
  };
  const handleData = async (e) => {
    e.preventDefault();
    setError({});
    let errors = Validation();
    if (!errors) {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
      try {
        let name = data?.first_name?.trim()?.split(" ");
        let firstName = name[0]
        let lastName = name?.slice(1)?.join(" ")
        let finalData = { ...data, phonenumber: (data?.phonenumber.toString()).slice(2), first_name: firstName }

        const response = await updateSuperAdminEmployee(token, finalData, state.update.id);

        if (!state?.filterParam || state?.filterParam == response?.data?.role_id) {
          let newData = employee?.map((item) => {
            if (item?.id === response?.data?.id) {
              return { ...item, ...response?.data };
            }
            return item;
          });
          setEmployee(newData)
        }

        else {
          let newData = employee?.filter((item) => item?.id != response?.data?.id);
          setEmployee(newData)
        }
        dispatch(tokenAction.showToaster({ status: true, message: `You have updated ${data?.first_name}`, open: true }));
        handleState("edit", false);
        setHasMore(true);
        setState((prev) => ({
          ...prev,
          // page:1,
          loading: false,
        }));
      } catch (error) {
        // console.log("errorerror,", error);

        // if (error?.response?.data?.message?.password) {
        //   dispatch(tokenAction.showToaster({ status: false, message: error?.response?.data?.message?.password, open: true }));
        // } else if (error?.response?.data?.message?.email){
        //   dispatch(tokenAction.showToaster({ status: false, message: error?.response?.data?.message?.email, open: true }));
        // }else {
        //   dispatch(tokenAction.showToaster({ status: false, message: error?.response?.data?.message, open: true }));
        // }
        if (typeof error?.response?.data?.message == "object") {
          dispatch(tokenAction.showToaster({ status: false, message: Object.values(error?.response?.data?.message)[0], open: true }));
        } else {
          dispatch(tokenAction.showToaster({ status: false, message: error?.response?.data?.message, open: true }));
        }
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } else {
      setError(errors);
    }
  };

  const requiredValue = (value) => {
    if (!value) {
      return false;
    }
    return true;
  };

  const fetchRoles = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    const param = {
      isAll: true,
    };
    try {
      const response = await getSuprAdminRolesAll(token, param);
      setRole(response);
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const fetchData = async () => {
    try {
      let resp = await axios.get(
        `${baseUrl}/api/v1/super-admin/employee/get-by-id/${props?.state?.update?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (resp?.status == 200) {
        setData((prev) => ({
          ...prev,
          first_name: `${resp?.data?.data?.first_name} ${resp?.data?.data?.last_name}`,
          email: resp?.data?.data?.email,
          phonenumber: `+1${resp?.data?.data?.phonenumber}`,
          role_id: resp?.data?.data?.role_id?.toString(),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchRoles();
  }, []);

  const handleSelectRole = (roleId, roleName) => {
    let newData = { ...data };
    newData["role_id"] = roleId;
    setData(newData);
    setShowRole(roleName);
    setError(error?.role_id == "");
  }
  useEffect(() => {
    if (role) {
      handleUserRole();
    }
  }, [role])
  function handleUserRole() {
    if (role?.length > 0) {
      let urole = role?.find((item) => item?.role_id == data?.role_id);
      setShowRole(urole?.role_name);
    }
  }
  return (
    <>
      <div className="container-xl">
        <div className="row">
          <div className="col-12">
            <label className="">FIRST AND LAST NAME</label>
            <input
              className="custom-date-picker"
              type="text"
              value={data.first_name?.trim()}
              placeholder="Enter first and last name"
              name="first_name"
              onChange={(e) => handleChange("first_name", e.target.value)}

              required
            />
            {error.first_name && (
              <span className="error text-danger fs-12">
                {error.first_name}
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label className="">EMAIL ADDRESS</label>
            <input
              className="custom-date-picker"
              type="text"
              name="email"
              placeholder="Enter email address"
              value={data.email}

              // onChange={(e) => handleChange("email", e)}

              inputMode="numeric"
              required
              readOnly
            />
          </div>
        </div>


        <div className="row">
          <div className="col-6">
            <label className="">Role Type</label>
            {/* <select
              className="custom-date-picker"
              name="role_id"
              onChange={handleChange}
              value={data.role_id}
            >
              <option value="">Role Type</option>
              {role &&
                role?.map((itm) => (
                  <option key={itm.role_id} value={itm.role_id}>
                    {itm.role_name}
                  </option>
                ))}
            </select> */}
            <div className="d-flex add-emp">

              <Dropdown>
                <Dropdown.Toggle style={{textTransform:'capitalize'}} variant="success" id="dropdown-basic-1">
                  {showRole ?? "Select Role"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {role?.map((itm, ind) => (

                    <Dropdown.Item style={{textTransform:'capitalize'}} href="javascript:void(0)" onClick={() => handleSelectRole(itm.role_id, itm?.role_name)} key={ind}>{itm.role_name}
                      {/* <span>Placeholder text</span> */}
                    </Dropdown.Item>
                  ))}

                </Dropdown.Menu>
              </Dropdown>
            </div>
            {error.role_id && (
              <span className="error text-danger fs-12">{error.role_id}</span>
            )}
          </div>
          <div className="col-6">
            <label className="">PHONE</label>
            {/* <input
              className="custom-date-picker"
              type="text"
              maxLength="10"
              value={data.phonenumber}
              name="phonenumber"
              placeholder="Phone"
              onChange={handleChange}
              required
              /> */}
            <Input
              maxLength="14"
              className="custom-date-picker"
              placeholder="Phone"
              country="US"
              value={data?.phonenumber}
              onChange={(e) => handleChange("phonenumber", e)}
            // onChange={handleChange}
            />
            {error.phonenumber && (
              <span className="error text-danger fs-12">
                {error.phonenumber}
              </span>
            )}
          </div>
        </div>
        <input type="text" name="bot_key" onChange={(e) => handleChange("bot_key", e.target.value)} style={{ display: "none !important" }} hidden />
        <div className="row">
          <div className="col-6">
            <button
              className="cancel-btn "
              onClick={() => {
                handleState("edit", false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="col-6">
            <button className="add-btn" onClick={handleData}>
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEmployee;
