import React from 'react'
import { Link } from 'react-router-dom'

const OnboardingSession = ({ handleActiveTab }) => {
    const head = {
        "Content-Type": "application/json",
        "Authorization": "Basic keyinfo=="
    }
    const body = {
        "merchant_key": "(merchant_key)"
    }

    const codeSnippet = `
        curl --location 'http://localhost:8081/api/v1/auth/onboarding/{{session_token}}' \\
        --header 'Content-Type: application/json' \\
        --header 'Authorization: {{client_auth}}=' \\
        --data-raw '{
          "company_id": "{{internal_company_id}}",
          "BusinessInfo": {
            "business_name": "Test Ready Mix LLC",
            "dba": "Test Ready Mix",
            "business_phone": "8599779166",
            "business_email": "testreadymix@fractal.com",
            "ein": "123456789",
            "ownershiptype": "LLC",
            "established_date": "2024-01-01",
            "business_address": "355 West Vine Street",
            "business_city": "Lexington",
            "business_state": "KY",
            "business_zip": "40517",
            "business_country": "US"
          }
        }'
    `;

    return (
        <>
            <div
                className="relative grow overflow-hidden mx-auto  lg:-ml-12 lg:pl-[23.7rem]"
                id="content-area"
            >
                <header id="header" className="relative">
                    <div className="mt-0.5 space-y-2.5">
                        <div className="eyebrow h-5 text-primary dark:text-primary-light text-sm font-semibold">
                            Hosted Onboarding
                        </div>
                        <div className="flex items-center">
                            <h1 className="inline-block text-2xl sm:text-3xl font-extrabold text-gray-900 tracking-tight dark:text-gray-200">
                                Fractal SAML Authentication & Onboarding
                            </h1>
                        </div>
                    </div>
                </header>

                <div className="relative mt-8 prose prose-gray dark:prose-invert">
                    <h2>Steps for Integrating Fractal SAML Authentication</h2>
                    <p>These steps will guide you through integrating Fractal SAML authentication and our hosted onboarding solution. Follow the steps below to ensure proper integration.</p>

                    <ol>
                        <li><strong>Step 1:</strong> Grab your client public key from your Fractal dashboard. This is a required step to proceed with the onboarding process.</li>
                        <li>
                            <strong>Step 3: Initiate the Onboarding Process</strong>
                            <p>To proceed with SAML authentication, you need to initiate the onboarding process as follows:</p>
                            <ol>
                                <li><strong>Step 3.1:</strong> Create a session via SAML using the following API call:
                                    <pre>
                                        <code>
                                            curl --location 'baseURL/api/v1/auth/create-session' \
                                            --header 'Authorization: Basic [[your_auth_token]]'
                                        </code>
                                    </pre>
                                </li>
                                <li><strong>Step 3.2:</strong> Retrieve the session token from the response.</li>
                            </ol>
                        </li>
                        <li>
                            <strong>Step 4: Initiating the Hosted Onboarding Form</strong>
                            <p>Once you have the session token, you can initiate the hosted onboarding form. The information provided in the body is optional and can be included if the data is available:</p>
                            <pre>
                                <code dangerouslySetInnerHTML={{ __html: codeSnippet }} />
                            </pre>
                        </li>
                        <li><strong>Step 4.1:</strong> Handle the response as needed. The response will include a one-time use link and a <code>merchant_id</code> that you must store for future use.</li>
                        <li>
                            <strong>Step 5: Continuing the Onboarding Process</strong>
                            <p>At each step, application progress is saved. If an application is not completed, you can create a new session (Step 3.1) to continue the onboarding process using the new token.</p>
                            <ul>
                                <li><strong>Step 5.1:</strong> You can use the session details to populate the Merchant Onboarding Form by submitting the API request with the <code>session_token</code>.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Step 6: Checking the Merchant Status for Payment Gateway Approval</strong>
                            <p>Once the form is submitted, the merchant’s status will be displayed as "Pending." Once Fractal’s Risk Department approves the merchant, the status will update to "Approved" in your dashboard.</p>
                            <p>You should subscribe to Fractal webhooks to track <code>merchant_status</code> for real-time updates.</p>
                        </li>
                    </ol>
                </div>

                <div className="leading-6 mt-14">
                    <div className="mb-12 px-0.5 flex items-center text-sm font-semibold text-gray-700 dark:text-gray-200">
                        <Link to={"/docs/api/get-customer"}
                              className="flex items-center space-x-3 group"
                              onClick={() => handleActiveTab("/docs/api/get-customer")}
                        >
                            <svg
                                viewBox="0 0 3 6"
                                className="h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                            >
                                <path
                                    d="M3 0L0 3L3 6"
                                    fill="none"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                                Get Customer
                            </span>
                        </Link>
                        <Link to={"/docs/api/request-card"}
                              className="flex items-center ml-auto space-x-3 group"
                              onClick={() => handleActiveTab("/docs/api/request-card")}
                        >
                            <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                                Request Card
                            </span>
                            <svg
                                viewBox="0 0 3 6"
                                className="rotate-180 h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                            >
                                <path
                                    d="M3 0L0 3L3 6"
                                    fill="none"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Link>
                    </div>

                    <footer className="justify-between pt-10 border-t border-gray-100 sm:flex dark:border-gray-800/50 pb-28">
                        <div className="flex mb-6 space-x-6 sm:mb-0">
                        </div>
                        <div className="sm:flex">
                            <a
                                rel="noreferrer"
                                className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                            >
                                Fractal Pay
                            </a>
                        </div>
                    </footer>
                </div>
            </div>

            <div
                className="z-10 hidden xl:flex flex-none pl-10 w-[19rem]"
                id="table-of-contents"
            />
        </>
    )
}

export default OnboardingSession
