import { createSlice } from "@reduxjs/toolkit";



let dateSlice = createSlice({
    name: "date",
    initialState: { startDate:null,endDate:null  },
    reducers: {
        setDates: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
      
    }
})

export const dateAction = dateSlice.actions;
const dateReducer = dateSlice.reducer;
export default dateReducer;