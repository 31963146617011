import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToken } from "../../../hooks/useToken";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Loader from "../../../DefaultComponent/Loader";
import Pagination from "../../../DefaultComponent/PaginationPg";
import { Table } from "react-bootstrap";
import {
  deleteEmployee,
  getEmployee,
  getRolesAll,
} from "../../../api/Employee";
import AddEmployee from "../Form/AddEmployee";
import EditEmployee from "../Form/EditEmployee";
import PinEmployee from "../Form/PinEmployee";
import Forgotpass from "../Form/Forgotpass";
import "../../Merchant/Tabs/MerchantOnboard.css";
import Edit from "../../../assets/images/Edit.svg";
import Delete from "../../../assets/images/Delete.svg";
import { tokenAction } from "../../../redux/tokenSlice";
import { useDispatch } from "react-redux";
import { debounce, isEmpty } from "lodash";

const EmployeeList = () => {
  const token = useToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employee, setEmployee] = useState([]);
  const [state, setState] = useState({
    page: 1,
    perPage: 10,
    totalPage: 0,
    searchParam: '',
    filterParam: null,
    message: "",
    loading: false,
    update: {},
    pass: {},
    deleteId: null,
    role: false,
    name: ""
  });

  const [form, setForm] = useState({
    show: false,
    edit: false,
    pin: false,
    pass: false,
    delete: false,
  });
  const [role, setRole] = useState([]);
  const [info, setInfo] = useState("");
  const divLoader = useRef(null);
  const [fetMoreData, setFetchMoreData] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [noDataMessage, setNoDataMessage] = useState(false);

  const handleInfo = (value) => {
    if (value == "user") {
      setInfo("user");
    } else {
      setInfo("security");
    }
  };
  const handleState = (item, value) => {
    setForm((prev) => ({
      ...prev,
      [item]: value,
    }));
    setHasMore(true);

  };
  let isScroll = true;
  const fetchEmployees = async (pageNo, perPageNo, searchValue, roleId) => {
    // console.log("searchValue=========",searchValue)
    
    const params = {
      page: pageNo,
      perPage: perPageNo,
    };
    if (searchValue?.trim()) {
      params.search = searchValue;
    }
    if (roleId) {
      params.role_id = roleId;
    }
    try {
      if (isScroll) {
        setState((prev) => ({
          ...prev,
          loading: true,
        }));
        const response = await getEmployee(token, params);

        if (pageNo == 1) {
          setEmployee([]);
        }
        if (!response.status) {
          setHasMore(false)
          isScroll = false;
        }
        if (response?.data?.allList?.length === 0 && response?.data?.count === 0) {
          console.log("no more data as data is empty");
          
          setHasMore(false);
          setNoDataMessage(true);
          setFetchMoreData(0);
          setState((prev) => ({
            ...prev,
            loading: false,
          }));
          setEmployee([]);
          return false;
        }

        if (response?.data?.totalPage == pageNo) {
          setHasMore(false)
          setFetchMoreData(0);
        }
        else {
          setHasMore(true);
          setFetchMoreData(fetMoreData + 1);

        }
        if (response.status) {
          setEmployee((prev) => [...prev, ...response?.data.allList]);
          setNoDataMessage(false);
          setState((prev) => ({
            ...prev,
            // page: response?.data?.page + 1,
            page: state.page + 1,
          }));
        }

        
      }
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
      toast.error(error?.response?.data?.message);
      if (error?.response?.data?.message?.includes("Unauthorized")) {
        navigate("/login");
        localStorage.removeItem("token");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    // console.log("=====================hasmore================", !state.searchParam);
    if (hasMore || !state.searchParam) {

      // if (!state.searchParam && !state.filterParam && hasMore) {
      const options = {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      };

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchEmployees(state.page, state.perPage, state.searchParam, state.filterParam);
        }
      }, options);

      if (divLoader.current) {
        observer.observe(divLoader.current);
      }

      return () => {
        if (divLoader.current) {
          observer.unobserve(divLoader.current);
        }
      };
    }
  }, [state.page, fetMoreData, state.filterParam]);

  const fetchRoles = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    const param = {
      isAll: true,
    };
    try {
      const response = await getRolesAll(token, param);
      setRole(response);
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleSearchChange = (e) => {
    // setEmployee([]);
    const searchData = e.target.value;
    if (searchData != " ") {
      setState((prev) => ({
        ...prev,
        page: 1,
        searchParam: searchData,
        filterParam: null,
      }));
      handleSearch(searchData);
    } else {
      // console.log("serardcj daa=================",searchData);
      setState((prev) => ({
        ...prev,
        page: 1,
        searchParam: null,
      }));
      setHasMore(true)
    }
  };

  const handleSearch = useCallback(
    debounce((searchQuery) => {
      fetchEmployees(1, state.perPage, searchQuery, null);
    }, 700),
    []
  );



  const handleEdit = (item, value) => {
    handleState("edit", true);
    handleInfo(value);
    setState((prev) => ({
      ...prev,
      update: item,
      pass: item,
    }));
  };

  // const handlePass = (item) => {
  //   handleState("pass", true);
  //   setState((prev) => ({
  //     ...prev,
  //     pass: item,
  //   }));
  // };

  const handleDelete = (item) => {
    handleState("delete", true);
    setState((prev) => ({
      ...prev,
      deleteId: item.id,
      name: `${item.first_name} ${item.last_name}`
    }));
  };

  const handleDeleteEmployee = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const response = await deleteEmployee(token, state.deleteId);
      dispatch(tokenAction.showToaster({ status: true, message: `You have deleted ${state?.name} `, open: true }));

      if (response?.result == true) {
        let newData = employee?.filter((item) => {
          if (item?.id != state.deleteId) {
            return item;
          }
        });
        // console.log("=============deleteemp========",newData);

        setEmployee(newData)
      }
      handleState("delete", false);
      setState((prev) => ({
        ...prev,
        // page:1,
        loading: false,
      }));
    } catch (error) {
      dispatch(tokenAction.showToaster({ status: false, message: error?.response?.message, open: true }));
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };
  const handlePin = (item) => {
    handleState("pin", true);
    setState((prev) => ({
      ...prev,
      pin: item,
    }));
  };

  // const handlePerPageChange = (e) => {
  //   const perPageNo = e.target.value;
  //   setEmployee([]);
  //   setstate((prev) => ({
  //     ...prev,
  //     perPage: perPageNo,
  //     loading: true,
  //   }));
  //   fetchEmployees(state.page, perPageNo, state.searchParam, state.filterParam);
  // };

  const handleFilter = (id) => {
    setEmployee([]);
    setHasMore(true);
    setState((prev) => ({
      ...prev,
      page: 1,
      filterParam: id,

      // loading: true,
    }));
    setFetchMoreData(fetMoreData + 1);
    // fetchEmployees(1, state.perPage, state.searchParam, id);
  };
  const handleAll = () => {
    setEmployee([]);
    setHasMore(true);
    setFetchMoreData(fetMoreData + 1);
    setState((prev) => ({
      ...prev,
      page: 1,
      filterParam: null,
      // loading: true,
    }));
    // console.log("=======all=========",state.page);

    // fetchEmployees(1, state.perPage, state.searchParam,null);

  };

  useEffect(() => {
    // fetchEmployees(
    //   state.page,
    //   state.perPage,
    //   state.searchParam,
    //   state.filterParam
    // );
    fetchRoles();
  }, []);

  // color gradient
  const gradientCircle = ({ color }) => {
    const style = {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      // background: `${color}`,
      background: `linear-gradient(171deg, ${color} 0%, rgba(239,239,242,1) 83%)`,
    };

    return style;
  };
  // console.log("hasMore", employee.length ,hasMore,divLoader)
  return (
    <>
      {/* <div className="row">
      <div className="col-12 col-md-4">
        <p className="heading">Employees</p>
      </div>
      <div className="col-12 col-md-8">
        <div className=" d-flex justify-content-md-end gap-2 justify-content-end bg-sucess">
          <input
            className="searchbox"
            type="text"
            placeholder="Search..."
            name="search"
            onChange={handleSearchChange}
            value={state.searchParam}
          />
          <button
            className="btn app-btn-primary purp border border-1"
            style={{ color: "#351269" }}
            onClick={() => handleState("show", true)}
          >
            <i className="fa-solid fa-plus"></i> Add Employee
          </button>
        </div>
      </div>
    </div> */}

      <div className="row">
        <div className="col-lg-2">
          <h4 className="app-page-title">Team</h4>
        </div>
        <div className="col-lg-10 d-md-flex justify-content-end">
          <div className="d-flex justify-content-start align-items-start gap-2 flex-wrap ">
            <div className="search-box">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_7372_11113)">
                  <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                </g>
                <defs>
                  <clipPath id="clip0_7372_11113">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <input
                className="searchbox"
                type="text"
                placeholder="Search"
                name="search"
                onChange={handleSearchChange}
                value={state.searchParam}
              />
            </div>
            <button
              className={`${state.filterParam === null ? "sky-btn-1" : "sky-btn-2"
                } `}
              // style={{
              //   border: `1px solid ${
              //     state.filterParam === null ? "#35254d" : "d4cddf"
              //   }`,
              // }}
              onClick={handleAll}
            >
              All
            </button>
            {role?.map((item) => (
              <button
                className={`${state.filterParam === item.role_id ? "sky-btn-1" : "sky-btn-2"
                  } `}
                key={item.role_id}
                onClick={() => {handleFilter(item.role_id); setState((pre)=>({...pre,searchParam:""}))}}
              // style={{
              //   border: `1px solid ${
              //     state.filterParam === item.role_id ? "#35254d" : "d4cddf"
              //   }`,
              // }}
              >
                {item.role_name}
              </button>
            ))}
            <button
              className="second-blk-btn py-1"
              onClick={() => handleState("show", true)}
            >
              Add member +
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive team-main-sec">
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>

              {/* <th>Location</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {/* {!state.loading && !employee.length > 0 && (
              <tr>
                <td colSpan="8" style={{ textAlign: "center" }}>
                  Records not found
                </td>
              </tr>
            )} */}
            {employee &&
              employee?.map((item) => {
                return (
                  <tr className="table-hover cursor-pointer" key={item.id}>
                    <td className="d-flex">
                      <div
                        className="round-gradient me-2"
                        style={gradientCircle("rgba(195, 209, 4)")}
                      ></div>
                      <div className="us-name">
                        <span className="d-block">
                          {item.first_name} {item.last_name}
                        </span>
                        <span className="fs-12 text-gray mt-0">
                          {item.email}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span className="truncate">{item?.role?.role_name}</span>
                    </td>

                    {/* <td>location</td> */}
                    <td>
                      {/* <div className="button-hover">
                      <img
                        src={Edit}
                        alt="Edit"
                        onClick={() => handleEdit(item)}
                      />
                      
                      {/* <i
                        style={{ marginLeft: "2px" }}
                        className="fa fa-map-pin"
                        onClick={() => handlePin(item)}
                      ></i>
                    </div> */}
                      <div className="button-hover">
                      <div className="tooltip-toggle" aria-label="Security" tabIndex="0">
                        <i
                          className="fas fa-key"
                          onClick={() => handleEdit(item, "security")}
                        ></i>
                        </div>
                        <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                        <img
                          style={{ marginLeft: 5 }}
                          src={Edit}
                          alt="Edit"
                          onClick={() => handleEdit(item, "user")}
                        />
                        </div>
                        <div className="tooltip-toggle" aria-label="Delete" tabIndex="0">
                        <img
                          style={{ marginLeft: "4px" }}
                          src={Delete}
                          alt="Delete"
                          onClick={() => handleDelete(item)}
                        />
                        </div>
                        {/* <i className="fa-solid fa-trash"></i> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      {noDataMessage && <div className="text-center mt-2">No Data Available</div>}
      {<div ref={divLoader} style={{ height: "20px" }} />}
      <Modal
        className="edit-members"
        show={form.show}
        centered
        onHide={() => handleState("show", false)}
      >
        <Modal.Header className="add-member-header" closeButton>
          <Modal.Title className="mx-3">Add Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddEmployee
            setEmployee={setEmployee}
            handleState={handleState}
            setState={setState}
            fetchEmployees={fetchEmployees}
            state={state}
            setHasMore={setHasMore}
            employee={employee}

          />
        </Modal.Body>
      </Modal>
      <Modal
        className="edit-members"
        style={{ width: "100%" }}
        show={form.edit}
        centered
        onHide={() => handleState("edit", false)}
      >
        <Modal.Header className="edit-member-header" closeButton>
          <Modal.Title className="mx-3">Edit {state.update.first_name} {state.update.last_name}</Modal.Title>
        </Modal.Header>
        <div className="d-flex p-2 gap-2 mx-3">
          <button
            className={`${info == "user" ? "sky-btn-1" : "sky-btn-2"
              } `}
            onClick={() => handleInfo("user")}
          >
            User info
          </button>
          <button
            className={`${info == "security" ? "sky-btn-1" : "sky-btn-2"
              } `}
            onClick={() => handleInfo("security")}
          >
            Security
          </button>
        </div>
        <Modal.Body>
          {info == "user" ? <EditEmployee
            state={state}
            handleState={handleState}
            setState={setState}
            fetchEmployees={fetchEmployees}
            setEmployee={setEmployee}
            setHasMore={setHasMore}
            employee={employee}

          />
            :
            <Forgotpass
              setEmployee={setEmployee}
              state={state}
              handleState={handleState}
              setState={setState}
              fetchEmployees={fetchEmployees}
              setHasMore={setHasMore}
              employee={employee}
            />}
        </Modal.Body>
      </Modal>
      <Modal
        className="forgot-pin"
        style={{ width: "100%" }}
        show={form.pin}
        onHide={() => handleState("pin", false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Set 4 Digit Pin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PinEmployee
            state={state}
            handleState={handleState}
            setState={setState}
            fetchEmployees={fetchEmployees}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="forgot-pass"
        style={{ width: "100%" }}
        show={form.pass}
        centered
        onHide={() => handleState("pass", false)}
      >
        <Modal.Header className="edit-member-header" closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Forgotpass
            state={state}
            handleState={handleState}
            setState={setState}
            fetchEmployees={fetchEmployees}
          />
        </Modal.Body>
      </Modal>
      <Modal
        className="delete-employee"
        style={{ width: "100%" }}
        show={form.delete}
        size="sm"
        centered
        onHide={() => handleState("delete", false)}
      >
        <Modal.Header className="delete-member-header" closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <h5 className="text-center">Are you sure you want to delete employee?</h5>
            <div className="row pt-3">
              <div className="col-6">
                <button
                  className="cancel-btn "
                  onClick={() => {
                    handleState("delete", false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="col-6">
                <button className="add-btn" onClick={handleDeleteEmployee}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <div> */}

      {/* <Pagination
          totalPages={state.totalPage}
          onPageChange={handlePageChange}
          current={state.page}
        /> */}
      {/* </div> */}

      <Loader loading={state.loading} />
    </>
  );
};

export default EmployeeList;
