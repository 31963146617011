import React from "react";
import EmployeeList from "../Tabs/EmployeeList";

const Employee = () => {
  return (
    <div className="container-xl">
      <EmployeeList />
    </div>
  );
};

export default Employee;
