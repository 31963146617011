import React, { useEffect, useRef, useState } from "react";
import { useToken } from "../../../hooks/useToken";
import { useNavigate } from "react-router-dom";
import Loader from "../../../DefaultComponent/Loader";
import { getActiveLog } from "../../../api/Profile";
import Accordion from "react-bootstrap/Accordion";
import "../../Merchant/Tabs/MerchantOnboard.css";

const ActiveLog = () => {
  const navigate = useNavigate();
  const token = useToken();
  const [state, setState] = useState({
    loading: false,
    head: [],
    body: [],
    res: [],
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [show, setShow] = useState(true);
  const loader = useRef(null);
  const fetchlogs = async (pageNo) => {
    setState((prev) => ({ ...prev, loading: true }));
    const params = {
      perPage: 10,
      page: pageNo,
    };
    try {
      const response = await getActiveLog(token, params);
      setState((prev) => ({ ...prev, loading: false }));
      if (response?.status) {
        console.log("status============",response)

        const logData = response?.data?.logData;
        if (logData.length === 0) {
          setHasMore(false);
        } else {
          setData((prev) => [...prev, ...logData]);
          setPage((prev) => prev + 1);
        }
      } else {
        setShow(response?.status);
      }
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
      if (error.response.data.message.includes("Unauthorized")) {
        navigate("/login");
        localStorage.removeItem("user-data");
      }
    }
  };
  const toggleAccordion = (index, key) => {
    if (key === "head") {
      if (state.head.includes(index)) {
        setState((prev) => ({
          ...prev,
          head: state.head.filter((i) => i !== index),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          head: [...state.head, index],
        }));
      }
    } else if (key === "body") {
      if (state.body.includes(index)) {
        setState((prev) => ({
          ...prev,
          body: state.body.filter((i) => i !== index),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          body: [...state.body, index],
        }));
      }
    } else if (key === "res") {
      if (state.res.includes(index)) {
        setState((prev) => ({
          ...prev,
          res: state.res.filter((i) => i !== index),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          res: [...state.res, index],
        }));
      }
    }
  };
  useEffect(() => {
    fetchlogs(page);
  }, []);

  useEffect(() => {
    if (page > 1 && hasMore) {
      const options = {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      };

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchlogs(page);
        }
      }, options);

      if (loader.current) {
        observer.observe(loader.current);
      }

      return () => {
        if (loader.current) {
          observer.unobserve(loader.current);
        }
      };
    }
  }, [page]);
  const [accId, setAccId] = useState("");
  const [accClose, setAccClose] = useState(true);
  const handleAccordian = (id) => {
    id == accId ? setAccClose(false) : setAccClose(true);
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <p className="heading">Logs</p>
          </div>
          </div>
        {/* <BreadCrumbs items={breadcrumbItems} /> */}

        {/* 
        <div className="accordion" id="accordionPanelsStayOpenExample">
          {data.map((item, index) => (
            <div className="accordion-item" key={item._id}>
              <div className="accordion-header container" id={`panelsStayOpen-heading${index}`}>
                <button className="accordion-button row" style={{backgroundColor:'white'}} type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${index}`} aria-expanded="true" aria-controls={`panelsStayOpen-collapse${index}`}>
                 
                    <div className="col-md-1 bg-success">{item.method}</div>
                  <div className="col-md-9 ">{item.url}</div>
                    <div className="col-md-2">{item.response.status}</div>
                 
                </button>
              </div>
              <div id={`panelsStayOpen-collapse${index}`} className="accordion-collapse collapse " aria-labelledby={`panelsStayOpen-heading${index}`}>
                <div className="accordion-body">
 
                </div>
              </div>
            </div>
          ))}
        </div> */}


        <Accordion className="logs-box">
          {data?.map((item, index) => {
            const date = new Date(item.timestamp);
            const localYear = date.getFullYear();
            const localMonth = String(date.getMonth() + 1).padStart(2, '0');
            const localDay = String(date.getDate()).padStart(2, '0');
            const localHour = String(date.getHours()).padStart(2, '0');
            const localMinute = String(date.getMinutes()).padStart(2, '0');
            const localSecond = String(date.getSeconds()).padStart(2, '0');
            const formattedTimestamp = `${localMonth}-${localDay}-${localYear} • ${localHour}:${localMinute}:${localSecond}`;

            return (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>
                  <div className="container" style={{ width: "95%" }}>
                    <div className="row">
                      <div className="col-12">
                      <div className=" row g-md-0 g-2">
                        <div className=" col-sm-1 col-5">
                          <div
                            className="post-btn text-center"
                          >
                            {item.method}
                          </div>
                       </div>
                          <div className="logs-url col-sm-10 overflow-x-auto ps-2 text-nowrap">{item.url}</div>
                        <div className="purple col-sm-1 text-md-end">{item?.response?.status}</div>
                      </div>
                      </div>
                      <div className="col-12">
                      <div>

                        <div className="logs-date">{formattedTimestamp}</div>
                      </div>

                      </div>

                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-md-6">
                        <h5>Body</h5>
                        <pre>{JSON.stringify(item.body, null, 2)}</pre>
                      </div>
                      <div className="col-md-6">
                        <h5>Response</h5>
                        <pre>{JSON.stringify(item.response, null, 2)}</pre>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
          {state.loading && <Loader loading={state.loading} />}
          {/* {!show && (
            <div className="row alert alert-danger mt-3 ahead-box">
              <small className="alert-info text-center">
                No logs available
              </small>
            </div>
          )} */}
          <div ref={loader} style={{ height: "20px" }} />
        </Accordion>

        {/* {data?.map((item, index) => (
          <div className="row" key={item._id}>
            <>
              <div className="col-md-6">
                <div
                  className="tab-pane fade show active"
                  id="orders-all"
                  role="tabpanel"
                  aria-labelledby="orders-all-tab"
                >
                  <div className="app-card app-card-orders-table shadow-sm mb-4">
                    <div className="app-card-body" style={{ padding: 20 }}>
                      <h5 className="app-page-title">{item.method}</h5>
                      <div className="copy-api" id="style-3">
                        {item.url}
                      </div>
                      <div className="p-2">
                        <table
                          className="table app-table-hover mb-0 "
                          style={{ padding: 10 }}
                        >
                          <tbody>
                            <tr>
                              <th width="20%">Message</th>
                              <td>{item.message}</td>
                            </tr>
                            <tr>
                              <th>type</th>
                              <td>{item.type}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="accordion accordion-flush"
                  id={`accordionFlushExample-${index}`}
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={`headingOne-${index}`}>
                      <button
                        className={`accordion-button ${state.head.includes(index) ? "" : "collapsed"
                          }`}
                        type="button"
                        onClick={() => toggleAccordion(index, "head")}
                        aria-expanded={state.head.includes(index)}
                        aria-controls={`flush-collapseOne-${index}`}
                      >
                        Headers
                      </button>
                    </h2>
                    <div
                      id={`flush-collapseOne-${index}`}
                      className={`accordion-collapse collapse ${state.head.includes(index) ? "show" : ""
                        }`}
                      aria-labelledby={`flush-headingOne-${index}`}
                      data-bs-parent={`#accordionFlushExample-${index}`}
                    >
                      <div
                        className="accordion-body"
                        style={{ wordWrap: "break-word" }}
                      >
                        <pre>
                          {item?.headers &&
                            JSON.stringify(item.headers, undefined, 4)}
                        </pre>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={`headingTwo-${index}`}>
                      <button
                        className={`accordion-button ${state.body.includes(index) ? "" : "collapsed"
                          }`}
                        type="button"
                        onClick={() => toggleAccordion(index, "body")}
                        aria-expanded={state.body.includes(index)}
                        aria-controls={`flush-collapseTwo-${index}`}
                      >
                        Body
                      </button>
                    </h2>
                    <div
                      id={`flush-collapseTwo-${index}`}
                      className={`accordion-collapse collapse ${state.body.includes(index) ? "show" : ""
                        }`}
                      aria-labelledby={`flush-headingTwo-${index}`}
                      data-bs-parent={`#accordionFlushExample-${index}`}
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-md-6">
                            <h5>Body</h5>
                            <pre>{JSON.stringify(item.body, null, 2)}</pre>
                          </div>
                          <div className="col-md-6">
                            <h5>Response</h5>
                            <pre>{JSON.stringify(item.response, null, 2)}</pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id={`headingThree-${index}`}
                    >
                      <button
                        className={`accordion-button ${state.res.includes(index) ? "" : "collapsed"
                          }`}
                        type="button"
                        onClick={() => toggleAccordion(index, "res")}
                        aria-expanded={state.res.includes(index)}
                        aria-controls={`flush-collapseThree-${index}`}
                      >
                        Response
                      </button>
                    </h2>
                    <div
                      id={`flush-collapseThree-${index}`}
                      className={`accordion-collapse collapse ${state.res.includes(index) ? "show" : ""
                        }`}
                      aria-labelledby={`flush-headingThree-${index}`}
                      data-bs-parent={`#accordionFlushExample-${index}`}
                    >
                      <div className="accordion-body">
                        <pre>
                          {item?.response &&
                            JSON.stringify(item.response, undefined, 4)}
                        </pre>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        ))} */}
      </div>
    </>
  );
};
export default ActiveLog;
