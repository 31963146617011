import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'

import { apiSlice } from './apiSlice'
import tokenReducer from './tokenSlice'
import dateReducer from './dateSlice'
import { merchantApiSlice } from './merchantApiSlice'

export const store = configureStore({
    reducer: {
        
        [apiSlice.reducerPath]: apiSlice.reducer,
        [merchantApiSlice.reducerPath]: merchantApiSlice.reducer,
        token:tokenReducer,
        dates:dateReducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware, merchantApiSlice.middleware),
})


setupListeners(store.dispatch)