import axios from "axios";
import baseUrl from "../baseUrl";

export const getFeeMerchant = async (token, id) => {
  const response = await axios.get(
    `${baseUrl}/api/v1/admin/fee/get-by-merchantId/${id}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const createFeeMerchant = async (token, body, id) => {
  const response = await axios.post(
    `${baseUrl}/api/v1/admin/fee/create`,
    { ...body, merchantId: id },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const createWidgetOrder = async (formData) => {
  // const jsonData = JSON.stringify(formData);

  // return axios(`${baseUrl}create-widget-order`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: jsonData
  // })
  //   .then(response => {
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     return response.json();
  //   });
  const response = await axios.post(
    `${process.env.REACT_APP_WIDGET_BASE_URL}/create-widget-order`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};
