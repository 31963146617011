import React from 'react'

import { Route, Routes } from 'react-router-dom'
import Introduction from './main/Introduction'
      import hero from  "../asset/hero1.png"
import ImplementationGuide from './main/ImplementationGuide'
import PlatformPortalGuide from './main/PlatformPortalGuide.jsx'
import WebHooks from './main/WebHooks'
import LowCode from './main/LowCode'
import FullMerchantSubmit from './main/FullMerchantSubmit'
import GetMerchantByClient from './main/GetMerchantByClient'
import CreateLink from './main/CreateLink'
import PaymentRequest from './main/PaymentRequest'
import PaymentLink from './main/PaymentLink'
import GetTransactions from './main/GetTransactions'
import GetTransaction from './main/GetTransaction'
import ReturnRule from './main/ReturnRule'
import VoidSale from './main/VoidSale'
import CreateCustomer from './main/CreateCustomer'
import GetCustomer from './main/GetCustomer'
import GetCustomers from './main/GetCustomers'
import RequestCard from './main/RequestCard'
import ChargeCustomer from './main/ChargeCustomer'
import ChargeCustomerPreAuth from './main/ChargeCustomerPreAuth'
import IncrementPreAuth from './main/IncrementPreAuth'

import PreauthWithToken from './main/PreauthWithToken'
import CapturePreAuthorization from './main/CapturePreAuthorization'
import PaymentRequestCustomer from './main/PaymentRequestCustomer'
import AuthorizationRequestCustomer from './main/AuthorizationRequestCustomer'
import RequestCardCustomer from './main/RequestCardCustomer'
import OnboardingSession from './main/OnboardingSession'









const ContentRoutes = ({setActiveTab,handleActiveTab}) => {
    return (
        <>
            <Routes>
                <Route path="/documentation/introduction" element={<Introduction setActiveTab={setActiveTab}/>} />
                <Route path="/documentation/implementation-guide" element={<ImplementationGuide setActiveTab={setActiveTab}/>} />
                <Route path="/documentation/portal-guide" element={<PlatformPortalGuide setActiveTab={setActiveTab}/>} />
                <Route path="/documentation/webhooks" element={<WebHooks setActiveTab={setActiveTab}/>} />
                <Route path="/documentation/low-code" element={<LowCode setActiveTab={setActiveTab}/>} />
                <Route path="/api/full-merchant-submit" element={<FullMerchantSubmit handleActiveTab={handleActiveTab} />} />
                <Route path="/api/get-merchant-by-client" element={<GetMerchantByClient handleActiveTab={handleActiveTab} />} />
                <Route path="/api/create-link" element={<CreateLink handleActiveTab={handleActiveTab} />} />
                <Route path="/api/payment-request" element={<PaymentRequest handleActiveTab={handleActiveTab} />} />
                <Route path="/api/payment-link" element={<PaymentLink handleActiveTab={handleActiveTab} />} />
                <Route path="/api/get-transactions" element={<GetTransactions handleActiveTab={handleActiveTab} />} />
                <Route path="/api/get-transaction" element={<GetTransaction handleActiveTab={handleActiveTab} />} />
                 <Route path="/api/return-sale" element={<ReturnRule handleActiveTab={handleActiveTab} />} /> 
                 <Route path="/api/void-sale" element={<VoidSale handleActiveTab={handleActiveTab} />} /> 
                <Route path="/api/create-customer" element={<CreateCustomer handleActiveTab={handleActiveTab} />} />
                <Route path="/api/get-customer" element={<GetCustomer handleActiveTab={handleActiveTab} />} />
                <Route path="/api/get-customers" element={<GetCustomers handleActiveTab={handleActiveTab} />} />
                <Route path="/api/request-card" element={<RequestCard handleActiveTab={handleActiveTab} />} />
                <Route path="/api/charge-customer" element={<ChargeCustomer handleActiveTab={handleActiveTab} />} />
                <Route path="/api/charge-customer-preauth" element={<ChargeCustomerPreAuth handleActiveTab={handleActiveTab} />} />
                <Route path="/api/PreauthWithToken" element={<PreauthWithToken handleActiveTab={handleActiveTab} />} />
                <Route path="/api/IncrementPreAuth" element={<IncrementPreAuth handleActiveTab={handleActiveTab} />} />
                <Route path="/api/CapturePreAuthorization" element={<CapturePreAuthorization handleActiveTab={handleActiveTab} />} />

                <Route path="/api/PaymentRequestCustomer" element={<PaymentRequestCustomer handleActiveTab={handleActiveTab} />} />
                <Route path="/api/AuthorizationRequestCustomer" element={<AuthorizationRequestCustomer handleActiveTab={handleActiveTab} />} />
                <Route path="/api/RequestCardCustomer" element={<RequestCardCustomer handleActiveTab={handleActiveTab} />} />
                <Route path="/api/hosted-onboarding" element={<OnboardingSession handleActiveTab={handleActiveTab} />} />

                
                
            </Routes>
          
            
        </>
    )
}

export default ContentRoutes