export const usesuperLoginDetails = () => {
  const login = localStorage.getItem("superLoginDetails");
  const Details = JSON.parse(login);
  if (Details) {
    return Details;
  }
  return null;
};

export const useRefToken = () => {
  const localTkn = localStorage.getItem("refreshToken");
  const sessionTkn = sessionStorage.getItem("refreshToken");
  const refToken = JSON.parse(localTkn ? localTkn : sessionTkn);
  if (refToken) {
    return refToken;
  }
  return null;
};
