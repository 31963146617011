import React, { useState } from "react";
import ProfileView from "../Profile/Tabs/ProfileView.js";
import AccountTableView from "../Profile/Tabs/AccountTableView.js";
import Loader from "../../DefaultComponent/Loader.jsx";


const ApiKeys = () => {
  const [state, setState] = useState({
    accData: {},
    loading: false,
  });
  
  return (
    <div className="apikeys container-xl">
      <div className="d-flex justify-content-between align-items-start mb-3"></div>
      <div className="tab-content" id="orders-table-tab-content">
        <ProfileView title="Developer" setState={setState} state={state} />
        {/* <AccountTableView state={state} setState={setState} /> */}
      </div>
      <Loader loading={state.loading} />
    </div>
  );
};

export default ApiKeys;
