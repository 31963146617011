import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const BreadCrumbs = ({ items }) => {
  return (
    <div className="breadcrumb-container d-flex justify-content-start">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {items?.map((item, index) => (
            <li
              key={index}
              className={`breadcrumb-item ${item.active ? "active" : ""}`}
            >
              {item.active ? (
                <span className="breadcrumb-label">{item.label}</span>
              ) : (
                <Link
                  to={item.href}
                  className="breadcrumb-link"
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  {item.label === "Dashboard" && (
                    <FontAwesomeIcon
                      icon={faHome}
                      className="breadcrumb-separator"
                    />
                  )}
                  {item.label}
                </Link>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

BreadCrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      active: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default BreadCrumbs;
