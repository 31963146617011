import React from 'react'

const AddPaymentGateway = ({ state, setState }) => {
    const handleChangeGateWay = (e) => {
        const { value } = e.target;
        setState((prev) => ({ ...prev, gatewayMth: value }));
    }

    const handleClose = () => {
        setState((prev) => ({ ...prev, gateway: false }));
    }
    return (
        <>
            {
                state.gatewayMth === "datacap" ? (
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <label className="text-capitalize">Active Payment Gateway</label>
                                <select
                                    className="custom-date-picker"
                                    value={state.gatewayMth}
                                    onChange={handleChangeGateWay}
                                >
                                    <option value="fractal">Fractal</option>
                                    <option value="datacap">Datacap</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-capitalize">Merchant Application Id</label>
                                <input
                                    className="custom-date-picker"
                                    type="password"
                                    placeholder="Merchant Application Id"
                                    name="password"
                                //   onChange={handleChange}
                                //   required
                                />
                                {/* {error.password && (
                      <span className="error text-danger fs-12">{error.password}</span>
                  )} */}
                            </div>

                            <div className="col-6">
                                <label className="text-capitalize">Infinicept Application Id</label>
                                <input
                                    className="custom-date-picker"
                                    type="password"
                                    name="cnf_password"
                                    placeholder="Infinicept Application Id"
                                //   onChange={handleChange}
                                //   required
                                />
                                {/* {error.cnf_password && (
                      <span className="error text-danger fs-12">
                          {error.cnf_password}
                      </span>
                  )} */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-capitalize">Ecommerce MId</label>
                                <input
                                    className="custom-date-picker"
                                    type="password"
                                    placeholder="Ecommerce MId"
                                    name="password"
                                //   onChange={handleChange}
                                //   required
                                />
                                {/* {error.password && (
                      <span className="error text-danger fs-12">{error.password}</span>
                  )} */}
                            </div>

                            <div className="col-6">
                                <label className="text-capitalize">Ecommerce Token Key</label>
                                <input
                                    className="custom-date-picker"
                                    type="password"
                                    name="cnf_password"
                                    placeholder="Ecommerce Token Key"
                                //   onChange={handleChange}
                                //   required
                                />
                                {/* {error.cnf_password && (
                      <span className="error text-danger fs-12">
                          {error.cnf_password}
                      </span>
                  )} */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-capitalize">Merchant Api Key (Optional)</label>
                                <input
                                    className="custom-date-picker"
                                    type="password"
                                    placeholder="Merchant Api Key"
                                    name="password"
                                //   onChange={handleChange}
                                //   required
                                />
                                {/* {error.password && (
                      <span className="error text-danger fs-12">{error.password}</span>
                  )} */}
                            </div>

                            <div className="col-6">
                                <label className="text-capitalize">Dc Direct MID</label>
                                <input
                                    className="custom-date-picker"
                                    type="password"
                                    name="cnf_password"
                                    placeholder="Dc Direct MID"
                                //   onChange={handleChange}
                                //   required
                                />
                                {/* {error.cnf_password && (
                      <span className="error text-danger fs-12">
                          {error.cnf_password}
                      </span>
                  )} */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-capitalize">Datacap App MID</label>
                                <input
                                    className="custom-date-picker"
                                    type="password"
                                    placeholder="Datacap App MID"
                                    name="password"
                                //   onChange={handleChange}
                                //   required
                                />
                                {/* {error.password && (
                      <span className="error text-danger fs-12">{error.password}</span>
                  )} */}
                            </div>

                            <div className="col-6">
                                <label className="text-capitalize">Apple App MID</label>
                                <input
                                    className="custom-date-picker"
                                    type="password"
                                    name="cnf_password"
                                    placeholder="Apple App MID"
                                //   onChange={handleChange}
                                //   required
                                />
                                {/* {error.cnf_password && (
                      <span className="error text-danger fs-12">
                          {error.cnf_password}
                      </span>
                  )} */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-capitalize">Google App MID</label>
                                <input
                                    className="custom-date-picker"
                                    type="password"
                                    placeholder="Google App MID"
                                    name="password"
                                //   onChange={handleChange}
                                //   required
                                />
                                {/* {error.password && (
                      <span className="error text-danger fs-12">{error.password}</span>
                  )} */}
                            </div>
                        </div>

                        <input type="text" name="bot_key"
                            //   onChange={handleChange} 
                            style={{ display: "none !important" }} hidden />
                        <div className="row">
                            <div className="col-6">
                                <button className="idle-blk-btn w-100" onClick={handleClose}>Cancel</button>
                            </div>
                            <div className="col-6">
                                <button className="idle-green-btn w-100">
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <label className="text-capitalize">Active Payment Gateway</label>
                                    <select
                                        className="custom-date-picker"
                                        value={state.gatewayMth}
                                        onChange={handleChangeGateWay}
                                    >
                                        <option value="fractal">Fractal</option>
                                        <option value="datacap">Datacap</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="text-capitalize">Merchant Key</label>
                                    <input
                                        className="custom-date-picker"
                                        type="text"
                                        placeholder="Merchant Key"
                                        name="first_name"
                                    //   onChange={handleChange}
                                    //   required
                                    />
                                    {/* {error.first_name && (
                      <span className="error text-danger fs-12">
                          {error.first_name}
                      </span>
                  )} */}
                                </div>
                            </div>

                            <input type="text" name="bot_key"
                                //   onChange={handleChange} 
                                style={{ display: "none !important" }} hidden />
                            <div className="row">
                                <div className="col-6">
                                    <button className="idle-blk-btn w-100" onClick={handleClose}>Cancel</button>
                                </div>
                                <div className="col-6">
                                    <button className="idle-green-btn w-100">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                )
            }
        </>
    )
}

export default AddPaymentGateway
