import React, { useState } from "react";
import ProfileView from "../Tabs/ProfileView.js";
// import AccountTableView from "../Tabs/AccountTableView.js";
import Loader from "../../../DefaultComponent/Loader.jsx";
// import { Modal } from "bootstrap";
// import KeyConfirm from "../Froms/KeyConfirm.js";

const Profile = () => {
  const [state, setState] = useState({
    accData: {},
    loading: false,
    key: false,
    keys: false,
  });
  
  return (
    <div className="container-xl">
      <div className="d-flex justify-content-between align-items-start mb-3"></div>
      <div className="tab-content" id="orders-table-tab-content">
        <ProfileView title="Profile" state={state} setState={setState}/>
        {/* <AccountTableView state={state} setState={setState} /> */}
      </div>
      <Loader loading={state.loading} />
    </div>
  );
};

export default Profile;
