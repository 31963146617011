import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useLocation } from "react-router-dom";
import baseUrl from "../../../baseUrl";
import Loader from "../../../DefaultComponent/Loader";
import BreadCrumbs from "../../../components/Breadcrumbs";

const AccountManager = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const user = localStorage.getItem("token");
      const token = JSON.parse(user);
      const { data } = await axios.get(
        `${baseUrl}/api/v1/admin/admin-manager/get-all-manager`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (data?.data?.allAdminManager?.length) {
        setUsers(data?.data?.allAdminManager);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response.data.message);
      if (error.response.data.message.includes("Unauthorized")) {
        navigate("/login");
        localStorage.removeItem("user-data");
      }
    }
  };
  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(
        `${baseUrl}/api/v1/account-manager/edit/${id}`
      );
      toast.success("Manager Deleted");
      fetchUsers();
    } catch (error) {
      toast.error(error?.response.data.message);
    }
  };
  const handleEdit = (item) => {
    navigate("/edit-manager", { state: { item } });
  };
  const handleFogotPassword = (id) => {
    navigate("/forgot-password", { state: { id } });
  };

  // const tokenizer = async () => {
  //   let track2_data = "371242084842003=2505 1234";
  //   const publicKeyPem =
  //     "-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAyI4xNEYfxaAMaOCRjPfp\nE/PnTHYwSeMY0slcXq9itEaxD2+KUaxFfpUdpows2pr/JwG9z4JmT9eYDvF2nMGv\niEESCNqibzkfROL2eDbvjF2aJkZuINmgmXuNE/7vA0tEIwOPPgvgnayppiwyimat\nLSK5Lq1bMgRxmBio7DGYe6U6GtfAZFiAOXmF9Oi4ET58Vp5B3UuFwR91JyrHJKJ2\nI2mharVhqWAj++G8+/DZuLPLAwHaK7/DgdwF8gce1DutSOnqTwSocurs8T8n/YC2\nCTuzVJHtHL4K2OIQf9zAGB3/uzLxprO4XDmCYAvnfmvefX7h0hsdxfI6JHSl1jyD\nQJfSc526VgIH8cVj1h/6d/bPEpMsIeVgSJOEAGvyPpivB2gxMpHxmfaU/T0sn7ox\nfTLL2m7yxSeTPsrM52JodOQmVmKui4n+OaKJOsp+pIEPAxBD9kxJbejulM1guGvZ\nMXzX8YT5eMbCQBcEfV51Viz9QMc8T6uKmmwRH+ayvX0PIEDtwEvJjGEG/PMR+9wS\nnEvOyZYS+nSiQ+5lbWxRyyRGCAGZmT005d1lsXeqEIUZXNUcqZv7VnoJftX/zKGZ\nJYK/LRlIRFwvlGBRgboS6K9Tc8eAOoy60yIUS/6qBuzTU7SSOhJXMpX4TpmtLqoW\nvUBLAYQlfU1paw4YcJ7hyhkCAwEAAQ==\n-----END PUBLIC KEY-----\n";

  //   const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

  //   const encrypted = publicKey.encrypt(track2_data, "RSA-OAEP", {
  //     md: forge.md.sha1.create(),
  //     mgf1: {
  //       md: forge.md.sha1.create(),
  //     },
  //   });

  //   const encryptedBase64 = forge.util.encode64(encrypted);
  //   

  //   try {
  //     const response = await axios.post(
  //       "https://m1ao5pku8b.execute-api.us-east-2.amazonaws.com/prod/tokenizer/tokenize",
  //       {
  //         enc_track2_data: encryptedBase64,
  //         session_key:
  //           "sk_36j3rYlwNy2WQy7LW5uWFQdCE6jkAyfoecyvrnf1V9tWnJwgAVlG-Jt73O3ANB98xg-jt3pwZ7JJSMm-fws=",
  //         algorithm: "RSAES_OAEP_SHA_1",
  //       },
  //       {
  //         headers: {
  //           "x-app-session-key":
  //             "sk_36j3rYlwNy2WQy7LW5uWFQdCE6jkAyfoecyvrnf1V9tWnJwgAVlG-Jt73O3ANB98xg-jt3pwZ7JJSMm-fws=",
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     console.log(response.data);
  //     alert("API response", response.data);
  //     // return response.data.data;
  //     //   return resp
  //     //     .status(200)
  //     //     .json({ result: true, message: "Tokenizer", data: response.data });
  //   } catch (error) {
  //     console.log("Error:", error);
  //     alert("API Error", error);
  //     // throw error;
  //     //   return resp
  //     //     .status(200)
  //     //     .json({ result: true, message: "Tokenizer", data: error });
  //   }
  // };
  const breadcrumbItems = [
    { label: "Dashboard", href: "/", active: false },
    { label: "Account-Manager", href: "/admin-manager", active: true },
  ];
  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <>
      <div
        style={{
          position: "absolute",
          left: "55%",
          top: "50%",
          transform: "inherit !important",
        }}
      >
        <Loader loading={loading} />
      </div>
      <div className="container-xl">
        <BreadCrumbs items={breadcrumbItems} />
        <div className="d-flex justify-content-between align-items-start mb-3">
          <div className="">
            <h1 className="app-page-title">Account Manager</h1>
          </div>
          <button
            className="btn app-btn-primary"
            onClick={() => {
              navigate("/add-manager");
            }}
          >
            Add Manager
          </button>
        </div>

        <div className="tab-content" id="orders-table-tab-content">
          <div
            className="tab-pane fade show active"
            id="orders-all"
            role="tabpanel"
            aria-labelledby="orders-all-tab"
          >
            <div className="app-card app-card-orders-table shadow-sm mb-5">
              <div className="app-card-body">
                <div className="table-responsive">
                  <table className="table app-table-hover mb-0 text-left">
                    <thead>
                      <tr>
                        <th className="cell">Name</th>
                        <th className="cell">Email</th>
                        <th className="cell">Created Date</th>
                        <th className="cell">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading && !users?.length > 0 && (
                        <tr>
                          <td colspan="8" style={{ textAlign: "center" }}>
                            Data not found
                          </td>
                        </tr>
                      )}
                      {users &&
                        users?.map((item, ind) => {
                          return (
                            <tr key={ind}>
                              <td className="cell">
                                {item.first_name} {item.last_name}
                              </td>
                              <td className="cell">
                                <span className="truncate">{item.email}</span>
                              </td>
                              <td className="cell">
                                {item.join_dates ?? "NA"}{" "}
                              </td>

                              <div className="d-flex gap-2">
                                <i
                                  className="fa-solid fa-key text-info "
                                  onClick={() => handleFogotPassword(item.id)}
                                ></i>
                                <i
                                  className="fa-solid fa-pen-to-square text-info "
                                  onClick={() => handleEdit(item)}
                                ></i>
                                <i
                                  className="fa-solid fa-trash text-danger"
                                  onClick={() => handleDelete(item.id)}
                                ></i>
                              </div>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* <nav className="app-pagination">
            <ul className="pagination justify-content-center">
              <li className="page-item disabled">
                <a className="page-link" href="#" tabIndex={-1} aria-disabled="true">
                  Previous
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountManager;
