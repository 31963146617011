import React, { useCallback, useEffect, useState } from 'react'
import Loader from '../../../DefaultComponents/Loader';
import axios from 'axios';
import baseUrl from '../../../../baseUrl';
import { Modal } from "react-bootstrap";
// import { useRefToken } from '../../../hooks/useRefToken';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PaginationPg from "../../../DefaultComponents/PaginationPg";
import { debounce } from 'lodash';
import { useRefToken } from '../../../hooks/useToken';
import View from "../../../../assets/images/View.svg";
import Fiserv from "../../../../assets/images/fiserv.svg";
import Edit from "../../../../assets/images/Edit.svg";
import Transaction from "../../../../assets/images/Transaction.svg";
import Download from "../../../../assets/images/Download.svg";
import DownloadSuperMerchantCSV from '../Form/DownloadSuperMerchantCSV';

const ClientMerchant = () => {
    const navigate = useNavigate();
    const refToken = useRefToken();
    const { clientId } = useParams();
    const [clientMerchantList, setClientMerchantList] = useState([]);
    const [state, setState] = useState({
        page: 1,
        // perPage: 15,
        searchValue: "",
        // deleteId: '',
        // delete: false,
        // add: false,
        // edit: false,
        download: false,
        loading: false,
        row: {},
    });
    const fetchClientMerchant = async (
        newPage, searchValue
    ) => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const params = {
                page: newPage,
            };
            if (searchValue) {
                params.search = searchValue;
            }
            const { data } = await axios.get(
                `${baseUrl}/api/v1/super-admin/merchant/merchant-list/${clientId}`,
                {
                    headers: {
                        Authorization: refToken,
                    },
                    params,
                }
            );
            setState((prev) => ({ ...prev, loading: false }));
            if (data?.status) {
                setClientMerchantList(data?.data?.allVerifiedMerchant);
                setState((prev) => ({ ...prev, totalPage: data?.data?.totalPage }));
            }
        } catch (error) {
            setState((prev) => ({ ...prev, loading: false }));
            if (error.response.data.message.includes("Unauthorized")) {
                navigate("/super-admin-login");
                localStorage.clear();
            }
            toast.error(error?.response.data.message);
            console.error(error);
        }
    };

    const handlePageChange = (pageNo) => {
        setState((prev) => ({
            ...prev,
            page: pageNo,
        }));
        fetchClientMerchant(pageNo, state.searchValue);
        setClientMerchantList([]);
    }

    const handleSearchChange = (e) => {
        const searchData = e.target.value;
        setState((prev) => ({
            ...prev,
            searchValue: searchData,
        }));
        handleSearch(searchData);
    };

    const handleSearch = useCallback(
        debounce((searchQuery) => {
            setClientMerchantList([]);
            fetchClientMerchant(state.page, searchQuery);
        }, 700),
        []
    );

    const handleTransactions = (id) => {
        navigate(`/admin/clients/${clientId}/${id}/transactions`);
    }

    const verifiedMerchant = async (item) => {
        if (item.isApproved) {
            toast.error("Merchant is already verified.");
        } else {
            setState((prev) => ({ ...prev, loading: true }));
            try {
                const response = await axios.get(
                    `${baseUrl}/api/v1/super-admin/merchant/approve-merchant/${item?.encodeId}`,
                    {
                        headers: {
                            Authorization: refToken,
                        },
                    }
                );
                setState((prev) => ({ ...prev, loading: false }));
                if (response.status === 200) {
                    toast.success("Merchant has been verified successfully.");
                    fetchClientMerchant(state.page, state.searchValue);
                } else {
                    toast.error("Merchant is not valid. Please try again.");
                }
            } catch (error) {
                setState((prev) => ({ ...prev, loading: false }));
                if (error.response && error.response.data) {
                    if (error.response.data.errors) {
                        const errorMessages = Object.values(error.response.data.errors).join(
                            ", "
                        );
                        toast.error(errorMessages);
                    } else if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error("An unexpected error occurred. Please try again later.");
                    }
                } else {
                    toast.error("An unexpected error occurred. Please try again later.");
                }
            }
        }
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");
        return `${month}.${day}.${year}`;
    };

    const handleState = (item, value) => {
        setState((prev) => ({
            ...prev,
            [item]: value,
        }));
    };

    const handleMerchantDetail = (id) => {
        navigate(`/admin/clients/${clientId}/${id}`);
    };

    const handleDownload = (row) => {
        handleState("download", true);
        handleState("row", row);
    }

    const handleEdit = (id) => {
        navigate(`/admin/clients/${clientId}/${id}/edit`);
    }

    const handleFiserv = async (event, id) => {
        event.stopPropagation();
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const response = await axios.get(
                `${baseUrl}/api/v1/super-admin/merchant/create-pdf/${id}`,
                {
                    headers: {
                        Authorization: refToken,
                    },
                }
            );
            // console.log(response, "log f");

            if (response?.data) {
                toast.success(response?.data?.message)
                setState((prev) => ({ ...prev, loading: false }));
            }

        }
        catch (error) {
            toast.error(error?.response?.data?.message)
            console.log("Error:", error);
            setState((prev) => ({ ...prev, loading: false }));
        }
    }

    let str = '';
    const handleRow = (item, value, e) => {
        e.stopPropagation();
        str = value;
        console.log(str, "str");
        if (str == "download") {
            handleDownload(item)
        } else if (str == "view") {
            handleMerchantDetail(item.id)
        } else if (str == "edit") {
            handleEdit(item.id)
        } else if (str == "merchant") {
            verifiedMerchant(item)
        } else if (str == "transaction") {
            handleTransactions(item.guid)
        }
    }

    useEffect(() => {
        fetchClientMerchant();
    }, []);



    return (
        <div className="container-xl">
            <div className="row">
                <div className="col-12 col-md-4">
                    <p className="heading">Client Merchants</p>
                </div>
                <div className="col-12  col-md-8">
                    <div className="search-container d-flex flex-wrap justify-content-md-end justify-content-between">
                        <div className="search-box">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_7372_11113)">
                                    <path d="M13.5526 12.7518L10.2206 9.28631C11.0773 8.26788 11.5467 6.98648 11.5467 5.65249C11.5467 2.53576 9.01093 0 5.89419 0C2.77746 0 0.241699 2.53576 0.241699 5.65249C0.241699 8.76923 2.77746 11.305 5.89419 11.305C7.06426 11.305 8.17928 10.9521 9.13258 10.2821L12.4899 13.7739C12.6302 13.9196 12.819 14 13.0213 14C13.2127 14 13.3943 13.927 13.5322 13.7943C13.8251 13.5124 13.8345 13.045 13.5526 12.7518ZM5.89419 1.47456C8.19795 1.47456 10.0721 3.34873 10.0721 5.65249C10.0721 7.95625 8.19795 9.83043 5.89419 9.83043C3.59043 9.83043 1.71626 7.95625 1.71626 5.65249C1.71626 3.34873 3.59043 1.47456 5.89419 1.47456Z" fill="#35254D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7372_11113">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <input
                                className="searchbox"
                                type="text"
                                placeholder="Search"
                                name="search"
                                onChange={handleSearchChange}
                                value={state.searchValue}
                            />
                        </div>

                        {/* <div className="cursor-pointer">
                            <DatePickers startDate={startDate} endDate={endDate} filterDays={filterDays} handleDateChange={handleDateChange} handleFilterChange={handleFilterChange} />
                        </div>
                        <button
                            className="second-blk-btn"
                            onClick={() => {
                                navigate(`/merchant-onboarding`);
                            }}
                        >
                            Add Merchant  <i className="fa-solid fa-plus"></i>
                        </button> */}
                    </div>
                </div>
            </div>
            <div className="app-card-body overflow-auto">
                <div className="sm-table-responsive md-none">
                    <table className="table">
                        <thead>
                            <tr>
                                <th align="right">Join Date</th>
                                <th align="right">Merchant Name</th>
                                <th align="right">Email</th>
                                <th align="right">Phone</th>
                                <th align="right">Status</th>
                                <th align="right">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientMerchantList && clientMerchantList?.length > 0 ? (
                                clientMerchantList?.map((item) => (
                                    <tr key={item.guid} className="table-hover cursor-pointer"
                                        onClick={(e) => handleRow(item, "view", e)}>

                                        <td>{formatDate(item.join_date)}</td>
                                        <td>{`${item.first_name} ${item.last_name}`}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phonenumber}</td>
                                        <td>
                                            {item.isApproved ? (
                                                <div className="active-btn">Active</div>
                                            ) : (
                                                <div className="active-btn">Inactive</div>
                                            )}
                                        </td>
                                        <td align='center' style={{ width: '18%' }}>
                                            <div className='button-hover'>
                                                {/* <svg
                                                    onClick={() => handleTransactions(item.guid)}
                                                    width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_7114_891)">
                                                        <path d="M16.5 5.99853C16.5004 6.06434 16.4869 6.12958 16.4604 6.19051C16.4339 6.25144 16.3948 6.30686 16.3453 6.35359L13.1453 9.35412C13.0955 9.40047 13.0364 9.43714 12.9714 9.46203C12.9064 9.48692 12.8369 9.49953 12.7667 9.49915C12.6973 9.49995 12.6285 9.48806 12.564 9.46414C12.4658 9.4263 12.3818 9.36182 12.3229 9.27896C12.264 9.19611 12.2328 9.09864 12.2333 8.99906V7.49879H7.43333C7.29189 7.49879 7.15623 7.44611 7.05621 7.35232C6.95619 7.25854 6.9 7.13134 6.9 6.9987V4.99835C6.9 4.86572 6.95619 4.73852 7.05621 4.64473C7.15623 4.55095 7.29189 4.49826 7.43333 4.49826H12.2333V2.99799C12.2338 2.89909 12.2655 2.80253 12.3244 2.72052C12.3834 2.63852 12.467 2.57475 12.5646 2.53728C12.6622 2.49981 12.7695 2.49032 12.8729 2.51002C12.9762 2.52971 13.071 2.57771 13.1453 2.64793L16.3453 5.64846C16.4439 5.74161 16.4994 5.86733 16.5 5.99853ZM9.56666 8.49897H4.76667V6.9987C4.76615 6.89997 4.73446 6.8036 4.67562 6.72172C4.61678 6.63985 4.5334 6.57614 4.436 6.53862C4.33888 6.50033 4.23183 6.48986 4.12837 6.50855C4.02491 6.52724 3.92967 6.57425 3.85467 6.64364L0.654675 9.64418C0.605245 9.6909 0.566139 9.74632 0.539598 9.80725C0.513056 9.86818 0.499603 9.93342 0.500009 9.99924C0.499603 10.0651 0.513056 10.1303 0.539598 10.1912C0.566139 10.2522 0.605245 10.3076 0.654675 10.3543L3.85467 13.3548C3.90451 13.4012 3.96361 13.4379 4.02859 13.4627C4.09357 13.4876 4.16315 13.5002 4.23334 13.4999C4.3033 13.5016 4.37271 13.4879 4.436 13.4599C4.5334 13.4223 4.61678 13.3586 4.67562 13.2768C4.73446 13.1949 4.76615 13.0985 4.76667 12.9998V11.4995H9.56666C9.70811 11.4995 9.84377 11.4468 9.94379 11.353C10.0438 11.2592 10.1 11.132 10.1 10.9994V8.99906C10.1 8.86643 10.0438 8.73923 9.94379 8.64544C9.84377 8.55166 9.70811 8.49897 9.56666 8.49897Z" fill="#35254D" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_7114_891">
                                                            <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg> */}

                                                <div className="tooltip-toggle" aria-label="View" tabIndex="0">
                                                    <img
                                                        src={View}
                                                        alt="view"
                                                        onClick={(e) => handleRow(item, "view", e)}
                                                    />
                                                </div>
                                                <div className="tooltip-toggle" aria-label="Edit" tabIndex="0">
                                                    <img
                                                        src={Edit}
                                                        alt="Edit"
                                                        style={{ marginLeft: "6px" }}
                                                        onClick={(e) => handleRow(item, "edit", e)}
                                                    />
                                                </div>
                                                <div className="tooltip-toggle" aria-label="Transactions" tabIndex="0">
                                                    <img
                                                        src={Transaction}
                                                        alt="Transaction"
                                                        style={{ marginLeft: "6px" }}
                                                        onClick={(e) => handleRow(item, "transaction", e)}
                                                    />
                                                </div>
                                                <div className="tooltip-toggle" aria-label="Upload" tabIndex="0">
                                                    <img
                                                        src={Download}
                                                        alt="Download"
                                                        style={{ marginLeft: "6px" }}
                                                        onClick={(e) => handleRow(item, "download", e)}
                                                    />
                                                </div>
                                                <div className="tooltip-toggle" aria-label="Verify" tabIndex="0">
                                                    <i
                                                        className="fas fa-user-check"
                                                        style={{ marginLeft: "6px" }}
                                                        onClick={(e) => handleRow(item, "merchant", e)}
                                                    ></i>
                                                </div>
                                                <div className="tooltip-toggle" aria-label="Fiserv" tabIndex="0">
                                                    <img
                                                        src={Fiserv}
                                                        alt="Fiserv"
                                                        onClick={(e) => handleFiserv(e, item.id)}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        {/* <td align="right" style={{ width: "10%" }}>
                                            <div className="button-hover">
                                                <img
                                                    src={View}
                                                    alt="view"
                                                    onClick={() => handleMerchantDetail(item.id)}
                                                />
                                                {role_id == '1' && <img
                                                    src={Edit}
                                                    alt="Edit"
                                                    style={{ marginLeft: "6px" }}
                                                    onClick={() => handleEdit(item)}
                                                />}
                                                <img
                                                    src={Transaction}
                                                    alt="Transaction"
                                                    style={{ marginLeft: "6px" }}
                                                    onClick={() => handleTransaction(item.id)}
                                                />
                                                <img
                                                    src={Download}
                                                    alt="Download"
                                                    style={{ marginLeft: "6px" }}
                                                    onClick={() => handleDownload(item)}
                                                />
                                                {role_id == '1' && <i
                                                    className="fas fa-user-check"
                                                    style={{ marginLeft: "6px" }}
                                                    onClick={() => verifiedMerchant(item?.encodeId)}
                                                ></i>}
                                            </div>
                                        </td> */}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={10} style={{ textAlign: "center" }}>
                                        No client merchants available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <PaginationPg
                totalPages={state.totalPage}
                onPageChange={handlePageChange}
                current={state.page}
            />
            {state.loading && <Loader loading={state.loading} />}
            <Modal
                className="upload-documents"
                show={state.download}
                onHide={() => handleState("download", false)}
            >
                <Modal.Header className="upload-doc" closeButton>
                    <Modal.Title>Upload Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DownloadSuperMerchantCSV handleState={handleState} state={state} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ClientMerchant
