import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
      <div className="section404">
          <div className="in404">

          <h1 className="error404">404</h1>
          <div className="page404">Ooops!!! The page you are looking for is not found</div>
          <Link className="back-home" to="/">
              Back to home
          </Link>
        </div>
      </div>
  )
}

export default PageNotFound