import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import baseUrl from "../../baseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../DefaultComponents/Loader";
import OtpInput from "react-otp-input";
import logo from "../../assets/images/Logo_Fractal_Icon_Black.svg";
import ReCAPTCHA from 'react-google-recaptcha'

const OTPVerification = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [bot_key, setBot_key] = useState('');
    const [captchaError, setCaptchaError] = useState(false);
    const [captchaValue, setCaptchaValue] = useState('');
    const [seconds, setSeconds] = useState(60);
    const [resend, setResend] = useState({ btn: false, timer: true });
    const timer = localStorage.getItem("timer");
    const user = localStorage.getItem("local-data");
    const [otpError, setOtpError] = useState(false);
    //    console.log(otp);

    const handleOtpChange = (otpValue) => {
        console.log(otpValue)
        if (/^[0-9]*$/.test(otpValue)) {
            setOtp(otpValue);
            setOtpError(false);
        } else {
            setOtp(otpValue);
            setOtpError(true);
        }
    };
    useEffect(() => {
        if (timer > 0 && timer) {
            setSeconds(timer);

        }
        else if (timer == 0) {
            setSeconds(0);
        }
    }, [timer]);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds > 0) {
                    localStorage.setItem("timer", prevSeconds - 1);
                    return prevSeconds - 1;
                } else {
                    clearInterval(interval);
                    setResend((pre) => ({ ...pre, timer: false }))
                    return 0;
                }
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);


    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}s`;
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        if (!captchaValue) return setCaptchaError(true);
        if (otp.length != 6) {
            toast.error("Invalid Code");
            setOtpError(true);
            return;
        }
        let local = JSON.parse(user);

        try {
            setOtpError(false);
            setLoading(true);
            let resp = await axios.post(`${baseUrl}/api/v1/super-admin/auth/otp-verify`, {
                otp: otp,
                token: local?.token,
                bot_key
            },
            );
            if (resp?.status == 200 && resp?.data?.result == true) {
                let refreshToken = JSON.stringify(resp?.data?.data?.token)
                localStorage.setItem("refreshToken", refreshToken);
                localStorage.removeItem("timer");
                localStorage.removeItem("local-data");
                toast.success("Login Successful");
                navigate("/admin/dashboard-admin", { replace: true });
            }
            else if (resp?.status == 200 && resp?.data?.result == false) {
                setOtpError(true);
                toast.error(resp?.data?.message);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error(error?.response?.data?.message);
        }

    }

    // handle resend otp
    const handleResendOtp = async () => {
        let local = JSON.parse(user);
        if (timer > 0) return;
        if (!captchaValue) return setCaptchaError(true);
        try {
            setLoading(true);
            let resp = await axios.post(`${baseUrl}/api/v1/super-admin/auth/resend-otp`, {
                token: local?.token
            },
            );
            if (resp.status == 200) {
                toast.success("Two-factor authentication code sent");
                let data = JSON.stringify(resp?.data);
                localStorage.setItem("local-data", data)
                if (seconds == 0) {
                    setSeconds(60);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message)
            console.log("error", error);
        }
    }

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        setCaptchaError(false);
    };

    return (
        <div className="app app-login p-0">
            <div className="row g-0 app-auth-wrapper">
                <div className="col-12 col-md-6 col-lg-6 auth-main-col log-left otp-mn-sec">
                    <div className="d-flex flex-column align-content-end">
                        <div className="app-auth-body mx-auto">
                            <div className="app-auth-branding mb-5 app-logo">
                                <img className="logo-icon me-2" src={logo} alt="logo" />
                            </div>
                            <div className='d-flex align-items-center' style={{ marginTop: "0px" }}>
                                <div className="otp-container">
                                    <form onSubmit={handleVerify}>
                                        <div className="row">
                                            <h3>Two-Factor Authentication</h3>
                                            <p className='fs-14'>Please enter the <strong>Verification Code</strong> that was delivered to your phone number. </p>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center my-3 otp-inputs">
                                            <OtpInput
                                                value={otp}
                                                onChange={handleOtpChange}
                                                numInputs={6}
                                                containerStyle={`${otpError ? "otp-container-error" : "otp-container-idle"}`}
                                                renderSeparator={<span className='mx-1'></span>}
                                                renderInput={(props) => <input {...props} className='otp-input' placeholder='•' />}
                                                shouldAutoFocus
                                            />

                                        </div>
                                        <input type='text' id='bot_key' name='bot_key' value={bot_key} onChange={(e) => { setBot_key(e.target.value) }} style={{ display: 'none' }} />
                                        <div className="row mb-2">
                                            <div className="col didnt-send">
                                                <p>Didn't get the code?
                                                    <strong
                                                        className="text-dark-purp cursor-pointer"
                                                        onClick={handleResendOtp}
                                                    >
                                                        Resend code
                                                    </strong>
                                                    in
                                                    <span className={`${seconds > 10 ? "text-success" : "text-danger"} ps-1`}>
                                                        {formatTime(seconds)}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <ReCAPTCHA
                                                    sitekey="6Lc5yLcpAAAAAGmxkAx0XZEdkPNJ-NzQsA4XHuPs"
                                                    onChange={handleCaptchaChange}
                                                />
                                            </div>
                                            {captchaError && <small className="text-danger">Captcha required</small>}
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <button className='verifi-btn ' type='submit'>Verify</button>

                                                <button className='cncl-btn' type='button' onClick={() => navigate("/login", { replace: true })}>Cancel</button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <Loader loading={loading} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 auth-background-col">
                    <div className="auth-background-holder"></div>
                    <div className="auth-background-overlay p-3 p-lg-5">
                        <div className="d-flex flex-column align-content-end">
                            <div className="h-100" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default OTPVerification