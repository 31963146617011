import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useToken } from "../../../hooks/useToken";
import { toast } from "react-toastify";
import { createFeeMerchant } from "../../../api/Merchant";

const AddFeeMerchant = (props) => {
  const { id, handleState, setState, fetchFeeMerchants, state } = props;
  const token = useToken();
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const Validation = () => {
    let error = {};
    if (!requiredValue(data.cp_ratepercent)) {
      error.cp_ratepercent = "CP field is required";
    } else if (checkNumberValue(data.cp_ratepercent)) {
      error.cp_ratepercent = "CP must be number";
    } else if (!requiredValue(data.cp_cent_ratepercent)) {
      error.cp_cent_ratepercent = "CP field is required";
    } else if (checkNumberValue(data.cp_cent_ratepercent)) {
      error.cp_cent_ratepercent = "CP must be number";
    } else if (!requiredValue(data.cnp_ratepercent)) {
      error.cnp_ratepercent = "CNP field is required";
    } else if (checkNumberValue(data.cnp_ratepercent)) {
      error.cnp_ratepercent = "CNP must be number";
    } else if (!requiredValue(data.cnpcents)) {
      error.cnpcents = "CNP field is required";
    } else if (checkNumberValue(data.cnpcents)) {
      error.cnpcents = "CNP must be number";
    }
    if (Object.keys(error).length < 1) {
      return false;
    }
    return error;
  };
  const handleChange = (e) => {
    let newData = { ...data };
    const { name, value } = e.target;
    newData[name] = value;
    setData(newData);
  };
  const handleData = async (e) => {
    e.preventDefault();
    setError({});
    let errors = Validation();
    if (!errors) {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
      try {
        const response = await createFeeMerchant(token, data, id);
        fetchFeeMerchants();
        toast.success(response.message);
        handleState("add", false);
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      } catch (error) {
        console.log("error", error);
        toast.error(error?.response?.data?.message);
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    } else {
      setError(errors);
    }
  };

  const requiredValue = (value) => {
    if (isEmpty(value)) {
      return false;
    }
    return true;
  };

  const checkNumberValue = (value) => {
    if (!isNaN(value) && !isNaN(parseFloat(value))) {
      return false;
    }
    return true;
  };
  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-md-6">
            <label className="labelStyle" style={{ display: "block" }}>
              CP*
            </label>
            <div className="input-group">
              <input
                className="custom-date-picker"
                type="text"
                placeholder="CP"
                name="cp_ratepercent"
                onChange={handleChange}
                required
              />
              <span className="input-group-text">%</span>
            </div>
            {error.cp_ratepercent && (
              <span className="error" style={{ color: "red" }}>
                {error.cp_ratepercent}
              </span>
            )}
          </div>

          <div className="col-md-6">
            <label className="labelStyle" style={{ display: "block" }}>
              CP*
            </label>
            <div className="input-group">
              <span className="input-group-text">$</span>
              <input
                className="custom-date-picker"
                type="text"
                name="cp_cent_ratepercent"
                placeholder="CP"
                onChange={handleChange}
                required
              />
            </div>
            {error.cp_cent_ratepercent && (
              <span className="error" style={{ color: "red" }}>
                {error.cp_cent_ratepercent}
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="labelStyle" style={{ display: "block" }}>
              CNP
            </label>
            <div className="input-group">
              <input
                className="custom-date-picker"
                type="text"
                name="cnp_ratepercent"
                placeholder="CNP"
                onChange={handleChange}
              />
              <span className="input-group-text">%</span>
            </div>
            {error.cnp_ratepercent && (
              <span className="error" style={{ color: "red" }}>
                {error.cnp_ratepercent}
              </span>
            )}
          </div>
          <div className="col-md-6">
            <label className="labelStyle" style={{ display: "block" }}>
              CNP
            </label>
            <div className="input-group">
              <span className="input-group-text">$</span>
              <input
                className="custom-date-picker"
                type="text"
                name="cnpcents"
                placeholder="CNP"
                onChange={handleChange}
              />
            </div>
            {error.cnpcents && (
              <span className="error" style={{ color: "red" }}>
                {error.cnpcents}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="col d-flex justify-content-center">
        <button
          className="btn btn-primary"
          disabled={state.loading}
          onClick={handleData}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default AddFeeMerchant;
