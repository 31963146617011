import React from 'react'
import { Accordion } from 'react-bootstrap'

const AccordianData = ({header,body}) => {
    const head = header;
  return (
    <>
          <Accordion>
              <Accordion.Item eventKey={1} className='my-3'>
                  <Accordion.Header>
                      <div className="container">
                          <div className="row">
                              <div

                              >
                                  Header
                              </div>
                          </div>
                      </div>
                  </Accordion.Header>
                  <Accordion.Body
                      style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                      <div className="accordion-body">
                          <div className="row">
                              <pre>{JSON.stringify(header, null, 2)}</pre>
                          </div>
                      </div>
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={2}>
                  <Accordion.Header>
                      <div className="container">
                          <div className="row">
                              <div

                              >
                                  Body
                              </div>
                          </div>
                      </div>
                  </Accordion.Header>
                  <Accordion.Body
                      style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                      <div className="accordion-body">
                          <div className="row">
                              <pre>{JSON.stringify(body, null, 2)}</pre>
                          </div>
                      </div>
                  </Accordion.Body>
              </Accordion.Item>


          </Accordion>
    </>
  )
}

export default AccordianData