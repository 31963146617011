import { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles for the date picker
import "../../../DefaultComponent/Loader.css";
import { useNavigate } from "react-router-dom";
import "./MerchantOnboard.css";
import "../../../DefaultComponent/Loader.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from "react-icons/md";

import { useParams } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import baseUrl from "../../../baseUrl";
import DragNDrop from "../../../components/DragNDrop";
import Breadcrumbs from "../../../components/Breadcrumbs";
import BreadCrumbs from "../../../components/Breadcrumbs";
import { useToken } from "../../../hooks/useToken";

const apiUrl = baseUrl;
const sitekey = process.env.REACT_APP_SITE_KEY;

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const MerchantOnboard = ({}) => {
  const [loading, setLoading] = useState(false);
  const token = useToken();
  const [website, setUrl] = useState("https://www.abc.com");
  const [setEIN, setEINField] = useState(
    Math.floor(Math.random() * 999999999)
      .toString()
      .padStart(9, "0")
  );
  const [files, setFiles] = useState([]);

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [address, setAddress] = useState(
    "4069 Lake Drive Southeast Grand Rapids MI USA"
  );

  const [city, setCity] = useState("Grand Rapids");
  const [state, setState] = useState("Michigan");
  const [country, setCountry] = useState("US");
  const [zip, setZipcode] = useState("49546");

  const [businessAddress, setBusinessAddress] = useState(
    "4069 Lake Drive Southeast Grand Rapids MI USA"
  );
  const [businessCity, setBusinessCity] = useState("Grand Rapids");
  const [businessState, setBusinessState] = useState("Michigan");
  const [businessCountry, setBusinessCountry] = useState("US");
  const [businessZip, setBusinessZipcode] = useState("49546");

  const [currentStep, setCurrentStep] = useState(1);

  const [selectedIndustry, setSelectedIndustry] = useState("5942");
  const [name, setName] = useState("");

  const fileInputRef = useRef(null);
  const [industries, setIndustries] = useState([]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: Math.floor(Math.random() * 9999999999),
    date_of_birth: "1993-06-16",
    address: "",
    city: "",
    state: "",
    zip: "",
    // pin: "",
    country: "",
    business_address: "",
    business_city: "",
    business_state: "",
    business_zip: "",
    business_country: "",
    business_industry: "5942",
    business_name: "ecommerce",
    business_structure: "Private Corporation",
    website: "",
    processing_environment: "",
    avgTicket: "",
    annualCCSales: "",
    business_structure:"",
    established_date: "",
    b_title: "CEO",
    beneficiary_fname:"",
    beneficiary_lname:"",
    // dba: "",
    ein: "",
    ssn: Math.floor(Math.random() * 999999999)
      .toString()
      .padStart(9, "0"),
    routing_number: Math.floor(Math.random() * 999999999),
    account_number: Math.floor(Math.random() * 999999999),
    bank_name: "Test Bank",
    ownership_percent:"",
    // ownershiptype: "CORPORATION",
    // business_structure: '',
    legal_b2c: "",
    legal_b2b: "",
    legal_cnpinternet: "",
    legal_cnpphoneemail: "",
    legal_percentdeliveredover30days: "",
    legal_percentdeliveredsameday: "",
    percentdelievered7days: "",
    // account_manager:"",
    percentdelivered14days: "",
    legal_cp: "",
    legal_productssold: "",
    file: null,
    // Estore_Slug: "",
    business_email: "",
  });

  useEffect(() => {
    // fetchIndustries();
    setIndustries(dataIndustries);
  }, []);

  const dataIndustries = [
    {
      code: "ac_refrigeration_repair",
      value: "7623",
      name: "A/C Refrigeration Repair",
    },
    {
      code: "accounting_bookkeeping_services",
      value: "8931",
      name: "Accounting/Bookkeeping Services",
    },
    {
      code: "advertising_services",
      value: "7311",
      name: "Advertising Services",
    },
    {
      code: "agricultural_cooperative",
      value: "0763",
      name: "Agricultural Cooperative",
    },
    {
      code: "airlines_air_carriers",
      value: "4511",
      name: "Airlines Air Carriers",
    },
    {
      code: "airports_flying_fields",
      value: "4582",
      name: "Airports Flying Fields",
    },
    {
      code: "ambulance_services",
      value: "4119",
      name: "Ambulance Services",
    },
    {
      code: "amusement_parks_carnivals",
      value: "7996",
      name: "Amusement Parks/Carnivals",
    },
    {
      code: "antique_reproductions",
      value: "5937",
      name: "Antique Reproductions",
    },
    {
      code: "antique_shops",
      value: "5932",
      name: "Antique Shops",
    },
    {
      code: "aquariums",
      value: "7998",
      name: "Aquariums",
    },
    {
      code: "architectural_surveying_services",
      value: "8911",
      name: "Architectural/Surveying Services",
    },
    {
      code: "art_dealers_and_galleries",
      value: "5971",
      name: "Art Dealers and Galleries",
    },
    {
      code: "artists_supply_and_craft_shops",
      value: "5970",
      name: "Artists Supply and Craft Shops",
    },
    {
      code: "auto_body_repair_shops",
      value: "7531",
      name: "Auto Body Repair Shops",
    },
    {
      code: "auto_paint_shops",
      value: "7535",
      name: "Auto Paint Shops",
    },
    {
      code: "auto_service_shops",
      value: "7538",
      name: "Auto Service Shops",
    },
    {
      code: "auto_and_home_supply_stores",
      value: "5531",
      name: "Auto and Home Supply Stores",
    },
    {
      code: "automated_cash_disburse",
      value: "6011",
      name: "Automated Cash Disburse",
    },
    {
      code: "automated_fuel_dispensers",
      value: "5542",
      name: "Automated Fuel Dispensers",
    },
    {
      code: "automobile_associations",
      value: "8675",
      name: "Automobile Associations",
    },
    {
      code: "automotive_parts_and_accessories_stores",
      value: "5533",
      name: "Automotive Parts and Accessories Stores",
    },
    {
      code: "automotive_tire_stores",
      value: "5532",
      name: "Automotive Tire Stores",
    },
    {
      code: "bail_and_bond_payments",
      value: "9223",
      name: "Bail and Bond Payments (payment to the surety for the bond not the actual bond paid to the government agency)",
    },
    {
      code: "bakeries",
      value: "5462",
      name: "Bakeries",
    },
    {
      code: "bands_orchestras",
      value: "7929",
      name: "Bands Orchestras",
    },
    {
      code: "barber_and_beauty_shops",
      value: "7230",
      name: "Barber and Beauty Shops",
    },
    {
      code: "betting_casino_gambling",
      value: "7995",
      name: "Betting/Casino Gambling",
    },
    {
      code: "bicycle_shops",
      value: "5940",
      name: "Bicycle Shops",
    },
    {
      code: "billiard_pool_establishments",
      value: "7932",
      name: "Billiard/Pool Establishments",
    },
    {
      code: "boat_dealers",
      value: "5551",
      name: "Boat Dealers",
    },
    {
      code: "boat_rentals_and_leases",
      value: "4457",
      name: "Boat Rentals and Leases",
    },
    {
      code: "book_stores",
      value: "5942",
      name: "Book Stores",
    },
    {
      code: "books_periodicals_and_newspapers",
      value: "5192",
      name: "Books Periodicals and Newspapers",
    },
    {
      code: "bowling_alleys",
      value: "7933",
      name: "Bowling Alleys",
    },
    {
      code: "bus_lines",
      value: "4131",
      name: "Bus Lines",
    },
    {
      code: "business_secretarial_schools",
      value: "8244",
      name: "Business/Secretarial Schools",
    },
    {
      code: "buying_shopping_services",
      value: "7278",
      name: "Buying/Shopping Services",
    },
    {
      code: "cable_satellite_and_other_pay_television_and_radio",
      value: "4899",
      name: "Cable Satellite and Other Pay Television and Radio",
    },
    {
      code: "camera_and_photographic_supply_stores",
      value: "5946",
      name: "Camera and Photographic Supply Stores",
    },
    {
      code: "candy_nut_and_confectionery_stores",
      value: "5441",
      name: "Candy Nut and Confectionery Stores",
    },
    {
      code: "car_rental_agencies",
      value: "7512",
      name: "Car Rental Agencies",
    },
    {
      code: "car_washes",
      value: "7542",
      name: "Car Washes",
    },
    {
      code: "car_and_truck_dealers_new_used",
      value: "5511",
      name: "Car and Truck Dealers (New &amp; Used) Sales Service Repairs Parts and Leasing",
    },
    {
      code: "car_and_truck_dealers_used_only",
      value: "5521",
      name: "Car and Truck Dealers (Used Only) Sales Service Repairs Parts and Leasing",
    },
    {
      code: "carpentry_services",
      value: "1750",
      name: "Carpentry Services",
    },
    {
      code: "carpet_upholstery_cleaning",
      value: "7217",
      name: "Carpet/Upholstery Cleaning",
    },
    {
      code: "caterers",
      value: "5811",
      name: "Caterers",
    },
    {
      code: "charitable_and_social_service_organizations_fundraising",
      value: "8398",
      name: "Charitable and Social Service Organizations - Fundraising",
    },
    {
      code: "chemicals_and_allied_products",
      value: "5169",
      name: "Chemicals and Allied Products (Not Elsewhere Classified)",
    },
    {
      code: "chidrens_and_infants_wear_stores",
      value: "5641",
      name: "Chidrens and Infants Wear Stores",
    },
    {
      code: "child_care_services",
      value: "8351",
      name: "Child Care Services",
    },
    {
      code: "chiropodists_podiatrists",
      value: "8049",
      name: "Chiropodists Podiatrists",
    },
    {
      code: "chiropractors",
      value: "8041",
      name: "Chiropractors",
    },
    {
      code: "cigar_stores_and_stands",
      value: "5993",
      name: "Cigar Stores and Stands",
    },
    {
      code: "civic_social_fraternal_associations",
      value: "8641",
      name: "Civic Social Fraternal Associations",
    },
    {
      code: "cleaning_and_maintenance",
      value: "7349",
      name: "Cleaning and Maintenance",
    },
    {
      code: "clothing_rental",
      value: "7296",
      name: "Clothing Rental",
    },
    {
      code: "colleges_universities",
      value: "8220",
      name: "Colleges Universities",
    },
    {
      code: "commercial_equipment",
      value: "5046",
      name: "Commercial Equipment (Not Elsewhere Classified)",
    },
    {
      code: "commercial_footwear",
      value: "5139",
      name: "Commercial Footwear",
    },
    {
      code: "commercial_photography_art_and_graphics",
      value: "7333",
      name: "Commercial Photography Art and Graphics",
    },
    {
      code: "commuter_transport_and_ferries",
      value: "4111",
      name: "Commuter Transport Ferries",
    },
    {
      code: "computer_network_services",
      value: "4816",
      name: "Computer Network Services",
    },
    {
      code: "computer_programming",
      value: "7372",
      name: "Computer Programming",
    },
    {
      code: "computer_repair",
      value: "7379",
      name: "Computer Repair",
    },
    {
      code: "computer_software_stores",
      value: "5734",
      name: "Computer Software Stores",
    },
    {
      code: "computers_peripherals_and_software",
      value: "5045",
      name: "Computers Peripherals and Software",
    },
    {
      code: "concrete_work_services",
      value: "1771",
      name: "Concrete Work Services",
    },
    {
      code: "construction_materials",
      value: "5039",
      name: "Construction Materials (Not Elsewhere Classified)",
    },
    {
      code: "consulting_public_relations",
      value: "7392",
      name: "Consulting Public Relations",
    },
    {
      code: "correspondence_schools",
      value: "8241",
      name: "Correspondence Schools",
    },
    {
      code: "cosmetic_stores",
      value: "5977",
      name: "Cosmetic Stores",
    },
    {
      code: "counseling_services",
      value: "7277",
      name: "Counseling Services",
    },
    {
      code: "country_clubs",
      value: "7997",
      name: "Country Clubs",
    },
    {
      code: "courier_services",
      value: "4215",
      name: "Courier Services",
    },
    {
      code: "court_costs",
      value: "9211",
      name: "Court Costs Including Alimony and Child Support - Courts of Law",
    },
    {
      code: "credit_reporting_agencies",
      value: "7321",
      name: "Credit Reporting Agencies",
    },
    {
      code: "cruise_lines",
      value: "4411",
      name: "Cruise Lines",
    },
    {
      code: "dairy_products_stores",
      value: "5451",
      name: "Dairy Products Stores",
    },
    {
      code: "dance_hall_studios_schools",
      value: "7911",
      name: "Dance Hall Studios Schools",
    },
    {
      code: "dating_escort_services",
      value: "7273",
      name: "Dating/Escort Services",
    },
    {
      code: "dentists_orthodontists",
      value: "8021",
      name: "Dentists Orthodontists",
    },
    {
      code: "department_stores",
      value: "5311",
      name: "Department Stores",
    },
    {
      code: "detective_agencies",
      value: "7393",
      name: "Detective Agencies",
    },
    {
      code: "direct_marketing_catalog_merchant",
      value: "5964",
      name: "Direct Marketing - Catalog Merchant",
    },
    {
      code: "direct_marketing_combination_catalog_and_retail_merchant",
      value: "5965",
      name: "Direct Marketing - Combination Catalog and Retail Merchant",
    },
    {
      code: "direct_marketing_inbound_telemarketing",
      value: "5967",
      name: "Direct Marketing - Inbound Telemarketing",
    },
    {
      code: "direct_marketing_insurance_services",
      value: "5960",
      name: "Direct Marketing - Insurance Services",
    },
    {
      code: "direct_marketing_other",
      value: "5969",
      name: "Direct Marketing - Other",
    },
    {
      code: "direct_marketing_outbound_telemarketing",
      value: "5966",
      name: "Direct Marketing - Outbound Telemarketing",
    },
    {
      code: "direct_marketing_subscription",
      value: "5968",
      name: "Direct Marketing - Subscription",
    },
    {
      code: "direct_marketing_travel",
      value: "5962",
      name: "Direct Marketing - Travel",
    },
    {
      code: "discount_stores",
      value: "5310",
      name: "Discount Stores",
    },
    {
      code: "doctors",
      value: "8011",
      name: "Doctors",
    },
    {
      code: "door_to_door_sales",
      value: "5963",
      name: "Door-To-Door Sales",
    },
    {
      code: "drapery_window_covering_and_upholstery_stores",
      value: "5714",
      name: "Drapery Window Covering and Upholstery Stores",
    },
    {
      code: "drinking_places",
      value: "5813",
      name: "Drinking Places",
    },
    {
      code: "drug_stores_and_pharmacies",
      value: "5912",
      name: "Drug Stores and Pharmacies",
    },
    {
      code: "drugs_drug_proprietaries_and_druggist_sundries",
      value: "5122",
      name: "Drugs Drug Proprietaries and Druggist Sundries",
    },
    {
      code: "dry_cleaners",
      value: "7216",
      name: "Dry Cleaners",
    },
    {
      code: "durable_goods",
      value: "5099",
      name: "Durable Goods (Not Elsewhere Classified)",
    },
    {
      code: "duty_free_stores",
      value: "5309",
      name: "Duty Free Stores",
    },
    {
      code: "eating_places_restaurants",
      value: "5812",
      name: "Eating Places Restaurants",
    },
    {
      code: "educational_services",
      value: "8299",
      name: "Educational Services",
    },
    {
      code: "electric_razor_stores",
      value: "5997",
      name: "Electric Razor Stores",
    },
    {
      code: "electrical_parts_and_equipment",
      value: "5065",
      name: "Electrical Parts and Equipment",
    },
    {
      code: "electrical_services",
      value: "1731",
      name: "Electrical Services",
    },
    {
      code: "electronics_repair_shops",
      value: "7622",
      name: "Electronics Repair Shops",
    },
    {
      code: "electronics_stores",
      value: "5732",
      name: "Electronics Stores",
    },
    {
      code: "elementary_secondary_schools",
      value: "8211",
      name: "Elementary Secondary Schools",
    },
    {
      code: "employment_temp_agencies",
      value: "7361",
      name: "Employment/Temp Agencies",
    },
    {
      code: "equipment_rental",
      value: "7394",
      name: "Equipment Rental",
    },
    {
      code: "exterminating_services",
      value: "7342",
      name: "Exterminating Services",
    },
    {
      code: "family_clothing_stores",
      value: "5651",
      name: "Family Clothing Stores",
    },
    {
      code: "fast_food_restaurants",
      value: "5814",
      name: "Fast Food Restaurants",
    },
    {
      code: "financial_institutions",
      value: "6012",
      name: "Financial Institutions",
    },
    {
      code: "fines_government_administrative_entities",
      value: "9222",
      name: "Fines - Government Administrative Entities",
    },
    {
      code: "fireplace_fireplace_screens_and_accessories_stores",
      value: "5718",
      name: "Fireplace Fireplace Screens and Accessories Stores",
    },
    {
      code: "floor_covering_stores",
      value: "5713",
      name: "Floor Covering Stores",
    },
    {
      code: "florists",
      value: "5992",
      name: "Florists",
    },
    {
      code: "florists_supplies_nursery_stock_and_flowers",
      value: "5193",
      name: "Florists Supplies Nursery Stock and Flowers",
    },
    {
      code: "freezer_and_locker_meat_provisioners",
      value: "5422",
      name: "Freezer and Locker Meat Provisioners",
    },
    {
      code: "fuel_dealers_non_automotive",
      value: "5983",
      name: "Fuel Dealers (Non Automotive)",
    },
    {
      code: "funeral_services_crematories",
      value: "7261",
      name: "Funeral Services Crematories",
    },
    {
      code: "furniture_repair_refinishing",
      value: "7641",
      name: "Furniture Repair Refinishing",
    },
    {
      code: "furniture_home_furnishings_and_equipment_stores_except_appliances",
      value: "5712",
      name: "Furniture Home Furnishings and Equipment Stores Except Appliances",
    },
    {
      code: "furriers_and_fur_shops",
      value: "5681",
      name: "Furriers and Fur Shops",
    },
    {
      code: "general_services",
      value: "1520",
      name: "General Services",
    },
    {
      code: "gift_card_novelty_and_souvenir_shops",
      value: "5947",
      name: "Gift Card Novelty and Souvenir Shops",
    },
    {
      code: "glass_paint_and_wallpaper_stores",
      value: "5231",
      name: "Glass Paint and Wallpaper Stores",
    },
    {
      code: "glassware_crystal_stores",
      value: "5950",
      name: "Glassware Crystal Stores",
    },
    {
      code: "golf_courses_public",
      value: "7992",
      name: "Golf Courses - Public",
    },
    {
      code: "government_services",
      value: "9399",
      name: "Government Services (Not Elsewhere Classified)",
    },
    {
      code: "grocery_stores_supermarkets",
      value: "5411",
      name: "Grocery Stores Supermarkets",
    },
    {
      code: "hardware_stores",
      value: "5251",
      name: "Hardware Stores",
    },
    {
      code: "hardware_equipment_and_supplies",
      value: "5072",
      name: "Hardware Equipment and Supplies",
    },
    {
      code: "health_and_beauty_spas",
      value: "7298",
      name: "Health and Beauty Spas",
    },
    {
      code: "hearing_aids_sales_and_supplies",
      value: "5975",
      name: "Hearing Aids Sales and Supplies",
    },
    {
      code: "heating_plumbing_a_c",
      value: "1711",
      name: "Heating Plumbing A/C",
    },
    {
      code: "hobby_toy_and_game_shops",
      value: "5945",
      name: "Hobby Toy and Game Shops",
    },
    {
      code: "home_supply_warehouse_stores",
      value: "5200",
      name: "Home Supply Warehouse Stores",
    },
    {
      code: "hospitals",
      value: "8062",
      name: "Hospitals",
    },
    {
      code: "hotels_motels_and_resorts",
      value: "7011",
      name: "Hotels Motels and Resorts",
    },
    {
      code: "household_appliance_stores",
      value: "5722",
      name: "Household Appliance Stores",
    },
    {
      code: "industrial_supplies",
      value: "5085",
      name: "Industrial Supplies (Not Elsewhere Classified)",
    },
    {
      code: "information_retrieval_services",
      value: "7375",
      name: "Information Retrieval Services",
    },
    {
      code: "insurance_default",
      value: "6399",
      name: "Insurance - Default",
    },
    {
      code: "insurance_underwriting_premiums",
      value: "6300",
      name: "Insurance Underwriting Premiums",
    },
    {
      code: "intra_company_purchases",
      value: "9950",
      name: "Intra-Company Purchases",
    },
    {
      code: "jewelry_stores_watches_clocks_and_silverware_stores",
      value: "5944",
      name: "Jewelry Stores Watches Clocks and Silverware Stores",
    },
    {
      code: "landscaping_services",
      value: "0780",
      name: "Landscaping Services",
    },
    {
      code: "laundries",
      value: "7211",
      name: "Laundries",
    },
    {
      code: "laundry_cleaning_services",
      value: "7210",
      name: "Laundry Cleaning Services",
    },
    {
      code: "legal_services_attorneys",
      value: "8111",
      name: "Legal Services Attorneys",
    },
    {
      code: "luggage_and_leather_goods_stores",
      value: "5948",
      name: "Luggage and Leather Goods Stores",
    },
    {
      code: "lumber_building_materials_stores",
      value: "5211",
      name: "Lumber Building Materials Stores",
    },
    {
      code: "manual_cash_disburse",
      value: "6010",
      name: "Manual Cash Disburse",
    },
    {
      code: "marinas_service_and_supplies",
      value: "4468",
      name: "Marinas Service and Supplies",
    },
    {
      code: "masonry_stonework_and_plaster",
      value: "1740",
      name: "Masonry Stonework and Plaster",
    },
    {
      code: "massage_parlors",
      value: "7297",
      name: "Massage Parlors",
    },
    {
      code: "medical_services",
      value: "8099",
      name: "Medical Services",
    },
    {
      code: "medical_and_dental_labs",
      value: "8071",
      name: "Medical and Dental Labs",
    },
    {
      code: "medical_dental_ophthalmic_and_hospital_equipment_and_supplies",
      value: "5047",
      name: "Medical Dental Ophthalmic and Hospital Equipment and Supplies",
    },
    {
      code: "membership_organizations",
      value: "8699",
      name: "Membership Organizations",
    },
    {
      code: "mens_and_boys_clothing_and_accessories_stores",
      value: "5611",
      name: "Mens and Boys Clothing and Accessories Stores",
    },
    {
      code: "mens_womens_clothing_stores",
      value: "5691",
      name: "Mens Womens Clothing Stores",
    },
    {
      code: "metal_service_centers",
      value: "5051",
      name: "Metal Service Centers",
    },
    {
      code: "miscellaneous_apparel_and_accessory_shops",
      value: "5699",
      name: "Miscellaneous Apparel and Accessory Shops",
    },
    {
      code: "miscellaneous_auto_dealers",
      value: "5599",
      name: "Miscellaneous Auto Dealers",
    },
    {
      code: "miscellaneous_business_services",
      value: "7399",
      name: "Miscellaneous Business Services",
    },
    {
      code: "miscellaneous_food_stores",
      value: "5499",
      name: "Miscellaneous Food Stores - Convenience Stores and Specialty Markets",
    },
    {
      code: "miscellaneous_general_merchandise",
      value: "5399",
      name: "Miscellaneous General Merchandise",
    },
    {
      code: "miscellaneous_general_services",
      value: "7299",
      name: "Miscellaneous General Services",
    },
    {
      code: "miscellaneous_home_furnishing_specialty_stores",
      value: "5719",
      name: "Miscellaneous Home Furnishing Specialty Stores",
    },
    {
      code: "miscellaneous_publishing_and_printing",
      value: "2741",
      name: "Miscellaneous Publishing and Printing",
    },
    {
      code: "miscellaneous_recreation_services",
      value: "7999",
      name: "Miscellaneous Recreation Services",
    },
    {
      code: "miscellaneous_repair_shops",
      value: "7699",
      name: "Miscellaneous Repair Shops",
    },
    {
      code: "miscellaneous_specialty_retail",
      value: "5999",
      name: "Miscellaneous Specialty Retail",
    },
    {
      code: "mobile_home_dealers",
      value: "5271",
      name: "Mobile Home Dealers",
    },
    {
      code: "motion_picture_theaters",
      value: "7832",
      name: "Motion Picture Theaters",
    },
    {
      code: "motor_freight_carriers_and_trucking",
      value: "4214",
      name: "Motor Freight Carriers and Trucking - Local and Long Distance Moving and Storage Companies and Local Delivery Services",
    },
    {
      code: "motor_homes_dealers",
      value: "5592",
      name: "Motor Homes Dealers",
    },
    {
      code: "motor_vehicle_supplies_and_new_parts",
      value: "5013",
      name: "Motor Vehicle Supplies and New Parts",
    },
    {
      code: "motorcycle_shops_and_dealers",
      value: "5571",
      name: "Motorcycle Shops and Dealers",
    },
    {
      code: "motorcycle_shops_dealers",
      value: "5561",
      name: "Motorcycle Shops Dealers",
    },
    {
      code: "music_stores_musical_instruments_pianos_and_sheet_music",
      value: "5733",
      name: "Music Stores-Musical Instruments Pianos and Sheet Music",
    },
    {
      code: "news_dealers_and_newsstands",
      value: "5994",
      name: "News Dealers and Newsstands",
    },
    {
      code: "non_fi_money_orders",
      value: "6051",
      name: "Non-FI Money Orders",
    },
    {
      code: "non_fi_stored_value_card_purchase_load",
      value: "6540",
      name: "Non-FI Stored Value Card Purchase/Load",
    },
    {
      code: "nondurable_goods",
      value: "5199",
      name: "Nondurable Goods (Not Elsewhere Classified)",
    },
    {
      code: "nurseries_lawn_and_garden_supply_stores",
      value: "5261",
      name: "Nurseries Lawn and Garden Supply Stores",
    },
    {
      code: "nursing_personal_care",
      value: "8050",
      name: "Nursing/Personal Care",
    },
    {
      code: "office_and_commercial_furniture",
      value: "5021",
      name: "Office and Commercial Furniture",
    },
    {
      code: "opticians_eyeglasses",
      value: "8043",
      name: "Opticians Eyeglasses",
    },
    {
      code: "optometrists_ophthalmologist",
      value: "8042",
      name: "Optometrists Ophthalmologist",
    },
    {
      code: "orthopedic_goods_prosthetic_devices",
      value: "5976",
      name: "Orthopedic Goods - Prosthetic Devices",
    },
    {
      code: "osteopaths",
      value: "8031",
      name: "Osteopaths",
    },
    {
      code: "package_stores_beer_wine_and_liquor",
      value: "5921",
      name: "Package Stores-Beer Wine and Liquor",
    },
    {
      code: "paints_varnishes_and_supplies",
      value: "5198",
      name: "Paints Varnishes and Supplies",
    },
    {
      code: "parking_lots_garages",
      value: "7523",
      name: "Parking Lots Garages",
    },
    {
      code: "passenger_railways",
      value: "4112",
      name: "Passenger Railways",
    },
    {
      code: "pawn_shops",
      value: "5933",
      name: "Pawn Shops",
    },
    {
      code: "pet_shops_pet_food_and_supplies",
      value: "5995",
      name: "Pet Shops Pet Food and Supplies",
    },
    {
      code: "petroleum_and_petroleum_products",
      value: "5172",
      name: "Petroleum and Petroleum Products",
    },
    {
      code: "photo_developing",
      value: "7395",
      name: "Photo Developing",
    },
    {
      code: "photographic_studios",
      value: "7221",
      name: "Photographic Studios",
    },
    {
      code: "photographic_photocopy_microfilm_equipment_and_supplies",
      value: "5044",
      name: "Photographic Photocopy Microfilm Equipment and Supplies",
    },
    {
      code: "picture_video_production",
      value: "7829",
      name: "Picture/Video Production",
    },
    {
      code: "piece_goods_notions_and_other_dry_goods",
      value: "5131",
      name: "Piece Goods Notions and Other Dry Goods",
    },
    {
      code: "plumbing_heating_equipment_and_supplies",
      value: "5074",
      name: "Plumbing Heating Equipment and Supplies",
    },
    {
      code: "political_organizations",
      value: "8651",
      name: "Political Organizations",
    },
    {
      code: "postal_services_government_only",
      value: "9402",
      name: "Postal Services - Government Only",
    },
    {
      code: "precious_stones_and_metals_watches_and_jewelry",
      value: "5094",
      name: "Precious Stones and Metals Watches and Jewelry",
    },
    {
      code: "professional_services",
      value: "8999",
      name: "Professional Services",
    },
    {
      code: "public_warehousing_and_storage",
      value: "4225",
      name: "Public Warehousing and Storage - Farm Products Refrigerated Goods Household Goods and Storage",
    },
    {
      code: "quick_copy_repro_and_blueprint",
      value: "7338",
      name: "Quick Copy Repro and Blueprint",
    },
    {
      code: "railroads",
      value: "4011",
      name: "Railroads",
    },
    {
      code: "real_estate_agents_and_managers_rentals",
      value: "6513",
      name: "Real Estate Agents and Managers - Rentals",
    },
    {
      code: "record_stores",
      value: "5735",
      name: "Record Stores",
    },
    {
      code: "recreational_vehicle_rentals",
      value: "7519",
      name: "Recreational Vehicle Rentals",
    },
    {
      code: "religious_goods_stores",
      value: "5973",
      name: "Religious Goods Stores",
    },
    {
      code: "religious_organizations",
      value: "8661",
      name: "Religious Organizations",
    },
    {
      code: "roofing_siding_sheet_metal",
      value: "1761",
      name: "Roofing/Siding Sheet Metal",
    },
    {
      code: "secretarial_support_services",
      value: "7339",
      name: "Secretarial Support Services",
    },
    {
      code: "security_brokers_dealers",
      value: "6211",
      name: "Security Brokers/Dealers",
    },
    {
      code: "service_stations",
      value: "5541",
      name: "Service Stations",
    },
    {
      code: "sewing_needlework_fabric_and_piece_goods_stores",
      value: "5949",
      name: "Sewing Needlework Fabric and Piece Goods Stores",
    },
    {
      code: "shoe_repair_hat_cleaning",
      value: "7251",
      name: "Shoe Repair/Hat Cleaning",
    },
    {
      code: "shoe_stores",
      value: "5661",
      name: "Shoe Stores",
    },
    {
      code: "small_appliance_repair",
      value: "7629",
      name: "Small Appliance Repair",
    },
    {
      code: "snowmobile_dealers",
      value: "5598",
      name: "Snowmobile Dealers",
    },
    {
      code: "special_trade_services",
      value: "1799",
      name: "Special Trade Services",
    },
    {
      code: "specialty_cleaning",
      value: "2842",
      name: "Specialty Cleaning",
    },
    {
      code: "sporting_goods_stores",
      value: "5941",
      name: "Sporting Goods Stores",
    },
    {
      code: "sporting_recreation_camps",
      value: "7032",
      name: "Sporting/Recreation Camps",
    },
    {
      code: "sports_clubs_fields",
      value: "7941",
      name: "Sports Clubs/Fields",
    },
    {
      code: "sports_and_riding_apparel_stores",
      value: "5655",
      name: "Sports and Riding Apparel Stores",
    },
    {
      code: "stamp_and_coin_stores",
      value: "5972",
      name: "Stamp and Coin Stores",
    },
    {
      code: "stationary_office_supplies_printing_and_writing_paper",
      value: "5111",
      name: "Stationary Office Supplies Printing and Writing Paper",
    },
    {
      code: "stationery_stores_office_and_school_supply_stores",
      value: "5943",
      name: "Stationery Stores Office and School Supply Stores",
    },
    {
      code: "swimming_pools_sales",
      value: "5996",
      name: "Swimming Pools Sales",
    },
    {
      code: "t_ui_travel_germany",
      value: "4723",
      name: "TUI Travel - Germany",
    },
    {
      code: "tailors_alterations",
      value: "5697",
      name: "Tailors Alterations",
    },
    {
      code: "tax_payments_government_agencies",
      value: "9311",
      name: "Tax Payments - Government Agencies",
    },
    {
      code: "tax_preparation_services",
      value: "7276",
      name: "Tax Preparation Services",
    },
    {
      code: "taxicabs_limousines",
      value: "4121",
      name: "Taxicabs/Limousines",
    },
    {
      code: "telecommunication_equipment_and_telephone_sales",
      value: "4812",
      name: "Telecommunication Equipment and Telephone Sales",
    },
    {
      code: "telecommunication_services",
      value: "4814",
      name: "Telecommunication Services",
    },
    {
      code: "telegraph_services",
      value: "4821",
      name: "Telegraph Services",
    },
    {
      code: "tent_and_awning_shops",
      value: "5998",
      name: "Tent and Awning Shops",
    },
    {
      code: "testing_laboratories",
      value: "8734",
      name: "Testing Laboratories",
    },
    {
      code: "theatrical_ticket_agencies",
      value: "7922",
      name: "Theatrical Ticket Agencies",
    },
    {
      code: "timeshares",
      value: "7012",
      name: "Timeshares",
    },
    {
      code: "tire_retreading_and_repair",
      value: "7534",
      name: "Tire Retreading and Repair",
    },
    {
      code: "tolls_bridge_fees",
      value: "4784",
      name: "Tolls/Bridge Fees",
    },
    {
      code: "tourist_attractions_and_exhibits",
      value: "7991",
      name: "Tourist Attractions and Exhibits",
    },
    {
      code: "towing_services",
      value: "7549",
      name: "Towing Services",
    },
    {
      code: "trailer_parks_campgrounds",
      value: "7033",
      name: "Trailer Parks Campgrounds",
    },
    {
      code: "transportation_services",
      value: "4789",
      name: "Transportation Services (Not Elsewhere Classified)",
    },
    {
      code: "travel_agencies_tour_operators",
      value: "4722",
      name: "Travel Agencies Tour Operators",
    },
    {
      code: "truck_stop_iteration",
      value: "7511",
      name: "Truck StopIteration",
    },
    {
      code: "truck_utility_trailer_rentals",
      value: "7513",
      name: "Truck/Utility Trailer Rentals",
    },
    {
      code: "typesetting_plate_making_and_related_services",
      value: "2791",
      name: "Typesetting Plate Making and Related Services",
    },
    {
      code: "typewriter_stores",
      value: "5978",
      name: "Typewriter Stores",
    },
    {
      code: "u_s_federal_government_agencies_or_departments",
      value: "9405",
      name: "U.S. Federal Government Agencies or Departments",
    },
    {
      code: "uniforms_commercial_clothing",
      value: "5137",
      name: "Uniforms Commercial Clothing",
    },
    {
      code: "used_merchandise_and_secondhand_stores",
      value: "5931",
      name: "Used Merchandise and Secondhand Stores",
    },
    {
      code: "utilities",
      value: "4900",
      name: "Utilities",
    },
    {
      code: "variety_stores",
      value: "5331",
      name: "Variety Stores",
    },
    {
      code: "veterinary_services",
      value: "0742",
      name: "Veterinary Services",
    },
    {
      code: "video_amusement_game_supplies",
      value: "7993",
      name: "Video Amusement Game Supplies",
    },
    {
      code: "video_game_arcades",
      value: "7994",
      name: "Video Game Arcades",
    },
    {
      code: "video_tape_rental_stores",
      value: "7841",
      name: "Video Tape Rental Stores",
    },
    {
      code: "vocational_trade_schools",
      value: "8249",
      name: "Vocational/Trade Schools",
    },
    {
      code: "watch_jewelry_repair",
      value: "7631",
      name: "Watch/Jewelry Repair",
    },
    {
      code: "welding_repair",
      value: "7692",
      name: "Welding Repair",
    },
    {
      code: "wholesale_clubs",
      value: "5300",
      name: "Wholesale Clubs",
    },
    {
      code: "wig_and_toupee_stores",
      value: "5698",
      name: "Wig and Toupee Stores",
    },
    {
      code: "wires_money_orders",
      value: "4829",
      name: "Wires Money Orders",
    },
    {
      code: "womens_accessory_and_specialty_shops",
      value: "5631",
      name: "Womens Accessory and Specialty Shops",
    },
    {
      code: "womens_ready_to_wear_stores",
      value: "5621",
      name: "Womens Ready-To-Wear Stores",
    },
    {
      code: "wrecking_and_salvage_yards",
      value: "5935",
      name: "Wrecking and Salvage Yards",
    },
  ];
  const fetchIndustries = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/json/mcc.json`);
      const data = await response.json();
      setIndustries(data.data);
    } catch (error) {
      setError(error.message);
    }
  };

  // useEffect(() => {
  //     const autocomplete = new window.google.maps.places.Autocomplete(
  //         document.getElementById('autocomplete'), {
  //         types: ['address']
  //     });
  //     autocomplete.addListener('place_changed', fillInAddress);
  // }, []);

  const fillInAddress = () => {
    const place = document.getElementById("autocomplete").getPlace();
    const addressComponents = place.address_components;
    let selectedCity = "";
    let selectedState = "";

    addressComponents.forEach((component) => {
      if (component.types.includes("locality")) {
        selectedCity = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        selectedState = component.short_name;
      }
    });

    setFormData({
      ...formData,
      business_state: selectedState,
      business_city: selectedCity,
      business_country:
        place.address_components.find((comp) => comp.types.includes("country"))
          ?.short_name || "",
      business_zip:
        place.address_components.find((comp) =>
          comp.types.includes("postal_code")
        )?.short_name || "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value?.trim() }));
  };
  const validateForm = () => {
    let errors = {};
    if (formData.first_name == "") {
      errors.first_name = "First name is required";
    }
    if (formData.last_name == "") {
      errors.last_name = "Last name is required";
    }
    // if (formData.pin == "") {
    //   errors.pin = "Pin is required";
    // } else if (!isValidPin(formData.pin)) {
    //   errors.pin = "Please enter a valid pin";
    // }
    // if (formData.account_manager === '') {
    //   errors.account_manager = 'Account manager is required';
    // } else if (!/^[a-zA-Z]*$/.test(formData.account_manager)) {
    //   errors.account_manager = 'Only alphabetic characters are allowed';
    // }
    if (formData.business_name == "") {
      errors.business_name = "Business name is required";
    }
    if (formData.email == "") {
      errors.email = "Email is required";
    }
    if (formData.website !== "" && !isValidUrl(formData.website)) {
      errors.website = "Please enter a valid website URL";
    }
    if (formData.business_address == "" && businessAddress == "") {
      errors.business_address = "Business address is required";
    }
    if (formData.business_city == "" && businessCity == "") {
      errors.business_city = "City is required";
    }
    if (formData.business_state == "" && businessState == "") {
      errors.business_state = "State is required";
    }
    if (formData.processing_environment == "") {
      errors.processing_environment = "Processing environment is required";
    }
    if (formData.business_country == "" && businessCountry == "") {
      errors.business_country = "Country is required";
    }
    if (formData.mid && formData.mid == "") {
      errors.mid = "Mid is required";
    }
    if (formData.beneficiary_fname && formData.beneficiary_fname == "") {
      errors.beneficiary_fname = "First name is required";
    }
    if (formData.beneficiary_lname && formData.beneficiary_lname == "") {
      errors.beneficiary_lname = "Last name is required";
    }
  

    if (formData.cms_type == "") {
      errors.cms_type = "Commission type is required";
    }
    if (formData.cms_acct == "") {
      errors.cms_acct = "Commission account is required";
    }
    if (formData.user_name == "") {
      errors.user_name = "User name is required";
    }
    if (formData.ein == "") {
      errors.ein = "Employee identification number must be 9 characters";
    }
    if (formData.ownership_percent == "") {
      errors.ownership_percent = "Ownership percent is required";
    }
    if (formData.ssn == "") {
      errors.ssn = "Security social number must be 9 characters";
    }

    if (formData.bank_name == "") {
      errors.bank_name = "Bank name is required";
    }
    // if (formData.estore_slug == "") {
    //   errors.estore_slug = "Estore Slug is required";
    // }
    if (formData.date_of_birth == "") {
      errors.date_of_birth = "Date of birth is required";
    } else if (!isValidDate(formData.date_of_birth)) {
      errors.date_of_birth = "Please enter a valid date of birth (YYYY-MM-DD)";
    }
    if (formData.business_zip == "" && businessZip == "") {
      errors.business_zip = "Zip code is required";
    } else if (!isValidZipCode(formData.business_zip)) {
      errors.business_zip = "Please enter a valid zip code";
    }
    if (formData.routing_number == "") {
      errors.routing_number = "Routing number is required";
    } else if (!isValidRoutingNumber(formData.routing_number)) {
      errors.routing_number = "Please enter a valid routing number";
    }

    if (formData.account_number == "") {
      errors.account_number = "Account number is required";
    } else if (!isValidAccountNumber(formData.account_number)) {
      errors.account_number = "Please enter a valid account number";
    }

    setErrors(errors);
    return Object.keys(errors).length == 0;
  };
  const isValidRoutingNumber = (routing_number) => {
    return true;
  };
  const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
  };
  const isValidPin = (pin) => {
    return true;
  };

  const isValidUrl = (url) => {
    return true;
  };
  const isValidAccountNumber = (accountNumber) => {
    // Add your account number validation logic here
    return true; // Placeholder validation
  };
  const isValidZipCode = (zipCode) => {
    return true;
  };
  console.log("error", errors);


  const formatPhoneNumber = (numericValue) => {
    const cleaned = ("" + numericValue).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return numericValue;
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    // console.log("handlePhoneChange :: name => %s ::: value => ", name, value);

    setFormData((prevState) => ({
      ...prevState,
      [name]: value.replace(/[^0-9]/g, ""),
    }));
  };

  const formattedDate = (data) => {
    if (!data) {
      return "";
    } else {
      return moment(data).format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    }
  };

  const handleDOBChange = (inputDate) => {
    if (inputDate) {
      const formattedDate = moment(inputDate).format("YYYY-MM-DD");
      // alert(formattedDate);
      setFormData((prevState) => ({
        ...prevState,
        date_of_birth: formattedDate,
      }));
    }
  };
  const handleEstChange = (inputDate) => {
    if (inputDate) {
      const formattedDate = moment(inputDate).format("YYYY-MM-DD");
      // alert(formattedDate);
      setFormData((prevState) => ({
        ...prevState,
        established_date: formattedDate,
      }));
    }
  };

  const handleIndustryChange = (e) => {
    const { name, value } = e.target;

    setSelectedIndustry(e.target.value);

    setFormData((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    const { address_components } = results[0];

    var streetNumber = "";
    address_components.forEach((component) => {
      const { types, long_name, short_name } = component;

      if (types == "street_number") {
        streetNumber = short_name;
      }
      if (types.includes("locality")) {
        setCity(short_name);
        setBusinessCity(short_name);
      } else if (types.includes("administrative_area_level_1")) {
        setState(short_name);
        setBusinessState(short_name);
      } else if (types.includes("country")) {
        setCountry(short_name);
        setBusinessCountry(short_name);
      } else if (types.includes("postal_code")) {
        setZipcode(long_name);
        setBusinessZipcode(long_name);
      } else if (types.includes("route")) {
        setAddress((streetNumber != "" ? streetNumber + " " : "") + short_name);
        setBusinessAddress(
          (streetNumber != "" ? streetNumber + " " : "") + short_name
        );
        // setAddress(short_name);
      }
    });

    try {
      const results = await geocodeByAddress(address);

      const latLng = await getLatLng(results[0]);
    } catch (error) {
      console.error("Error fetching address coordinates:", error);
    }
  };

  const handleBusinessSelect = async (address) => {
    // setBusinessAddress(address);
    const results = await geocodeByAddress(address);
    // const { address_components } = results[0];
    // console.log("address_components :", address_components);
    const { address_components } = results[0];

    //   const zipcode = address_components.find(
    //     (component) => component.types.includes('postal_code')
    //   ).long_name;
    //   set
    //   console.log(zipcode);
    // Extract address details
    var streetNumber = "";

    address_components.forEach((component) => {
      const { types, long_name, short_name } = component;
      if (types == "street_number") {
        streetNumber = short_name;
      }
      if (types.includes("locality")) {
        setBusinessCity(short_name);
      } else if (types.includes("administrative_area_level_1")) {
        setBusinessState(short_name);
      } else if (types.includes("country")) {
        setBusinessCountry(short_name);
      } else if (types.includes("postal_code")) {
        setBusinessZipcode(long_name);
      } else if (types.includes("route")) {
        setBusinessAddress(
          (streetNumber != "" ? streetNumber + " " : "") + short_name
        );
      }
    });

    try {
      const results = await geocodeByAddress(address);

      const latLng = await getLatLng(results[0]);
    } catch (error) {
      console.error("Error fetching address coordinates:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      toast.error("Please fill out all required fields.");
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append("first_name", formData.first_name);
    formDataToSend.append("last_name", formData.last_name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("date_of_birth", formData.date_of_birth);
    formDataToSend.append("address", address);
    formDataToSend.append("city", city);
    formDataToSend.append("state", state);
    formDataToSend.append("zip", zip);
    formDataToSend.append("business_email", formData.business_email);
    formDataToSend.append("beneficiary_fname", formData.beneficiary_fname);
    formDataToSend.append("beneficiary_lname", formData.beneficiary_lname);

    formDataToSend.append("country", country);
    formDataToSend.append("business_address", businessAddress);
    formDataToSend.append("business_city", businessCity);
    formDataToSend.append("business_state", businessState);
    formDataToSend.append("business_zip", businessZip);
    formDataToSend.append("business_country", businessCountry);
    formDataToSend.append("business_industry", formData.business_industry);
    formDataToSend.append("business_name", formData.business_name);
    formDataToSend.append("business_structure", formData.business_structure);
    formDataToSend.append("website", website);
    formDataToSend.append(
      "processing_environment", 
      formData.processing_environment

    );
    formDataToSend.append("avgTicket", formData.avgTicket);
    formDataToSend.append("annualCCSales", formData.annualCCSales);
    formDataToSend.append("established_date", formData.established_date);
    formDataToSend.append("ownership_percent", formData.ownership_percent);
    formDataToSend.append("b_title", formData.b_title);
    // formDataToSend.append("dba", formData.dba);
    formDataToSend.append("ein", formData.ein);
    formDataToSend.append("ssn", formData.ssn);

    formDataToSend.append("routing_number", formData.routing_number);
    formDataToSend.append("account_number", formData.account_number);
    formDataToSend.append("bank_name", formData.bank_name)
    formDataToSend.append("ownershiptype", formData.ownershiptype);
    // formDataToSend.append("account_manager", formData.account_manager);
    formDataToSend.append(
      "percentdelivered14days",
      formData.percentdelivered14days
    );
    formDataToSend.append(
      "percentdelievered7days",
      formData.percentdelievered7days
    );
    formDataToSend.append(
      "legal_percentdeliveredsameday",
      formData.legal_percentdeliveredsameday
    );
    formDataToSend.append(
      "legal_percentdeliveredover30days",
      formData.legal_percentdeliveredover30days
    );
    formDataToSend.append("legal_cnpphoneemail", formData.legal_cnpphoneemail);
    formDataToSend.append("legal_cnpinternet", formData.legal_cnpinternet);
    formDataToSend.append("legal_b2b", formData.legal_b2b);
    formDataToSend.append("legal_b2c", formData.legal_b2c);
    formDataToSend.append("legal_cp", formData.legal_cp);
    formDataToSend.append("legal_productssold", formData.legal_productssold);
    files.forEach((fileData) => {
      formDataToSend.append("file", fileData);
    });

    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/admin/merchant/add-merchant`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }
      );

      if (response.status === 201) {
        setLoading(false);
        toast.success("Merchant added successfully!");
        navigate("/merchants")
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error calling API:", error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  // const handleChanged = (e) => {
  //     e.preventDefault();
  //     console.log("handleChanged : ", e.target.value);
  //     const newValue = e.target.value;
  //     if (/^\d{0,9}$/.test(newValue)) {
  //         // Update the formData state with the new EIN value
  //         setFormData(prevState => ({
  //             ...prevState,
  //             ein: newValue
  //         }));
  //         errors.ein = false;
  //     }
  // }

  const handleUrlChange = (event) => {
    const newValue = event.target.value;
    // console.log("handleUrlChange :: newValue => ", newValue);

    if (newValue) {
      if (newValue == "https:/") {
        setUrl("https://");
      } else {
        // Check if the new value starts with "https://"
        if (newValue.startsWith("https://")) {
          setUrl(newValue); // Update the URL state if "https://" is already present
        } else {
          // If "https://" is not present, add it and then append the new value
          setUrl("https://" + newValue);
        }
      }
    }
  };
  // console.log("selectedFiles",selectedFiles);
  const handleUrlInputClick = () => {
    if (!website.startsWith("https://")) {
      setUrl("https://" + website);
    }
  };
  const breadcrumbItems = [
    { label: "Dashboard", href: "/", active: false },
    { label: "Merchants", href: "/merchants", active: false },
    { label: "Merchant-onboard", href: "/merchant-onboarding", active: true },
  ];
  return (
    <div className="container-xl">
      {/* <BreadCrumbs items={breadcrumbItems} /> */}

      <h1 className="app-page-title" style={{ fontFamily: 'Poppins, sans-serif' , fontSize:'23px'}} >Add Merchant </h1>

      <div className="app-card app-card-orders-table shadow-sm px-4 py-4">
        <h1 className="app-page-title">Basic Details</h1>

        <div className="step-content">
          {currentStep === 1 && (
            <>
              <div className="step-pane active" id="step-1">
                <div className="container all-steps">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        First Name*
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                      />
                      {errors.first_name && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.first_name}
                        </span>
                      )}
                    </div>

                    <div className="col-md-4 ">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Last Name*
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        placeholder="Last Name"
                        onChange={handleChange}
                        required
                      />
                      {errors.last_name && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.last_name}
                        </span>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Phone Number*
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={formatPhoneNumber(formData.phone)}
                        onChange={handlePhoneChange}
                        // pattern="[0-9]*"
                        inputMode="numeric"
                        maxLength="13"
                        required
                      />
                      {errors.phone && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.phone}
                        </span>
                      )}
                    </div>

                    <div className="col-md-4 mb-3">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Password
                      </label>
                      <input
                        className="custom-date-picker"
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        required
                      />
                      {/* {errors.password && <span className="error" style={{ color: 'red' }}>{errors.password}</span>} */}
                    </div>
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Confirm Password
                      </label>
                      <input
                        className="custom-date-picker"
                        type="password"
                        name="password"
                        placeholder=" Confirm Password"
                        onChange={handleChange}
                        required
                      />
                      {/* {errors.password && <span className="error" style={{ color: 'red' }}>{errors.password}</span>} */}
                    </div>
{/* 
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Pin
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="pin"
                        placeholder="Pin"
                        onChange={handleChange}
                        required
                      />
                      {errors.pin && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.pin}
                        </span>
                      )}
                    </div> */}
                    <div className="col-md-4 mb-3">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Ein*
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="ein"
                        maxLength={9}
                        placeholder="Ein"
                        onChange={handleChange}
                        required
                      />
                      {errors.ein && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.ein}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        SSN*
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="ssn"
                        maxLength={9}
                        placeholder="SSN"
                        onChange={handleChange}
                        required
                      />
                      {errors.ssn && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.ssn}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Email*
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        required
                      />
                      {errors.email && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.email}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Mid
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="mid"
                        placeholder="Mid"
                        onChange={handleChange}
                        required
                      />
                      {errors.mid && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.mid}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Commision Type
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="cms_type"
                        placeholder="Commision Type"
                        onChange={handleChange}
                        required
                      />
                      {errors.cms_type && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.cms_type}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Commision Account
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="cms_acct"
                        placeholder="Commision Account"
                        onChange={handleChange}
                        required
                      />
                      {errors.cms_acct && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.cms_acct}
                        </span>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        User-Name
                      </label>
                      <input
                        className="custom-date-picker"
                        name="user_name"
                        type="text"
                        placeholder="User Name"
                        onChange={handleChange}
                        required
                      />
                      {errors.user_name && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.user_name}
                        </span>
                      )}
                    </div>

                    {/* <div className="col-md-4 mb-3">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Store-Slug
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        placeholder="Store-Slug"
                        name="store_slug"
                        onChange={handleChange}
                        required
                      />
                      {errors.store_slug && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.store_slug}
                        </span>
                      )}
                    </div> */}
                    {/* <div className="col-md-4">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Estore-Slug
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        placeholder="Estore-Slug"
                        name="Estore_Slug"
                        onChange={handleChange}
                        required
                      />
                      {errors.Estore_Slug && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.Estore_Slug}
                        </span>
                      )}
                    </div> */}
                    {/* <div className="col-md-12">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Account Manager
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        placeholder="Account Manager"
                        name="account_manager"
                        onChange={handleChange}
                        required
                      />
                      {errors.account_manager && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.account_manager}
                        </span>
                      )}
                    </div> */}

                    <div className="">
                      <h4 className="app-page-title mt-5">
                        Beneficiary Details
                      </h4>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        First name
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="beneficiary_fname"
                        placeholder="First name"
                        onChange={handleChange}
                        required
                      />
                      {errors.beneficiary_fname && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.beneficiary_fname}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Last name
                      </label>
                      <input
                        className="custom-date-picker"
                        type="text"
                        name="beneficiary_lname"
                        placeholder="Last name"
                        onChange={handleChange}
                        required
                      />
                      {errors.beneficiary_lname && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.beneficiary_lname}
                        </span>
                      )}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label
                        className="labelStyle"
                        style={{ display: "block" }}
                      >
                        Date Of Birth*
                      </label>
                      <DatePicker
                        name="dob"
                        selected={formattedDate(formData.date_of_birth)}
                        onChange={handleDOBChange}
                        className="custom-date-picker"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="YYYY-MM-DD"
                      />
                      {errors.date_of_birth && (
                        <span className="error" style={{ color: "red" }}>
                          {errors.date_of_birth}
                        </span>
                      )}
                    </div>

                    <div className="heading-container">
                      <div className="row">
                        <div className="heading-text">
                          <h4 className="app-page-title mt-5">Other Details</h4>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Business name
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="business_name"
                            value={formData.business_name}
                            placeholder="Business name"
                            onChange={handleChange}
                            required
                          />
                          {errors.business_name && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.business_name}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="labelStyle">Business Email</label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="business_email"
                            value={formData.business_email}
                            placeholder="Business email"
                            onChange={handleChange}
                          />
                          {errors.business_email && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.business_email}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label
                            className="labelStyle"
                            htmlFor="industrySelect"
                            style={{
                              display: "block",
                              width: "11.3vw",
                            }}
                          >
                            Industry
                          </label>
                          <select
                            className="custom-date-picker"
                            id="industrySelect"
                            onChange={handleIndustryChange}
                            // defaultValue=""
                            value={selectedIndustry}
                            name="business_industry"
                          >
                            <option value="">Select Industry</option>
                            {industries.map((industry) => (
                              <option
                                value={industry.value}
                                key={industry.value}
                              >
                                {industry.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Website
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="website"
                            value={website}
                            placeholder="Website link"
                            onChange={handleUrlChange}
                            onClick={handleUrlInputClick}
                            required
                          />
                          {errors.website && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.website}
                            </span>
                          )}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Business Address
                          </label>
                          <PlacesAutocomplete
                            value={businessAddress}
                            onChange={(text) => {
                              setBusinessAddress(text);
                            }}
                            onSelect={handleBusinessSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  className="custom-date-picker"
                                  {...getInputProps({
                                    placeholder: "Search owner address",
                                  })}
                                  required
                                />
                                <div>
                                  {loading ? <div>Loading...</div> : null}
                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41b6e6"
                                        : "#fff",
                                      border: suggestion.active
                                        ? "1px solid #41b6e6"
                                        : "1px solid #ccc",
                                      width: "410px",
                                      marginLeft: "10px", // Add margin right
                                      marginTop: "5px",
                                    };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          {errors.address && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.address}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            City
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            placeholder="City"
                            name="business_city"
                            value={businessCity}
                            onChange={handleChange}
                          />
                          {errors.business_city && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.business_city}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            State
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="business_state"
                            value={businessState}
                            placeholder="State"
                            onChange={handleChange}
                          />
                          {errors.business_state && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.business_state}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Country
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="business_country"
                            value={businessCountry}
                            placeholder="Country"
                            onChange={handleChange}
                          />

                          {errors.business_country && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.business_country}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Zip Code
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            placeholder="Zip Code"
                            name="business_zip"
                            value={businessZip}
                            onChange={handleChange}
                          />
                          {errors.business_zip && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.business_zip}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                           Established Date
                          </label>
                          <DatePicker
                            name="established_date"
                            selected={formattedDate(formData.established_date)}
                            onChange={handleEstChange}
                            className="custom-date-picker"
                            dateFormat="yyyy-MM-dd"
                            placeholderText="YYYY-MM-DD"
                          />
                          {errors.business_zip && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.business_zip}
                            </span>
                          )}
                        </div>


                        <div className="col-md-6">
                          <label
                            className="labelStyle"
                            style={{ display: "block" }}
                          >
                            Ownership Percent (%)*
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="ownership_percent"
                            value={formData.ownership_percent}
                            placeholder="Ownership Percent"
                            onChange={handleChange}

                            required
                          />
                          {errors.ownership_percent && (
                            <span className="error" style={{ color: "red" }}>
                              {errors.ownership_percent}
                            </span>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="labelStyle" style={{ display: "block" }}>
                            Products Sold
                          </label>
                          <input
                            className="custom-date-picker"
                            type="text"
                            name="legal_productssold"
                            value={formData.legal_productssold}
                            placeholder="Products Sold"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <label className="labelStyle" style={{ display: "block" }}>
                            Company Structure
                          </label>
                          <select
                            className="custom-date-picker"
                            name="business_structure"
                            value={formData.business_structure}
                            onChange={handleChange}
                            placeholder="Select Company Structure"
                          >
                            <option value="">Select Company Structure</option>
                            <option value="Sole Prop">Sole Prop</option>
                            <option value="LLC">LLC</option>
                            <option value="Private Corporation">Private Corporation</option>
                            <option value="Public Corporation">Public Corporation</option>
                            <option value="Non profit">Non Profit</option>
                            <option value="limited liability partnership">Limited  Liability Partnership</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label className='labelStyle' style={{ display: "block" }} >Processing Environment*
                          </label>
                          <select
                            className='custom-date-picker'
                            name="processing_environment"
                            value={formData.processing_environment}
                            onChange={handleChange}
                            placeholder='Select Processing Environment'
                          >
                            <option value="">Select Processing Environment</option>
                            <option value="cardPresent">Card Present</option>
                            <option value="ecommerce">E-Commerce</option>
                            <option value="fuel">Fuel</option>
                            <option value="moto">Mail Order/Telephone Order</option>
                            <option value="restaurant">Restaurant</option>
                            <option value="serviceStation">Service Station</option>
                            <option value="supermarket">Supermarket</option>

                          </select>
                        </div>

                        {errors.processing_environment && (
                          <span className="error" style={{ color: "red" }}>
                            {errors.processing_environment}
                          </span>
                        )}



                      </div>
                
                
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Average Ticket
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      name="avgTicket"
                      value={formData.avgTicket}
                      placeholder="($)"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Annual Credit Card Sales
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      name="annualCCSales"
                      value={formData.annualCCSales}
                      placeholder="($)"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="labelStyle" style={{ display: "block" }}>
                      B2C Transactions
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="a. Cumulative to 100%"
                      name="legal_b2c"
                      value={formData.legal_b2c}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      B2B Transactions
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="a. Cumulative to 100%"
                      name="legal_b2b"
                      value={formData.legal_b2b}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Card Present
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="b. Cumulative to 100%"
                      name="legal_cp"
                      value={formData.legal_cp}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Manual Entry via Phone/Email
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="b. Cumulative to 100%"
                      name="legal_cnpphoneemail"
                      value={formData.legal_cnpphoneemail}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Manual Entry via Internet
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="b. Cumulative to 100%"
                      name="legal_cnpinternet"
                      value={formData.legal_cnpinternet}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Same-Day Delivery
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="c. Cumulative to 100%"
                      name="legal_percentdeliveredsameday"
                      value={formData.legal_percentdeliveredsameday}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Deferred Delivery {">"} 7 Days
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="c. Cumulative to 100%"
                      name="percentdelievered7days"
                      value={formData.percentdelievered7days}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Deferred Delivery {">"} 14 Days
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="c. Cumulative to 100%"
                      name="percentdelivered14days"
                      value={formData.percentdelivered14days}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Deferred Delivery {">"} 30 Days
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="c. Cumulative to 100%"
                      name="legal_percentdeliveredover30days"
                      value={formData.legal_percentdeliveredover30days}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Routing number*
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="Routing number"
                      name="routing_number"
                      value={formData.routing_number}
                      onChange={handleChange}
                      required
                    />

                    {errors.routing_number && (
                      <span className="error" style={{ color: "red" }}>
                        {errors.routing_number}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Bank Name
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="Bank name"
                      name="bank_name"
                      value={formData.bank_name}
                      onChange={handleChange}
                    />
                    {errors.bank_name && (
                      <span className="error" style={{ color: "red" }}>
                        {errors.bank_name}
                      </span>
                    )}
                  </div>

                  <div className="col-md-6">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Account Number*
                    </label>
                    <input
                      className="custom-date-picker"
                      type="text"
                      placeholder="Account Number"
                      name="account_number"
                      value={formData.account_number}
                      onChange={handleChange}
                      required
                    />
                    {errors.account_number && (
                      <span className="error" style={{ color: "red" }}>
                        {errors.account_number}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="labelStyle" style={{ display: "block" }}>
                      Account Type
                    </label>
                    <select
                      className="custom-select"
                      name="account_method"
                      value={formData.account_method}
                      onChange={handleChange}
                      placeholder="Select Bank Account Type"
                    >
                      <option value="">Select Bank Account Type</option>
                      <option value="8">Personal checking account</option>
                      <option value="9">Personal saving account</option>
                      <option value="10">Corporate checking account</option>
                      <option value="11">Corporate saving account</option>
                    </select>
                  </div>
                  <div className="row d-flex  justify-content-center align-align-items-center my-4">
                    <DragNDrop
                      onFilesSelected={setFiles}
                      width=" auto"
                      height="auto"
                    />
                  </div>
                  <div className="row">
                    <div className="col  d-flex justify-content-center">
                      <button
                        onClick={handleSubmit}
                        className="btn btn-primary"
                      >
                        {loading ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MerchantOnboard;
