import axios from 'axios';
import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import baseUrl from '../baseUrl';
import Loader from '../DefaultComponent/Loader';
import logo from "../assets/images/Logo_Fractal_Icon_Black.svg";
import merchantlogo from "../assets/images/merchantlogo.png";
import ReCAPTCHA from 'react-google-recaptcha'
import { CheckDomain } from '../hooks/CheckDomain';

const EmailVerification = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const token = location?.state;
    const [captchaValue, setCaptchaValue] = useState('');
    const [captchaError, setCaptchaError] = useState(false);
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(60);
    const [resend, setResend] = useState({ btn: false, timer: true });
    const timer = localStorage.getItem("timer");
    const user = localStorage.getItem("local-data");
    const [otpError, setOtpError] = useState(false);

    const handleOtpChange = (otpvalue) => {
        console.log(otpvalue)
        if (/^[0-9]*$/.test(otpvalue)) {
            setOtp(otpvalue);
            setOtpError(false);
        } else {
            setOtp(otpvalue);
            setOtpError(true);
        }
    };

    useEffect(() => {
        if (timer > 0 && timer) {
            setSeconds(timer);
        }
        else if (timer == 0) {
            setSeconds(0);
        }
    }, [timer]);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds > 0) {
                    localStorage.setItem("timer", prevSeconds - 1);
                    return prevSeconds - 1;
                } else {
                    clearInterval(interval);
                    setResend((pre) => ({ ...pre, timer: false }))
                    return 0;
                }
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);


    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 20);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}s`;
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        if (!captchaValue) return setCaptchaError(true);

        if (otp.length != 6) {
            toast.error("Invalid Code");
            setOtpError(true);
            return;
        }
        try {
            setOtpError(false);
            setLoading(true);
            let resp = await axios.post(`${baseUrl}/api/v1/merchant/account/register/verifyOtp`, {
                otp: otp,
                token,
            },
            );
            if (resp?.status == 200 && resp?.data?.result == true) {
                localStorage.removeItem("timer");
                console.log("resonse urll===========", resp);

                window.location.href = resp?.data?.data?.onboardingUrl;
            }
            else if (resp?.status == 200 && resp?.data?.result == false) {
                setOtpError(true);
                toast.error(resp?.data?.message);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error(error?.response?.data?.message);
        }

    }

    // handle resend otp
    const handleResendOtp = async () => {
        if (timer > 0) return;
        if (!captchaValue) return setCaptchaError(true);
        try {
            setLoading(true);
            let resp = await axios.post(`${baseUrl}/api/v1/merchant/account/register/resendOtp`, {
                token,
            },
            );
            if (resp.status == 200 && resp?.data?.result == true) {
                toast.success("Email verification code resent");
                let data = JSON.stringify(resp?.data);
                localStorage.setItem("local-data", data)
                if (seconds == 0) {
                    setSeconds(60);
                }
            } else if (resp?.status == 200 && resp?.data?.result == false) {
                setOtpError(true);
                toast.error(resp?.data?.message);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message)
            console.log("error", error);
        }
    }
    // handle recaptcha
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        setCaptchaError(false);
    };
    return (

        <>
            <div className="app app-login p-0">
                <div className="row g-0 app-auth-wrapper">
                    <div className="col-12 col-md-6 col-lg-6 auth-main-col log-left otp-mn-sec">
                        <div className="d-flex flex-column align-content-end">
                            <div className="app-auth-body mx-auto">
                                <div className="app-auth-branding mb-5 app-logo">
                                    {
                                        CheckDomain() ? (
                                            <img className="logo-icon me-2" style={{ width: '200px' }} src={merchantlogo} alt="logo" />
                                        ) : (
                                            <img className="logo-icon me-2" src={logo} alt="logo" />
                                        )
                                    }
                                </div>
                                <div className='d-flex align-items-center' style={{ marginTop: "0px" }}>
                                    <div className="otp-container">
                                        <form onSubmit={handleVerify}>

                                            <div className="row">
                                                <h3>Email Verification</h3>
                                                <p className='fs-14'>Please enter the <strong>Verification Code</strong> that was delivered to your email. </p>
                                            </div>
                                            <div className="d-flex justify-content-start align-items-center my-3 otp-inputs">
                                                <OtpInput
                                                    value={otp}
                                                    onChange={handleOtpChange}
                                                    numInputs={6}
                                                    containerStyle={`${otpError ? "otp-container-error" : "otp-container-idle"}`}
                                                    renderSeparator={<span className='mx-1'></span>}
                                                    renderInput={(props) => <input {...props} className='otp-input' placeholder='•' />}
                                                />

                                            </div>
                                            <div className="row mb-2">
                                                <div className="col didnt-send">
                                                    <p>Didn't get the code?
                                                        <strong
                                                            className="text-dark-purp cursor-pointer"
                                                            onClick={handleResendOtp}
                                                        >
                                                            Resend code
                                                        </strong>
                                                        in
                                                        <span className={`${seconds > 10 ? "text-success" : "text-danger"} ps-1`}>
                                                            {formatTime(seconds)}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <ReCAPTCHA
                                                        sitekey="6Lc5yLcpAAAAAGmxkAx0XZEdkPNJ-NzQsA4XHuPs"
                                                        onChange={handleCaptchaChange}
                                                    />
                                                </div>
                                                {captchaError && <small className="text-danger">Captcha required</small>}
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <button className='verifi-btn ' type='submit'>Verify</button>

                                                    <button className='cncl-btn' type='button' onClick={() => navigate("/login", { replace: true })}>Cancel</button>
                                                </div>

                                            </div>
                                        </form>

                                    </div>
                                    <Loader loading={loading} />

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 auth-background-col">
                        <div className="auth-background-holder"></div>
                        <div className="auth-background-overlay p-3 p-lg-5">
                            <div className="d-flex flex-column align-content-end">
                                <div className="h-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


export default EmailVerification