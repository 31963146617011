import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import baseUrl from '../../baseUrl';
import { toast } from 'react-toastify';
import logo from "../../assets/images/Logo_Fractal_Icon_Black.svg";
import ReCAPTCHA from 'react-google-recaptcha'

const SuperForgotPasswordEmailSend = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [bot_key, setBot_key] = useState('');
    const [loading, setLoading] = useState(false);
    const [captchaValue, setCaptchaValue] = useState('');
    const [captchaError, setCaptchaError] = useState(false);
    // Handle changes to email input field
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // Handle email submission to trigger password reset
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        if (!email.trim()) {
            toast.error("Email is required");
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(
                `${baseUrl}/api/v1/super-admin/auth/forgot-password`,
                {
                    email: email,
                    bot_key
                }, // Body
                {
                    // headers: {
                    //     Authorization: `Bearer ${token}`, // Optionally add the token
                    // },
                }
            );

            if (response?.data?.result === true) {
                toast.success(response.data.message);
                setStep(2);
            } else {
                toast.error(response.data.message || "Failed to send reset link.");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message || "Error sending reset link.");
        }
    };
    // handle recaptcha
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        setCaptchaError(false);

        console.log("==========capchata===", value);


    };
    return (
        <div className="app app-login p-0">
            <div className="row g-0 app-auth-wrapper">
                <div className="col-12 col-md-6 col-lg-6 auth-main-col log-left otp-mn-sec">
                    <div className="d-flex flex-column align-content-end">
                        <div className="app-auth-body mx-auto">
                            <div className="app-auth-branding mb-5 app-logo">
                                <img className="logo-icon me-2" src={logo} alt="logo" />
                            </div>

                            <div className="back-button mb-3">
                                <button
                                    className="btn btn-link text-black-50 fs-6 text-decoration-none"
                                    onClick={() => navigate(-1)}
                                    style={{ fontWeight: 'regular', fontSize: '1rem', textDecoration: 'none' }}
                                >
                                    <i className="fas fa-arrow-left me-2"></i> Back
                                </button>
                            </div>

                            <h2 className="auth-heading mt-5">Reset your password</h2>

                            <div className="auth-form-container text-start mt-3">
                                {step === 1 && (
                                    <form className="auth-form login-form" onSubmit={handleEmailSubmit}>
                                        <div className="email mb-3">
                                            <label>Email Address</label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                className="form-control signin-email"
                                                placeholder="Enter email address"
                                                value={email}
                                                onChange={handleEmailChange}
                                                autoComplete='off'
                                            />
                                        </div>
                                        <input type='text' id='bot_key' name='bot_key' value={bot_key} onChange={(e) => setBot_key(e.target.value)} style={{ display: 'none' }} />
                                        <div className="row">
                                            <div className="col">
                                                <ReCAPTCHA
                                                    sitekey="6Lc5yLcpAAAAAGmxkAx0XZEdkPNJ-NzQsA4XHuPs"
                                                    onChange={handleCaptchaChange}
                                                />
                                            </div>
                                            {captchaError && <small className="text-danger">Captcha required</small>}
                                        </div>
                                        <button className="btn app-btn-primary w-100" type="submit" disabled={loading}>
                                            {loading ? "Sending Reset Link..." : "Send Reset Link"}
                                        </button>
                                    </form>
                                )}

                                {step === 2 && (
                                    <div className="mb-3">
                                        <h4>Password reset link has been sent to your email.</h4>
                                        <p>Please check your inbox (or spam folder) and click the link to reset your password.</p>
                                        <button className="btn app-btn-primary w-100" onClick={() => navigate("/super-admin-login")}>
                                            Back to Login
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuperForgotPasswordEmailSend
