import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar";
import MerchantDashoard from "../merchant/MerchantDashoard";
import Footer from "./Footer";
import MerchantTransaction from "../merchant/MerchantTransaction";
import DigitalPos from "../merchant/DigitalPos";
import Quickpayment from "../merchant/quickpayment/Quickpayment";
import MerchantDashboard from "../merchant/MerchantDashboard/MerchantDashboard";
import PaymentDetails from "../pages/Payments/Detail/PaymentDetails";

const MerchantRoutes = () => {
    return (
        <div className=" ">
            <Sidebar />
            <div className="app-wrapper">
                <div className="app-content ">
                    <div className="container-xl">
                        <div className="white-box-main">
                            <div className="" id="style-4">
                                <Routes>
                                    <Route path="/transactions" element={<MerchantTransaction />} />
                                    <Route path="/transactions/:id" element={<PaymentDetails />} />
                                    <Route path="/profile" element={<DigitalPos />} />
                                    <Route path="/quick-payment" element={<Quickpayment />} />
                                    <Route path="/" element={<MerchantDashboard />} />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default MerchantRoutes