import React from "react";
import "../assets/css/loader.css";

const Loader = (props) => {
  return (
    <>
      {props.loading && (
        <div className="loader">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
