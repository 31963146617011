// // Custom number formatting function
// export const numberFormat = (value, flag) => {
//     if (isNaN(value)) return "0"; // Handle edge case if value is not a number
    
//     const formatterWithDecimals = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
//     const formatterWithoutDecimals = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

//     if (flag === true) {
//         // Trillion
//         if (Number(value) >= 1e12) {
//             console.log(("hello",Number(value) / 1e12).toFixed(2) + "T");
//             return (Number(value) / 1e12).toFixed(2) + "T";
//         }
//         // Billion
//         else if (Number(value) >= 1e9) {
//             return (Number(value) / 1e9).toFixed(2) + "B";
//         }
//         // Million
//         else if (Number(value) >= 1e6) {
//             return (Number(value) / 1e6).toFixed(2) + "M";
//         }
//         else if (Number(value) >= 1e3) {
//             return (Number(value) / 1e3).toFixed(2) + "K";
//         }
//         // For numbers below a million, just return the formatted number without abbreviation
//         else {
            
//             return Number.isInteger(Number(value))
//                 ? formatterWithoutDecimals.format(Number(value))
//                 : formatterWithDecimals.format(Number(value));
//         }
//     } else {
//         // console.log("Number(value)Number(value)", Number(value));
        
//         // Trillion
//         if (Number(value) >= 1e12) {
//             return formatterWithDecimals.format(Number(value) / 1e12) + "T";
//         }
//         // Billion
//         else if (Number(value) >= 1e9) {
//             return formatterWithDecimals.format(Number(value) / 1e9) + "B";
//         }
//         // Million
//         else if (Number(value) >= 1e6) {
//             return formatterWithDecimals.format(Number(value) / 1e6) + "M";
//         }
//         else if (Number(value) >= 1e3) {
//             return formatterWithDecimals.format(Number(value) / 1e3) + "K";
//         }
//         // For numbers below a million, just return the formatted number without abbreviation
//         else {
//             return Number.isInteger(Number(value))
//                 ? formatterWithoutDecimals.format(Number(value))
//                 : formatterWithDecimals.format(Number(value));
//         }
//     }
// };


export const numberFormat = (value, flag) => {
    if (isNaN(value)) return "0"; // Handle edge case if value is not a number

    const formatterWithDecimals = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const formatterWithoutDecimals = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

    if (flag === true) {
        // Trillion
        if (Number(value) >= 1e12) {
            return Math.floor(Number(value) / 1e12) + "T";
        }
        // Billion
        else if (Number(value) >= 1e9) {
            return Math.floor(Number(value) / 1e9) + "B";
        }
        // Million
        else if (Number(value) >= 1e6) {
            return Math.floor(Number(value) / 1e6) + "M";
        }
        // Thousand
        else if (Number(value) >= 1e3) {
            return Math.floor(Number(value));
        }
        // For numbers below a thousand, just return the formatted number without abbreviation
        else {
            return Number.isInteger(Number(value))
                ? formatterWithoutDecimals.format(Number(value))
                : formatterWithDecimals.format(Number(value));
        }
    } else {
        // Trillion
        if (Number(value) >= 1e12) {
            return formatterWithoutDecimals.format(Math.floor(Number(value) / 1e12)) + "T";
        }
        // Billion
        else if (Number(value) >= 1e9) {
            return formatterWithoutDecimals.format(Math.floor(Number(value) / 1e9)) + "B";
        }
        // Million
        else if (Number(value) >= 1e6) {
            return formatterWithoutDecimals.format(Math.floor(Number(value) / 1e6)) + "M";
        }
        // Thousand
        else if (Number(value) >= 1e3) {
            return formatterWithoutDecimals.format(Math.floor(Number(value) / 1e3)) + "K";
        }
        // For numbers below a thousand, just return the formatted number without abbreviation
        else {
            return Number.isInteger(Number(value))
                ? formatterWithoutDecimals.format(Number(value))
                : formatterWithDecimals.format(Number(value));
        }
    }
};



