import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./Request.css";
import axios from "axios";
import forge from "node-forge";
import baseUrl from "../../../baseUrl";

const Payment = () => {
  const [state, setState] = useState({
    show: false,
    publicKey: "",
    sessionKey: "",
    cardNumber: "",
    year: "",
    month: "",
    cvv: "",
    zip: "",
    amount: "",
  });
  const [isValid, setIsValid] = useState(false);
  const [isValidMonth, setIsValidMonth] = useState(false);
  const [data, setData] = useState(null);
  const handlePaymentClick = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/widget/generate-session`,
        {}
      );
      const data = response.data;
      if (data && data?.result === true) {
        setState((prev) => ({
          ...prev,
          show: true,
          publicKey: data?.data?.publicKeyPem,
          sessionKey: data?.data?.session_key,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitPay = async () => {
    let track2_data = `${state.cardNumber}=${state.month}${state.year} ${state.cvv}`;
    const publicKey = forge.pki.publicKeyFromPem(state.publicKey);

    const encrypted = publicKey.encrypt(track2_data, "RSA-OAEP", {
      md: forge.md.sha1.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    });

    const encryptedBase64 = forge.util.encode64(encrypted);

    const requestBody = {
      enc_track2_data: encryptedBase64,
      session_key: state.sessionKey,
      algorithm: "RSAES_OAEP_SHA_1",
    };

    const apiUrl =
      "https://m1ao5pku8b.execute-api.us-east-2.amazonaws.com/prod/tokenizer/tokenize";

    // const headers = {
    //   "x-app-session-key": state.sessionKey,
    //   "Content-Type": "application/json",
    //   Accept: "application/json",
    //   "Access-Control-Allow-Origin": "*",
    // };

    // try {
    //   const response = await axios.post(
    //     "https://m1ao5pku8b.execute-api.us-east-2.amazonaws.com/prod/tokenizer/tokenize",
    //     requestBody,
    //     {
    //       headers: {
    //         "x-app-session-key":
    //           "sk_UufaUureioecIg6mKaRriylsM4V4NO5lDZR5Erh180CHD0OS5609OspTNQfOoxV7NRCkzldOE9SSIAcyMPs=",
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );

    try {
      // const response = await axios.post(apiUrl, requestBody, { headers });
      // console.log("Response:", response.data);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", apiUrl);
      xhr.setRequestHeader("x-app-session-key", state.sessionKey);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onload = function () {
        if (xhr.status === 200) {
          setData(JSON.parse(xhr.responseText));
        }
      };
      xhr.send(JSON.stringify(requestBody));
      // xhr.send();
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }

    // console.log(response.data);
    // return response.data.data;
    //   return resp
    //     .status(200)
    //     .json({ result: true, message: "Tokenizer", data: response.data });
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value;
    if (/^\d{0,19}$/.test(value)) {
      // let formatted = formatCard(value);
      // if (formatted.length <= 19) {
      setState((prev) => ({
        ...prev,
        cardNumber: value,
      }));
      // }

      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  // function formatCard(cardNumber) {
  //   let formattedNumber = "";
  //   for (let i = 0; i < cardNumber.length; i++) {
  //     if (i > 0 && i % 4 === 0) {
  //       formattedNumber += " ";
  //     }
  //     formattedNumber += cardNumber[i];
  //   }
  //   return formattedNumber;
  // }

  const handleMonthChange = (e) => {
    setIsValid(false);
    let value = e.target.value;
    if (
      /^\d{0,2}$/.test(value) &&
      (value === "" || (parseInt(value, 10) >= 1 && parseInt(value, 10) <= 12))
    ) {
      setState((prev) => ({
        ...prev,
        month: value,
      }));
      setIsValidMonth(false);
    } else {
      setIsValidMonth(true);
    }
  };

  const handleYearChange = (e) => {
    setIsValid(false);
    setIsValidMonth(false);
    let value = e.target.value;
    if (
      /^\d{0,2}$/.test(value) &&
      (value === "" || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 99))
    ) {
      setState((prev) => ({
        ...prev,
        year: value,
      }));
    }
  };

  const handleCVVChange = (e) => {
    setIsValid(false);
    setIsValidMonth(false);
    let value = e.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setState((prev) => ({
        ...prev,
        cvv: value,
      }));
    }
  };

  const handleZIP = (e) => {
    setIsValid(false);
    setIsValidMonth(false);
    let value = e.target.value;
    if (/^\d{0,7}$/.test(value)) {
      setState((prev) => ({
        ...prev,
        zip: value,
      }));
    }
  };

  const handleAmount = (e) => {
    setIsValid(false);
    setIsValidMonth(false);
    let value = e.target.value;
    value = value.replace(/[^0-9.]/g, "");
    let parts = value.split(".");
    if (parts.length > 2) {
      parts = [parts.shift(), parts.join(".")];
      value = parts.join("");
    }
    if (parts.length === 2 && parts[1].length > 2) {
      value = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    setState((prev) => ({
      ...prev,
      amount: value,
    }));
  };

  function amountFormat(amt) {
    if (amt) {
      return `${parseFloat(amt).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    }
    return "";
  }

  return (
    <div className="">
      <button
        className="export-btn"
        style={{ width: "83px" }}
        onClick={() => handlePaymentClick()}
      >
        Payment
      </button>
      <Modal
        show={state.show}
        onHide={() =>
          setState((prev) => ({
            ...prev,
            show: false,
          }))
        }
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="payment-popup">
            <div className="row">
              <div className="">
                <div id="Checkout" className="inline">
                  <div className="header">
                    <h1>Pay</h1>
                  </div>

                  <form id="PaymentForm" className="payment-form">
                    {/* <input
                      type="hidden"
                      name="description"
                      value="widget payment"
                    /> */}
                    <div className="form-group" style={{ display: "none" }}>
                      <label>Payment amount</label>
                      <div className="input-group">
                        <span className="input-group-addon">$</span>
                        <input
                          type="text"
                          name="amount"
                          value="1"
                          className="form-control"
                          id=""
                        />
                      </div>
                      <span
                        id="amount-error"
                        style={{ color: "red", display: "none" }}
                      ></span>
                    </div>

                    {/* <div className="form-group" style={{ display: "none" }}>
                      <label for="">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone_number"
                        value="1234567908"
                      />
                      <span
                        id="phone-number-error"
                        style={{ color: "red", display: "none" }}
                      ></span>
                    </div> */}

                    <div className="form-group">
                      <label>Name on card</label>
                      <input
                        id="NameOnCard"
                        className="form-control required"
                        type="text"
                        maxLength="100"
                        placeholder="Name"
                      />
                      <span
                        id="NameOnCard-error"
                        style={{ color: "red", display: "none" }}
                      ></span>
                    </div>

                    <div className="form-group">
                      <input
                        data-token="card_number"
                        className="null card-image form-control required"
                        type="text"
                        maxLength="19"
                        value={state.cardNumber}
                        placeholder="0000 0000 0000 0000"
                        onChange={(e) => handleCardNumberChange(e)}
                      />
                      {isValid && (
                        <span id="card_number-error" style={{ color: "red" }}>
                          Please input numeric characters only
                        </span>
                      )}
                    </div>

                    <div className="expiry-date-group form-group">
                      <input
                        data-token="exp_month"
                        className="form-control required"
                        type="text"
                        placeholder="MM"
                        value={state.month}
                        maxLength="2"
                        onChange={(e) => handleMonthChange(e)}
                      />
                      {isValidMonth && (
                        <span
                          id="card_number-error"
                          style={{ color: "red", fontSize: "15px" }}
                        >
                          Please write month only 1 to 12
                        </span>
                      )}
                    </div>

                    <div className="expiry-date-group form-group">
                      <input
                        data-token="exp_year"
                        className="form-control required"
                        type="text"
                        placeholder="YY"
                        maxLength="2"
                        value={state.year}
                        onChange={(e) => handleYearChange(e)}
                      />

                      <span
                        id="exp_year-error"
                        style={{ color: "red", display: "none" }}
                      ></span>
                    </div>

                    <div className="security-code-group form-group">
                      <div className="input-container">
                        <input
                          data-token="cvv"
                          className="form-control required"
                          type="text"
                          maxLength="4"
                          value={state.cvv}
                          placeholder="CVV"
                          onChange={(e) => handleCVVChange(e)}
                        />

                        <i id="cvc" className="fa fa-question-circle"></i>
                        <span
                          id="cvv-error"
                          style={{ color: "red", display: "none" }}
                        ></span>
                      </div>
                      <div className="cvc-preview-container two-card hide">
                        <div className="amex-cvc-preview"></div>
                        <div className="visa-mc-dis-cvc-preview"></div>
                      </div>
                    </div>

                    <div className="zip-code-group form-group">
                      <label>Postal code</label>
                      <div className="input-container">
                        <input
                          id="ZIPCode"
                          className="form-control required"
                          name="zip"
                          type="text"
                          maxLength="7"
                          placeholder="000000"
                          value={state.zip}
                          onChange={(e) => handleZIP(e)}
                        />

                        <a
                          tabIndex="0"
                          role="button"
                          data-toggle="popover"
                          data-trigger="focus"
                          data-placement="left"
                          data-content="Enter the ZIP/Postal code for your credit card billing address."
                        >
                          <i className="fa fa-question-circle"></i>
                        </a>
                        <span
                          id="ZIPCode-error"
                          style={{ color: "red", display: "none" }}
                        >
                          This field is required
                        </span>
                      </div>
                    </div>

                    <div className="form-group top-amnt">
                      <div>
                        <label>Payment amount</label>
                        <div className="amount-placeholder">
                          <input
                            id="Amount"
                            className="form-control required"
                            name="amount"
                            type="text"
                            placeholder=""
                            value={state.amount}
                            onChange={(e) => handleAmount(e)}
                          />
                        </div>
                      </div>
                      <div className="card-row">
                        <span className="visa"></span>
                        <span className="mastercard"></span>
                        <span className="amex"></span>
                        <span className="discover"></span>
                      </div>
                    </div>
                    {/* 
                    <input
                      type="button"
                      id="submitButton"
                      onclick={handleSubmitPay}
                      className="btn btn-block btn-success submit-button"
                      value="Pay"
                    /> */}
                    <button
                      type="button"
                      id="submitButton"
                      onClick={() => handleSubmitPay()}
                      className="btn btn-block btn-success submit-button"
                    >
                      Pay
                    </button>
                  </form>
                  {/* <img src="https://fractalpay.com/static/media/MTrends1.e4ebfe2316d410344a5b.png" className="powered-logo"> --> */}
                  {/* <!-- <img src="https://fractalpay.com/static/media/fractalfull.3d8af9622f2c9446fce36a88e6359077.svg" alt="Fractal logo" height="50" width="200" className="powered-logo"> --> */}
                  <div className="powerd-by-part">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 26 26"
                    >
                      <path
                        fill="currentColor"
                        d="M23.633 5.028a1.074 1.074 0 0 0-.777-.366c-2.295-.06-5.199-2.514-7.119-3.477C14.551.592 13.768.201 13.18.098a1.225 1.225 0 0 0-.36.001c-.588.103-1.371.494-2.556 1.087c-1.92.962-4.824 3.416-7.119 3.476a1.08 1.08 0 0 0-.778.366a1.167 1.167 0 0 0-.291.834c.493 10.023 4.088 16.226 10.396 19.831c.164.093.346.141.527.141s.363-.048.528-.141c6.308-3.605 9.902-9.808 10.396-19.831a1.161 1.161 0 0 0-.29-.834zM18.617 8.97l-5.323 7.855c-.191.282-.491.469-.788.469c-.298 0-.629-.163-.838-.372l-3.752-3.753a.656.656 0 0 1 0-.926l.927-.929a.658.658 0 0 1 .926 0l2.44 2.44l4.239-6.257a.657.657 0 0 1 .91-.173l1.085.736a.657.657 0 0 1 .174.91z"
                      ></path>
                    </svg>
                    Secure payments powered by Fractal
                    <img
                      src="https://ui.fractalpay.com/favicon.ico"
                      alt="Fractal logo"
                      className="powered-logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Payment;
