import React from 'react'
import { GetPaymentPage, RequestPayment, RequestPaymentDynamic } from 'fractalpay';

const QuickPayProd = ({ amount, selectedOption, fractalpayClientKey, unformattedGrandTotal, sendRequestPayment }) => {
    return (
        <div className="conditional-render text-center pt-5">
            {amount && selectedOption === 'charge' && (
                <GetPaymentPage fractalpayClientKey={fractalpayClientKey} amount={unformattedGrandTotal} />
            )}
            {selectedOption === 'request' && (
                <RequestPayment fractalpayClientKey={fractalpayClientKey} />
            )}
            {amount && selectedOption === 'qr' && (
                // <GetPaymentPage fractalpayClientKey={fractalpayClientKey} amount={unformattedGrandTotal} />
                <button className='paymentBtn' onClick={() => sendRequestPayment(fractalpayClientKey, unformattedGrandTotal)}>Send Request</button>
            )}
        </div>
    )
}

export default QuickPayProd
