import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import "../../assets/css/drag.css";
import { toast } from "react-toastify";
const DragNDrop = ({ onFilesSelected, width, height }) => {
  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      let newFiles = Array.from(selectedFiles);

      newFiles = newFiles?.filter((file) => {
        if (file.size > 5 * 1024 * 1024) {
          toast.error("File size should be less than 5mb");
          return false;
        }
        return true;
      });

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      let newFiles = Array.from(droppedFiles);
      newFiles = newFiles?.filter((file) => {
        if (file.size > 5 * 1024 * 1024) {
          toast.error("File size should be less than 5mb");
          return false;
        }
        return true;
      });
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
  }, [files, onFilesSelected]);

  return (
    <section
      className="drag-drop"
      style={{ width: width, height: height }}
      htmlFor="browse"
    >
      <input
        type="file"
        hidden
        id="browse"
        onChange={handleFileChange}
        accept=".pdf,.docx,.png,.jpg,.jpeg"
        multiple
      />
      <div
        className={`document-uploader ${
          files.length > 0 ? "upload-box active" : "upload-box"
        }`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <label htmlFor="browse">
          <div className="upload-info">
            <AiOutlineCloudUpload />
            <div>
              <p>Drag and drop your docs here</p>
              <p>
                Limit 5MB per file. Supported files: .PDF, .PNG, .JPG,
                .JPEG,
              </p>
            </div>
          </div>
          <div className="browse-btn">Browse files</div>
        </label>

        {files.length > 0 && (
          <div className="file-list">
            <div className="file-list__container">
              {files.map((file, index) => {
                return (
                  <div className="file-item" key={index}>
                    <div className="file-info">
                      <p>{file.name}</p>
                      {/* <p>{file.type}</p> */}
                    </div>
                    <div className="file-actions">
                      <MdClear onClick={() => handleRemoveFile(index)} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {files.length > 0 && (
          <div className="success-file">
            <AiOutlineCheckCircle
              style={{ color: "#6DC24B", marginRight: 1 }}
            />
            <p>{files.length} file(s) selected</p>
          </div>
        )}
      </div>
      <small></small>
    </section>
  );
};

export default DragNDrop;
