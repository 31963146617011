export const PaymentGateway = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
            <path fill="currentColor" d="M13.5 12.423q-.846 0-1.423-.577q-.577-.577-.577-1.423q0-.846.577-1.423q.577-.577 1.423-.577q.846 0 1.423.577q.577.577.577 1.423q0 .846-.577 1.423q-.577.577-1.423.577Zm-6.192 3.192q-.667 0-1.141-.474q-.475-.475-.475-1.141V6.846q0-.666.475-1.14q.474-.475 1.14-.475h12.385q.667 0 1.141.474t.475 1.141V14q0 .666-.475 1.14q-.474.475-1.14.475H7.307Zm1-1h10.384q0-.67.475-1.143q.474-.472 1.14-.472V7.846q-.67 0-1.143-.474q-.472-.475-.472-1.141H8.308q0 .67-.475 1.143q-.474.472-1.14.472V13q.67 0 1.143.475q.472.474.472 1.14Zm10.038 4H4.308q-.667 0-1.141-.474q-.475-.475-.475-1.141V8.192h1V17q0 .23.193.423q.192.192.423.192h14.038v1Zm-11.038-4h-.616V6.231h.616q-.25 0-.433.182q-.183.183-.183.433V14q0 .25.183.433q.183.182.433.182Z" />
        </svg>
    )

}

export const FileIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 24 32"
        >
            <g fill="#35254D">
                <path d="M1.5 32h21c.827 0 1.5-.673 1.5-1.5v-21c0-.017-.008-.031-.009-.047c-.002-.023-.008-.043-.013-.065a.488.488 0 0 0-.09-.191c-.007-.009-.006-.02-.013-.029l-8-9c-.003-.003-.007-.003-.01-.006a.494.494 0 0 0-.223-.134c-.019-.006-.036-.008-.056-.011C15.557.012 15.53 0 15.5 0h-14C.673 0 0 .673 0 1.5v29c0 .827.673 1.5 1.5 1.5zM16 1.815L22.387 9H16.5c-.22 0-.5-.42-.5-.75V1.815zM1 1.5a.5.5 0 0 1 .5-.5H15v7.25c0 .809.655 1.75 1.5 1.75H23v20.5a.5.5 0 0 1-.5.5h-21c-.28 0-.5-.22-.5-.5v-29z" />
                <path d="M5.5 14h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0 0 1zm0 4h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0 0 1zm0-8h6a.5.5 0 0 0 0-1h-6a.5.5 0 0 0 0 1zm0 12h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0 0 1zm0 4h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0 0 1z" />
            </g>
        </svg>
    )
}