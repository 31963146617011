import React from 'react'
import { Link } from 'react-router-dom'
import AccordianData from '../AccordianData'

const GetCustomers = ({handleActiveTab}) => {
    const head = {
        "Content-Type": "application/json",
        "Authorization": "Basic keyinfo=="
    }
    const body = {
        "merchant_key": "(merchant_key)"
    }
  return (
      <>
          <div
              className="relative grow overflow-hidden mx-auto  lg:-ml-12 lg:pl-[23.7rem]"
              id="content-area"
          >
              <header id="header" className="relative">
                  <div className="mt-0.5 space-y-2.5">
                      <div className="eyebrow h-5 text-primary dark:text-primary-light text-sm font-semibold">
                          Customers
                      </div>
                      <div className="flex items-center">
                          <h1 className="inline-block text-2xl sm:text-3xl font-extrabold text-gray-900 tracking-tight dark:text-gray-200">
                              Get customers
                          </h1>
                      </div>
                  </div>
              </header>
              {/* <AccordianData header={head} body={body} /> */}
              <div className="relative mt-8 prose prose-gray dark:prose-invert" />
              <div className="leading-6 mt-14">
                  <div className="mb-12 px-0.5 flex items-center text-sm font-semibold text-gray-700 dark:text-gray-200">
                      <Link to={"/docs/api/get-customer"}
                          className="flex items-center space-x-3 group"
                          onClick={()=>handleActiveTab("/docs/api/get-customer")}
                      >
                          <svg
                              viewBox="0 0 3 6"
                              className="h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                          >
                              <path
                                  d="M3 0L0 3L3 6"
                                  fill="none"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                              />
                          </svg>
                          <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                              Get Customer
                          </span>
                      </Link>
                      <Link to={"/docs/api/request-card"}
                          className="flex items-center ml-auto space-x-3 group"
                          onClick={()=>handleActiveTab("/docs/api/request-card")}
                      >
                          <span className="group-hover:text-gray-900 dark:group-hover:text-white">
                              Request Card
                          </span>
                          <svg
                              viewBox="0 0 3 6"
                              className="rotate-180 h-1.5 stroke-gray-400 overflow-visible group-hover:stroke-gray-600 dark:group-hover:stroke-gray-300"
                          >
                              <path
                                  d="M3 0L0 3L3 6"
                                  fill="none"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                              />
                          </svg>
                      </Link>
                  </div>
                  <footer className="justify-between pt-10 border-t border-gray-100 sm:flex dark:border-gray-800/50 pb-28">
                      <div className="flex mb-6 space-x-6 sm:mb-0">
                          {/* <a
                              
                          >
                              <span className="sr-only">linkedin</span>
                              <svg
                                  className="w-5 h-5 bg-gray-400 dark:bg-gray-500 hover:bg-gray-500 dark:hover:bg-gray-400"
                                  style={{
                                      maskImage:
                                          'url("https://mintlify.b-cdn.net/v6.5.1/brands/linkedin.svg")',
                                      maskRepeat: "no-repeat",
                                      maskPosition: "center center"
                                  }}
                              />
                          </a> */}
                      </div>
                      <div className="sm:flex">
                          <a
                              
                              rel="noreferrer"
                              className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
                          >
                              Fractal Pay
                          </a>
                      </div>
                  </footer>
              </div>
          </div>
          <div
              className="z-10 hidden xl:flex flex-none pl-10 w-[19rem]"
              id="table-of-contents"
          />
      </>

  )
}

export default GetCustomers