import axios from "axios";
import baseUrl from "../baseUrl";

export const createPublicKey = async (token, body) => {
  const response = await axios.post(
    `${baseUrl}/api/v1/order/generate-hash`,
    body,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      },
    }
  );
  if (!response.data.result) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const getActiveLog = async (token, params) => {
  const response = await axios.get(`${baseUrl}/api/v1/admin/log/get-allLog`, {
    headers: {
      Authorization: token,
    },
    params,
  });
  return response.data;
};

export const checkRoutesPermission = async (token, id) => {
  const response = await axios.get(`${baseUrl}/api/v1/admin/auth/check-access`, {
    headers: {
      Authorization: token,
      menu_id: id,
    },
  });
  return response?.data;
};
