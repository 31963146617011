import React, { useEffect, useState } from 'react'
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { useLocation } from 'react-router-dom';
import BackBtn from './BackBtn';
import Loader from '../../DefaultComponent/Loader';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import './pdfviewer.css';

const PdfViewer = () => {
    const locationstate = useLocation()
    const { state, pathname } = locationstate || {}
    const [isLoading, setLoading] = useState(true)
    const [pdfUrl, setPdfUrl] = useState()

    const zoomPluginInstance = zoomPlugin();
    const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;
    const fullScreenPluginInstance = fullScreenPlugin();
    const { EnterFullScreenButton } = fullScreenPluginInstance;

    const base64ToBlobUrl = (base64pdf) => {
        try {
            const decodeBase64 = (base64) => {
                const binaryString = window.atob(base64);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }

                return bytes.buffer;
            };

            const pdfBuffer = decodeBase64(base64pdf);

            const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });

            const pdfUrl = URL.createObjectURL(pdfBlob);

            if (typeof setPdfUrl === 'function') {
                setPdfUrl(pdfUrl);
            }

            setLoading(false)
            return pdfUrl;
        } catch (error) {
            setLoading(false)
            console.error('Error converting base64 to Blob URL:', error);
        }
    };

    useEffect(() => {
        if (state) {
            base64ToBlobUrl(state?.pdf)
        }
    }, [])

    // console.log(isLoading, "loading");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <div className='container-xl'>
            <div className='row d-flex align-item-center' >
                <div className="col-md-4 ">
                    <BackBtn />
                </div>
                <div className='col-md-6'>
                    <p className="heading">{state?.name}</p>
                </div>
            </div>
            <Worker workerUrl={"https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"} >
                <div className='file-viewer'>
                    {/* Zoom and Full Screen Controls */}
                    <div className='controls' >
                        <ZoomOut>
                            {(props) => (
                                <button className='zoom' onClick={props.onClick}>
                                    -
                                </button>
                            )}
                        </ZoomOut>
                        <CurrentScale>
                            {(props) => (
                                <span style={{ margin: '0 8px', color: '#121212' }}>
                                    {`${Math.round(props.scale * 100)}%`}
                                </span>
                            )}
                        </CurrentScale>
                        <ZoomIn>
                            {(props) => (
                                <button className='zoom' onClick={props.onClick}>
                                    +
                                </button>
                            )}
                        </ZoomIn>
                        <span className='full-screen'>
                            <EnterFullScreenButton />
                        </span>
                    </div>
                    {pdfUrl && (
                        <Viewer fileUrl={pdfUrl} plugins={[zoomPluginInstance, fullScreenPluginInstance]} />
                    )}
                </div>
            </Worker>
            <Loader loading={isLoading} />
        </div>
    )
}

export default PdfViewer